import React, { useEffect, useState } from "react";
import { TopHeader } from "../TopHeader/TopHeader";
import { EventDetails } from "./EventDetails/EventDetails";
import { Financials } from "./Financials/Financials";
import { Seatings } from "./Seatings/Seatings";
import styles from "./createEvent.module.css";
import { useNavigate } from "react-router-dom";
import { EventConfig } from "../EventConfig";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DiscardModal } from "../DiscardModal/DiscardModal";
import dayjs from "dayjs";
import { loadData } from "../../Utils/localStorage";
import { UploadImageForm } from "../../Components/UploadImageForm/UploadImageForm";
import { AddSeatCategorization } from "./AddSeatCategorization/AddSeatCategorization";
import { RegistrationDetail } from "./RegistrationDetail/Audience/RegistrationDetail";

import { useSelector } from "react-redux";
import { RegistrationDetailPage } from "./RegistrationDetail/Participant/RegistrationDetailPage";
import { RegistrationFieldModal } from "./RegistrationDetail/Audience/RegistrationFieldModal/RegistrationFieldModal";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  IS_REGISTRATION_FLOW,
  SHOW_REGISTRATION_PAGE,
} from "../../Utils/moduleConfig";
dayjs.extend(utc);
dayjs.extend(timezone);

export const CreateEvent = () => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const effectiveTimeZone = profileDetails.comm_time_zone || dayjs.tz.guess();
  //images
  const [imagesEvent, setImagesEvent] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [imagesEventHomeCover, setImagesEventHomeCover] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = React.useState([]);
  const [createEvent, setCreateEvent] = useState({
    name: "",
    description: "",
    // venue: "",
    instructions: "",
    eventStatus: "OPEN",
    eventType: "Conference",
    startDateTime: "",
    endDateTime: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    hasArtist: false,
    guest: [],
    // language: "",
    // suitableAgeGroup: "",
    seatingCapacity: "",
    seatingStyle: "OPEN",
    maxNoOfSeats: "",
    accessibleTo: "ALL",
    isSeatingCategorization: "0",
    waitingList: "",
    bookingType: "PAID",
    bookingPrice: "",
    tax: "0",
    cancellationAllowed: 1,
    cancellationPolicy: "",
    eventImages: [],
    bookingPriceGuest: "",
    allowBookingAfterStartTimePassed: 0,
    invoiceDescription: "",
    startDateTimeRegistration: "",
    endDateTimeRegistration: "",
  });
  const [markCoverId, setMarkCoverId] = useState(0);
  useEffect(() => {
    const hasRefreshed = localStorage.getItem("hasRefreshed");
    if (!hasRefreshed) {
      localStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
    // Clean up on component unmount
    return () => {
      localStorage.removeItem("hasRefreshed");
    };
  }, []);
  useEffect(() => {
    value
      ? setCreateEvent({
          ...createEvent,
          startDate: value[0],
          endDate: value[1],
        })
      : setCreateEvent({
          ...createEvent,
          startDate: "",
          endDate: "",
        });
  }, [value]);

  // guests/artists --------------------------
  const [guest, setGuest] = useState([
    {
      guestName: "",
      data_url: "",
    },
  ]);

  // suitable age group dropdown
  const [suitableAgeGroups, setSuitableAgeGroups] = useState([
    {
      value: "",
      label: "Select",
    },
  ]);
  const [selectedSuitableAgeGroups, setSelectedSuitableAgeGroups] =
    useState(null);

  const handleSuitableAgeGroupsChange = (selectedOption) => {
    setSelectedSuitableAgeGroups(selectedOption);
  };

  // venue dropdown
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState();
  const [newVenueName, setNewVenueName] = useState();
  const [isVenueLoading, setisVenueLoading] = useState(false);

  const handleVenueChange = (selectedOption) => {
    setSelectedVenue(selectedOption);
  };
  // Handle creation of a new option
  const handleCreateVenue = (newVenue) => {
    setisVenueLoading(true);
    setSelectedVenue("");
    fetch(`${process.env.REACT_APP_API_URL}/api/venue/create_event_venue`, {
      method: "POST",
      body: JSON.stringify({
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
        name: newVenue,
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.status_code == 200) {
          setNewVenueName(newVenue);
          getData();
        } else {
          toast.error(res.message);
        }
        setisVenueLoading(false);
      })
      .catch((er) => {
        setisVenueLoading(false);
        toast.error("something went wrong");
      });
  };

  // language dropdown
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };

  // event types dropdown
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState("");

  const handleEventTypesChange = (selectedOption) => {
    setSelectedEventTypes(selectedOption);
  };

  // event status radio
  const [eventStatus, setEventStatus] = useState({});

  // seat types
  const [seatTypes, setSeatTypes] = useState({});

  // accessible to
  const [accessibleTo, setAccessibleTo] = useState({});

  // booking types radio
  const [bookingTypes, setBookingTypes] = useState({});

  useEffect(() => {
    setCreateEvent((prevEvent) => ({
      ...prevEvent,
      bookingType:
        process.env.REACT_APP_PRODUCT === "DLF"
          ? "PAID"
          : profileDetails?.event_payment
          ? "PAID"
          : "FREE",
    }));

    getData();
  }, [profileDetails]);

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get-data/`, {
      method: "POST",
      body: JSON.stringify({
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        const languageOptions = res?.data?.languages?.map((language) => ({
          value: language.id,
          label: language.name,
        }));
        setLanguages(languageOptions);

        const audienceOptions = res?.data?.audience?.map((audience) => ({
          value: audience.id,
          label: audience.name,
        }));

        setSuitableAgeGroups([...suitableAgeGroups, ...audienceOptions]);

        const eventTypeOptions = res?.data?.eventTypes?.map((eventTypes) => ({
          value: eventTypes.id,
          label: eventTypes.name,
        }));
        setEventTypes(eventTypeOptions);
        const venueOptions = res?.data?.venue?.map((venues) => ({
          value: venues?.id,
          label: venues?.name,
        }));
        setVenues(venueOptions);
        const isNewVenue = venueOptions?.filter((e) => e.label == newVenueName);
        if (isNewVenue?.length) {
          setSelectedVenue(isNewVenue[0]);
        }
      })
      .catch((err) => {});
  };
  const handleCreateEventChange = (e) => {
    const { name, value, type, checked } = e.target;
    type == "checkbox"
      ? checked
        ? setCreateEvent({
            ...createEvent,
            [name]: [...createEvent.accessibleTo, value],
          })
        : setCreateEvent({
            ...createEvent,
            [name]: createEvent.accessibleTo.filter((e) => e !== value),
          })
      : name == "bookingPrice" ||
        name == "waitingList" ||
        name == "seatingCapacity" ||
        name == "maxNoOfSeats" ||
        name == "bookingPriceGuest"
      ? setCreateEvent({
          ...createEvent,
          [name]: value.replace(/[^0-9]/g, ""),
        })
      : setCreateEvent({
          ...createEvent,
          [name]: value,
        });
  };
  const handleBookingType = (e) => {
    const { name, value } = e.target;
    if (value == "FREE") {
      setCreateEvent({
        ...createEvent,
        [name]: value,
        isSeatingCategorization: 0,
      });
    } else {
      setCreateEvent({
        ...createEvent,
        [name]: value,
      });
    }
  };
  //function for timepicker
  const handleTimePicker = (e, timeName) => {
    setCreateEvent({
      ...createEvent,
      [timeName]: moment(e.$d).format("h:mm A"),
    });
  };
  const handleDateTimePicker = (e, Name) => {
    setCreateEvent({
      ...createEvent,
      [Name]: dayjs(e.$d).format("YYYY-MM-DD h:mm A"),
    });
  };
  // function for calenderComponent
  const handleCallbackforClosingOn = (date) => {
    setCreateEvent({
      ...createEvent,
      ["startDate"]: date[0],
      ["endDate"]: date[1],
    });
  };
  const [inputFields, setInputFields] = useState([
    {
      duration: "",
      deduction: "",
    },
  ]);

  useEffect(() => {
    setCreateEvent({
      ...createEvent,
      eventImages: imagesEvent,
    });
  }, [imagesEvent]);

  const handleSubmit = () => {
    let currTime = dayjs().format("h:mm A");
    let currNewDate = new Date();
    let sDate = new Date(createEvent?.startDateTime);
    let eDate = new Date(createEvent?.endDateTime);
    let tempImagesArr1 = [];

    imagesEvent.map((i, index) => {
      markCoverId == index
        ? tempImagesArr1.push({
            ...i,
            is_main: true,
          })
        : tempImagesArr1.push({
            ...i,
            is_main: false,
          });
    });
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      name: createEvent?.name,
      venue_id: selectedVenue?.value,
      description: createEvent?.description,
      instructions: createEvent?.instructions,
      status: createEvent?.eventStatus,
      type: selectedEventTypes?.value,
      event_start_time: moment(createEvent?.startDateTime).format("hh:mm A"),
      event_end_time: moment(createEvent?.endDateTime).format("hh:mm A"),
      start_at: dayjs(createEvent?.startDateTime).format("YYYY-MM-DD hh:mm A"),
      end_at: dayjs(createEvent?.endDateTime).format("YYYY-MM-DD hh:mm A"),
      has_artist: 0,
      language: selectedLanguage?.value,
      target_audience: selectedSuitableAgeGroups?.value,
      total_seats: createEvent?.seatingCapacity,
      seat_type: createEvent?.seatingStyle,
      max_per_member_seats: createEvent?.maxNoOfSeats,
      accessible_to: createEvent?.accessibleTo,
      has_seating_category: parseInt(createEvent?.isSeatingCategorization),
      waiting_list:
        parseInt(createEvent?.isSeatingCategorization) == 0 &&
        createEvent?.waitingList !== ""
          ? createEvent?.waitingList
          : 0,
      booking_type: createEvent?.bookingType,
      price:
        createEvent?.bookingType == "PAID" &&
        createEvent?.isSeatingCategorization == 0 &&
        createEvent?.bookingPrice,
      tax: createEvent?.tax,
      cancellation_allowed: createEvent?.cancellationAllowed,
      images: tempImagesArr1,
    };
    if (parseInt(createEvent?.isSeatingCategorization) == 1) {
      payload["seats"] =
        createEvent?.isSeatingCategorization == 1 && seatCategory;
    }
    let tempGuest = createEvent?.guest
      ?.filter((i, index) => {
        return i.guestName !== "" && i.data_url !== "" ? i : "";
      })
      .map((i, index) => {
        return {
          name: i.guestName,
          image: i.data_url,
        };
      });
    if (tempGuest?.length > 0) {
      payload["has_artist"] = 1;
    }
    if (tempGuest?.length > 0 && payload["has_artist"] == true) {
      payload["artists"] = tempGuest;
    }
    let tempCancellation = inputFields?.filter((i, index) => {
      return i.duration !== "" && i.deduction !== "" ? i : "";
    });
    if (tempCancellation?.length > 0 && createEvent?.cancellationAllowed == 1) {
      payload["cancellation"] = tempCancellation;
    }
    if (imagesEventHomeCover?.length > 0) {
      payload["homescreen_image"] = imagesEventHomeCover[0].path;
    }

    if (createEvent?.name == "") {
      toast.error("Please enter Event name");
    } else if (createEvent?.name?.length < 3) {
      toast.error("Please enter valid Event name");
    } else if (
      Object.keys(selectedVenue)?.length <= 0 ||
      selectedVenue?.value == ""
    ) {
      toast.error("Please select Event venue");
    } else if (createEvent?.description == "") {
      toast.error("Please Enter Synopsis");
    } else if (createEvent?.description?.length < 30) {
      toast.error("Minimum Synopsis Length should be 30");
    } else if (createEvent?.description?.length > 10000) {
      toast.error("Synopsis length should be less than 10,000");
    } else if (createEvent?.instructions?.length > 10000) {
      toast.error("T&C length should be less than 10,000");
    } else if (createEvent?.eventStatus == "") {
      toast.error("Event status cannot be empty");
    } else if (createEvent?.startDateTime == "") {
      toast.error(" Start Date Time cannot be empty ");
    } else if (sDate < currNewDate) {
      toast.error("Plese Select Valid Start Date Time ");
    } else if (createEvent?.endDateTime == "") {
      toast.error(" End Date Time cannot be empty ");
    } else if (sDate >= eDate) {
      toast.error("End Date Time should be greater than Start Date Time");
    } else if (
      Object.keys(selectedLanguage)?.length <= 0 ||
      selectedLanguage?.value == ""
    ) {
      toast.error("Please select language");
    } else if (createEvent?.seatingStyle == "") {
      toast.error("Seating Style cannot be empty");
    } else if (createEvent?.accessibleTo == "") {
      toast.error("Accessibility cannot be empty");
    } else if (createEvent?.seatingCapacity == "") {
      toast.error("Seating Capacity cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.seatingCapacity != "" &&
      createEvent?.seatingCapacity !=
        seatCategory.reduce((acc, curr) => acc + parseInt(curr?.total), 0)
    ) {
      toast.error("Seating capacity does not match total seat categorization");
    } else if (createEvent?.seatingCapacity < 1) {
      toast.error("Please Enter valid Seating Capacity");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      (seatCategory == undefined || seatCategory.length == 0)
    ) {
      toast.error("Please add a new category");
    } else if (
      createEvent?.waitingList !== "" &&
      parseInt(createEvent?.waitingList) < 0
    ) {
      toast.error("Please enter valid Waiting List");
    } else if (
      createEvent?.waitingList !== "" &&
      parseInt(createEvent?.waitingList) >
        parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error("Waiting List cannot be greater than Seating Capacity");
    } else if (createEvent?.maxNoOfSeats == "") {
      toast.error("Max. number of seats per membership cannot be empty");
    } else if (parseInt(createEvent?.maxNoOfSeats) < 1) {
      toast.error("Please enter valid Max. number of seats per membership");
    } else if (
      parseInt(createEvent?.maxNoOfSeats) >
      parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error(
        "Max. number of seats per membership cannot be greater than Seating Capacity"
      );
    } else if (createEvent?.bookingType == "") {
      toast.error("Booking Type cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice == ""
    ) {
      toast.error("Booking Price cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice < 1
    ) {
      toast.error("Please Enter valid Booking Price");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.bookingType == "PAID" &&
      seatCategory.every((category) => {
        return (
          category.price == null ||
          category.price == undefined ||
          category.price == ""
        );
      })
    ) {
      toast.error("Price per category cannot be empty");
    } else if (createEvent?.eventImages?.length < 1) {
      toast.error("Upload Image is Mandatory");
    } else if (imagesEventHomeCover?.length < 1) {
      toast.error("Home Screen Cover Images is Mandatory");
    } else {
      setIsDisabled(true);

      // create event API
      fetch(process.env.REACT_APP_API_URL + "/api/event/create", {
        method: "POST",
        body: JSON.stringify(payload, {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
        }),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      })
        .then((d) => d.json())
        .then((res) => {
          if (res.status_code == 101) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else if (res.status_code == 102) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else if (res?.success == false) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else if (res?.success == true) {
            toast(`${res?.message}`, {
              type: "success",
            });
            navigate({
              pathname: process.env.REACT_APP_PATH + "/events",
              search: `?event_id=${res?.data?.event?.id}`,
            });
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
        });
    }
  };
  // ----------------------imgae
  const [images, setImages] = React.useState([]);
  const [imagesHomeCover, setImagesHomeCover] = React.useState([]);
  const [imagesSplGuest0, setImagesSplGuest0] = React.useState([]);
  const [imagesSplGuest1, setImagesSplGuest1] = React.useState([]);
  const [imagesSplGuest2, setImagesSplGuest2] = React.useState([]);
  // ------------------------
  const [discardModal, setDiscardModal] = useState(false);
  const onOpenDiscardModal = () => setDiscardModal(true);
  const onCloseDiscardModal = () => setDiscardModal(false);

  //seat categorization---------------------------------------
  const [seatCategorizationModal, setSeatCategorizationModal] = useState(false);
  const [seatCategory, setSeatCategory] = useState([]);

  const addSeatCategory = (categoryName, isWaitingList) => {
    setSeatCategory([
      ...seatCategory,
      isWaitingList == "yes"
        ? {
            id: 1,
            name: categoryName,
            total: "",
            waiting: "0",
            price: "",
          }
        : {
            id: 1,
            name: categoryName,
            total: "",
            price: "",
          },
    ]);
  };

  const removeSeatCategory = (index) => {
    const rows = [...seatCategory];
    rows.splice(index, 1);
    setSeatCategory(rows);
  };
  // ------------------------------------------------------------------------------------------
  // for registration detail
  //registrationModal
  const [showParticipantReg, setShowParticipantReg] = useState(false);
  const [payloadSecondry, setPayloadSecondry] = useState("");
  const [registrationFieldModal, setRegistrationFieldModal] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState([]);

  const [
    participantRegistrationFieldModal,
    setParticipantRegistrationFieldModal,
  ] = useState(false);
  const [activeRegistrationBtn, setActiveRegistrationBtn] = useState("");
  const [dropDownFieldType, setDropDownFieldType] = useState([]);
  const [audienceForm, setAudienceForm] = useState([]);
  const [selectedAudienceForm, setSelectedAudienceForm] = useState({});
  const [selectedAudienceFormDetails, setSelectedAudienceFormDetails] =
    useState([]);
  const [selectedParticipantForm, setSelectedParticipantForm] = useState({});
  const handleChangeAudienceDropdown = (selectedOption) => {
    setSelectedAudienceForm(selectedOption);
    getSelecteFormDetailApi(selectedOption.value);
  };
  const addParticipantRegistrationField = (newFields, existingformName) => {
    if (existingformName) {
      let updatedForms = [...selectedAudienceFormDetails, ...newFields];
      setSelectedAudienceFormDetails(updatedForms);
    }

    setParticipantRegistrationFieldModal(false);
  };
  const getFormList = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      request_from: "event_audience",
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get_exiting_form_list/`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setAudienceForm(
            res?.data?.Form_list?.map((i, index) => {
              return {
                label: i?.form_name,
                value: i?.id,
              };
            })
          );
        }
      })
      .catch((err) => {});
  };
  const getInputTypeList = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/event/get_request_form_custom_fields/`,
      {
        method: "POST",

        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setDropDownFieldType(
            Object.keys(res?.data).map((i, index) => {
              return {
                label: res?.data[i],
                value: i,
              };
            })
          );
        }
      })
      .catch((err) => {});
  };

  const getSelecteFormDetailApi = (formId) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      form_id: formId,
      request_from: "event_audience",
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get_form_details_by_id`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setSelectedAudienceFormDetails(
            res?.data?.map((i, index) => {
              return {
                label: i?.field_name,
                value: "",
                type: i?.field_type,
                fieldOrder: i?.event_form_id,
                isMandatory: i?.is_mandatory,
                options: i?.options,
                isVisible: i?.is_visible,
                isDelete: i?.is_deleted,
                formFieldId: i?.form_field_id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.error("API response error:", err.message || "Unknown error");
      });
  };

  useEffect(() => {
    if (IS_REGISTRATION_FLOW) {
      getFormList();
      getInputTypeList();
    }
  }, []);
  //   -------------------------------------------------
  useEffect(() => {
    value
      ? setCreateEvent({
          ...createEvent,
          startDate: value[0],
          endDate: value[1],
        })
      : setCreateEvent({
          ...createEvent,
          startDate: "",
          endDate: "",
        });
  }, [value]);

  //-------------------------Ac--------------------------------
  const [showGuest, setShowGuest] = useState(true);
  //guest category------------------------------
  const [guestSeatCategoryBtn, setGuestSeatCategoryBtn] = useState(true);
  const [guestSeatCategory, setGuestSeatCategory] = useState([]);
  //-------------------------------------------
  const addSeatCategoryAc = (categoryName, total, waiting) => {
    setSeatCategory([
      ...seatCategory,
      {
        id: 1,
        name: categoryName,
        total: total,
        waiting: waiting,
        price: "",
        guest_price: "",
      },
    ]);
  };
  const handleSubmitAc = () => {
    let currTime = dayjs().format("h:mm A");
    let currNewDate = new Date();
    let sDate = dayjs(createEvent?.startDateTime, "YYYY-MM-DD hh:mm A");
    sDate = sDate.$d;
    let eDate = dayjs(createEvent?.endDateTime, "YYYY-MM-DD hh:mm A");
    eDate = eDate.$d;
    let tempImagesArr1 = [];
    imagesEvent.map((i, index) => {
      markCoverId == index
        ? tempImagesArr1.push({
            ...i,
            is_main: true,
          })
        : tempImagesArr1.push({
            ...i,
            is_main: false,
          });
    });
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      login_user_id: profileDetails?.login_user_id,
      name: createEvent?.name,
      venue_id: selectedVenue?.value,
      description: createEvent?.description,
      instructions: createEvent?.instructions,
      status: createEvent?.eventStatus,
      type: selectedEventTypes?.value,
      event_start_time: dayjs(createEvent?.startDateTime, "YYYY-MM-DD hh:mm A")
        .tz(effectiveTimeZone)
        .format("hh:mm A"),
      event_end_time: dayjs(createEvent?.endDateTime, "YYYY-MM-DD hh:mm A")
        .tz(effectiveTimeZone)
        .format("hh:mm A"),
      start_at: dayjs(createEvent?.startDateTime, "YYYY-MM-DD hh:mm A")
        .tz(effectiveTimeZone)
        .format("YYYY-MM-DD hh:mm A"),
      end_at: dayjs(createEvent?.endDateTime, "YYYY-MM-DD hh:mm A")
        .tz(effectiveTimeZone)
        .format("YYYY-MM-DD hh:mm A"),
      has_artist: 0,
      language: selectedLanguage?.value,
      target_audience: selectedSuitableAgeGroups?.value,
      total_seats: createEvent?.seatingCapacity,
      seat_type: createEvent?.seatingStyle,
      max_per_member_seats: createEvent?.maxNoOfSeats,
      accessible_to: createEvent?.accessibleTo,
      has_seating_category: parseInt(createEvent?.isSeatingCategorization),
      waiting_list: 0,
      booking_type: createEvent?.bookingType,
      price:
        createEvent?.bookingType == "PAID" &&
        createEvent?.isSeatingCategorization == 0 &&
        createEvent?.bookingPrice,
      tax: createEvent?.tax,
      cancellation_allowed: 0,
      images: tempImagesArr1,
      guest_price_status: showGuest ? 1 : 0,
      guest_no_category: guestSeatCategoryBtn ? 1 : 0,
      allow_booking_after_start_time_passed:
        createEvent?.allowBookingAfterStartTimePassed,
    };
    if (createEvent?.bookingType == "PAID") {
      payload["invoice_description"] = createEvent?.invoiceDescription;
    }
    if (parseInt(createEvent?.isSeatingCategorization) == 1) {
      payload["seats"] =
        createEvent?.isSeatingCategorization == 1 && seatCategory;
    }
    // validations
    let tempGuest = createEvent?.guest
      ?.filter((i, index) => {
        return (
          i.guestName !== "" ||
          (i.data_url !== "" && i.data_url !== undefined && i.data_url !== null)
        );
      })
      .map((i, index) => {
        return {
          name: i.guestName,
          image: i.data_url,
        };
      });
    if (tempGuest?.length > 0) {
      payload["has_artist"] = 1;
    }
    if (tempGuest?.length > 0 && payload["has_artist"] == true) {
      payload["artists"] = tempGuest;
    }
    let tempCancellation = inputFields?.filter((i, index) => {
      return i.duration !== "" && i.deduction !== "" ? i : "";
    });
    if (tempCancellation?.length > 0 && createEvent?.cancellationAllowed == 1) {
      payload["cancellation"] = tempCancellation;
    }
    if (imagesEventHomeCover?.length > 0) {
      payload["homescreen_image"] = imagesEventHomeCover[0].path;
    }
    if (createEvent.isSeatingCategorization == 0 && !guestSeatCategoryBtn) {
      payload["guest_price"] = createEvent.bookingPriceGuest;
    }
    if (Object.keys(selectedAudienceForm)?.length > 0) {
      let tempForm = selectedAudienceFormDetails?.map((i, index) => {
        let tempOption = i?.options
          ?.filter((option) => option.label !== "")
          ?.map((j, idx) => {
            return j?.label;
          });
        return {
          field_name: i?.label,
          field_type: i?.type?.value,
          is_mandatory: i?.isMandatory,
          options: tempOption?.length > 0 ? tempOption : [],
          is_visible: i?.isVisible,
          is_deleted: i?.isDelete,
          status: i?.status ? 1 : 0,
          form_field_id: i?.formFieldId,
          field_order: index,
        };
      });
      payload["audience_form_id"] = selectedAudienceForm?.value;
      payload["audience_form"] = tempForm;
    }
    if (createEvent?.name == "") {
      toast.error("Please enter Event name");
    } else if (createEvent?.name?.length < 3) {
      toast.error("Please enter valid Event name");
    } else if (createEvent?.description == "") {
      toast.error("Please Enter Description");
    } else if (createEvent?.description?.length < 30) {
      toast.error("Minimum Description Length should be 30");
    } else if (createEvent?.description?.length > 700) {
      toast.error("Description length should be less than 700");
    } else if (createEvent?.instructions?.length > 500) {
      toast.error("Instruction length should be less than 500");
    } else if (createEvent?.eventStatus == "") {
      toast.error("Event status cannot be empty");
    } else if (createEvent?.startDateTime == "") {
      toast.error(" Start Date Time cannot be empty ");
    } else if (sDate < currNewDate) {
      toast.error("Plese Select Valid Start Date Time ");
    }
    // else if (createEvent?.startDateTime < currDate) {
    //     toast.error("Plese Select Valid Start Date Time ");
    // }
    else if (createEvent?.endDateTime == "") {
      toast.error(" End Date Time cannot be empty ");
    } else if (sDate >= eDate) {
      toast.error("End Date Time should be greater than Start Date Time");
    } else if (
      (Object.keys(selectedLanguage)?.length <= 0 ||
        selectedLanguage?.value == "") &&
      process.env.REACT_APP_PRODUCT == "DLF"
    ) {
      toast.error("Please select language");
    } else if (
      tempGuest?.length > 0 &&
      payload["has_artist"] == true &&
      tempGuest?.some((category) => {
        return (
          category.name == null ||
          category.name == undefined ||
          category.name == ""
        );
      })
    ) {
      toast.error("Enter valid artist name");
    } else if (
      tempGuest?.length > 0 &&
      payload["has_artist"] == true &&
      tempGuest?.some((category) => {
        return (
          category.image == null ||
          category.image == undefined ||
          category.image == ""
        );
      })
    ) {
      toast.error("Please upload artist image");
    } else if (createEvent?.seatingStyle == "") {
      toast.error("Seating Style cannot be empty");
    } else if (createEvent?.accessibleTo == "") {
      toast.error("Accessibility cannot be empty");
    } else if (createEvent?.seatingCapacity == "") {
      toast.error("Seating Capacity cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.seatingCapacity != "" &&
      createEvent?.seatingCapacity !=
        seatCategory.reduce((acc, curr) => acc + parseInt(curr?.total), 0)
    ) {
      toast.error("Seating capacity does not match total seat categorization");
    } else if (createEvent?.seatingCapacity < 1) {
      toast.error("Please Enter valid Seating Capacity");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      (seatCategory == undefined || seatCategory.length == 0)
    ) {
      toast.error("Please add a new category");
    }
    //else if (
    //   createEvent?.waitingList !== "" &&
    //   parseInt(createEvent?.waitingList) < 0
    // ) {
    //   toast.error("Please enter valid Waiting List");
    // }
    else if (
      createEvent?.waitingList !== "" &&
      parseInt(createEvent?.waitingList) >
        parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error("Waiting List cannot be greater than Seating Capacity");
    } else if (createEvent?.maxNoOfSeats == "") {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Max. Number of Seats Per Occupant cannot be empty"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Max. Number of Seats Per Employee cannot be empty"
            : "Max. number of seats per unit cannot be empty"
        }`
      );
    } else if (parseInt(createEvent?.maxNoOfSeats) < 1) {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Please enter valid Max. number of seats per occupant"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Please enter valid Max. number of seats per employee"
            : "Please enter valid Max. number of seats per unit"
        }`
      );
    } else if (
      parseInt(createEvent?.maxNoOfSeats) >
      parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Max. number of seats per occupant cannot be greater than seating capacity"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Max. number of seats per employee cannot be greater than Seating Capacity"
            : "Max. number of seats per unit cannot be greater than seating capacity"
        }`
      );
    } else if (createEvent?.bookingType == "") {
      toast.error("Booking Type cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice == ""
    ) {
      toast.error("Booking Price cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice < 1
    ) {
      toast.error("Please Enter valid Booking Price");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      !guestSeatCategoryBtn &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPriceGuest == ""
    ) {
      toast.error("Price for guest cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      !guestSeatCategoryBtn &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPriceGuest < 1
    ) {
      toast.error("please enter valid price for guest");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.bookingType == "PAID" &&
      seatCategory.every((category) => {
        return (
          category.price == null ||
          category.price == undefined ||
          category.price == "" ||
          category.price == 0
        );
      })
    ) {
      toast.error("Enter valid price per category");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.bookingType == "PAID" &&
      showGuest == false &&
      seatCategory.some((category) => {
        return (
          category.guest_price == null ||
          category.guest_price == undefined ||
          category.guest_price == "" ||
          category.guest_price == 0
        );
      })
    ) {
      toast.error("Enter valid guest price per category");
    } else if (
      createEvent?.bookingType == "PAID" &&
      createEvent?.invoiceDescription == ""
    ) {
      toast.error("Please Enter Invoice Description");
    } else if (
      createEvent?.bookingType == "PAID" &&
      createEvent?.invoiceDescription?.length < 10
    ) {
      toast.error("Minimum Invoice Description Length should be 10");
    } else if (
      createEvent?.bookingType == "PAID" &&
      createEvent?.invoiceDescription?.length > 500
    ) {
      toast.error("Invoice Description length should be less than 500");
    } else if (createEvent?.eventImages?.length < 1) {
      toast.error("Upload Image is Mandatory");
    } else if (imagesEventHomeCover?.length < 1) {
      toast.error("Home Screen Cover Images is Mandatory");
    } else if (IS_REGISTRATION_FLOW) {
      setPayloadSecondry(payload);
      setShowParticipantReg(true);
    } else {
      setIsDisabled(true);
      handleFormSubmit(payload);
    }
  };
  const handleFormSubmit = (payload) => {
    fetch(process.env.REACT_APP_API_URL + "/api/event/create", {
      method: "POST",
      body: JSON.stringify(payload, {
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.status_code == 101) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res.status_code == 102) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res?.success == false) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res?.success == true) {
          // navigate(EventConfig.event);
          navigate({
            pathname: process.env.REACT_APP_PATH + "/",
            search: `?event_id=${res?.data?.event?.id}`,
          });
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
      });
  };
  return (
    <>
      {!showParticipantReg && (
        <div>
          <TopHeader title={"Create Event"} />
          <div className={styles.creatEventMainDiv}>
            <DiscardModal
              open={discardModal}
              onCloseModal={onCloseDiscardModal}
              msg={"Are you sure you want to discard this event"}
            />
            <AddSeatCategorization
              title={"Add Category"}
              open={seatCategorizationModal}
              onCloseModal={() => setSeatCategorizationModal(false)}
              addSeatCategory={addSeatCategory}
              removeSeatCategory={removeSeatCategory}
              createEvent={createEvent}
              addSeatCategoryAc={addSeatCategoryAc}
            />
            {process.env.REACT_APP_PRODUCT !== "DLF" &&
              profileDetails?.show_audience_flow && (
                <RegistrationFieldModal
                  title={"Add New Field"}
                  open={participantRegistrationFieldModal}
                  onCloseModal={() =>
                    setParticipantRegistrationFieldModal(false)
                  }
                  dropDownFieldType={dropDownFieldType}
                  activeRegistrationBtn={activeRegistrationBtn}
                  setActiveRegistrationBtn={setActiveRegistrationBtn}
                  selectedAudienceForm={selectedAudienceForm}
                  selectedAudienceFormDetails={selectedAudienceFormDetails}
                  addParticipantRegistrationField={
                    addParticipantRegistrationField
                  }
                  getFormList={getFormList}
                />
              )}
            <EventDetails
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleCreateEventChange={handleCreateEventChange}
              handleTimePicker={handleTimePicker}
              handleCalender={handleCallbackforClosingOn}
              guest={guest}
              setGuest={setGuest}
              value={value}
              setValue={setValue}
              handleDateTimePicker={handleDateTimePicker}
              images0={imagesSplGuest0}
              setImages0={setImagesSplGuest0}
              images1={imagesSplGuest1}
              setImages1={setImagesSplGuest1}
              images2={imagesSplGuest2}
              setImages2={setImagesSplGuest2}
              handleEventTypesChange={handleEventTypesChange}
              selectedEventTypes={selectedEventTypes}
              eventTypes={eventTypes}
              handleSuitableAgeGroupsChange={handleSuitableAgeGroupsChange}
              selectedSuitableAgeGroups={selectedSuitableAgeGroups}
              suitableAgeGroups={suitableAgeGroups}
              handleVenueChange={handleVenueChange}
              handleCreateVenue={handleCreateVenue}
              selectedVenue={selectedVenue}
              setSelectedVenue={setSelectedVenue}
              venues={venues}
              setVenues={setVenues}
              isVenueLoading={isVenueLoading}
              handleLanguageChange={handleLanguageChange}
              selectedLanguage={selectedLanguage}
              languages={languages}
              // customStyles={styles.customDropdown}
              eventStatus={eventStatus}
            />
            <Seatings
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleCreateEventChange={handleCreateEventChange}
              setSeatCategorizationModal={setSeatCategorizationModal}
              seatCategory={seatCategory}
              setSeatCategory={setSeatCategory}
              removeSeatCategory={removeSeatCategory}
            />
            <Financials
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleCreateEventChange={handleCreateEventChange}
              inputFields={inputFields}
              setInputFields={setInputFields}
              seatCategory={seatCategory}
              setShowGuest={setShowGuest}
              showGuest={showGuest}
              setSeatCategory={setSeatCategory}
              // price={price}
              guestSeatCategory={guestSeatCategory}
              setGuestSeatCategory={setGuestSeatCategory}
              guestSeatCategoryBtn={guestSeatCategoryBtn}
              setGuestSeatCategoryBtn={setGuestSeatCategoryBtn}
              handleBookingType={handleBookingType}
            />
            <UploadImageForm
              title={"Event Images"}
              imagesEvent={imagesEvent}
              setImagesEvent={setImagesEvent}
              images={images}
              setImages={setImages}
              maxNumber={5}
              note={"(Resolution 900px * 440px)"}
              resolutionWidth={900}
              resolutionHeight={440}
              markCoverId={markCoverId}
              setMarkCoverId={setMarkCoverId}
              showCover={true}
            />
            <UploadImageForm
              title={"Home Screen Cover Image"}
              imagesEvent={imagesEventHomeCover}
              setImagesEvent={setImagesEventHomeCover}
              images={imagesHomeCover}
              setImages={setImagesHomeCover}
              maxNumber={1}
              note={"(Resolution 710px * 960px)"}
              resolutionWidth={710}
              resolutionHeight={960}
            />
            {IS_REGISTRATION_FLOW && profileDetails?.show_audience_flow && (
              <RegistrationDetail
                selectedAudienceForm={selectedAudienceForm}
                selectedAudienceFormDetails={selectedAudienceFormDetails}
                setSelectedAudienceFormDetails={setSelectedAudienceFormDetails}
                audienceForm={audienceForm}
                handleChangeAudienceDropdown={handleChangeAudienceDropdown}
                setActiveRegistrationBtn={setActiveRegistrationBtn}
                participantRegistrationFieldModal={
                  participantRegistrationFieldModal
                }
                setParticipantRegistrationFieldModal={
                  setParticipantRegistrationFieldModal
                }
                getFormList={getFormList}
              />
            )}
            {SHOW_REGISTRATION_PAGE ? (
              <span className={styles.btnCreateEvent}>
                <button
                  className={styles.discardBtn}
                  onClick={() => onOpenDiscardModal()}
                >
                  {process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : "Discard"}
                </button>
                <button
                  disabled={isDisabled}
                  className={styles.createBtn}
                  onClick={() => {
                    process.env.REACT_APP_PRODUCT === "NUCLEUS"
                      ? navigate(EventConfig?.RegistrationDetailPage)
                      : process.env.REACT_APP_PRODUCT != "DLF"
                      ? handleSubmitAc()
                      : handleSubmit();
                  }}
                >
                  Create Event
                </button>
              </span>
            ) : (
              <span className={styles.saveBtn}>
                <button
                  disabled={isDisabled}
                  className={styles.createBtn}
                  onClick={() => {
                    handleSubmitAc();
                  }}
                >
                  Next
                </button>
              </span>
            )}
          </div>
        </div>
      )}
      {IS_REGISTRATION_FLOW && showParticipantReg && (
        <div>
          <TopHeader title={"Registration Event"} />
          <RegistrationDetailPage
            payloadSecondry={payloadSecondry}
            handleFormSubmit={handleFormSubmit}
            selectedParticipantForm={selectedParticipantForm}
            setSelectedParticipantForm={setSelectedParticipantForm}
            dropDownFieldType={dropDownFieldType}
            setShowParticipantReg={setShowParticipantReg}
          />
        </div>
      )}
    </>
  );
};
