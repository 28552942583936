import React, { useEffect, useState } from "react";
import styles from "./clubs.module.css";
import { TopHeader } from "./Components/TopHeader/TopHeader";
import TableHomepage from "./Components/TableHomepage/TableHomepage";
import { getUserData } from "../Components/Redux/clubsSlice";
import { loadData } from "../Utils/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { getClubAdminProfile } from "../Components/Redux/authSlice";
import { Logo } from "../Components/Logo";
import { formatData } from "../Utils/formatData";

const Club = () => {
  const dispatch = useDispatch();
  const { loading, userData, records } = useSelector(
    (state) => state.clubsSlice
  );
  const { paramClubId } = useSelector((state) => state.authSlice);
  const [inputValue, setInputValue] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [fullData, setFullData] = useState([]);

  const handleUserData = (ofset) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
    };
    if (inputValue !== "") {
      payload["search"] = inputValue;
    }
    if (offset !== "") {
      payload["offset"] = ofset !== undefined ? ofset : offset;
    }
    if (limit !== "") {
      payload["limit"] = limit;
    }
    dispatch(getUserData(payload));
  };

  const handleOffset = (val) => {
    if (val == "next") {
      offset + fullData?.length < records && setOffset(offset + limit);
      offset + fullData?.length < records && handleUserData(offset + limit);
    } else {
      offset > 0 && setOffset(offset - limit);
      offset > 0 && handleUserData(offset - limit);
    }
  };

  useEffect(() => {
    const resultantData = formatData(userData);
    setFullData(resultantData);
    dispatch(getClubAdminProfile(loadData("ClubToken")));
  }, [userData]);

  useEffect(() => {
    handleUserData(0);
  }, [loadData("ClubToken"), loadData("Token"), paramClubId, inputValue]);

  return (
    <>
      <div>
        <TopHeader title={"Roles and Descriptions"} showBtn={true} />
      </div>
      <div className={styles.searchDiv}>
        <div className={styles.searchIcon}>
          <img
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
          />
        </div>
        <input
          className={styles.searchDivInput}
          placeholder={"Search User / Role"}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleUserData();
            }
          }}
        />
      </div>
      {loading ? (
        <Logo />
      ) : (
        <>
          {userData?.length !== 0 ? (
            <>
              <div className={styles.clubOuterDiv}>
                <div className={styles.opDiv}>
                  <div>Create: </div>
                  <img
                    className={styles.imgDimenDiv}
                    src={
                      process.env.PUBLIC_URL +
                      `/${process.env.REACT_APP_IMAGES}/c.svg`
                    }
                  />
                </div>
                <div className={styles.opDiv}>
                  <div>Read: </div>
                  <img
                    className={styles.imgDimenDiv}
                    src={
                      process.env.PUBLIC_URL +
                      `/${process.env.REACT_APP_IMAGES}/r.svg`
                    }
                  />
                </div>
                <div className={styles.opDiv}>
                  <div>Update: </div>
                  <img
                    className={styles.imgDimenDiv}
                    src={
                      process.env.PUBLIC_URL +
                      `/${process.env.REACT_APP_IMAGES}/u.svg`
                    }
                  />
                </div>
                <div className={styles.opDiv}>
                  <div>Delete: </div>
                  <img
                    className={styles.imgDimenDiv}
                    src={
                      process.env.PUBLIC_URL +
                      `/${process.env.REACT_APP_IMAGES}/d.svg`
                    }
                  />
                </div>
              </div>
              <div>
                <TableHomepage
                  handleOffset={handleOffset}
                  fullData={fullData}
                  offset={offset}
                />
              </div>
            </>
          ) : (
            <div className={styles.noDataMainDiv}>
              <div className={styles.noDataDiv}>
                <span className={styles.noDataImgDiv}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
                  />
                </span>
                <h2>Not Found</h2>
                <p>Search did not match the current user / role listed</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Club;
