import React from "react";
import "./weekCalender.css";
import styles from "./toggleDays.module.css";
import ToggleDays from "./ToggleDays";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Popover, Pane, Position } from "evergreen-ui";
import ToggleDaysTrainer from "./ToggleDaysTrainer";

export const WeekCalender = ({
  toggleType,
  days,
  handleChangeDays,
  isDisabledPopover,
  filterSelect,
  daysFromFacility,
}) => {
  return !isDisabledPopover ? (
    <Popover
      content={({ close }) => (
        <Pane width={300} height={150} textAlign="center">
          <>
            <div className={styles.filterCrossIcon} onClick={close}>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/remove.png`}
                alt="removeIcon"
              />
            </div>
            <p className={styles.calenderP}>Available Days</p>
            {toggleType == "trainer" ? (
              <ToggleDaysTrainer
                daysFromFacility={daysFromFacility}
                days={days}
                handleChangeDays={handleChangeDays}
              />
            ) : (
              <ToggleDays days={days} handleChangeDays={handleChangeDays} />
            )}
            <div className={styles.selClearOuterDiv}>
              <button
                className={styles.selectClearDiv}
                onClick={() => handleChangeDays("clear")}
              >
                Clear
              </button>
              <button
                className={styles.selectClearDiv}
                onClick={() => handleChangeDays("selectAll")}
              >
                Select All
              </button>
            </div>
            {/* <button className={styles.btn} onClick={close}>
              Close
            </button> */}
          </>
        </Pane>
      )}
      position={Position.BOTTOM_RIGHT}
    >
      <div className={styles.weekInput}>
        {days?.length <= 0 ? (
          <p className={styles.selectDays}>Select Days</p>
        ) : Array.isArray(days) && filterSelect == true ? (
          days?.join(",")
        ) : Array.isArray(days) ? (
          <div
            style={{
              display: "flex",
              gap: "12px",
            }}
          >
            {days?.map((d) => {
              return (
                <p
                  className={
                    toggleType == "trainer"
                      ? styles.daysChipDivTrainer
                      : styles.daysChipDiv
                  }
                >
                  {d}
                </p>
              );
            })}
          </div>
        ) : (
          days
        )}
        <span className={styles.calenderIconDiv}>
          <CalendarMonthIcon />
        </span>
      </div>
    </Popover>
  ) : (
    <div className={`${styles.weekInput} ${styles.weekInputDisabled}`}>
      {days?.length <= 0 ? (
        <p className={styles.selectDays}>Select Days</p>
      ) : Array.isArray(days) && filterSelect == true ? (
        days?.join(",")
      ) : Array.isArray(days) ? (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          {days?.map((d) => {
            return (
              <p
                className={
                  toggleType == "trainer"
                    ? styles.daysChipDivTrainer
                    : styles.daysChipDiv
                }
              >
                {d}
              </p>
            );
          })}
        </div>
      ) : (
        days
      )}
      <span className={styles.calenderIconDiv}>
        <CalendarMonthIcon />
      </span>
    </div>
  );
};
