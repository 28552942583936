import React, { useEffect, useState } from "react";
import { TextInput } from "evergreen-ui";
import { Modal } from "react-responsive-modal";
import styles from "./nonMemberPayment.module.css";
import { ButtonBorder } from "../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { CustomSelect } from "../../../Components/CustomSelect/CustomSelect";
import UploadDocument from "./UploadDocument";
import { loadData } from "../../../Utils/localStorage";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getBookingHistory } from "../../../Components/Redux/eventsSlice";
import { EventConfig } from "../../EventConfig";

const NonMemberPayment = ({ open, onCloseModal, details, viewNonMemberId }) => {
  const [nonMemberTransactionNum, setNonMemberTransactionNum] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [metaData, setMetaData] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    let viewDet =
      details?.length > 0 &&
      details?.filter((i, index) => {
        return i.id == viewNonMemberId ? i : "";
      });
    setViewDetails(viewDet);
  }, [details, viewNonMemberId]);

  // transaction id number
  const handleTransactionIdNumber = (e) => {
    setNonMemberTransactionNum(e.target.value);
  };

  //select dropDownValue
  let nonMemberPaymentModes = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "UPI",
      value: "UPI",
    },
    {
      label: "Credit/Debit",
      value: "Credit/Debit",
    },
    {
      label: "Net Banking",
      value: "Net Banking",
    },
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const handleSetMode = (selectedOption) => {
    setPaymentMode(selectedOption);
  };

  const handleAdd = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      transaction_id: nonMemberTransactionNum,
      payment_mode: paymentMode.value,
      document: metaData,
      event_id: viewDetails[0]?.event_id,
      booking_id: viewDetails[0]?.id,
    };
    fetch(process.env.REACT_APP_API_URL + "/api/event/post-transactions", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.status_code == 101) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res.status_code == 102) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res?.success == false) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res?.success == true) {
          onCloseModal();
          let payload = {
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: EventConfig.requestBy,
            is_history_api: "yes",
          };
          dispatch(getBookingHistory(payload));
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    setNonMemberTransactionNum("");
    setPaymentMode("");
    setMetaData("");
  }, [onCloseModal]);

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={true}>
        <div className={styles.mainDivBookEventModal}>
          <div>
            <h1 className={styles.headingBookEventModal}>
              {"Payment Details"}
            </h1>
          </div>
          <div className={styles.scrollDiv}>
            <div>
              <label className={styles.LLabel} htmlFor="">
                Transaction Id Number{" "}
                <span style={{ color: "#FF443A" }}>*</span>
              </label>
              <TextInput
                className={styles.mgbottominputSB}
                width={"100%"}
                type="text"
                height={44}
                placeholder="Enter"
                name="TransactionIdNumber"
                value={nonMemberTransactionNum}
                onChange={handleTransactionIdNumber}
              />
            </div>
            <div style={{ paddingBottom: "30px", zIndex: "1" }}>
              <label className={styles.LLabel} htmlFor="">
                Payment Mode <span style={{ color: "#FF443A" }}>*</span>
              </label>
              <div className={styles.dropdownDivOuter}>
                <div className={styles.dropdownDiv}>
                  <CustomSelect
                    options={nonMemberPaymentModes}
                    value={paymentMode}
                    handleOnChangeDropdown={handleSetMode}
                    padding={"4px 5px 4px 4px"}
                    border={"1px solid #DEDFE1"}
                  />
                </div>
              </div>
            </div>
            <div style={{ zIndex: "-1" }}>
              <label className={styles.LLabel} htmlFor="">
                Upload Document{" "}
                <span
                  style={{
                    color: "#868686",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  (less than 3.5 mb)
                </span>
                <span style={{ color: "#FF443A" }}>*</span>
              </label>
              <div className={styles.dropdownDivOuter}>
                <UploadDocument setMetaData={setMetaData} />
              </div>
            </div>
          </div>
          <div className={styles.btnDivBookEventDetail}>
            <ButtonBorder
              text="Back"
              width="200"
              buttonHandler={() => onCloseModal()}
            />
            <ButtonColor
              text="Add"
              width="200"
              buttonHandler={() => {
                if (nonMemberTransactionNum == "") {
                  toast.error("Please enter the transaction id");
                } else if (paymentMode.value == "") {
                  toast.error("Please select the mode of payment");
                } else if (metaData == "") {
                  toast.error("Please select the document");
                } else {
                  handleAdd();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NonMemberPayment;
