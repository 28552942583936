import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  totalAmount: 0,
  restaurantName: "",
  restaurantDeliveryTime: "",
  packingCharges: 0,
  deliveryCharges: 0,
  restaurantMenuData: [],
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    removeItem: (state, { payload }) => {
      state.cartItems = state.cartItems.filter((item) => item.id !== payload);
    },
    addItem: (state, { payload }) => {
      let targetItem = state.cartItems.find((item) => {
        return item.ItemCode === payload.ItemCode;
      });
      if (targetItem) {
        targetItem.OrderedQuantity += 1;
      } else {
        state.cartItems = [...state.cartItems, payload];
      }
    },
    clearCart: (state, action) => {
      state.cartItems = [];
    },
    incrementQnty: (state, { payload }) => {
      let targetItem = state.cartItems.find((item) => {
        return item.ItemCode === payload.ItemCode;
      });
      if (targetItem) {
        targetItem.OrderedQuantity += 1;
      }
    },
    decrementQnty: (state, { payload }) => {
      let targetItem = state.cartItems.find((item) => {
        return item.ItemCode === payload.ItemCode;
      });
      if (targetItem) {
        targetItem.OrderedQuantity -= 1;
        if (targetItem.OrderedQuantity === 0) {
          state.cartItems = state.cartItems.filter((item) => {
            return item.ItemCode !== payload.ItemCode;
          });
        }
      }
    },
    calcTotal: (state, action) => {
      state.totalAmount = state?.cartItems?.reduce(
        (acc, item) => (acc += item.ItemPrice * item.OrderedQuantity),
        0
      );
    },
    updateRestaurantName: (state, action) => {
      state.restaurantName = action.payload;
    },
    updateRestaurantDeliveryTime: (state, action) => {
      state.restaurantDeliveryTime = action.payload;
    },
    updatePackingCharge: (state, action) => {
      state.packingCharges = action.payload;
    },
    updateDeliveryCharge: (state, action) => {
      state.deliveryCharges = action.payload;
    },
    updateRestaurantMenuData: (state, { payload }) => {
      state.restaurantMenuData = payload;
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const {
  removeItem,
  addItem,
  reset,
  clearCart,
  incrementQnty,
  decrementQnty,
  calcTotal,
  updateRestaurantName,
  updateRestaurantDeliveryTime,
  updatePackingCharge,
  updateDeliveryCharge,
  updateRestaurantMenuData,
} = cartSlice.actions;

export default cartSlice.reducer;
