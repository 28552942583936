import React from "react";
import styles from "./discardModal.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EventConfig } from "../EventConfig";
import { IS_REGISTRATION_FLOW } from "../../Utils/moduleConfig";

export const DiscardModal = ({
  open,
  onCloseModal,
  msg,
  handleUpdateStatus,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { eventsActiveTab } = useSelector((state) => state.eventsSlice);
  return (
    <>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={false}>
        <div className={styles.tooltipChangeStatus}>
          <p className={styles.modalDivp}>{msg} </p>
          <div className={styles.btnDiv}>
            <button
              className={styles.discard}
              onClick={() => {
                onCloseModal();
                // navigate(`/${id}`)
                navigate({
                  pathname:
                    process.env.REACT_APP_PRODUCT == "DLF"
                      ? process.env.REACT_APP_PATH + "/events"
                      : process.env.REACT_APP_PATH + "/",
                  search: `?event_id=${eventsActiveTab}`,
                });
              }}
            >
              {process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : IS_REGISTRATION_FLOW ? "Cancel" : "Discard"}
            </button>
            <button
              className={styles.cancel}
              onClick={() => {
                onCloseModal();
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
