import React, { useEffect, useRef, useState } from "react";
import styles from "./bookingRegistrationHistory.module.css";
import { TopHeader } from "../../../../Components/TopHeaderNav/TopHeader";
import { BookingRegistrationTbl } from "./BookingRegistrationTbl/BookingRegistrationTbl";
import { useSelector } from "react-redux";
import { loadData } from "../../../../Utils/localStorage";
import { EventConfig } from "../../../../Events/EventConfig";
import { Logo } from "../../../../Components/Logo";
import dayjs from "dayjs";

import { exportToCSV } from "../../../../Utils/excelHelper";

export const BookingRegistrationHistory = ({ activeTab, setActiveTab }) => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const [bookingHistoryEvents, setBookingHistroyEvent] = useState([]);
  const [totalBookingHistoryCount, setTotalBookingHistoryCount] = useState(0);

  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(4);
  const columns = [
    "Event",
    "Event Status",
    "Registrations",
    "Start Date & Time",
    "End Date & Time",
  ];
  const tableRef = useRef(null);
  const getHistoryApi = async (ofset) => {
    setLoader(true);
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
    };
    if (offset !== "") {
      payload["offset"] = ofset !== undefined ? ofset : offset;
    }
    if (limit !== "") {
      payload["limit"] = limit;
    }
    fetch(
      process.env.REACT_APP_API_URL + "/api/event/participant-history-by-event",
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        setLoader(false);
        if (res?.success) {
          setTotalBookingHistoryCount(res?.total_record);
          setBookingHistroyEvent(res?.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const handleOffset = (val) => {
    if (val == "next") {
      offset + bookingHistoryEvents?.length < totalBookingHistoryCount &&
        setOffset(offset + limit);
      offset + bookingHistoryEvents?.length < totalBookingHistoryCount &&
        getHistoryApi(offset + limit);
    } else {
      offset > 0 && setOffset(offset - limit);
      offset > 0 && getHistoryApi(offset - limit);
    }
  };
  // function for excel
  const excelFun = () => {
    let requestBody = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/event/participant-history-by-event`,
      {
        method: "POST",
        body: JSON.stringify({ ...requestBody }),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        const data = [];
        for (let x of res?.data) {
          const downloadData = {};
          downloadData["Event"] = x?.name;
          downloadData["Event Status"] =
            x?.status === "COMING_SOON" ? "COMING SOON" : x?.status;
          downloadData["Registrations"] = x?.user_participent_count;
          downloadData["Start Date & Time"] = dayjs(
            x?.start_at,
            "YYYY-MM-DD hh:mm:ss"
          ).format("DD MMM YYYY, hh:mm A");
          downloadData["End Date & Time"] = dayjs(
            x?.end_at,
            "YYYY-MM-DD hh:mm:ss"
          ).format("DD MMM YYYY, hh:mm A");
          data.push(downloadData);
        }
        data.push({
          "Event Status": "",
        });
        let fileNameForDownload;
        fileNameForDownload =
          "RegistrationHistory_" + dayjs().format("DD-MM-YYYY HH:mm:ss");
        exportToCSV(data, fileNameForDownload);
      });
  };

  useEffect(() => {
    getHistoryApi();
  }, []);
  if (loader) return <Logo />;
  return (
    <div className={styles.mainDivBookingHistory}>
      <TopHeader title={"Registration History"} />
      {process.env.REACT_APP_PRODUCT !== "DLF" && (
        <div className={styles.actionDiv}>
          {profileDetails?.show_audience_flow && (
            <div className={styles.activeTabDiv}>
              <span
                className={activeTab == "Booking" && styles.activeTab}
                onClick={() => setActiveTab("Booking")}
              >
                Booking
              </span>
              <span
                className={activeTab != "Booking" && styles.activeTab}
                onClick={() => setActiveTab("Registration")}
              >
                Registration
              </span>
            </div>
          )}
          {bookingHistoryEvents?.length > 0 && (
            <div className={styles.excelDiv} onClick={excelFun}>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/excel.png`}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.tblMainDiv}>
        <BookingRegistrationTbl
          columns={columns}
          details={bookingHistoryEvents}
          tableRef={tableRef}
        />
        {bookingHistoryEvents?.length > 0 && (
          <div className={styles.pagination}>
            <div>
              <div className={styles.paginationValuesDiv}>
                <p>{offset + 1}</p>
                <p>-</p>
                <p>{offset + bookingHistoryEvents?.length}</p>
                <p>of</p>
                <p>{totalBookingHistoryCount && totalBookingHistoryCount}</p>
              </div>
              <div className={styles.paginationIconDiv}>
                <span onClick={() => handleOffset("prev")}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                  />
                </span>
                <span onClick={() => handleOffset("next")}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
