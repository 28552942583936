import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ButtonBorder } from "../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import styles from "./addSeatCategory.module.css";
import { TextInput } from "evergreen-ui";
import { toast } from "react-toastify";

export const AddSeatCategorization = ({
  open,
  onCloseModal,
  title,
  addSeatCategory,
  createEvent,
  addSeatCategoryAc,
}) => {
  const [categoryName, setCategoryName] = useState("");
  const [isWaitingList, setIsWaitingList] = useState("yes");
  //for AC --------------------------------------
  const [total, setTotal] = useState("");
  const [waiting, setWaiting] = useState(0);

  useEffect(() => {
    setCategoryName("");
    setIsWaitingList("");
    setWaiting(0);
    setTotal("");
    // if (isWaitingList == "No") {
    //   createEvent.Object.keys(seats_booked).waiting_seats = 0;
    // }
    // if (categoryName != null) {
    //   createEvent.Object.keys(seats_booked)[0] = categoryName;
    // }
  }, [onCloseModal]);

  return (
    <Modal open={open} onClose={onCloseModal} center showCloseIcon={true}>
      <div className={styles.mainDiv}>
        <h1 className={styles.title}>{title}</h1>
        <div>
          <label className={styles.labelFont}>Seat Category Name</label>
          <span className={styles.star}>*</span>
          <br />
          <TextInput
            className={styles.inputText}
            value={categoryName}
            maxLength="30"
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
        {process.env.REACT_APP_PRODUCT == "DLF" && (
          <div className={styles.divOutline}>
            <label className={styles.labelFont}>Waiting list</label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.radioMainDiv}>
              <span className={true ? styles.radioDivActive : styles.radioDiv}>
                <input
                  type="radio"
                  name="isWaitingList"
                  value={true}
                  checked={isWaitingList === "yes"}
                  onChange={() => setIsWaitingList("yes")}
                />
                <label className={styles.radioLabel}>Yes</label>
              </span>
              <span className={true ? styles.radioDivActive : styles.radioDiv}>
                <input
                  type="radio"
                  name="isWaitingList"
                  value={false}
                  checked={isWaitingList === "no"}
                  onChange={() => setIsWaitingList("no")}
                />
                <label className={styles.radioLabel}>No</label>
              </span>
            </div>
          </div>
        )}
        {process.env.REACT_APP_PRODUCT != "DLF" && (
          <div className={styles.seatAndWaitingDiv}>
            <div>
              <label className={styles.labelFont}>Total seats</label>
              <span className={styles.star}>*</span>
              <br />
              <TextInput
                className={styles.inputText}
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
            </div>
            {/* <div>
              <label className={styles.labelFont}>Waiting list</label>
              <span className={styles.star}>*</span>
              <br />
              <TextInput
                className={styles.inputText}
                value={waiting}
                onChange={(e) => setWaiting(e.target.value)}
              />
            </div> */}
          </div>
        )}
        <div className={styles.btnDiv}>
          <ButtonBorder
            text={process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : "Discard"}
            width={200}
            buttonHandler={() => {
              onCloseModal();
            }}
          />
          {process.env.REACT_APP_PRODUCT != "DLF" ? (
            <ButtonColor
              text={"Save"}
              width={200}
              buttonHandler={() => {
                if (categoryName == "") {
                  toast.error("Please enter category name");
                } else if (categoryName.length < 1) {
                  toast.error("Please enter valid category name");
                } else if (total === "") {
                  toast.error("Please enter total seats");
                } else if (waiting === "") {
                  toast.error("Please enter waiting list");
                } else {
                  addSeatCategoryAc(categoryName, total, waiting);
                  onCloseModal();
                }
              }}
            />
          ) : (
            <ButtonColor
              text={"Save"}
              width={200}
              buttonHandler={() => {
                if (categoryName == "") {
                  toast.error("Please enter category name");
                } else if (categoryName.length < 1) {
                  toast.error("Please enter valid category name");
                } else if (isWaitingList == "") {
                  toast.error("Please select waiting list");
                } else {
                  addSeatCategory(categoryName, isWaitingList);
                  onCloseModal();
                }
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
