import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData, saveData } from "../../Utils/localStorage";

export const activeTabSidebar = createSlice({
    name: "activeTabSidebar",
    initialState: {
        active: loadData("active_Tab") ? loadData("active_Tab") : 2,
        loading: false,
        error: null,
    },
    reducers: {
        activeTab: (state, action) => {
            saveData("active_Tab", action.payload);
            state.active = action.payload;
        },
    },

});

export const { activeTab } = activeTabSidebar.actions;
export default activeTabSidebar.reducer;