import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./toggle.css";

const ToggleCrud = ({ toggle, toggleFunction }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Toggle
        checked={toggle}
        icons={false}
        value={toggle}
        onChange={() => {
          toggleFunction();
        }}
      />
    </div>
  );
};

export default ToggleCrud;
