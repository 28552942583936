import React from "react";
import { CancelComponent } from "../../../Components/CancelComponent/CancelComponent";

export const CancelEventModal = ({
  open,
  onCloseModal,
  handleUpdateStatus,
  isDisabled,
}) => {
  return (
    <CancelComponent
      open={open}
      onCloseModal={onCloseModal}
      msg={"Are you sure you want to cancel this Event"}
      handleUpdateStatus={handleUpdateStatus}
      isDisabled={isDisabled}
      btnText={"Yes, Cancel"}
    />
  );
};
