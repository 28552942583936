import React from "react";
import styles from "./buttonSmall.module.css";

export const ButtonSmall = ({ title, buttonHandler, to }) => {
  return (
    <div
      className={styles.buttonSmall}
      onClick={() => {
        buttonHandler(to);
      }}
    >
      {" "}
      {title}
    </div>
  );
};
