import React, { useEffect, useState } from "react";
import styles from "./eventsListItems.module.css";
import dayjs from "dayjs";
import { handleEventsActiveTab } from "../../Components/Redux/eventsSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const EventsListItems = ({
  onClickTabItem,
  // club_short_name,
  activeTabList,
  event_venue,
  start_at,
  name,
  status,
  id,
  // dataCategory,
  reef,
  classNamee,
}) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const [paramId, setparamId] = useState(+searchParam.get("event_id"));
  // const {id:paramId}=useParams()
  const navigate = useNavigate();

  useEffect(() => {
    if (paramId == id) {
      onClickTabItem(id);
      dispatch(handleEventsActiveTab(id));
    }
  }, [paramId]);

  useEffect(() => {
    setparamId(+searchParam.get("event_id"));
  }, [searchParam]);

  return (
    <div
      className={
        activeTabList === id
          ? styles.mainDivEventsListItemsActive +
            " " +
            styles.activetab +
            " " +
            { classNamee }
          : styles.mainDivEventsListItems + " " + { classNamee }
      }
      // data-category={dataCategory}
      ref={reef}
      onClick={() => {
        // handleDetail(id);
        // navigate(`/${id}`)
        setSearchParam({ event_id: id });
        // onClickTabItem(id);
        // dispatch(handleEventsActiveTab(id));
      }}
    >
      <div
        className={
          activeTabList === id ? styles.titleDivActive : styles.titleDiv
        }
      >
        <h1>{name}</h1>
        {/* <p>{club_short_name}</p> */}
      </div>
      <div className={styles.ImgEventsListItems}>
        {activeTabList === id ? (
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/locationImg.png`}
              alt=""
            />
          </div>
        ) : (
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/locationImgInActive.png`}
              alt=""
            />
          </div>
        )}
        <h1>{event_venue?.name}</h1>
      </div>
      <span
        className={
          activeTabList === id
            ? styles.amountDateDivEventsListItems
            : styles.amountDateDivEventsListItems
        }
      >
        <h5>Event Date</h5>
        <h5>Status</h5>
      </span>
      <span
        className={
          activeTabList === id
            ? styles.amountDateDivEventsListItems
            : styles.amountDateDivEventsListItems
        }
      >
        <p>
          {start_at?.split(" ")[0] &&
            `${dayjs(start_at, "YYYY-MM-DD hh:mm A").format("DD MMM YYYY")}`}
        </p>
        <p
          className={
            status === "OPEN" ? styles.statusColorOpen : styles.statusColor
          }
        >
          {status && status == "OPEN"
            ? "OPEN"
            : status == "COMING_SOON"
            ? "COMING SOON"
            : status == "CANCELED"
            ? "CANCELED"
            : status == "COMPLETED"
            ? "COMPLETED"
            : "CLOSE"}
        </p>
      </span>
    </div>
  );
};
