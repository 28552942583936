import React, { useState, useEffect } from "react";
import "./searchandfilter.css";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Popover, Position, Pane, Select } from "evergreen-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAccessibleToSlice,
  handleAvailableDays,
  handleCalender,
  handleCategorySlice,
  handleEndCalender,
  handleEventMonth,
  handleSearchSlice,
  handleStartCalender,
  handleStatusSlice,
  handlEventTypeSlice,
  handleStartCalenderDate,
  handleEndCalenderDate,
} from "../Redux/searchSlice";
import { getAllEvents } from "../Redux/eventsSlice";
import { WeekCalender } from "../WeekCalender/WeekCalender";

const SearchAndFilter = ({
  handleSearch,
  dropdownOption,
  showStatus,
  showDate,
  showEventType,
  categoryArr,
  showCategory,
  showAvailability,
  showEventAccessibleTo,
  showAccessibleTo,
  categoryTitle,
  filterType,
}) => {
  const dispatch = useDispatch();
  const {
    accessibleTo,
    eventType,
    status,
    eventDateCalender,
    startCalender,
    endCalender,
    startCalenderDate,
    endCalenderDate,
    eventMonth,
    category,
    availableDays,
  } = useSelector((state) => state.searchSlice);
  const [inputValue, setinputValue] = useState();
  const [showFilterData, setshowFilterData] = useState(false);
  const [accessibleTo1, setAccessibleTo] = useState("");
  const [eventType1, setEventType] = useState("");
  const [status1, setStatus] = useState("");
  const [category1, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [days, setDays] = useState([]);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleCallbackforClosingOn = (date) => {
    setStartDate(moment(date[0]).format("YYYY-MM-DD"));
    setEndDate(moment(date[1]).format("YYYY-MM-DD"));
  };

  const handleSearchFun = () => {
    handleSearch();
  };

  const handleChangeDays = (id) => {
    if (days.includes(id)) {
      setDays(days.filter((e) => e !== id));
    } else if (id == "selectAll") {
      if (days?.length < 7) {
        setDays(daysOfWeek);
      } else {
        setDays([]);
      }
    } else if (id == "clear") {
      setDays([]);
    } else {
      const updatedDays = [...days, id];
      updatedDays.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));
      setDays(updatedDays);
    }
  };

  const handleApplyFun = () => {
    setshowFilterData(true);
    dispatch(handleCalender(""));
    dispatch(handleEventMonth(""));
    dispatch(handleSearchSlice(""));
    setinputValue("");
    dispatch(handlEventTypeSlice(eventType1));
    dispatch(handleAccessibleToSlice(accessibleTo1));
    dispatch(handleStatusSlice(status1));
    dispatch(handleStartCalender(startDate));
    dispatch(handleEndCalender(endDate));
    dispatch(handleCategorySlice(category1));
    dispatch(handleAvailableDays(days));
  };

  const ClearAllFilter = () => {
    dispatch(getAllEvents());
    setshowFilterData(false);
    dispatch(handleAccessibleToSlice(""));
    dispatch(handleStatusSlice(""));
    dispatch(handlEventTypeSlice(""));
    dispatch(handleCalender(""));
    dispatch(handleStartCalender(""));
    dispatch(handleEndCalender(""));
    dispatch(handleCategorySlice(""));
    dispatch(handleAvailableDays([]));
    dispatch(handleStartCalenderDate(""));
    dispatch(handleEndCalenderDate(""));
    setAccessibleTo("");
    setEventType("");
    setStatus("");
    setCategory("");
    setDays([]);
  };

  const FilterBtn = () => {
    setshowFilterData(false);
    dispatch(handleAccessibleToSlice(""));
    dispatch(handleStatusSlice(""));
    dispatch(handlEventTypeSlice(""));
    dispatch(handleCalender(""));
    dispatch(handleCategorySlice(""));
    dispatch(handleAvailableDays([]));
    dispatch(handleStartCalender(""));
    dispatch(handleEndCalender(""));
    dispatch(handleStartCalenderDate(""));
    dispatch(handleEndCalenderDate(""));
    setAccessibleTo("");
    setEventType("");
    setStatus("");
    setCategory("");
    setDays([]);
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    dispatch(handleSearchSlice(inputValue));
  }, [inputValue]);

  useEffect(() => {
    startCalenderDate !== "" && setshowFilterData(true);
  }, [startCalenderDate]);

  useEffect(() => {
    eventMonth !== "" && setshowFilterData(true);
  }, [eventMonth]);

  // useEffect(() => {
  //   FilterBtn();
  //   setshowFilterData(false);
  // }, [filterType]);

  return (
    <div className="SrearchAndFilterMainDiv">
      <div className="SrearchAndFilterSubMainDiv">
        <div className="searchbarDivForIcon">
          <div className="searchDiv">
            <div className="searchIcon">
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
              />
            </div>
            <input
              className="searchDivInput"
              placeholder={"   Search"}
              value={inputValue}
              onChange={(e) => {
                setinputValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchFun();
                }
              }}
            />
          </div>
        </div>
        {filterType !== "fnbFilter" && (
          <Popover
            position={Position.BOTTOM_LEFT}
            shouldCloseOnExternalClick={false}
            content={({ close }) => (
              <Pane>
                <div className="filterLstMain">
                  <div
                    className="filterCrossIcon"
                    onClick={() => {
                      close();
                      FilterBtn();
                      setshowFilterData(false);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/remove.png`}
                      alt="removeIcon"
                    />
                  </div>
                  <div className="filterLst">
                    <p htmlFor="">Status</p>
                    {showStatus ? (
                      <Select
                        width={244}
                        height={44}
                        className="mgbottominputSB"
                        name="unitNo"
                        value={status1}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        {dropdownOption.map((i, index) => {
                          return <option value={i.name}>{i.name}</option>;
                        })}
                      </Select>
                    ) : (
                      <Select
                        width={244}
                        height={44}
                        className="mgbottominputSB"
                        name="unitNo"
                        value={status1}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        <option value="OPEN" selected>
                          Open
                        </option>
                        <option value="CLOSE">Close</option>
                        <option value="COMING_SOON">Coming Soon</option>
                        <option value="COMPLETED">Completed</option>
                      </Select>
                    )}
                  </div>
                  {showAvailability && (
                    <div className="filterLst">
                      <p htmlFor="">Availability</p>
                      <WeekCalender
                        days={days}
                        handleChangeDays={handleChangeDays}
                        filterSelect={true}
                      />
                    </div>
                  )}
                  {showDate && (
                    <div className="filterLst">
                      <p htmlFor="">DATE</p>
                      <DateRangePicker
                        format={"dd-MM-yyyy"}
                        onChange={handleCallbackforClosingOn}
                        showOneCalendar
                        style={{ width: "244px", zIndex: "3000" }}
                      />
                    </div>
                  )}
                  {showEventAccessibleTo &&
                    process.env.REACT_APP_PRODUCT === "DLF" && (
                      <div className="filterLst">
                        <p htmlFor="">Accessible To</p>
                        <Select
                          width={244}
                          height={44}
                          className="mgbottominputSB"
                          name=""
                          value={accessibleTo1}
                          onChange={(e) => {
                            setAccessibleTo(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            Select
                          </option>
                          <option value="ALL">All</option>
                          <option value="MEMBERS" selected>
                            Member
                          </option>
                          <option value="NON_MEMBERS">Non Members</option>
                        </Select>
                      </div>
                    )}
                  {showAccessibleTo && (
                    <div className="filterLst">
                      <p htmlFor="">Accessible To</p>
                      <Select
                        width={244}
                        height={44}
                        className="mgbottominputSB"
                        name=""
                        value={accessibleTo1}
                        onChange={(e) => {
                          setAccessibleTo(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        <option value="All" selected>
                          All
                        </option>
                        <option value="Member">Member</option>
                        <option value="Family Member">Family Member</option>
                      </Select>
                    </div>
                  )}
                  {/* facility category */}
                  {showCategory && (
                    <div className="filterLst">
                      <p htmlFor="">{categoryTitle}</p>
                      <Select
                        width={244}
                        height={44}
                        className="mgbottominputSB"
                        name=""
                        value={category1}
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        {categoryArr?.map((i, index) => {
                          return <option value={i.name}>{i.name}</option>;
                        })}
                      </Select>
                    </div>
                  )}
                  {showEventType && (
                    <div className="filterLst">
                      <p htmlFor="">Event Type</p>
                      <Select
                        width={244}
                        height={44}
                        className="mgbottominputSB"
                        name="eventType1"
                        value={eventType1}
                        onChange={(e) => {
                          setEventType(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        <option value="PAID">Paid</option>
                        <option value="FREE">
                          {process.env.REACT_APP_PRODUCT === "DLF"
                            ? "With Compliments"
                            : "Free"}
                        </option>
                      </Select>
                    </div>
                  )}
                  <div className="filterbtnDiv">
                    <button
                      onClick={() => {
                        handleApplyFun();
                        close();
                      }}
                      className="filterApply"
                    >
                      Apply
                    </button>{" "}
                    <button
                      className="filterReset"
                      onClick={() => {
                        ClearAllFilter();
                        close();
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Pane>
            )}
          >
            <div>
              <button
                className="filterMainBtn"
                onClick={() => {
                  FilterBtn();
                  setshowFilterData(false);
                }}
              >
                <div className="filterDiv">
                  <div className="filterLeftIconDiv">
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/rightSearch2.png`}
                      alt=""
                    />
                  </div>
                  <div className="filterLineIconDiv">
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/line2.png`}
                      alt=""
                    />
                  </div>
                  <span className="filterMargin">Filters</span>
                  <div className="filterRightIconDiv">
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/leftSearch2.png`}
                      alt=""
                    />
                  </div>
                </div>
              </button>
            </div>
          </Popover>
        )}
      </div>
      {filterType !== "fnbFilter" && (
        <div className="fiterdataDiv">
          <div className="selectedFilterData">
            {/* Events status */}
            {showFilterData && status && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleStatusSlice(""));
                  setStatus("");
                }}
              >
                Status -
                {status == "OPEN"
                  ? "Open"
                  : status == "CLOSE"
                  ? "Close"
                  : status == "COMING_SOON"
                  ? "Coming Soon"
                  : status == "COMPLETED"
                  ? "Completed"
                  : status}
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                />
              </div>
            )}
            {/* Date */}
            {showFilterData && startCalender && endCalender && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleStartCalender(""));
                  dispatch(handleEndCalender(""));
                  setStartDate("");
                  setEndDate("");
                }}
              >
                {startCalender === endCalender ? (
                  <>
                    {moment(startCalender, "YYYY-MM-DD").format("DD MMMM YYYY")}
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                    />
                  </>
                ) : (
                  <>
                    {moment(startCalender, "YYYY-MM-DD").format("DD MMMM YYYY")}
                    -{moment(endCalender, "YYYY-MM-DD").format("DD MMMM YYYY")}
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                    />
                  </>
                )}
              </div>
            )}
            {/* Calender date  */}
            {showFilterData && startCalenderDate && endCalenderDate && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleStartCalenderDate(""));
                  dispatch(handleEndCalenderDate(""));
                }}
              >
                {startCalender === endCalender ? (
                  <>
                    {moment(startCalenderDate, "YYYY-MM-DD").format(
                      "DD MMMM YYYY"
                    )}
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                    />
                  </>
                ) : (
                  <>
                    {moment(startCalenderDate, "YYYY-MM-DD").format(
                      "DD MMMM YYYY"
                    )}
                    -
                    {moment(endCalenderDate, "YYYY-MM-DD").format(
                      "DD MMMM YYYY"
                    )}
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                    />
                  </>
                )}
              </div>
            )}
            {/* accessible to */}
            {showFilterData && accessibleTo && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleAccessibleToSlice(""));
                  setAccessibleTo("");
                }}
              >
                Accessible To -{" "}
                {accessibleTo == "ALL"
                  ? "All"
                  : accessibleTo == "MEMBERS"
                  ? "Member"
                  : accessibleTo == "NON_MEMBERS"
                  ? "Non Members"
                  : accessibleTo}{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                />
              </div>
            )}
            {/* category */}
            {showFilterData && category && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleCategorySlice(""));
                  setCategory("");
                }}
              >
                Category - {category}{" "}
                <img
                  src={process.env.PUBLIC_URL + "/images/filterRemove.png"}
                />
              </div>
            )}
            {/* Event Type */}
            {showFilterData && eventType && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handlEventTypeSlice(""));
                  setEventType("");
                }}
              >
                Event Type - {eventType}{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                />
              </div>
            )}
            {/* Available Days */}
            {showFilterData && availableDays.length > 0 && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleAvailableDays([]));
                  setDays([]);
                }}
              >
                Available Days - {availableDays.join(",")}
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                />
              </div>
            )}
            {showFilterData && eventDateCalender && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleCalender(""));
                }}
              >
                {moment(eventDateCalender, "DD-MM-YYYY").format("DD MMMM YYYY")}{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                />
              </div>
            )}
            {showFilterData && eventMonth && (
              <div
                type="button"
                className="filterDisplayNav"
                onClick={() => {
                  dispatch(handleEventMonth(""));
                }}
              >
                {moment(eventMonth, "MM-YYYY").format("MMMM YYYY")}{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/filterRemove.png`}
                />
              </div>
            )}
          </div>
          {showFilterData &&
          (startCalender ||
            status ||
            accessibleTo ||
            eventType ||
            availableDays.length > 0 ||
            category) ? (
            <>
              <div className="fiterClearDiv" onClick={() => ClearAllFilter()}>
                Clear
              </div>
            </>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchAndFilter;
