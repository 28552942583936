import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EventConfig } from "../EventConfig";
import { EventsListItems } from "../EventsListItem/EventsListItems";
import styles from "./eventsList.module.css";

export const EventsList = ({
  searchParam,
  setSearchParam,
  paramId,
  setparamId,
}) => {
  const [activeTabList, setActiveTabList] = useState();
  const onClickTabItem = (tabId) => {
    setActiveTabList(tabId);
  };
  const { events, eventsActiveTab } = useSelector((state) => state.eventsSlice);
  const navigate = useNavigate();
  // const [searchParam, setSearchParam] = useSearchParams();
  // const [paramId, setparamId] = useState(+searchParam.get("event_id"));
  // const {id:paramId}=useParams()

  const handleCalender = () => {
    navigate(EventConfig.EventCalender);
  };
  //-------title------------------
  const [currentCategory, setCurrentCategory] = useState("");
  const scrollableListRef = useRef(null);
  const cardRefs = useRef([]);

  const handleCardRef = (index) => (ref) => {
    cardRefs.current[index] = ref;
  };
  //-------------------------------
  const containerRef = useRef(null);

  useEffect(() => {
    setActiveTabList(parseInt(eventsActiveTab));
  }, [eventsActiveTab]);

  useEffect(() => {
    const element = containerRef.current.querySelector(
      `[data-id="${paramId}"]`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [paramId]);

  useEffect(() => {
    setparamId(+searchParam.get("event_id"));
  }, [searchParam]);

  return (
    <div className={styles.mainDivEventsList}>
      <div className={styles.topNavEventsList}>
        <h1>
          {process.env.REACT_APP_PRODUCT == "DLF" ? "List" : "Events List"}
        </h1>
        <div className={styles.calenderCtaDiv} onClick={handleCalender}>
          <img
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/calender.png`}
            alt="Event calender"
          />
        </div>
      </div>
      {/* <span className={styles.monthHeader}> <p>April</p> </span> */}
      <div className={styles.middleDivEventsList} ref={containerRef}>
        {Object.values(events) &&
          Object.values(events)?.map((item, index) => {
            return (
              <div key={item.id} data-id={item.id}>
                <EventsListItems
                  key={item.id}
                  // dataCategory={item.date}
                  reef={handleCardRef(index)}
                  classNamee="food-item-card"
                  onClickTabItem={onClickTabItem}
                  activeTabList={activeTabList}
                  {...item}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
