import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";
import axios from "axios";

export const getClubAdminProfile = createAsyncThunk(
  "getClubAdminProfile",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/member/get_club_admin_profile",
        { user_id: loadData("Token"), club_id: args },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const res = await response?.data;
      if (res?.status == "success") {
        const data = res?.role_details[0];
        return data;
      } else {
        return rejectWithValue("Failed to fetch user profile");
      }
    } catch (err) {
      return rejectWithValue(
        "Oops found an error in getClubAdminProfile",
        err.response.data
      );
    }
  }
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    userId: "",
    community: "",
    adminName: "",
    clubId: "",
    dlfClubId: "",
    paramClubId: "",
    modules: [],
    loading: false,
    error: null,
    eventRights: {},
    facilityRights: {},
    fnBRights: {},
    salonRights: {},
    spaRights: {},
    venueRights: {},
    conciergeRights: {},
    roleManagementRights: {},
    condominiumArr: [],
    clubsArr: [],
    initialRoute: window.location.pathname || null,
    profileDetails: {},
  },
  reducers: {
    searchUser: (state, action) => {
      state.searchData = action.payload;
    },
    handleEventsActiveTab: (state, action) => {
      state.eventsActiveTab = action.payload;
    },
    handleProfile: (state, action) => {
      state.community = action.payload.club_name;
      state.adminName = action.payload.username;
      state.clubId = action.payload.club_id;
      state.profileDetails = action.payload;
    },
    handleUserKey: (state, action) => {
      state.userId = action.payload;
    },
    handleClubKey: (state, action) => {
      state.paramClubId = action.payload;
    },
    handleCondominium: (state, action) => {
      state.condominiumArr = action.payload;
    },
    handleClub: (state, action) => {
      state.clubsArr = action.payload;
    },
    handleInitialRoute: (state, action) => {
      state.initialRoute = action.payload;
    },
    handleDLFClubId: (state, action) => {
      state.dlfClubId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClubAdminProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClubAdminProfile.fulfilled, (state, action) => {
        state.loading = false;
        const allModules = action?.payload?.modules;
        state.modules =
          action?.payload?.modules?.length > 0 ? [...allModules] : [];
        for (let i = 0; i < allModules?.length; i++) {
          if (allModules[i]?.name == "Salon") {
            state.salonRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Events") {
            state.eventRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Recreation & Others") {
            state.facilityRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Food & Beverage") {
            state.fnBRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Spa") {
            state.spaRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Meet & Celebrate") {
            state.venueRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Concierge") {
            state.conciergeRights = allModules[i]?.rights;
            continue;
          }
          if (allModules[i]?.name == "Role Management") {
            state.roleManagementRights = allModules[i]?.rights;
            continue;
          }
        }
      })
      .addCase(getClubAdminProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const {
  searchUser,
  handleProfile,
  handleEventsActiveTab,
  handleUserKey,
  handleClubKey,
  handleCondominium,
  handleClub,
  handleInitialRoute,
  handleDLFClubId,
} = authSlice.actions;
export default authSlice.reducer;
