import React from "react";
import Select from "react-select";

export const CustomSelect = ({
  options,
  value,
  handleOnChangeDropdown,
  padding,
  border,
  isEdit,
  type,
  customStyles,
  height,
}) => {
  return (
    <Select
      isDisabled={isEdit}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: "1px solid #DEDFE1 !important",
          outline: "none",
          borderRadius: padding,
          boxShadow: "none",
          height: "40px",
        }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          height: height && height,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        border: "none",
        outline: "none",
        colors: {
          ...theme.colors,
          primary25: `var(--bgColorActiveTab)`,
          primary: `var(--fontColorActiveTab1)`,
        },
      })}
      className="basic-single"
      classNamePrefix="select"
      value={value}
      // formatGroupLabel={formatGroupLabel}
      onChange={handleOnChangeDropdown}
      name="name"
      placeholder="Select"
      options={options}
    />
  );
};
