import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";

// homepage all events
export const getAllEvents = createAsyncThunk(
  "getUsers",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/event/get-all-events",
        {
          method: "GET",
          body: JSON.stringify({
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: "Admin",
          }),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response?.data);
    }
  }
);

//eventsDetails
export const getEventsDetailsbyId = createAsyncThunk(
  "getEventsDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/event/get-event-details`,
        {
          method: "POST",
          body: JSON.stringify({
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: "Admin",
            event_id: id,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue(
        "Opps found an error in getEventsDetailsById",
        err.response.data
      );
    }
  }
);

//search
export const eventFilter = createAsyncThunk(
  "eventFilter",
  async ({ args, paramId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/event/search-events",
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return { data: result, paramId };
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

//bookingHistory
export const getBookingHistory = createAsyncThunk(
  "getBookingHistory",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/event/booking-history-admin",
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

//bookingHistorybyId
export const getBookingHistoryById = createAsyncThunk(
  "getBookingHistoryById",
  async ({ args, eventsActiveTab }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/event/get-event-booking`,
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const eventsSlice = createSlice({
  name: "eventsSlice",
  initialState: {
    events: [],
    eventsDetails: {},
    eventsActiveTab: "",
    loading: false,
    error: null,
    EventLoading: true,
    bookingHistoryEvents: [],
    totalBookingHistoryCount: "",
    Loader: true,
  },
  reducers: {
    searchUser: (state, action) => {
      state.searchData = action?.payload;
    },
    handleEventsActiveTab: (state, action) => {
      state.eventsActiveTab = action?.payload;
    },
    handleEventDeatils: (state, action) => {
      state.eventsDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // get all events data
      .addCase(getAllEvents.pending, (state, action) => {
        state.loading = true;
        state.EventLoading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.eventsActiveTab = action?.payload?.data?.events[0]?.id;
        state.events = action?.payload?.data?.events;
        state.EventLoading = false;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.loading = false;
        state.EventLoading = false;
        state.error = action?.payload;
      })
      // get event details by id data
      .addCase(getEventsDetailsbyId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEventsDetailsbyId.fulfilled, (state, action) => {
        state.loading = false;
        state.eventsDetails = action?.payload?.data?.event;
      })
      .addCase(getEventsDetailsbyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      // search event data
      .addCase(eventFilter.pending, (state, action) => {
        state.EventLoading = true;
        state.loading = false;
        state.events = [];
        state.eventsDetails = {};
        state.eventsActiveTab = "";
      })
      .addCase(eventFilter.fulfilled, (state, action) => {
        state.EventLoading = true;
        state.loading = false;
        state.events = action?.payload?.data?.data?.events;
        state.eventsDetails =
          action?.payload?.data?.data?.events?.length == 0 && {};
        // state.eventsActiveTab = action?.payload?.data?.events[0]?.id ?
        //  action?.payload?.data?.events[0]?.id
        //   : "";
        state.eventsActiveTab = action?.payload?.paramId
          ? action?.payload?.paramId
          : action?.payload?.data?.data?.events[0]?.id;
        state.EventLoading = false;
      })
      .addCase(eventFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        state.EventLoading = false;
      })
      // booking history data
      .addCase(getBookingHistory.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryEvents = action?.payload?.data?.bookings;
        state.totalBookingHistoryCount = action?.payload?.data?.total_records;
        state.Loader = false;
      })
      .addCase(getBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.Loader = false;
      })
      // booking history by id data
      .addCase(getBookingHistoryById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBookingHistoryById.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryEvents = action?.payload?.data?.bookings;
        state.totalBookingHistoryCount = action?.payload?.data?.total_records;
      })
      .addCase(getBookingHistoryById.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export const { searchUser, handleEventsActiveTab, handleEventDeatils } =
  eventsSlice.actions;
export default eventsSlice.reducer;
