import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

// ----------------email regex---------------------
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// -----------------mobile number regex---------------
export function validatePhoneNumber(input_str) {
  // var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  var re = /^([+]\d{2}[ ])?\d{10}$/;
  return re.test(input_str);
}

// -------------------valid name regex-----------------
export function validatename(input_str) {
  var re = /^[a-zA-Z][a-zA-Z ]*$/;
  return re.test(input_str);
}

// -------------------only Number regex-----------------
export function validateOnlyNumbers(str) {
  return /^\d+$/.test(str);
}
// -----------------------------------------------------
export const checkIfBetweenDiscountPeriod = (start_date, end_date, start_time, end_time, effectiveTimeZone) => {
  if (!start_date || !end_date) {
    return false;
  } else {
    let currDateTime = effectiveTimeZone ? dayjs().tz(effectiveTimeZone).format('YYYY-MM-DD hh:mm A') : dayjs().format('YYYY-MM-DD hh:mm A');
    let startDateTime = dayjs(`${start_date} ${start_time}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');
    let endDateTime = dayjs(`${end_date} ${end_time}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');
    let sd = dayjs(startDateTime, 'YYYY-MM-DD hh:mm A');
    let ed = dayjs(endDateTime, 'YYYY-MM-DD hh:mm A');
    let cd = dayjs(currDateTime, 'YYYY-MM-DD hh:mm A');
    let diffCurrStart = cd.diff(sd, 'minute');
    let diffEndCurr = ed.diff(cd, 'minute');
    if (diffCurrStart >= 0 && diffEndCurr >= 0) {
      return true;
    }
    return false;
  }
};

// ---------------------------------------------------------
export const checkOfferIsBetweenDiscountPeriod = (
  start_date, //offer start date
  end_date,   //offer end date
  start_time, //offer start time
  end_time,   // offer end time
  selected_date, // selected start date 
  selected_start_time, // selected start time-selected end time
  selected_end_time,
  selsected_end_date // selected end date
) => {
  let selected_time = selected_start_time + "-" + selected_end_time;
  if (!start_date || !end_date) {
    return false;
  } else {
    let selectedStartDateTime = dayjs(
      dayjs(`${selected_date} ${selected_time?.split('-')[0]}`, 'D-M-YYYY hh:mm A')
    ).format('YYYY-MM-DD hh:mm A');
    let selectedEndDateTime = dayjs(
      dayjs(
        `${selsected_end_date ? selsected_end_date : selected_date} ${selected_time?.split('-')[1]}`,
        'D-M-YYYY hh:mm A'
      )
    ).format('YYYY-MM-DD hh:mm A');

    let offerStartDateTime = dayjs(`${start_date} ${start_time}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');
    let offerEndDateTime = dayjs(`${end_date} ${end_time}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD hh:mm A');

    let osdt = dayjs(offerStartDateTime, 'YYYY-MM-DD hh:mm A');
    let oedt = dayjs(offerEndDateTime, 'YYYY-MM-DD hh:mm A');

    let ssdt = dayjs(selectedStartDateTime, 'YYYY-MM-DD hh:mm A');
    let sedt = dayjs(selectedEndDateTime, 'YYYY-MM-DD hh:mm A');

    let diffCurrStart = ssdt.diff(osdt, 'minute');
    let diffEndCurr = oedt.diff(sedt, 'minute');

    if (diffCurrStart >= 0 && diffEndCurr >= 0) {
      return true;
    }
    return false;
  }
};

export const rupee = new Intl.NumberFormat('en-IN')

