import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";
import axios from "axios";

// singleUserRoleData
export const getSingleUserRoleData = createAsyncThunk(
  "getSingleUserRoleData",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/role-management/get-all-users`,
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response?.data;
      return result;
    } catch (err) {
      return rejectWithValue(
        "Oops found an error in getSingleUserRoleData",
        err.response.data
      );
    }
  }
);

// getUserData
export const getUserData = createAsyncThunk(
  "getUserData",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/role-management/get-all-users`,
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response?.data;
      return result;
    } catch (err) {
      return rejectWithValue(
        "Oops found an error in getUserData",
        err.response.data
      );
    }
  }
);

export const clubsSlice = createSlice({
  name: "clubsSlice",
  initialState: {
    singleUserRoleData: {},
    userData: [],
    loading: false,
    error: null,
    records: 0,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      // get user by id data
      .addCase(getSingleUserRoleData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSingleUserRoleData.fulfilled, (state, action) => {
        state.loading = false;
        state.singleUserRoleData = action?.payload?.data?.users;
      })
      .addCase(getSingleUserRoleData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // get user data
      .addCase(getUserData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action?.payload?.data?.users;
        state.records = action?.payload?.data?.records;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default clubsSlice.reducer;
