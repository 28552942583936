import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
export const exportToCSV = (apiData, fileName) => {
  // Set the file type and extension
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Convert the data to worksheet
  const ws = XLSX.utils.json_to_sheet(apiData);

  // Customize the style for the header row (bold and left-aligned)
  const headerStyle = {
    font: { bold: true }, // Make the text bold for the header
    alignment: { horizontal: "left" }, // Align header cells to the left
  };

  // Set the header style for each cell in the first row
  const range = XLSX.utils.decode_range(ws["!ref"]); // Get the range of cells
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const headerCell = XLSX.utils.encode_cell({ c: C, r: range.s.r }); // Encode the cell coordinates
    ws[headerCell].s = headerStyle; // Apply the header style to the cell
  }

  // Loop through the entire data (rows and columns) and set left alignment for all cells
  for (let R = range.s.r + 1; R <= range.e.r; ++R) {
    // Start from the first data row (after header)
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ c: C, r: R });

      // Apply left alignment to every cell (data rows and total row)
      if (ws[cellAddress]) {
        ws[cellAddress].s = { alignment: { horizontal: "left" } };
      }
    }
  }

  // Apply bold style to the last row (total row)
  const lastRowIndex = range.e.r;
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const lastRowCell = XLSX.utils.encode_cell({ c: C, r: lastRowIndex });
    if (ws[lastRowCell]) {
      ws[lastRowCell].s = {
        font: { bold: true }, // Make the last row bold
        alignment: { horizontal: "left" }, // Ensure the alignment is still left for the last row
      };
    }
  }

  // Create the workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "data");

  // Convert the workbook to array buffer
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  // Save the file
  FileSaver.saveAs(data, fileName + fileExtension);
};
