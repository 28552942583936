import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  eventFilter,
  getAllEvents,
  getEventsDetailsbyId,
} from "../../Components/Redux/eventsSlice";
import { EventsDetailsCard } from "../EventsDetailsCard/EventsDetailsCard";
import styles from "./eventsDetails.module.css";
import { BookEventModal } from "../BookEventModal/BookEventModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Popover, Pane, Position } from "evergreen-ui";
import Tooltip from "@mui/material/Tooltip";
import { EventConfig } from "../EventConfig";
import { ClickAwayListener } from "@mui/material";
import { EventGallery } from "../EventGallery/EventGallery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BookingSuccess } from "../BookingSuccess/BookingSuccess";
import { BookingFailed } from "../BookingSuccess/BookingFailed";
import { loadData } from "../../Utils/localStorage";
import { DeleteModal } from "./DeleteModal/DeleteModal";
import { AcBooking } from "../../AC/Event/Booking/AcBooking";
import { ParticipantBooking } from "../../AC/Event/ParticipantBooking/ParticipantBooking";

export const EventsDetails = () => {
  const dispatch = useDispatch();
  const { eventsDetails, eventsActiveTab, events } = useSelector(
    (state) => state.eventsSlice
  );
  const { profileDetails } = useSelector((state) => state.authSlice);
  const { eventRights } = useSelector((state) => state.authSlice);
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("event_id");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  //---------------cancel Event btn status
  const [isDisabled, setIsDisabled] = useState(false);

  // ----tooltip-----------------
  const [eventStatus, setEventStatus] = useState(eventsDetails?.status);
  const [activePopover, setActivePopover] = useState("");
  const [openTootip, setOpenTooltip] = React.useState(false);

  const [isNonMember, setIsNonMember] = useState();

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const [openTootip1, setOpenTooltip1] = React.useState(false);

  const handleTooltipClose1 = () => {
    setOpenTooltip1(false);
  };

  const handleTooltipOpen1 = () => {
    if (process.env.REACT_APP_PRODUCT == "DLF") {
      if (eventRights?.update == 1) {
        setOpenTooltip1(true);
      } else {
        toast.error("You don't have update access rights");
      }
    } else {
      setOpenTooltip1(true);
    }
  };

  // cancelModal
  const [cancelModal, setCancelModal] = useState(false);
  const onOpenCancelModal = () => setCancelModal(true);
  const onCloseCancelModal = () => setCancelModal(false);

  //gallery
  const [gallery, setGallery] = useState(false);
  const galleryOpen = () => {
    if (process.env.REACT_APP_PRODUCT == "DLF") {
      if (eventRights?.update == 1) {
        setGallery(true);
      } else {
        toast.error("You don't have update access rights");
      }
    } else {
      setGallery(true);
    }
  };
  const galleryClose = () => setGallery(false);

  //success
  const [success, setSuccess] = useState(false);
  const onOpenSuccess = () => setSuccess(true);
  const onCloseSuccess = () => setSuccess(false);

  //failed
  const [failed, setFailed] = useState(false);
  const onOpenFailed = () => setFailed(true);
  const onCloseFailed = () => setFailed(false);

  //book event error state
  const [bookEventError, setBookEventError] = useState("");

  //book event msg state
  const [bookMsg, setBookMsg] = useState("");

  //---------------------------------------------------------------
  const handleUpdateStatus = (updated_status) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      status: updated_status,
      event_id: eventsDetails?.id,
    };
    if (process.env.REACT_APP_PRODUCT != "DLF") {
      payload["community_type"] = loadData("Community_type");
    }
    setIsDisabled(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/event/event-status-change`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        toast(`${res.message}`, {
          type: "success",
        });
        setIsDisabled(false);
        dispatch(getEventsDetailsbyId(eventsActiveTab));
        // dispatch(getAllEvents());
        let payload1 = {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: EventConfig.requestBy,
        };

        // navigate("/");
        setTimeout(() => {
          dispatch(eventFilter({ args: payload1, paramId: eventId }));
        }, 1000);
      })
      .catch((err) => {
        setIsDisabled(false);
      });
  };

  //upadte open
  const handleOpenFun = () => {
    setEventStatus("OPEN");
    navigate(
      `${process.env.REACT_APP_PATH}/events/edit-event/${eventsDetails.id}`
    );
  };
  const handleCloseFun = () => {
    setEventStatus("CLOSE");
    handleUpdateStatus("CLOSE");
  };
  //------------------Ac---------------------
  const [openBookingAc, setOpenBookingAc] = useState(false);

  //---------------------------------------------
  useEffect(() => {
    eventsActiveTab && dispatch(getEventsDetailsbyId(eventsActiveTab));
  }, [eventsActiveTab]);

  useEffect(() => {
    setEventStatus(eventsDetails?.status);
  }, [eventsDetails]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <div className={styles.mainDivEventsDetails}>
      <BookingSuccess
        open={success}
        onCloseModal={onCloseSuccess}
        isNonMember={isNonMember}
        bookingStatus={eventsDetails?.booking_type}
        msg={bookMsg}
      />
      <BookingFailed
        open={failed}
        onCloseModal={onCloseFailed}
        error={bookEventError}
      />
      {(process.env.REACT_APP_PRODUCT === "NUCLEUS") ? (
        <ParticipantBooking
          open={openBookingAc}
          onCloseModal={() => setOpenBookingAc(false)}
          onOpen={onOpenSuccess}
          failedStatus={onOpenFailed}
          setBookEventError={setBookEventError}
        />
      ) : process.env.REACT_APP_PRODUCT != "DLF" ? (
        <AcBooking
          open={openBookingAc}
          onCloseModal={() => setOpenBookingAc(false)}
          onOpen={onOpenSuccess}
          failedStatus={onOpenFailed}
          setBookEventError={setBookEventError}
        />
      ) : (
        <BookEventModal
          open={open}
          onCloseModal={onCloseModal}
          onOpen={onOpenSuccess}
          failedStatus={onOpenFailed}
          setBookEventError={setBookEventError}
          isNonMember={isNonMember}
          setIsNonMember={setIsNonMember}
          setBookMsg={setBookMsg}
        />
      )}
      <EventGallery
        open={gallery}
        onCloseModal={galleryClose}
        viewId={eventsDetails?.id}
        name={eventsDetails?.name}
      />
      <DeleteModal open={cancelModal} onCloseModal={onCloseCancelModal} />
      <>
        <div className={styles.topDivEventsDetails}>
          <span>
            <h1>{eventsDetails?.name}</h1>
            <p>{eventsDetails?.event_venue?.name}</p>
          </span>
          <div className={styles.ctaDiv}>
            {eventsDetails?.status == "OPEN" &&
            (process.env.REACT_APP_PRODUCT === "NUCLEUS") &&
            eventsDetails?.is_participant_form ? (
              <div
                className={styles.topDivImgEventsDetails}
                onClick={() => {
                  setOpenBookingAc(true);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/bookIcon.png`}
                  alt="bookIcon"
                />
              </div>
            ) : (
              eventsDetails?.status == "OPEN" &&
              process.env.REACT_APP_PRODUCT !== "NUCLEUS" && (
                <div
                  className={styles.topDivImgEventsDetails}
                  onClick={() => {
                    if (process.env.REACT_APP_PRODUCT == "DLF") {
                      if (eventRights?.create == 1) {
                        onOpenModal();
                      } else {
                        toast.error("You don't have create access rights");
                      }
                      setActivePopover("edit");
                    } else {
                      setOpenBookingAc(true);
                    }
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/bookIcon.png`}
                    alt="bookIcon"
                  />
                </div>
              )
            )}
            {(process.env.REACT_APP_PRODUCT !== "DLF" ||
              (process.env.REACT_APP_PRODUCT == "DLF" &&
                eventsDetails?.status !== "COMPLETED")) && (
              <div
                className={styles.topDivImgEventsDetails}
                onClick={galleryOpen}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addImage.png`}
                />
              </div>
            )}
            {(process.env.REACT_APP_PRODUCT !== "DLF" ||
              (process.env.REACT_APP_PRODUCT == "DLF" &&
                eventsDetails?.status !== "COMPLETED")) && (
              <Popover
                style={{
                  left: "716px",
                  top: "294px",
                }}
                content={
                  <Pane className={styles.hamsburgerDiv}>
                    <div
                      className={
                        activePopover == "edit"
                          ? styles.popoverDivActive + " " + styles.popoverDiv
                          : styles.popoverDiv
                      }
                      onClick={() => {
                        if (process.env.REACT_APP_PRODUCT == "DLF") {
                          if (eventRights?.update == 1) {
                            navigate(
                              `${process.env.REACT_APP_PATH}/events/edit-event/${eventsActiveTab}`
                            );
                          } else {
                            toast.error("You don't have update access rights");
                          }
                          setActivePopover("edit");
                        } else {
                          navigate(
                            `${process.env.REACT_APP_PATH}/events/edit-event/${eventsActiveTab}`
                          );
                          setActivePopover("edit");
                        }
                      }}
                    >
                      <p>Edit Event</p>
                    </div>
                    <div
                      className={
                        activePopover == "cancel"
                          ? styles.popoverDivActive + " " + styles.popoverDiv
                          : styles.popoverDiv
                      }
                      onClick={() => {
                        if (process.env.REACT_APP_PRODUCT == "DLF") {
                          if (eventRights?.delete == 1) {
                            onOpenCancelModal();
                          } else {
                            toast.error("You don't have delete access rights");
                          }
                          setActivePopover("cancel");
                        } else {
                          setActivePopover("cancel");
                          onOpenCancelModal();
                        }
                      }}
                    >
                      <p>Cancel Event</p>
                    </div>
                    <div
                      onClick={() => {
                        setActivePopover("changeStatus");
                      }}
                    >
                      <ClickAwayListener onClickAway={handleTooltipClose1}>
                        <Tooltip
                          placement="left-start"
                          title={
                            <div className={styles.tooltipChangeStatus}>
                              <p
                                className={
                                  eventStatus == "OPEN" && styles.status
                                }
                                onClick={() => {
                                  eventStatus !== "OPEN" && handleOpenFun();
                                }}
                              >
                                Open
                              </p>
                              <p
                                className={
                                  eventStatus == "CLOSE" && styles.status
                                }
                                onClick={() => {
                                  eventStatus !== "CLOSE" && handleCloseFun();
                                }}
                              >
                                Close
                              </p>
                            </div>
                          }
                          onClose={handleTooltipClose1}
                          open={openTootip1}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                        >
                          <p
                            className={
                              activePopover == "changeStatus"
                                ? styles.popoverDivActive +
                                  " " +
                                  styles.popoverDiv
                                : styles.popoverDiv
                            }
                            onClick={handleTooltipOpen1}
                          >
                            Change Status
                          </p>
                        </Tooltip>
                      </ClickAwayListener>
                    </div>
                  </Pane>
                }
                position={Position.LEFT_BOTTOM}
              >
                <div className={styles.topDivImgEventsDetails}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/hamburger1.png`}
                  />
                </div>
              </Popover>
            )}
          </div>
        </div>
        <div className={styles.midDivEventsDetails}>
          <EventsDetailsCard {...eventsDetails} />
        </div>
        {/* <ToastContainer /> */}
      </>
    </div>
  );
};
