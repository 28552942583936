import React from "react";
//STEP 1:
//create components using React.lazy
const AcTheme = React.lazy(() => import("./AcTheme"));
const DlfTheme = React.lazy(() => import("./DlfTheme"));
const AlfuttaimTheme = React.lazy(() =>
  import("./AlfuttaimTheme/AlfuttaimTheme")
);
export const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = process.env.REACT_APP_PRODUCT;
  return (
    <>
      <React.Suspense fallback={<></>}>
        {CHOSEN_THEME === "DLF" ? (
          <DlfTheme />
        ) : CHOSEN_THEME === "ALFUTTAIM" ? (
          <AlfuttaimTheme />
        ) : (
          <AcTheme />
        )}
      </React.Suspense>
      {children}
    </>
  );
};
