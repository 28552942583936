import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";
import { toast } from "react-toastify";
export const getDesignation = createAsyncThunk(
  "getDesignation",
  async ({ args }, { rejectWithValue }) => {
    try {
      let body = {
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/get-designations`,
        {
          method: "POST",
          body: JSON.stringify({ ...body }),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const getDepartments = createAsyncThunk(
  "getDepartments",
  async ({ args }, { rejectWithValue }) => {
    try {
      let body = {
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/get-departments`,
        {
          method: "POST",
          body: JSON.stringify({ ...body }),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const changeRequestStatus = createAsyncThunk(
  "changeRequestStatus",
  async ({ args }, { dispatch, rejectWithValue }) => {
    try {
      let body = {
        user_id: loadData("Token"),
        club_id: loadData("Club_id"),
        status: args.statusChangeArguments.value,
        id: args.statusChangeArguments.id,
        request_by: "Admin",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/udpate-request-callback`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      if (result?.success) {
        dispatch(getCallbackRequest({ ...args.getHistoryPayload }));
      }
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);
export const getDirectoryData = createAsyncThunk(
  "getDirectoryData",
  async (args, { rejectWithValue }) => {
    try {
      const body = { ...args };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/get-all-employees`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const addEmployeeData = createAsyncThunk(
  "addEmployeeData",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const body = { ...args.requestBody };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/create-employee`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      if (result.success) {
        dispatch(getDirectoryData({ ...args.refreshBody }));
        dispatch(setModalState(false));
      }
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);
export const updateEmployeeData = createAsyncThunk(
  "updateEmployeeData",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const body = { ...args.requestBody };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/update-employee`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      if (result.success) {
        dispatch(getDirectoryData({ ...args.updateBody }));
        dispatch(setModalState(false));
      }
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const getCallbackRequest = createAsyncThunk(
  "getCallbackRequest",
  async (args, { rejectWithValue }) => {
    try {
      const body = { ...args };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/concierge/request-callbacks`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);
//
const initialState = {
  loadingRequest: false,
  requestData: [],
  loadingDepartment: false,
  departmentData: [],
  loadingDirectory: false,
  directoryData: [],
  loadingDesignation: false,
  designationData: [],
  loadingForm: false,
  totalRequestCount: 0,
  totalDirectoryCount: 0,
  modalState: false,
  edit: false,
  error: null,
};

const conciergeSlice = createSlice({
  name: "conciergeSlice",
  initialState,
  reducers: {
    setModalState: (state, action) => {
      state.modalState = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDesignation.pending, (state, action) => {
        state.loadingDesignation = true;
      })
      .addCase(getDesignation.fulfilled, (state, action) => {
        state.loadingDesignation = false;
        let responseData = action.payload.data.designations;
        let designationOptions = [];
        responseData.forEach((obj, index) => {
          let tempObj = { ...obj };
          tempObj["value"] = obj.id;
          tempObj["label"] = obj.name;
          designationOptions.push(tempObj);
        });
        state.designationData = designationOptions;
      })
      .addCase(getDesignation.rejected, (state, action) => {
        state.loadingDesignation = false;
        state.error = action.payload;
      })
      .addCase(getDepartments.pending, (state, action) => {
        state.loadingDepartment = true;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.loadingDepartment = false;
        let responseData = action.payload.data.departments;
        let designationOptions = [];
        responseData.forEach((obj, index) => {
          let tempObj = { ...obj };
          tempObj["value"] = obj.id;
          tempObj["label"] = obj.name;
          designationOptions.push(tempObj);
        });
        state.departmentData = designationOptions;
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.loadingDepartment = false;
        state.error = action.payload;
      })
      .addCase(getCallbackRequest.pending, (state, action) => {
        state.loadingRequest = true;
      })
      .addCase(getCallbackRequest.fulfilled, (state, action) => {
        state.loadingRequest = false;
        state.requestData = action.payload.data.request_callbacks;
        state.totalRequestCount = action.payload.data.records;
      })
      .addCase(getCallbackRequest.rejected, (state, action) => {
        state.loadingRequest = false;
        state.error = action.payload;
      })
      .addCase(changeRequestStatus.pending, (state, action) => {
        state.loadingRequest = false;
      })
      .addCase(changeRequestStatus.fulfilled, (state, action) => {
        state.loadingRequest = false;
      })
      .addCase(changeRequestStatus.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getDirectoryData.pending, (state, action) => {
        state.loadingDirectory = true;
      })
      .addCase(getDirectoryData.fulfilled, (state, action) => {
        state.loadingDirectory = false;
        state.directoryData = action.payload.data.employess;
        state.totalDirectoryCount = action.payload.data.records;
      })
      .addCase(getDirectoryData.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(addEmployeeData.pending, (state, action) => {
        state.loadingForm = true;
      })
      .addCase(addEmployeeData.fulfilled, (state, action) => {
        state.loadingForm = false;
        toast(`${action.payload.message}`, {
          type: "success",
        });
      })
      .addCase(addEmployeeData.rejected, (state, action) => {
        state.error = action.payload;
        toast(`${action.payload.message}`, {
          type: "error",
        });
      })
      .addCase(updateEmployeeData.pending, (state, action) => {
        state.loadingForm = true;
      })
      .addCase(updateEmployeeData.fulfilled, (state, action) => {
        state.loadingForm = false;
        toast(`${action.payload.message}`, {
          type: "success",
        });
        state.edit = false;
      })
      .addCase(updateEmployeeData.rejected, (state, action) => {
        state.error = action.payload;
        toast(`${action.payload.message}`, {
          type: "error",
        });
      });
  },
});
export const { setModalState, setEdit } = conciergeSlice.actions;

export default conciergeSlice.reducer;
