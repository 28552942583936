import React, { useEffect, useState } from "react";
import styles from "./editRole.module.css";
// import Box from "@mui/material/Box";
import { TextInput } from "evergreen-ui";
import { ButtonBorder } from "../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { loadData } from "../../../Utils/localStorage";
import axios from "axios";
import { CustomSelect } from "../../../Components/CustomSelect/CustomSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validatePhoneNumber } from "../../../Utils/Validation";
import AccordionListenerUserRights from "../AccordionListenerUserRights/AccordionListenerUserRights";
import { Avatar } from "@mui/material";
import { Person2, CameraAlt } from "@mui/icons-material";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleUserRoleData,
  getUserData,
} from "../../../Components/Redux/clubsSlice";
import PanoramaFishEyeOutlinedIcon from "@mui/icons-material/PanoramaFishEyeOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { DiscardModal } from "../../../Components/DiscardModal/DiscardModal";
import { formatData } from "../../../Utils/formatData";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
      width: 250,
    },
  },
};

const EditRole = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roleManagementRights } = useSelector((state) => state.authSlice);
  const { singleUserRoleData } = useSelector((state) => state.clubsSlice);
  const [singleUserDataState, setSingleUserDataState] = useState([]);
  const [singleUserData, setSingleUserData] = useState(singleUserDataState[0]);

  const [roleData, setRoleData] = useState({
    name: "",
    designation: "",
    contactNo: "",
    emailId: "",
    allowAccessTo: [],
    profImg: "",
  });
  const [isActive, setIsActive] = useState(
    singleUserData?.is_active == true ? true : false
  );
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectAllModules, setSelectAllModules] = useState(false);
  const [moduleNames, setModuleNames] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEditableIsDisabled, setIsEditableIsDisabled] = useState(true);
  const [profileImg, setProfileImg] = useState([]);
  const [moduleAccess, setModuleAccess] = useState({});
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [discard, setDiscard] = useState(false);

  const handleChangeProfPicture = (imageList, addUpdateIndex) => {
    setProfileImg([...imageList]);
    setRoleData({ ...roleData, profImg: imageList[0]?.dataURL });
  };

  const handleToggleChange = (moduleId, accessType) => {
    if (accessType == "read" && moduleAccess[moduleId]["read"] == true) {
      setModuleAccess((prev) => ({
        ...prev,
        [moduleId]: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
      }));
    } else if (
      accessType == "create" &&
      moduleAccess[moduleId]["create"] == false
    ) {
      setModuleAccess((prev) => ({
        ...prev,
        [moduleId]: {
          read: true,
          create: true,
          update: prev[moduleId]["update"],
          delete: prev[moduleId]["delete"],
        },
      }));
    } else if (
      accessType == "update" &&
      moduleAccess[moduleId]["update"] == false
    ) {
      setModuleAccess((prev) => ({
        ...prev,
        [moduleId]: {
          read: true,
          update: true,
          create: prev[moduleId]["create"],
          delete: prev[moduleId]["delete"],
        },
      }));
    } else if (
      accessType == "delete" &&
      moduleAccess[moduleId]["delete"] == false
    ) {
      setModuleAccess((prev) => ({
        ...prev,
        [moduleId]: {
          read: true,
          delete: true,
          create: prev[moduleId]["create"],
          update: prev[moduleId]["update"],
        },
      }));
    } else {
      setModuleAccess((prev) => ({
        ...prev,
        [moduleId]: {
          ...prev[moduleId],
          [accessType]: !prev[moduleId][accessType],
        },
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name == "contactNo") {
      setRoleData({
        ...roleData,
        [name]: value.replace(/[^0-9]/g, ""),
      });
    } else {
      setRoleData({
        ...roleData,
        [name]: value,
      });
    }
  };

  const handleModulesChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value.includes("Select All Modules")) {
      if (selectAllModules) {
        setModuleNames([]);
        setSelectAllModules(false);
      } else {
        const allSelectedModules = allModules?.map((mod) => ({
          id: mod?.id,
          name: mod?.name,
        }));
        setModuleNames(allSelectedModules);
        changesAccessData(allSelectedModules);
        setSelectAllModules(true);
      }
    } else {
      const allSelectedModules = value?.filter(
        (mod) => mod?.name !== "Select All Modules"
      );
      setModuleNames(allSelectedModules);
      changesAccessData(allSelectedModules);
      setSelectAllModules(false);
    }
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
  };

  const getDesignations = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/role-management/get-designations",
        {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const res = response?.data?.data;
      const designationOptions = res?.designations?.map((desig) => ({
        label: desig?.name,
        value: desig?.id,
      }));
      let obj = { label: "Select", value: "" };
      designationOptions.unshift(obj);
      setDesignations(designationOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const getModules = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/role-management/get-modules",
        {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const res = response?.data?.data;
      setAllModules(res?.modules);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRole = async () => {
    try {
      let payload = {
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
        role_id: singleUserData?.role_id,
        role_user_id: singleUserData?.user_id,
        full_name: roleData?.name,
        is_active: isActive,
        designation_id: selectedDesignation?.value,
        allow_access_to: Object.entries(moduleAccess).map(
          ([moduleId, accessRights]) => ({
            module_id: parseInt(moduleId),
            create: accessRights?.create ? 1 : 0,
            read: accessRights?.read ? 1 : 0,
            update: accessRights?.update ? 1 : 0,
            delete: accessRights?.delete ? 1 : 0,
          })
        ),
        user_profile_picture: roleData?.profImg,
      };
      if (singleUserData?.is_phone_editable == true) {
        payload["mobile_phone"] = roleData?.contactNo;
      }
      if (singleUserData?.is_email_editable == true) {
        payload["email"] = roleData?.emailId;
      }
      let res = Object.entries(moduleAccess).map(
        ([moduleId, accessRights]) => accessRights?.read
      );
      if (roleData?.name == "") {
        toast.error("Name cannot be empty");
      } else if (roleData?.designation == undefined) {
        toast.error("Designation cannot be empty");
      }
      // else if (roleData?.contactNo == "") {
      //   toast.error("Contact No cannot be empty");
      // }
      else if (roleData?.emailId == "") {
        toast.error("Email Id cannot be empty");
      }
      // else if (!validatePhoneNumber(roleData?.contactNo)) {
      //   toast.error("Please enter a valid contact number");
      // }
      else if (selectedModules?.length < 1) {
        toast.error("Select the modules to define user access rights");
      } else if (
        Object.entries(moduleAccess)?.length == 1 &&
        Object.values(moduleAccess)[0]?.read == false
      ) {
        toast.error(
          "Please give read access rights of atleast one module to create a user"
        );
      } else {
        setIsDisabled(true);
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/api/role-management/update-role",
          payload
        );
        const res = response?.data;
        if (res.success == true) {
          let payload = {
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: "Admin",
          };
          if (offset !== "") {
            payload["offset"] = offset;
          }
          if (limit !== "") {
            payload["limit"] = limit;
          }
          dispatch(getUserData(payload));
          toast(`${res.message}`, {
            type: "success",
          });
          setIsDisabled(false);
          navigate(process.env.REACT_APP_PATH + "/clubs");
        } else {
          toast(`${res.message}`, {
            type: "error",
          });
          setIsDisabled(false);
        }
      }
    } catch (error) {
      setIsDisabled(false);
      console.error(error);
    }
  };

  // const ChipComponent = ({ label }) => (
  //   <span
  //     style={{
  //       background: "rgba(248, 236, 243, 1)",
  //       color: "rgba(172, 16, 99, 1)",
  //       height: "21px",
  //       width: "fit-content",
  //       padding: "0px 5px",
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       borderRadius: "4px",
  //       margin: "5px",
  //     }}
  //   >
  //     {label}
  //   </span>
  // );

  useEffect(() => {
    getDesignations();
    getModules();
  }, []);

  useEffect(() => {
    if (singleUserData) {
      if (singleUserData?.is_editable == false) {
        setIsEditableIsDisabled(true);
      } else {
        setIsEditableIsDisabled(false);
      }
      const userModules = singleUserData?.access_rights?.map((data) => ({
        module_id: data?.id,
        create: data?.create === 1,
        read: data?.read === 1,
        update: data?.update === 1,
        delete: data?.delete === 1,
      }));
      setRoleData({
        name: singleUserData?.full_name,
        designation: singleUserData?.designation,
        contactNo: singleUserData?.mobile_phone,
        emailId: singleUserData?.email,
        allowAccessTo: userModules,
        profImg: singleUserData?.user_profile_picture,
      });
      setSelectedDesignation(singleUserData?.designation);
      setModuleNames(
        singleUserData?.access_rights?.map((mod) => ({
          id: mod?.id,
          name: mod?.name,
        }))
      );
      setModuleAccess(
        singleUserData?.access_rights?.reduce((acc, mod) => {
          acc[mod?.id] = {
            create: mod?.create === 1,
            read: mod?.read === 1,
            update: mod?.update === 1,
            delete: mod?.delete === 1,
          };
          return acc;
        }, {})
      );
    }
  }, [singleUserData]);

  const changesAccessData = (accessData) => {
    let newAccessData = {};
    for (let itr = 0; itr < accessData.length; itr++) {
      if (!moduleAccess.hasOwnProperty(accessData[itr]?.id)) {
        newAccessData[accessData[itr]?.id] = {
          create: true,
          read: true,
          delete: true,
          update: true,
        };
      } else {
        newAccessData[accessData[itr]?.id] = {
          ...moduleAccess[accessData[itr]?.id],
        };
      }
    }
    setModuleAccess({ ...newAccessData });
  };

  useEffect(() => {
    setSelectedModules(moduleNames?.map((mod) => mod?.id));
  }, [moduleNames, selectedDesignation]);

  useEffect(() => {
    setIsActive(singleUserData?.is_active == true ? true : false);
  }, [singleUserData]);

  useEffect(() => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      role_user_id: id,
    };
    dispatch(getSingleUserRoleData(payload));
  }, [id]);

  useEffect(() => {
    const resultantData = formatData(singleUserRoleData);
    setSingleUserDataState(resultantData);
    setSingleUserData(resultantData[0]);
  }, [singleUserRoleData]);

  return (
    <div>
      <DiscardModal
        open={discard}
        onCloseModal={() => setDiscard(false)}
        msg="Are you sure you want to discard the changes"
        handleDisacrd={() => {
          navigate(process.env.REACT_APP_PATH + "/clubs");
        }}
      />
      <div className={styles.paddingDiv}>
        <div className={styles.topDiv}>
          <div className={styles.leftDiv}>
            <div className={styles.leftTopDiv}>
              <div className={styles.imgDiv}>
                <div className={styles.avatarDiv}>
                  {profileImg?.length > 0 ? (
                    <Avatar
                      alt={roleData?.name}
                      src={profileImg[0]?.dataURL}
                      sx={{ height: "90%", width: "90%" }}
                      style={{ background: "rgba(222, 223, 225, 1)" }}
                    />
                  ) : (
                    <Avatar
                      sx={{ height: "90%", width: "90%" }}
                      style={{ background: "rgba(222, 223, 225, 1)" }}
                    >
                      {roleData?.profImg && roleData?.profImg != "" ? (
                        <img
                          src={roleData?.profImg}
                          alt=""
                          height={"100%"}
                          width={"100%"}
                        />
                      ) : roleData?.name ? (
                        <div className={styles.avatarName}>
                          {roleData?.name?.charAt(0)}
                        </div>
                      ) : (
                        <Person2
                          sx={{ height: "80px", width: "80px" }}
                          style={{ color: "rgba(89, 20, 56, 1)" }}
                        />
                      )}
                    </Avatar>
                  )}
                  <div className={styles.cameraIconDiv}>
                    <ImageUploading
                      multiple={false}
                      value={profileImg}
                      onChange={handleChangeProfPicture}
                      maxNumber={1}
                      acceptType={["jpg", "png", "jpeg"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <div
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={() => {
                            if (imageList?.length == 0) {
                              onImageUpload();
                            } else {
                              onImageUpdate(0);
                            }
                          }}
                          {...dragProps}
                          className={styles.imageSelectorDiv}
                        >
                          <CameraAlt />
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.leftBottomDiv}>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/CrestLogo.png`}
                alt=""
              />
            </div>
          </div>
          <div className={styles.rightDiv}>
            <div className={styles.rightTopDiv}>
              <h5>User Role</h5>
              <div className={styles.detailsDiv}>
                <div className={styles.displayFlexDiv}>
                  <div>
                    <label className={styles.labelFont}>Name</label>
                    <span className={styles.star}>*</span>
                    <br />
                    <TextInput
                      width={400}
                      className={styles.inputText}
                      name="name"
                      value={roleData?.name}
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength="50"
                      placeholder={"Enter"}
                      // disabled={true}
                    />
                  </div>
                  <div>
                    <label className={styles.labelFont}>Designation</label>
                    <span className={styles.star}>*</span>
                    <br />
                    <div className={styles.customSelectDesignation}>
                      <CustomSelect
                        name="designation"
                        options={designations}
                        value={selectedDesignation}
                        handleOnChangeDropdown={handleRoleChange}
                        padding={"4px"}
                        height={"10px"}
                        border={"none"}
                      />
                      {/* <div className={styles.designationDiv}>
                          {selectedDesignation?.label}
                        </div> */}
                    </div>
                  </div>
                </div>
                <div className={styles.displayFlexDiv}>
                  <div>
                    <label className={styles.labelFont}>Contact no.</label>
                    {/* <span className={styles.star}>*</span> */}
                    <br />
                    <TextInput
                      width={400}
                      className={styles.inputText}
                      name="contactNo"
                      type="mobile"
                      value={roleData?.contactNo}
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength="50"
                      placeholder={"Enter"}
                      disabled={singleUserData?.is_phone_editable == false}
                    />
                  </div>
                  <div>
                    <label className={styles.labelFont}>Email id</label>
                    <span className={styles.star}>*</span>
                    <br />
                    <TextInput
                      width={400}
                      className={styles.inputText}
                      name="emailId"
                      type="email"
                      value={roleData?.emailId}
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength="50"
                      placeholder={"Enter"}
                      disabled={singleUserData?.is_email_editable == false}
                    />
                  </div>
                </div>
                <div className={styles.displayFlexDiv}>
                  <div style={{ width: "400px" }}>
                    <label className={styles.labelFont}>Allow access to</label>
                    <span className={styles.star}>*</span>
                    <br />
                    <div className={styles.dropdownModules}>
                      <FormControl sx={{ width: "100%", height: "40px" }}>
                        <Select
                          multiple
                          style={{ fontSize: "13px", height: "40px" }}
                          value={moduleNames}
                          // renderValue={(selected) => (
                          //   <Box
                          //     sx={{
                          //       display: "flex",
                          //       flexWrap: "wrap",
                          //       gap: 0.5,
                          //     }}
                          //   >
                          //     {selected.includes("selectAllModules") ? (
                          //       selected
                          //     ) : selected?.length == 1 ? (
                          //       <span
                          //         style={{
                          //           background: "rgba(248, 236, 243, 1)",
                          //           color: "rgba(172, 16, 99, 1)",
                          //           height: "21px",
                          //           width: "fit-content",
                          //           padding: "0px 5px",
                          //           display: "flex",
                          //           justifyContent: "center",
                          //           alignItems: "center",
                          //           borderRadius: "4px",
                          //         }}
                          //       >
                          //         {selected[0]?.name}
                          //       </span>
                          //     ) : (
                          //       selected?.map((sel) => (
                          //         <ChipComponent label={sel?.name} />
                          //       ))
                          //     )}
                          //   </Box>
                          // )}
                          renderValue={(selected) =>
                            selected.includes("selectAllModules")
                              ? selected
                              : `${selected?.length} ${
                                  selected?.length == 1
                                    ? "module selected"
                                    : "modules selected"
                                }`
                          }
                          MenuProps={MenuProps}
                        >
                          <div className={styles.filterLstMain}>
                            <div className={styles.profListPaddingSelectAll}>
                              {allModules?.length > 0 && (
                                <div className={styles.singleProfSelectAll}>
                                  <MenuItem
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                    key="selectAllModules"
                                    value="selectAllModules"
                                  >
                                    <Checkbox
                                      checked={selectAllModules}
                                      onChange={(event) => {
                                        const { checked } = event.target;
                                        setSelectAllModules(checked);
                                        let provideAccessToModules = checked
                                          ? allModules?.map((mod) => ({
                                              id: mod?.id,
                                              name: mod?.name,
                                            }))
                                          : [];
                                        setModuleNames(provideAccessToModules);
                                        changesAccessData(
                                          provideAccessToModules
                                        );
                                      }}
                                    />
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                </div>
                              )}
                            </div>
                            <div className={styles.outerModuleDiv}>
                              {allModules?.length > 0 &&
                                allModules?.map((mod) => {
                                  return (
                                    <div className={styles.profListPadding}>
                                      <div className={styles.singleProf}>
                                        <div>
                                          <MenuItem
                                            sx={{
                                              "&:hover": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                            style={{
                                              paddingLeft: "0px",
                                              width: "100%",
                                            }}
                                            key={mod}
                                            value={mod?.name}
                                          >
                                            <Checkbox
                                              checked={moduleNames?.some(
                                                (item) => item?.id === mod?.id
                                              )}
                                              onChange={() => {
                                                handleModulesChange({
                                                  target: {
                                                    value: moduleNames?.some(
                                                      (item) =>
                                                        item?.id === mod?.id
                                                    )
                                                      ? moduleNames?.filter(
                                                          (item) =>
                                                            item?.id !== mod?.id
                                                        )
                                                      : [
                                                          ...moduleNames,
                                                          {
                                                            id: mod?.id,
                                                            name: mod?.name,
                                                          },
                                                        ],
                                                  },
                                                });
                                              }}
                                            />
                                            <ListItemText primary={mod?.name} />
                                          </MenuItem>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div style={{ width: "400px" }}>
                    <label className={styles.labelFont}>Status</label>
                    {/* <span className={styles.star}>*</span> */}
                    <br />
                    <div className={styles.radioSelector}>
                      <div
                        className={
                          isActive == true
                            ? styles.radioSelectorDiv +
                              " " +
                              styles.isRadioActive
                            : styles.radioSelectorDiv
                        }
                        onClick={() => {
                          if (isActive == true) {
                            setIsActive(false);
                          } else {
                            setIsActive(true);
                          }
                        }}
                      >
                        {isActive == true ? (
                          <RadioButtonCheckedOutlinedIcon
                            style={{
                              color: "var(--BaseDarkColor)",
                              height: "10px",
                              width: "10px",
                            }}
                          />
                        ) : (
                          <PanoramaFishEyeOutlinedIcon
                            style={{
                              color: "var(--TextLightColor)",
                              height: "10px",
                              width: "10px",
                            }}
                          />
                        )}
                        <div className={styles.radioStatus}>
                          <span>Active</span>
                        </div>
                      </div>
                      <div
                        className={
                          isActive == false
                            ? styles.radioSelectorDiv +
                              " " +
                              styles.isRadioActive
                            : styles.radioSelectorDiv
                        }
                        onClick={() => {
                          if (isActive == true) {
                            setIsActive(false);
                          } else {
                            setIsActive(true);
                          }
                        }}
                      >
                        {isActive == false ? (
                          <RadioButtonCheckedOutlinedIcon
                            style={{
                              color: "var(--BaseDarkColor)",
                              height: "10px",
                              width: "10px",
                            }}
                          />
                        ) : (
                          <PanoramaFishEyeOutlinedIcon
                            style={{
                              color: "var(--TextLightColor)",
                              height: "10px",
                              width: "10px",
                            }}
                          />
                        )}
                        <div className={styles.radioStatus}>
                          <span>Inactive</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rightBottomDiv}>
              <h5 style={{ paddingBottom: "10px" }}>User Rights</h5>
              <div className={styles.scrollDiv}>
                {moduleNames?.map((mod) => {
                  return (
                    <AccordionListenerUserRights
                      moduleName={mod?.name}
                      moduleId={mod?.id}
                      moduleAccess={moduleAccess}
                      handleToggleChange={handleToggleChange}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.btnDiv}>
          <ButtonBorder
            text={"Back"}
            width={200}
            buttonHandler={() => setDiscard(true)}
          />
          <ButtonColor
            text={"Save Changes"}
            width={200}
            isDisabled={
              isEditableIsDisabled == true
                ? isEditableIsDisabled
                : isDisabled == true
                ? isDisabled
                : false
            }
            buttonHandler={() => {
              if (roleManagementRights.update == 1) {
                handleEditRole();
              } else {
                toast.error("You don't have update access rights");
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditRole;
