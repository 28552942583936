import React, { useEffect, useState } from "react";
import styles from "./eventGallery.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ImageUploading from "react-images-uploading";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadData } from "../../Utils/localStorage";

export const EventGallery = ({ open, onCloseModal, viewId, name }) => {
  const { eventsDetails, eventsActiveTab, events } = useSelector(
    (state) => state.eventsSlice
  );
  const [images, setImages] = React.useState([]);
  const [imagesEvent, setImagesEvent] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImagesEvent(
      imageList?.map((i, index) => {
        return i.data_url;
      })
    );
  };

  const onRemoveImage = (index) => {
    const rows = [...imagesEvent];
    rows.splice(index, 1);
    setImagesEvent(rows);
  };

  const handleSubmit = () => {
    if (imagesEvent?.length <= 0) {
      return;
    }
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      event_id: viewId,
      images: imagesEvent,
    };
    setIsDisabled(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/event/add-gallery-images`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        setIsDisabled(false);
        if (res.success == true) {
          toast(`${res.message}`, {
            type: "success",
          });
        }
        onCloseModal();
      })
      .catch((err) => {
        setIsDisabled(false);
      });
  };

  //getImages
  const getGalleryImages = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      event_id: viewId,
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get-event-gallery`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        res?.data.event_images?.length > 0 &&
          setImages(
            res?.data?.event_images?.map((img, index) => {
              return { data_url: img.path };
              // {
              // path: img.image_url,
              // images_name: img.image_name,
              // };
            })
          );
        res?.data.event_images?.length > 0 &&
          // name &&
          setImagesEvent(
            res?.data.event_images?.map((img, index) => {
              return { data_url: img.path };
              // {
              // path: img.image_url,
              // images_name: img.image_name,
              // };
            })
          );
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setImages([]);
    setImagesEvent([]);
    viewId && getGalleryImages();
  }, [viewId]);

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} showCloseIcon={true} center>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
          acceptType={["jpg", "jpeg", "png"]}
          maxFileSize="3500000"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => (
            // write your building UI
            <div>
              <div className={styles.galleryModal}>
                <div className={styles.eventGalleryNav}>
                  <h2>{name}</h2>
                  {images?.length > 0 && (
                    <span>
                      <button
                        className={styles.closeBtn}
                        onClick={onImageUpload}
                      >
                        Add Images
                      </button>
                    </span>
                  )}
                </div>
                {errors?.maxFileSize && (
                  <span className={styles.error}>
                    Selected file size exceed Max File Size
                  </span>
                )}
                {errors?.acceptType && (
                  <span className={styles.error}>
                    Selected file type is not allowed
                  </span>
                )}
                {images?.length !== 0 ? (
                  <>
                    <div className={styles.galleryMainDiv}>
                      {imageList.map((i, index) => {
                        return (
                          <div
                            className={
                              index % 4 === 0
                                ? styles.galleryDivEven
                                : index % 4 === 1
                                ? styles.galleryDivOdd
                                : index % 4 === 2
                                ? styles.galleryDivOdd
                                : styles.galleryDivEven
                            }
                          >
                            <div
                              className={styles.closeIcon}
                              onClick={() => {
                                onRemoveImage(index);
                                onImageRemove(index);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/cross.png`}
                                alt="removeIcon"
                              />
                            </div>
                            <img src={i.data_url} alt="" />
                          </div>
                        );
                      })}
                    </div>
                    <span className={styles.btnDiv}>
                      <button
                        className={styles.closeBtn}
                        onClick={() => {
                          onCloseModal();
                          onImageRemoveAll();
                          getGalleryImages();
                        }}
                      >
                        {process.env.REACT_APP_PRODUCT == "DLF"
                          ? "Back"
                          : "Close"}
                      </button>
                      <button
                        className={styles.uploadBtn}
                        disabled={isDisabled}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Upload
                      </button>
                    </span>
                  </>
                ) : (
                  <>
                    <div className={styles.emptyDiv}>
                      <span className={styles.uploadGalleryImgIcon}>
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/uploadGallery.png`}
                        />
                      </span>
                      <p className={styles.galleryP}>
                        Upload Images to Event Gallery
                        <span>(Less than 3.5mb)</span>
                      </p>
                      <button
                        className={styles.closeBtn}
                        onClick={onImageUpload}
                      >
                        Add Images
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </ImageUploading>
      </Modal>
    </div>
  );
};
