import React, { useEffect, useState } from "react";
import "./routing.css";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../Components/Logo";
import {
  handleProfile,
  handleUserKey,
  handleClubKey,
  handleCondominium,
  handleClub,
  handleInitialRoute,
  handleDLFClubId,
} from "../Components/Redux/authSlice";
import { loadData, saveData } from "../Utils/localStorage";
import { ToastContainer } from "react-toastify";
import Concierge from "../Conceirge/Concierge";
import Clubs from "../Clubs/Clubs";
import { Events } from "../Events/Events";
import { Facilities } from "../Facilities/Facilities";
import Salon from "../Salon/Salon";
import FoodAndBeverages from "../FoodAndBeverages/FoodAndBeverages";
import { Venue } from "../Venue/Venue";
import { CreateEvent } from "../Events/CreateEvent/CreateEvent";
import { CreateFacility } from "../Facilities/CreateFacility/CreateFacility";
import { CreateRestaurant } from "../FoodAndBeverages/Pages/CreateRestaurant/CreateRestaurant";
import { CreateSalon } from "../Salon/Pages/CreateSalon/CreateSalon";
import { CreateVenue } from "../Venue/CreateVenue/CreateVenue";
import { CreateProfessional } from "../Salon/Pages/Professionals/CreateProfessional/CreateProfessional";
import { EditEvent } from "../Events/EditEvent/EditEvent";
import { EditFac } from "../Facilities/EditFacilityFac/EditFac";
import { EditRestaurant } from "../FoodAndBeverages/Pages/EditRestaurant/EditRestaurant";
import { EditSalon } from "../Salon/Pages/EditSalon/EditSalon";
import { EditVenue } from "../Venue/EditVenue/EditVenue";
import { EditProfessional } from "../Salon/Pages/Professionals/EditProfessional/EditProfessional";
import { BookingHistory } from "../Events/BookingHistory/BookingHistory";
import { BookingHistoryFac } from "../Facilities/BookingHistoryFac/BookingHistoryFac";
import { BookingRequestFac } from "../Facilities/BookingRequestFac/BookingRequestFac";
import { TableBookingHistory } from "../FoodAndBeverages/Pages/TableBookingHistory/TableBookingHistory";
import { OrderHistory } from "../FoodAndBeverages/Pages/OrderHistory/OrderHistory";
import { BookingHistorySalon } from "../Salon/Pages/BookingHistorySalon/BookingHistorySalon";
import { BookingHistoryVenue } from "../Venue/BookingHistoryVenue/BookingHistoryVenue";
import { BookingRequestVenue } from "../Venue/BookingRequestVenue/BookingRequestVenue";
import { EventCalender } from "../Events/EventCalender/EventCalender";
import { CategoryFac } from "../Facilities/Category/CategoryFac";
import { Category } from "../Venue/Category/Category";
import FnBCart from "../FoodAndBeverages/Pages/FnBCart/FnBCart";
import CreateBooking from "../Salon/Pages/CreateBooking/CreateBooking";
import Professionals from "../Salon/Pages/Professionals/Professionals";
import Sidebar from "../Components/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import Directory from "../Conceirge/Directory/Directory";
import AccessDenied from "../AccessDenied/AccessDenied";
import CreateRoute from "../PrivateRoute/CreateRoute";
import UpdateRoute from "../PrivateRoute/UpdateRoute";
import { activeTab } from "../Components/Redux/tabSlice";
import AddRole from "../Clubs/Components/AddRole/AddRole";
import EditRole from "../Clubs/Components/EditRole/EditRole";
import SchedularView from "../Salon/Pages/SchedularView/SchedularView";
import SpaProfessionals from "../Spa/Pages/SpaProfessionals/SpaProfessionals";
import CreateBookingSpa from "../Spa/Pages/CreateBookingSpa/CreateBookingSpa";
import { BookingHistorySpa } from "../Spa/Pages/BookingHistorySalon/BookingHistorySpa";
import Spa from "../Spa/Spa";
import { CreateSpa } from "../Spa/Pages/CreateSpa/CreateSpa";
import { EditSpa } from "../Spa/Pages/EditSpa/EditSpa";
import { EditProfessionalSpa } from "../Spa/Pages/SpaProfessionals/EditProfessionalSpa/EditProfessionalSpa";
import { CreateProfessionalSpa } from "../Spa/Pages/SpaProfessionals/CreateProfessional/CreateProfessionalSpa";
import { BookingHistoryRegistrationDetails } from "../AC/Event/Booking/BookingRegistrationHistory/BookingHistoryRegistrationDetails/BookingHistoryRegistrationDetails";

export const Routing = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [clubToken, setClubToken] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    eventRights,
    fnBRights,
    facilityRights,
    salonRights,
    spaRights,
    venueRights,
    conciergeRights,
    roleManagementRights,
    initialRoute,
  } = useSelector((state) => state.authSlice);
  let param = location.search;
  let userKey = new URLSearchParams(param).get("key");
  let clubKey = new URLSearchParams(param).get("club");

  const getInitialRoute = (mod) => {
    if (mod !== "") {
      if (mod?.name == "Events") {
        localStorage.setItem("active_Tab", JSON.stringify(2));
        dispatch(activeTab(2));
        return "/events";
      } else if (mod?.name == "Concierge") {
        localStorage.setItem("active_Tab", JSON.stringify(10));
        dispatch(activeTab(10));
        return "/concierge";
      } else if (mod?.name == "Salon") {
        localStorage.setItem("active_Tab", JSON.stringify(4));
        dispatch(activeTab(4));
        return "/salon";
      } else if (mod?.name == "Spa") {
        localStorage.setItem("active_Tab", JSON.stringify(5));
        dispatch(activeTab(5));
        return "/spa";
      } else if (mod?.name == "Role Management") {
        localStorage.setItem("active_Tab", JSON.stringify(11));
        dispatch(activeTab(11));
        return "/clubs";
      } else if (mod?.name == "Recreation & Others") {
        localStorage.setItem("active_Tab", JSON.stringify(3));
        dispatch(activeTab(3));
        return "/facilities";
      } else if (mod?.name == "Food & Beverage") {
        localStorage.setItem("active_Tab", JSON.stringify(6));
        dispatch(activeTab(6));
        return "/fnb";
      } else if (mod?.name == "Meet & Celebrate") {
        localStorage.setItem("active_Tab", JSON.stringify(7));
        dispatch(activeTab(7));
        return "/venue";
      }
    }
    // else {
    //   return "/accessDenied";
    // }
  };

  const DLFAdminProfile = () => {
    setLoading(true);
    if (userKey && clubKey) {
      setToken(userKey);
      setClubToken(clubKey);
      saveData("Token", userKey);
      saveData("ClubToken", clubKey);
      dispatch(handleUserKey(userKey));
      dispatch(handleClubKey(clubKey));
    } else {
      if (!loadData("Token")) {
        window.location.href = process.env.REACT_APP_LOGIN + "/auth/logout";
      }
    }
    let payload = {
      user_id: loadData("Token"),
      club_id: loadData("ClubToken"),
    };
    fetch(
      process.env.REACT_APP_API_URL + "/api/member/get_club_admin_profile",
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "content-type": "application/json" },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        if (res?.status == "success") {
          saveData("Club_id", JSON.stringify(res?.data[0]?.club_id));
          dispatch(handleDLFClubId(res?.data[0]?.club_id));
          if (
            (initialRoute == null || initialRoute == "/") &&
            process.env.REACT_APP_PRODUCT == "DLF"
          ) {
            const path = window.location.pathname;
            let firstRoute;
            if (path == "/" || initialRoute == null) {
              firstRoute = getInitialRoute(
                res?.role_details[0]?.modules[0]
                  ? res?.role_details[0]?.modules[0]
                  : ""
              );
            } else {
              localStorage.setItem("active_Tab", JSON.stringify(1000));
              dispatch(activeTab(1000));
              firstRoute = path;
            }
            navigate(firstRoute, { replace: true });
            dispatch(handleInitialRoute(firstRoute));
            dispatch(handleCondominium(res?.condominiums));
          }
          setLoading(false);
          dispatch(handleProfile(res?.data[0]));
          dispatch(handleClub(res?.club));
        } else {
          window.location.href = process.env.REACT_APP_LOGIN + "/auth/logout";
        }
      })
      .catch((err) => {
        window.location.href = process.env.REACT_APP_LOGIN + "/auth/logout";
      });
  };

  const nonDLFAdminProfile = () => {
    if (userKey) {
      setToken(userKey);
      saveData("Token", userKey);
      dispatch(handleUserKey(userKey));
    } else {
      if (!loadData("Token")) {
        window.location.href = process.env.REACT_APP_LOGIN + "/auth/logout";
      }
    }
    fetch(
      process.env.REACT_APP_API_URL + "/api/member/get_club_admin_profile",
      {
        method: "POST",
        body: JSON.stringify({ user_id: loadData("Token") }),
        headers: { "content-type": "application/json" },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        if (res?.status == "success") {
          saveData("Community", res.data[0].club_name);
          saveData("Admin_name", res.data[0].club_name);
          saveData("Club_id", res.data[0].club_id);
          saveData(
            "Auth",
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiJmNDFkYTc5Mi05ZTY1LTRhMmQtYjAwNy03MGY5YzkwN2U5MjQiLCJpYXQiOjE2ODY5MjEwOTUsIlVzZXJJZCI6IjExMDEiLCJQcm9wZXJ0eUlkIjoiREdPTEYiLCJDaGFpbklkIjoiRExGICAiLCJuYmYiOjE2ODY5MjEwOTUsImV4cCI6MTY4NjkyMTY5NX0.WgZbDPBC0sLOMLdge-v_31qv_geXLdiiYi-XH_P4j1g"
          );
          process.env.REACT_APP_PRODUCT != "DLF" &&
            saveData("Community_type", res.data[0].community_type);
          dispatch(handleProfile(res.data[0]));
        } else {
          window.location.href = process.env.REACT_APP_LOGIN + "/auth/logout";
        }
      })
      .catch((err) => {
        //HANDLING SAFARI CREATE EVENT LOGOUT
        if (
          err != "TypeError: NetworkError when attempting to fetch resource." &&
          err != "TypeError: Load failed"
        )
          window.location.href = process.env.REACT_APP_LOGIN + "/auth/logout";
      });
  };

  useEffect(() => {
    process.env.REACT_APP_PRODUCT !== "DLF" && nonDLFAdminProfile();
  }, [location]);

  useEffect(() => {
    process.env.REACT_APP_PRODUCT == "DLF" && DLFAdminProfile();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCT == "DLF") {
      navigate(initialRoute, { replace: true });
    }
  }, [initialRoute]);

  if (loading) {
    return <Logo />;
  } else {
    return (
      <>
        <div className={"routingMainContainer"}>
          {process.env.REACT_APP_PRODUCT == "DLF" && <Sidebar />}
          <div>
            <Header title={"The Camellias Club"} />
            <Routes>
              <Route path="*" element={<AccessDenied />} />
              {/* Clubs */}
              {roleManagementRights.read == 1 ? (
                <Route
                  path={process.env.REACT_APP_PATH + "/clubs"}
                  element={<Clubs />}
                />
              ) : (
                <Route path="/clubs" element={<AccessDenied />} />
              )}
              <Route
                path={`${process.env.REACT_APP_PATH}/clubs/add-role`}
                element={<AddRole />}
              />
              <Route
                path={`${process.env.REACT_APP_PATH}/clubs/edit-role/:id`}
                element={<EditRole />}
              />
              {/* End Clubs */}
              {/* Event */}
              {process.env.REACT_APP_PRODUCT !== "DLF" ? (
                <Route
                  path={process.env.REACT_APP_PATH + "/"}
                  element={<Events />}
                />
              ) : eventRights.read == 1 ? (
                <Route
                  path={process.env.REACT_APP_PATH + "/events"}
                  element={<Events />}
                />
              ) : (
                <Route path="/events" element={<AccessDenied />} />
              )}
              <Route
                path={`${process.env.REACT_APP_PATH}/events/event-calender`}
                element={<EventCalender />}
              />
              <Route
                path={`${process.env.REACT_APP_PATH}/events/event-history`}
                element={<BookingHistory />}
              />
              {process.env.REACT_APP_PRODUCT == "DLF" ? (
                <Route element={<CreateRoute />}>
                  <Route
                    path={`${process.env.REACT_APP_PATH}/events/add-event`}
                    element={<CreateEvent />}
                  />
                </Route>
              ) : (
                <Route
                  path={`${process.env.REACT_APP_PATH}/events/add-event`}
                  element={<CreateEvent />}
                />
              )}
              {process.env.REACT_APP_PRODUCT == "DLF" ? (
                <Route element={<UpdateRoute />}>
                  <Route
                    path={`${process.env.REACT_APP_PATH}/events/edit-event/:id`}
                    element={<EditEvent />}
                  />
                </Route>
              ) : (
                <Route
                  path={`${process.env.REACT_APP_PATH}/events/edit-event/:id`}
                  element={<EditEvent />}
                />
              )}

              <Route
                path={`${process.env.REACT_APP_PATH}/events/booking-registration-details/:id`}
                element={<BookingHistoryRegistrationDetails />}
              />
              {/* End Event */}
              {process.env.REACT_APP_PRODUCT == "DLF" && (
                <>
                  {/* Facilities, Venues, F n B, Salon */}
                  {facilityRights.read == 1 ? (
                    <Route
                      path={process.env.REACT_APP_PATH + "/facilities"}
                      element={<Facilities />}
                    />
                  ) : (
                    <Route path="/facilities" element={<AccessDenied />} />
                  )}
                  <Route
                    path="/facilities/booking-history"
                    element={<BookingHistoryFac />}
                  />
                  <Route
                    path="/facilites/booking-request"
                    element={<BookingRequestFac />}
                  />
                  <Route
                    path="/facilities/category"
                    element={<CategoryFac />}
                  />
                  {venueRights.read == 1 ? (
                    <Route path="/venue" element={<Venue />} />
                  ) : (
                    <Route path="/venue" element={<AccessDenied />} />
                  )}
                  <Route
                    path="/venue/booking-request"
                    element={<BookingRequestVenue />}
                  />
                  <Route
                    path="/venue/booking-history"
                    element={<BookingHistoryVenue />}
                  />
                  <Route path="/venue/category" element={<Category />} />
                  {fnBRights.read == 1 ? (
                    <Route
                      path={process.env.REACT_APP_PATH + "/fnb"}
                      element={<FoodAndBeverages />}
                    />
                  ) : (
                    <Route path="/fnb" element={<AccessDenied />} />
                  )}
                  <Route
                    path={`${process.env.REACT_APP_PATH}/fnb/order-history`}
                    element={<OrderHistory />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/fnb/table-history`}
                    element={<TableBookingHistory />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/fnb/cart/:id`}
                    element={<FnBCart />}
                  />
                  {/* Create routes */}
                  <Route element={<CreateRoute />}>
                    <Route
                      path="/facilities/create-facilites"
                      element={<CreateFacility />}
                    />
                    <Route
                      path="/venue/create-venue"
                      element={<CreateVenue />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/fnb/add-restaurant`}
                      element={<CreateRestaurant />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/salon/add-salon-service`}
                      element={<CreateSalon />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/salonProfessional/add-professional`}
                      element={<CreateProfessional />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/spa/add-spa-service`}
                      element={<CreateSpa />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/spaProfessional/add-professional`}
                      element={<CreateProfessionalSpa />}
                    />
                  </Route>
                  {/* Update routes */}
                  <Route element={<UpdateRoute />}>
                    <Route
                      path="/facilities/edit-facility/:id"
                      element={<EditFac />}
                    />
                    <Route
                      path="/venue/edit-venue/:id"
                      element={<EditVenue />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/fnb/edit-restaurant/:id`}
                      element={<EditRestaurant />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/salon/edit-salon-service/:id`}
                      element={<EditSalon />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/salonProfessional/edit-professional/:id`}
                      element={<EditProfessional />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/spa/edit-spa-service/:id`}
                      element={<EditSpa />}
                    />
                    <Route
                      path={`${process.env.REACT_APP_PATH}/spaProfessional/edit-professional/:id`}
                      element={<EditProfessionalSpa />}
                    />
                  </Route>
                  {salonRights.read == 1 ? (
                    <Route
                      path={`${process.env.REACT_APP_PATH}/salon`}
                      element={<Salon />}
                    />
                  ) : (
                    <Route path="/salon" element={<AccessDenied />} />
                  )}
                  {spaRights.read == 1 ? (
                    <Route
                      path={`${process.env.REACT_APP_PATH}/spa`}
                      element={<Spa />}
                    />
                  ) : (
                    <Route path="/spa" element={<AccessDenied />} />
                  )}
                  <Route
                    path={`${process.env.REACT_APP_PATH}/salon/booking-history`}
                    element={<BookingHistorySalon />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/salonProfessional`}
                    element={<Professionals />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/salon/create-booking`}
                    element={<CreateBooking />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/salon/schedular-view`}
                    element={<SchedularView />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/spa/booking-history`}
                    element={<BookingHistorySpa />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/spaProfessional`}
                    element={<SpaProfessionals />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_PATH}/spa/create-booking`}
                    element={<CreateBookingSpa />}
                  />
                  {/* Start Concierge */}
                  {conciergeRights.read == 1 ? (
                    <Route path={"/concierge"} element={<Concierge />} />
                  ) : (
                    <Route path="/concierge" element={<AccessDenied />} />
                  )}
                  <Route
                    path={"/concierge/directory"}
                    element={<Directory />}
                  />
                  {/* End Concierge */}
                </>
              )}
            </Routes>
            <ToastContainer />
          </div>
        </div>
      </>
    );
  }
};
