import React, { useState } from "react";
import styles from "./tableHomepage.module.css";
import { useSelector } from "react-redux";
import { Logo } from "../../../Components/Logo";
import { useNavigate } from "react-router-dom";

const TableHomepage = ({ handleOffset, fullData, offset }) => {
  const navigate = useNavigate();
  const { userData, records } = useSelector((state) => state.clubsSlice);
  const [viewId, setViewId] = useState("");

  const Icon = ({ type }) => (
    <img
      style={{ height: "25px", width: "25px", marginRight: "5px" }}
      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/${type}.svg`}
      alt={type}
    />
  );

  const checkAccess = (user, moduleId) => {
    return user?.access_rights?.some((right) => right?.id === moduleId);
  };

  if (userData?.length == 0) {
    <Logo />;
  }
  return (
    <>
      {fullData?.length > 0 ? (
        <>
          <div className={styles.tableOuterDiv}>
            <table className={styles.tableDiv}>
              <thead>
                <tr>
                  <th className={styles.tableHead}>Users</th>
                  <th className={styles.tableHead}>Roles</th>
                  <th className={styles.tableHead}>Events</th>
                  <th className={styles.tableHead}>Recreation & More</th>
                  <th className={styles.tableHead}>Food & Beverages</th>
                  <th className={styles.tableHead}>Salon</th>
                  <th className={styles.tableHead}>Spa</th>
                  <th className={styles.tableHead}>Meet & Celebrate</th>
                </tr>
              </thead>
              <tbody>
                {fullData?.map((userD) => {
                  return (
                    <tr>
                      <td className={styles.userName}>
                        <div
                          style={{
                            display: "flex",
                            gap: "15px",
                            width: "100%",
                          }}
                          onClick={() => {
                            setViewId(userD?.user_id);
                            navigate(
                              `${process.env.REACT_APP_PATH}/clubs/edit-role/${userD?.user_id}`,
                              { state: { singleUserData: userD } }
                            );
                          }}
                        >
                          {userD?.full_name}{" "}
                          {userD?.is_active == false ? (
                            <span className={styles.inactiveDiv}>Inactive</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td className={styles.role}>
                        {userD?.designation?.label}
                      </td>
                      <td
                        className={
                          checkAccess(userD, 1)
                            ? styles.access
                            : styles.noAccess
                        }
                      >
                        {userD?.access_rights?.map((right) => {
                          if (right?.id === 1) {
                            const icons = [];
                            if (right?.create === 1)
                              icons.push(<Icon key="create" type="c" />);
                            if (right?.read === 1)
                              icons.push(<Icon key="read" type="r" />);
                            if (right?.update === 1)
                              icons.push(<Icon key="update" type="u" />);
                            if (right?.delete === 1)
                              icons.push(<Icon key="delete" type="d" />);
                            return icons;
                          }
                        })}
                      </td>
                      <td
                        className={
                          checkAccess(userD, 2)
                            ? styles.access
                            : styles.noAccess
                        }
                      >
                        {userD?.access_rights?.map((right) => {
                          if (right?.id === 2) {
                            const icons = [];
                            if (right?.create === 1)
                              icons.push(<Icon key="create" type="c" />);
                            if (right?.read === 1)
                              icons.push(<Icon key="read" type="r" />);
                            if (right?.update === 1)
                              icons.push(<Icon key="update" type="u" />);
                            if (right?.delete === 1)
                              icons.push(<Icon key="delete" type="d" />);
                            return icons;
                          }
                        })}
                      </td>
                      <td
                        className={
                          checkAccess(userD, 3)
                            ? styles.access
                            : styles.noAccess
                        }
                      >
                        {userD?.access_rights?.map((right) => {
                          if (right?.id === 3) {
                            const icons = [];
                            if (right?.create === 1)
                              icons.push(<Icon key="create" type="c" />);
                            if (right?.read === 1)
                              icons.push(<Icon key="read" type="r" />);
                            if (right?.update === 1)
                              icons.push(<Icon key="update" type="u" />);
                            if (right?.delete === 1)
                              icons.push(<Icon key="delete" type="d" />);
                            return icons;
                          }
                        })}
                      </td>
                      <td
                        className={
                          checkAccess(userD, 4)
                            ? styles.access
                            : styles.noAccess
                        }
                      >
                        {userD?.access_rights?.map((right) => {
                          if (right?.id === 4) {
                            const icons = [];
                            if (right?.create === 1)
                              icons.push(<Icon key="create" type="c" />);
                            if (right?.read === 1)
                              icons.push(<Icon key="read" type="r" />);
                            if (right?.update === 1)
                              icons.push(<Icon key="update" type="u" />);
                            if (right?.delete === 1)
                              icons.push(<Icon key="delete" type="d" />);
                            return icons;
                          }
                        })}
                      </td>
                      <td
                        className={
                          checkAccess(userD, 5)
                            ? styles.access
                            : styles.noAccess
                        }
                      >
                        {userD?.access_rights?.map((right) => {
                          if (right?.id === 5) {
                            const icons = [];
                            if (right?.create === 1)
                              icons.push(<Icon key="create" type="c" />);
                            if (right?.read === 1)
                              icons.push(<Icon key="read" type="r" />);
                            if (right?.update === 1)
                              icons.push(<Icon key="update" type="u" />);
                            if (right?.delete === 1)
                              icons.push(<Icon key="delete" type="d" />);
                            return icons;
                          }
                        })}
                      </td>
                      <td
                        className={
                          checkAccess(userD, 6)
                            ? styles.access
                            : styles.noAccess
                        }
                      >
                        {userD?.access_rights?.map((right) => {
                          if (right?.id === 6) {
                            const icons = [];
                            if (right?.create === 1)
                              icons.push(<Icon key="create" type="c" />);
                            if (right?.read === 1)
                              icons.push(<Icon key="read" type="r" />);
                            if (right?.update === 1)
                              icons.push(<Icon key="update" type="u" />);
                            if (right?.delete === 1)
                              icons.push(<Icon key="delete" type="d" />);
                            return icons;
                          }
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {fullData?.length > 0 && (
            <div className={styles.pagination}>
              <div>
                <div className={styles.paginationValuesDiv}>
                  <p>{offset + 1}</p>
                  <p>-</p>
                  <p>{offset + fullData?.length}</p>
                  <p>of</p>
                  <p>{records}</p>
                </div>
                <div className={styles.paginationIconDiv}>
                  <span onClick={() => handleOffset("prev")}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                    />
                  </span>
                  <span onClick={() => handleOffset("next")}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ padding: "20px" }}>
          <img
            style={{ width: "95%" }}
            src={
              process.env.PUBLIC_URL +
              `/${process.env.REACT_APP_IMAGES}/roleIllustration.svg`
            }
          />
        </div>
      )}
    </>
  );
};

export default TableHomepage;
