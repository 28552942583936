import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartServiceItems: [],
};

const salonSpaCartSlice = createSlice({
  name: "salonSpaCartSlice",
  initialState,
  reducers: {
    removeItem: (state, { payload }) => {
      state.cartServiceItems = state.cartServiceItems.filter(
        (item) => item?.id !== payload
      );
    },
    addItem: (state, { payload }) => {
      state.cartServiceItems = [...state.cartServiceItems, payload];
    },
    clearCart: (state, action) => {
      state.cartServiceItems = [];
    },
  },
});

export const { removeItem, addItem, clearCart } = salonSpaCartSlice.actions;

export default salonSpaCartSlice.reducer;
