import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import styles from "./bookingTblModal.module.css";
import { Textarea } from "evergreen-ui";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { ButtonBorder } from "../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { loadData } from "../../../Utils/localStorage";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCurrencySymbolByTimeZone } from "../../../Utils/currencySymbol";

export const BookingTblModal = ({
  open,
  onCloseModal,
  viewId,
  details,
  reason,
  setReason,
  handleBookingHistory,
  setViewId = { setViewId },
}) => {
  let viewDetails =
    details?.length > 0 &&
    details?.filter((i, index) => {
      return i.id == viewId ? i : "";
    });

  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(profileDetails?.comm_time_zone)  

  const [isDisabled, setIsDisabled] = useState(false);
  const [seatsConfirmed, setSeatsConfirmed] = useState({});
  const [guestSeatsConfirmed, setGuestSeatsConfirmed] = useState({});
  const [totalCancelledSeatCount, setTotalCancelledSeatCount] = useState(0);
  const [refundableAmount, setRefundableAmount] = useState(0);

  const handleRemove = (bookingType) => {
    if (
      seatsConfirmed[bookingType]?.booked_seats_count -
        seatsConfirmed[bookingType]?.cancelled_count >
      0
    ) {
      seatsConfirmed[bookingType]["cancelled_count"] =
        seatsConfirmed[bookingType]["cancelled_count"] + 1;
      setSeatsConfirmed({ ...seatsConfirmed });
      setTotalCancelledSeatCount((prev) => prev + 1);
      viewDetails[0]?.payment_status === "PAID" && handlePreCancelApi();
    }
  };

  const handleAdd = (bookingType) => {
    if (
      seatsConfirmed[bookingType]?.booked_seats_count -
        seatsConfirmed[bookingType]?.cancelled_count !=
      seatsConfirmed[bookingType]?.booked_seats_count
    ) {
      seatsConfirmed[bookingType]["cancelled_count"] =
        seatsConfirmed[bookingType]["cancelled_count"] - 1;
      setSeatsConfirmed({ ...seatsConfirmed });
      setTotalCancelledSeatCount((prev) => prev - 1);
      viewDetails[0]?.payment_status === "PAID" && handlePreCancelApi();
    }
  };

  const formatDataAsRequired = () => {
    let bookedSeats = {};
    viewDetails[0]?.booked_seats?.map((seat) => {
      if (seat?.seat_canceled == 0) {
        if (bookedSeats.hasOwnProperty(seat?.seat_type?.name)) {
          bookedSeats[seat?.seat_type?.name]["booked_seats_count"] =
            bookedSeats[seat?.seat_type?.name]["booked_seats_count"] + 1;
          bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
        } else {
          bookedSeats[seat?.seat_type?.name] = {};
          bookedSeats[seat?.seat_type?.name]["cancelled_count"] = 0;
          bookedSeats[seat?.seat_type?.name]["booking_id"] = seat["booking_id"];
          bookedSeats[seat?.seat_type?.name]["booked_seats_count"] = 1;
          bookedSeats[seat?.seat_type?.name]["price"] = seat?.seat_price;
          bookedSeats[seat?.seat_type?.name]["seats_data"] = [];
          bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
        }
      }
    });
    setSeatsConfirmed(bookedSeats);
    setTotalCancelledSeatCount(0);
  };

  const formatDataAsRequiredNoSeatCategory = () => {
    let bookedSeats = {};
    viewDetails[0]?.booked_seats?.map((seat) => {
      if (seat?.seat_canceled == 0) {
        if (bookedSeats.hasOwnProperty("Seats")) {
          bookedSeats["Seats"]["booked_seats_count"] =
            bookedSeats["Seats"]["booked_seats_count"] + 1;
          bookedSeats["Seats"]["seats_data"].push(seat);
        } else {
          bookedSeats["Seats"] = {};
          bookedSeats["Seats"]["cancelled_count"] = 0;
          bookedSeats["Seats"]["booking_id"] = seat["booking_id"];
          bookedSeats["Seats"]["booked_seats_count"] = 1;
          bookedSeats["Seats"]["price"] = viewDetails[0].event?.price;
          bookedSeats["Seats"]["seats_data"] = [];
          bookedSeats["Seats"]["seats_data"].push(seat);
        }
      }
    });
    setSeatsConfirmed(bookedSeats);
    setTotalCancelledSeatCount(0);
  };

  const createReqBody = () => {
    let body = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      cancel_reason: reason.trim(),
    };
    let cancelData = [];
    let total_seats_cancelled = 0;
    for (let key in seatsConfirmed) {
      if (seatsConfirmed[key]["cancelled_count"] != 0) {
        let tempCancelData = {};
        tempCancelData["no_of_seat_cancelled"] =
          seatsConfirmed[key]["cancelled_count"];
        tempCancelData["seat_id"] =
          seatsConfirmed[key]["seats_data"][0]["seat_id"];
        total_seats_cancelled =
          total_seats_cancelled + seatsConfirmed[key]["cancelled_count"];
        body["booking_id"] = seatsConfirmed[key]["booking_id"];
        cancelData.push(tempCancelData);
      }
    }
    body["seats_cancelled"] = cancelData;
    body["total_seats_cancelled"] = total_seats_cancelled;
    return body;
  };

  const handlePreCancelApi = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/event/pre-cancel-booking", {
      method: "POST",
      body: JSON.stringify(
        process.env.REACT_APP_PRODUCT != "DLF"
          ? createReqBody()
          : createReqBodyAc()
      ),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.success == true) {
          setRefundableAmount(res?.data?.refund_amount);
        } else {
          toast(`${res.message}`, {
            type: "error",
          });
        }
      })
      .catch((err) => {});
  };

  const handleCancellation = () => {
    let payload =
      process.env.REACT_APP_PRODUCT != "DLF"
        ? createReqBodyAc()
        : createReqBody();
    if (isDisabled) {
      return;
    }
    if (reason.trim() == "") {
      toast.error("Enter reason for cancellation");
    } else if (
      process.env.REACT_APP_PRODUCT == "DLF" &&
      payload.seats_cancelled?.length <= 0
    ) {
      toast.error("please select the seats to cancel");
    } else {
      setIsDisabled(true);
      fetch(process.env.REACT_APP_API_URL + "/api/event/admin-cancel-booking", {
        method: "POST",
        body: JSON.stringify({
          ...(process.env.REACT_APP_PRODUCT != "DLF"
            ? createReqBodyAc()
            : createReqBody()),
          request_by: "Admin",
          member_id: viewDetails[0]?.member_details?.member_id,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      })
        .then((d) => d.json())
        .then((res) => {
          if (res.success == true) {
            toast(`${res.message}`, {
              type: "success",
            });
            handleBookingHistory();
            // let args = {
            //   club_id: loadData("Club_id"),
            //   user_id: loadData("Token"),
            //   request_by: EventConfig.requestBy,
            //   event_id: eventsActiveTab,
            //   limit: 4,
            //   offset: 0,
            // };
            // dispatch(getBookingHistoryById({ args, eventsActiveTab }));
            setViewId(0);
            onCloseModal();
          } else {
            toast(`${res.message}`, {
              type: "error",
            });
          }
          setTimeout(() => {
            setIsDisabled(false);
          }, 1000);
        })
        .catch((err) => {
          setIsDisabled(false);
        });
    }
  };

  // Ac
  const formatDataAsRequiredAc = () => {
    let bookedSeats = {};
    let guestbookedSeats = {};
    viewDetails[0]?.booked_seats?.map((seat) => {
      if (seat?.seat_booked_for == "Guest") {
        if (seat?.seat_canceled == 0) {
          if (guestbookedSeats.hasOwnProperty(seat?.seat_type?.name)) {
            guestbookedSeats[seat?.seat_type?.name][
              "guest_booked_seats_count"
            ] =
              guestbookedSeats[seat?.seat_type?.name][
                "guest_booked_seats_count"
              ] + 1;
            guestbookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          } else {
            guestbookedSeats[seat?.seat_type?.name] = {};
            guestbookedSeats[seat?.seat_type?.name][
              "guest_cancelled_count"
            ] = 0;
            guestbookedSeats[seat?.seat_type?.name]["booking_id"] =
              seat["booking_id"];
            guestbookedSeats[seat?.seat_type?.name][
              "guest_booked_seats_count"
            ] = 1;
            guestbookedSeats[seat?.seat_type?.name]["guest_price"] =
              seat?.seat_type?.guest_price;
            guestbookedSeats[seat?.seat_type?.name]["seats_data"] = [];
            guestbookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          }
        }
      } else {
        if (seat?.seat_canceled == 0) {
          if (bookedSeats.hasOwnProperty(seat?.seat_type?.name)) {
            bookedSeats[seat?.seat_type?.name]["booked_seats_count"] =
              bookedSeats[seat?.seat_type?.name]["booked_seats_count"] + 1;
            bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          } else {
            bookedSeats[seat?.seat_type?.name] = {};
            bookedSeats[seat?.seat_type?.name]["cancelled_count"] = 0;
            bookedSeats[seat?.seat_type?.name]["booking_id"] =
              seat["booking_id"];
            bookedSeats[seat?.seat_type?.name]["booked_seats_count"] = 1;
            bookedSeats[seat?.seat_type?.name]["price"] =
              seat?.seat_type?.price;
            bookedSeats[seat?.seat_type?.name]["seats_data"] = [];
            bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          }
        }
      }
    });
    setSeatsConfirmed(bookedSeats);
    setTotalCancelledSeatCount(0);
    setGuestSeatsConfirmed(guestbookedSeats);
  };

  const formatDataAsRequiredNoSeatCategoryAc = () => {
    let bookedSeats = {};
    let guestbookedSeats = {};
    viewDetails[0]?.booked_seats?.map((seat) => {
      if (seat?.seat_booked_for == "Guest") {
        if (seat?.seat_canceled == 0) {
          if (guestbookedSeats.hasOwnProperty("Seats")) {
            guestbookedSeats["Seats"]["guest_booked_seats_count"] =
              guestbookedSeats["Seats"]["guest_booked_seats_count"] + 1;
            guestbookedSeats["Seats"]["seats_data"].push(seat);
          } else {
            guestbookedSeats["Seats"] = {};
            guestbookedSeats["Seats"]["guest_cancelled_count"] = 0;
            guestbookedSeats["Seats"]["booking_id"] = seat["booking_id"];
            guestbookedSeats["Seats"]["guest_booked_seats_count"] = 1;
            guestbookedSeats["Seats"]["guest_price"] =
              viewDetails[0].event?.guest_price;
            guestbookedSeats["Seats"]["seats_data"] = [];
            guestbookedSeats["Seats"]["seats_data"].push(seat);
          }
        }
      } else {
        if (seat?.seat_canceled == 0) {
          if (bookedSeats.hasOwnProperty("Seats")) {
            bookedSeats["Seats"]["booked_seats_count"] =
              bookedSeats["Seats"]["booked_seats_count"] + 1;
            bookedSeats["Seats"]["seats_data"].push(seat);
          } else {
            bookedSeats["Seats"] = {};
            bookedSeats["Seats"]["cancelled_count"] = 0;
            bookedSeats["Seats"]["booking_id"] = seat["booking_id"];
            bookedSeats["Seats"]["booked_seats_count"] = 1;
            bookedSeats["Seats"]["price"] = viewDetails[0]?.event?.price;
            bookedSeats["Seats"]["seats_data"] = [];
            bookedSeats["Seats"]["seats_data"].push(seat);
          }
        }
      }
    });
    setSeatsConfirmed(bookedSeats);
    setTotalCancelledSeatCount(0);
    setGuestSeatsConfirmed(guestbookedSeats);
  };

  const handleRemoveGuest = (bookingType) => {
    if (
      guestSeatsConfirmed[bookingType]?.guest_booked_seats_count -
        guestSeatsConfirmed[bookingType]?.guest_cancelled_count >
      0
    ) {
      guestSeatsConfirmed[bookingType]["guest_cancelled_count"] =
        guestSeatsConfirmed[bookingType]["guest_cancelled_count"] + 1;
      setGuestSeatsConfirmed({ ...guestSeatsConfirmed });
      setTotalCancelledSeatCount((prev) => prev + 1);
      viewDetails[0]?.payment_status === "PAID" && handlePreCancelApi();
    }
  };

  const handleAddGuest = (bookingType) => {
    if (
      guestSeatsConfirmed[bookingType]?.guest_booked_seats_count -
        guestSeatsConfirmed[bookingType]?.guest_cancelled_count !=
      guestSeatsConfirmed[bookingType]?.guest_booked_seats_count
    ) {
      guestSeatsConfirmed[bookingType]["guest_cancelled_count"] =
        guestSeatsConfirmed[bookingType]["guest_cancelled_count"] - 1;
      setGuestSeatsConfirmed({ ...guestSeatsConfirmed });
      setTotalCancelledSeatCount((prev) => prev - 1);
      viewDetails[0]?.payment_status === "PAID" && handlePreCancelApi();
    }
  };

  const createReqBodyAc = () => {
    let body = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      cancel_reason: reason.trim(),
    };
    let cancelData = [];
    let total_seats_cancelled = 0;
    let guestcancelData = [];
    let guest_total_seats_cancelled = 0;
    for (let key in seatsConfirmed) {
      // if (seatsConfirmed[key]["cancelled_count"] != 0) {
      let tempCancelData = {};
      tempCancelData["no_of_seat_cancelled"] =
        seatsConfirmed[key]["booked_seats_count"];
      tempCancelData["seat_id"] =
        seatsConfirmed[key]["seats_data"][0]["seat_id"];
      total_seats_cancelled =
        total_seats_cancelled + seatsConfirmed[key]["booked_seats_count"];
      body["booking_id"] = seatsConfirmed[key]["booking_id"];
      cancelData.push(tempCancelData);
    }
    // }
    for (let key in guestSeatsConfirmed) {
      // if (guestSeatsConfirmed[key]["guest_cancelled_count"] != 0) {
      let tempCancelData = {};
      tempCancelData["no_of_seat_cancelled"] =
        guestSeatsConfirmed[key]["guest_booked_seats_count"];
      tempCancelData["seat_id"] =
        guestSeatsConfirmed[key]["seats_data"][0]["seat_id"];
      guest_total_seats_cancelled =
        guest_total_seats_cancelled +
        guestSeatsConfirmed[key]["guest_booked_seats_count"];
      body["booking_id"] = guestSeatsConfirmed[key]["booking_id"];
      guestcancelData.push(tempCancelData);
    }
    // }
    body["seats_cancelled"] = cancelData;
    body["guest_seats_cancelled"] = guestcancelData;
    body["total_seats_cancelled"] = total_seats_cancelled;
    body["guest_total_seats_cancelled"] = guest_total_seats_cancelled;
    return body;
  };

  useEffect(() => {
    if (viewId) {
      if (process.env.REACT_APP_PRODUCT != "DLF") {
        viewDetails[0].event?.has_seating_category
          ? formatDataAsRequiredAc()
          : formatDataAsRequiredNoSeatCategoryAc();
      } else {
        viewDetails[0].event?.has_seating_category
          ? formatDataAsRequired()
          : formatDataAsRequiredNoSeatCategory();
      }
    }
  }, [viewId, open]);

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={true}>
        <h2 className={styles.heading}>{viewDetails[0]?.event?.name}</h2>
        <p className={styles.subHeading}>
          Are you sure you want to cancel booking
        </p>
        <div style={{ overflowY: "auto", height: "430px" }}>
          <div
            style={{
              paddingLeft: "56px",
              paddingRight: "56px",
            }}
          >
            {/* --------Seat Booked-------------------------- */}
            <div className={styles.bookedMainDiv}>
              <p className={styles.textLabel}>Seats Booked</p>
              {Object.keys(seatsConfirmed).map((seatType) => {
                return (
                  <div className={styles.flexDiv}>
                    <span className={styles.bookedDivTitle}>
                      <span>
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/eventBookModalIcon.svg`}
                          alt="subtractIcon"
                        />
                      </span>
                      <p>{seatType}</p>
                    </span>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {process.env.REACT_APP_PRODUCT == "DLF" && (
                        <span
                          className={styles.counterMinus}
                          onClick={() => handleRemove(seatType)}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/subtractIcon.png`}
                            alt="subtractIcon"
                          />
                        </span>
                      )}
                      <span className={styles.counterModal}>
                        {seatsConfirmed[seatType]?.booked_seats_count -
                          seatsConfirmed[seatType]?.cancelled_count}
                      </span>
                      {process.env.REACT_APP_PRODUCT == "DLF" && (
                        <span
                          className={styles.counterMinus}
                          onClick={() => handleAdd(seatType)}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addIcon.png`}
                            alt="addIcon"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
              {process.env.REACT_APP_PRODUCT != "DLF" &&
                Object.keys(guestSeatsConfirmed)?.length > 0 && (
                  <>
                    <p className={styles.textLabel}>For Guests</p>
                    {Object.keys(guestSeatsConfirmed).map((seatType) => {
                      return (
                        <div className={styles.flexDiv}>
                          <span className={styles.bookedDivTitle}>
                            <span>
                              <img
                                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/eventBookModalIcon.svg`}
                                alt="subtractIcon"
                              />
                            </span>
                            <p>{seatType}</p>
                          </span>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {/* <span
                            className={styles.counterMinus}
                            onClick={() => handleRemoveGuest(seatType)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/subtractIcon.png`}
                              alt="subtractIcon"
                            />
                          </span> */}
                            <span className={styles.counterModal}>
                              {guestSeatsConfirmed[seatType]
                                ?.guest_booked_seats_count -
                                guestSeatsConfirmed[seatType]
                                  ?.guest_cancelled_count}
                            </span>
                            {/* <span
                            className={styles.counterMinus}
                            onClick={() => handleAddGuest(seatType)}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addIcon.png`}
                              alt="addIcon"
                            />
                          </span> */}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
            </div>
            {/*------Seats Cancelled------------------------- */}
            {totalCancelledSeatCount > 0 && (
              <div className={styles.seatCancelledMainDiv}>
                <p className={styles.textLabel}>Seats Cancelled</p>
                {Object.keys(seatsConfirmed).map((seatType) => {
                  return seatsConfirmed[seatType]?.booked_seats_count -
                    seatsConfirmed[seatType]?.cancelled_count !=
                    seatsConfirmed[seatType]?.booked_seats_count ? (
                    <div className={styles.flexDiv}>
                      <span>
                        <p className={styles.colorText}>{seatType}</p>
                        <p className={styles.colorText}>
                          {" "}
                          {currencySymbol} {seatsConfirmed[seatType]?.price}
                        </p>
                      </span>
                      <p className={styles.colorText}>
                        {seatsConfirmed[seatType]?.cancelled_count}
                      </p>
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            )}
            <div className={styles.flexDiv}>
              <p className={styles.textLabel}>Total amount paid</p>
              {viewDetails[0]?.payment_status === "UNPAID" ? (
                <p className={styles.textLabel}>{currencySymbol} {0}</p>
              ) : (
                <p className={styles.textLabel}>
                  {currencySymbol} {viewDetails[0]?.total_amount_with_tax}
                </p>
              )}
            </div>
            {totalCancelledSeatCount > 0 && (
              <div className={styles.flexDiv}>
                <p className={styles.textLabel}>Refundable amount</p>
                {viewDetails[0]?.payment_status === "UNPAID" ? (
                  <p className={styles.textLabel}>{currencySymbol} {0}</p>
                ) : (
                  <p className={styles.textLabel}>{currencySymbol} {refundableAmount}</p>
                )}
              </div>
            )}
          </div>
          <div className={styles.reasonDiv}>
            <label className={styles.labelFont}>Reason for cancellation</label>
            <span className={styles.star}>*</span>
            <br />
            <Textarea
              className={styles.inputTextArea}
              width={534}
              height={44}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.btnDiv}>
          {viewDetails[0]?.booking_status !== "Cancelled" &&
            viewDetails[0]?.booking_status !== "Booked" && (
              <ButtonBorder
                isDisabled={isDisabled}
                text="Yes, Cancel"
                width={200}
                buttonHandler={() =>
                  // handleCancelBooking(viewDetails[0]?.booking_no)
                  handleCancellation()
                }
              />
            )}
          <ButtonColor
            text="Back"
            width={200}
            buttonHandler={() => {
              onCloseModal();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
