import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import styles from "./registrationfieldModal.module.css";
import { TextInput } from "evergreen-ui";
import { ButtonBorder } from "../../../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { CustomSelect } from "../../../../../Components/CustomSelect/CustomSelect";
import { ButtonColor } from "../../../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { Togle } from "../../../../../Components/Togle/Togle";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { loadData } from "../../../../../Utils/localStorage";
import { EventConfig } from "../../../../EventConfig";
import { IS_REGISTRATION_FLOW } from "../../../../../Utils/moduleConfig";
export const RegistrationFieldModal = ({
  open,
  onCloseModal,
  title,
  activeRegistrationBtn,
  selectedAudienceForm,
  dropDownFieldType,

  addParticipantRegistrationField,
  getFormList,
  selectedAudienceFormDetails,
}) => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const [customFieldLabel, setCustomFieldLabel] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [formName, setFormName] = useState("");
  const [formPrimaryOptions, setFormPrimaryOptions] = useState({});
  const [fields, setFields] = useState([]);
  const [fieldsPrimary, setFieldsPrimary] = useState([
    {
      value: "",
      label: customFieldLabel,
      label: "",
      type: "",
      isMandatory: 0,
      isVisible: 1,
      isDelete: 0,
      status: 1,
      // options: [{ label: "utk", value: "utk" }],
    },
  ]);

  const toggleFunction = (index) => {
    const list = [...fields];
    list[index].isMandatory = list[index].isMandatory == true ? 0 : 1;
    setFields(list);
  };
  const handleAddField = () => {
    setFields([
      ...fields,
      {
        value: "",
        label: customFieldLabel,
        type: "",
        isMandatory: 0,
        isVisible: 1,
        isDelete: 0,
      },
    ]);
  };
  const handleAddFieldPrimary = () => {
    setFieldsPrimary([
      ...fieldsPrimary,
      {
        value: "",
        label: "",
        type: "",
        isMandatory: 0,
        isVisible: 1,
        isDelete: 0,
      },
    ]);
  };
  const handleDelete = (index) => {
    const rows = [...fields];
    rows.splice(index, 1);
    setFields(rows);
  };
  const handleDeletePrimary = (index) => {
    const rows = [...fieldsPrimary];
    rows.splice(index, 1);
    setFieldsPrimary(rows);
  };
  const handleFields = (index, e) => {
    const { name, value } = e.target;
    const list = [...fields];
    list[index][name] = value;
    setFields(list);
  };
  const handleFieldsDropdown = (idx, e) => {
    const list = [...fields];
    if (e.value == "single_choice") {
      list[idx].type = e;
      list[idx].options = [{ label: `option 1`, value: `option 1` }];
    } else if (e.value == "checkbox") {
      list[idx].type = e;
      list[idx].selectedDropdown = {};
      list[idx].options = [{ label: `option 1`, value: `option 1` }];
    } else if (e.value == "dropdown") {
      list[idx].type = e;
      list[idx].selectedDropdown = {};
      list[idx].options = [{ label: `option 1`, value: `option 1` }];
    } else {
      list[idx].type = e;
      delete list[idx].options;
      delete list[idx].selectedDropdown;
    }

    setFields(list);
  };
  const handleDropDownSecondry = (idx, e) => {
    let list = [...fieldsPrimary];
    list[idx] = {
      label: e?.label,
      type: { label: e?.fields?.field_type, value: e?.fields?.field_type },
      isMandatory: e?.fields?.is_mandatory,
      isDelete: e?.fields?.is_deleted,
      isVisible: e?.fields?.is_visible,
      value: e?.value,
    };
    if (e?.fields?.options?.length > 0) {
      list[idx].options = e?.fields?.options;
    }

    setFieldsPrimary(list);
  };
  const handleAddOption = (index) => {
    let list = [...fields];
    list[index].options = [
      ...list[index].options,
      {
        label: `option${list[index].options.length + 1}`,
        value: `option${list[index].options.length + 1}`,
      },
    ];
    setFields(list);
  };
  const handleOptionUpdate = (idx, e, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    if (updatedFields[index]?.options) {
      const updatedOption = { ...updatedFields[index].options[idx] };
      updatedOption[name] = value;
      updatedFields[index].options[idx] = updatedOption;
      setFields(updatedFields);
    }
  };
  const handleOptionDelete = (index, idx) => {
    const updatedFields = [...fields];
    if (updatedFields[index]?.options) {
      updatedFields[index].options = updatedFields[index].options.filter(
        (_, i) => i !== idx
      );
      setFields(updatedFields);
    }
  };

  const handleRegistrationSubmit = () => {
    let validationErr = false;

    let tempField = [...fieldsPrimary, ...fields];
    if (tempField?.length < 1) {
      validationErr = true;
      return;
    }

    if (activeRegistrationBtn === "addForm" && !formName) {
      toast(`Form Name cannot be empty`, {
        type: "error",
      });
      validationErr = true;
      return;
    }
    tempField?.map((field, index) => {
      if (validationErr) {
        return;
      }
      const { label, isMandatory, options, type } = field;

      // Check for mandatory fields
      if (!label) {
        toast(`Field Name ${index + 1} cannot be empty`, {
          type: "error",
        });
        validationErr = true;
        return;
      } else if (!type) {
        toast(`Field type ${index + 1} cannot be empty`, {
          type: "error",
        });
        validationErr = true;
        return;
      }

      // If the field has options, check if at least one is selected (if applicable)
      else if (options && options.length > 0) {
        const selectedOption = options.find((option) => option.label); // Adjust according to how selection is represented
        if (!selectedOption) {
          toast(`options cannot be empty`, {
            type: "error",
          });
          validationErr = true;
        }
      }
    });

    if (!validationErr) {
      const hasDuplicateLabels = (arr) => {
        const labels = arr.map((obj) => obj.label.toLowerCase());
        return labels.some((label, index) => labels.indexOf(label) !== index);
      };
      if (hasDuplicateLabels(tempField)) {
        toast("Duplicate Field Names found! Submission is not allowed.", {
          type: "error",
        });
        return;
      }
      if (activeRegistrationBtn == "addForm") {
        setBtnDisable(true);
        handleCreateApi();
      } else if (activeRegistrationBtn == "addField") {
        const hasMatchingLabels = (arr1, arr2) => {
          return arr1.some((obj1) =>
            arr2.some(
              (obj2) => obj1.label.toLowerCase() === obj2.label.toLowerCase()
            )
          );
        };
        if (hasMatchingLabels(selectedAudienceFormDetails, tempField)) {
          toast("Matching Field Names found on the selected form.", {
            type: "error",
          });
          return;
        }
        addParticipantRegistrationField(tempField, selectedAudienceForm?.label);
        onCloseModal();
      }
    }
  };
  const handleCreateApi = async () => {
    try {
      let tempField = [...fieldsPrimary, ...fields];
      tempField = tempField?.map((i, idx) => {
        let tempOption = i?.options
          ?.filter((option) => option.label !== "")
          ?.map((j, idx) => {
            return j?.label;
          });
        return {
          field_name: i?.label,
          field_type: i?.type?.value,
          field_order: idx,
          is_mandatory: i?.isMandatory,
          options: tempOption?.length > 0 ? tempOption : [],
          availble_for: "Both",
          is_visible: i?.isVisible,
          is_deleted: i?.isDelete,
          status: i?.status ? 1 : 0,
        };
      });

      let payload = {
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: EventConfig.requestBy,
        community_type: "AC_COMM",
        registration_allowed: 1,
        login_user_id: profileDetails?.login_user_id,
        request_from: "event_audience",
        event_form_name: formName,
        participant_form: tempField,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/event/create_participant`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );

      const res = await response.json();

      if (res?.success) {
        setFieldsPrimary([
          {
            value: "",
            label: customFieldLabel,
            type: "",
            isMandatory: 0,
            isVisible: 1,
            isDelete: 0,
            status: 1,
          },
        ]);

        // Call these functions if response is successful
        await getFormList(); // Ensure getFormList completes
        onCloseModal(); // Close the modal
        setBtnDisable(false);
        toast(`${res.message}`, { type: "success" });
      } else {
        setBtnDisable(false);
        toast(`Error: ${res.message}`, { type: "error" });
      }
    } catch (err) {
      console.error("Error in handleCreateApi:", err);
      toast(`An error occurred: ${err.message}`, { type: "error" });
    }
  };
  const getExistingFormList = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      request_from: "event_audience",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/event/get_exiting_field_details/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setFormPrimaryOptions(
            res?.data?.map((item, index) => {
              return {
                label: item?.field_name,
                value: item?.id,
                fields: item,
              };
            })
          );
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setCustomFieldLabel("");

    setFormName("");
    setFieldsPrimary([
      {
        value: "",
        label: customFieldLabel,
        type: "",
        isMandatory: 0,
        isVisible: 1,
        isDelete: 0,
        status: 1,
      },
    ]);
    setFields([]);
  }, [onCloseModal]);
  useEffect(() => {
    IS_REGISTRATION_FLOW && getExistingFormList();
  }, []);
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      showCloseIcon={true}
      classNames={{
        modal: styles.customModal,
      }}
    >
      <div className={styles.mainDiv}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.scrollDiv}>
          {activeRegistrationBtn === "addForm" && (
            <div style={{ marginBottom: "22px" }}>
              <label className={styles.labelFont}>Form Name</label>
              <span className={styles.star}>*</span>
              <br />
              <TextInput
                className={styles.inputText}
                name="formName"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                autoComplete="off"
                maxLength="50"
              />
            </div>
          )}
          {/* ----------------------PrimaryDynamic-------------------------- */}
          <div className={styles.btnDivExisting}>
            <div
              className={styles.addBtnSecondry}
              onClick={() => {
                handleAddFieldPrimary();
              }}
            >
              + Existing Field
            </div>
          </div>
          <div className={styles.dynamicDiv}>
            {fieldsPrimary?.map((i, index) => {
              return (
                <div style={{ background: " #f9f9f9" }}>
                  <div className={styles.displayFlexDiv}>
                    <div>
                      <div className={styles.displayFlexDivRight}>
                        <div className={styles.fieldTypeDiv}>
                          <label className={styles.labelFont}>Field Name</label>
                          <span className={styles.star}>*</span>
                          <br />
                          <div style={{ marginTop: "12px" }}>
                            <CustomSelect
                              options={formPrimaryOptions}
                              value={i?.id}
                              name="type"
                              handleOnChangeDropdown={(e) => {
                                handleDropDownSecondry(index, e);
                              }}
                              padding={"4px"}
                              border={"none"}
                              height={"100px"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.displayFlexDivLeft}>
                        <label className={styles.labelFont}>Field Type</label>
                        <span className={styles.star}>*</span>
                        <br />
                        <TextInput
                          className={styles.inputText}
                          value={i?.type?.value}
                          name="label"
                          onChange={(e) => handleFields(index, e)}
                          disabled={true}
                        />
                      </div>
                      <div
                        className={styles.cursor}
                        onClick={() => {
                          handleDeletePrimary(index);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/deleteLogo.svg`}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
          {/* ---------------------------------------------- */}

          <div className={styles.dynamicDiv}>
            {fields.map((i, index) => {
              return (
                <div style={{ background: " #f9f9f9" }}>
                  <div className={styles.displayFlexDiv}>
                    <div>
                      <div className={styles.displayFlexDivLeft}>
                        <label className={styles.labelFont}>Field Name</label>
                        <span className={styles.star}>*</span>
                        <br />
                        <TextInput
                          className={styles.inputText}
                          value={i.label}
                          name="label"
                          onChange={(e) => handleFields(index, e)}
                        />
                      </div>
                      <div className={styles.displayFlexDivRight}>
                        <div className={styles.fieldTypeDiv}>
                          <label className={styles.labelFont}>Field Type</label>
                          <span className={styles.star}>*</span>
                          <br />

                          <div style={{ marginTop: "12px" }}>
                            <CustomSelect
                              options={dropDownFieldType}
                              value={i?.type}
                              name="type"
                              handleOnChangeDropdown={(e) => {
                                handleFieldsDropdown(index, e);
                              }}
                              padding={"4px"}
                              border={"none"}
                              height={"100px"}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.cursor}
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/deleteLogo.svg`}
                        />
                      </div>
                    </div>
                    <div className={styles.togleDiv}>
                      <Togle
                        toggle={i?.isMandatory}
                        toggleFunction={() => toggleFunction(index)}
                      />
                      <span>Required</span>
                    </div>
                    {fields[index]?.options && (
                      <span>
                        <div
                          onClick={() => {
                            handleAddOption(index);
                          }}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addGuestIcon.png`}
                          />
                        </div>
                      </span>
                    )}
                  </div>
                  {fields[index]?.options?.map((j, idx) => {
                    return (
                      <div className={styles.displayFlexDiv}>
                        <div>
                          <div className={styles.displayFlexDivLeft}>
                            <TextInput
                              className={styles.inputText}
                              value={`option${idx + 1}`}
                              name="label"
                              // onChange={(e) => handleOptionUpdate(idx, e)}
                              disabled={true}
                            />
                          </div>
                          <div className={styles.displayFlexDivRight}>
                            <div className={styles.fieldTypeDiv}>
                              <TextInput
                                className={styles.inputText}
                                value={j.label}
                                name="label"
                                onChange={(e) =>
                                  handleOptionUpdate(idx, e, index)
                                }
                              />
                            </div>
                          </div>
                          <div
                            className={styles.cursor}
                            onClick={() => {
                              handleOptionDelete(index, idx);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/vector.png`}
                              alt="removeIcon"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <hr />
                </div>
              );
            })}
          </div>

          <div className={styles.btnDivExisting}>
            <div
              className={styles.addBtnSecondry}
              onClick={() => {
                handleAddField();
              }}
            >
              + Add field
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.btnDiv}>
          <ButtonBorder
            text={"Cancel"}
            width={200}
            buttonHandler={() => {
              onCloseModal();
            }}
          />
          <ButtonColor
            text={"Save"}
            width={200}
            isDisabled={btnDisable}
            buttonHandler={() => {
              handleRegistrationSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
