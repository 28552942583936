import React, { useEffect, useState } from "react";
import styles from "./bookingVenueViewModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { BookingReqTblVenue } from "../../BookingRequestVenue/BookingReqTblVenue/BookingReqTblVenue";
import { loadData } from "../../../Utils/localStorage";
import { getBookingRequestById } from "../../../Components/Redux/venueSlice";

function BookingVenueRequestView({ open, setNoOfBooking }) {
  const columns = [
    "Booking Id",
    "Booked For",
    "Booked By",
    "Membership no.",
    "Venue",
    "Start Date & Time",
    "End Date & Time",
    "Advance Amount",
    "Outstanding Amount",
    "Actions",
  ];
  const dispatch = useDispatch();
  const {
    bookingRequestVenue,
    venueActiveTab,
    venueDetails,
    totalBookingRequestCount,
  } = useSelector((state) => state.venueSlice);
  // pagination
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  // search
  const [inputValue, setInputValue] = useState("");

  const handleOffset = (val) => {
    if (val == "next") {
      offset + bookingRequestVenue.length < totalBookingRequestCount &&
        setOffset(offset + limit);
    } else {
      offset > 0 && setOffset(offset - limit);
    }
  };

  const handleBookingRequest = () => {
    let args = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      property_id: "DGOLF",
      booking_status: "Pending",
    };
    if (inputValue !== "") {
      args["membership_no"] = inputValue;
    }
    if (offset !== "") {
      args["offset"] = offset;
    }
    if (limit !== "") {
      args["limit"] = limit;
    }
    dispatch(getBookingRequestById({ args, venueActiveTab }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleBookingRequest();
    }, 500);
    return () => clearTimeout(timer);
  }, [venueActiveTab, offset, inputValue]);

  return (
    <div className={styles.facilityBookingRequestViewMainContainer}>
      <Modal
        open={open}
        onClose={() => setNoOfBooking(false)}
        showCloseIcon={true}
        center
        classNames={{
          overlay: styles.customOverlay,
          modal: styles.customModal,
        }}
      >
        <h2 className={styles.heading}>{venueDetails?.name}</h2>
        <div className={styles.searchParentDiv}>
          <div className={styles.searchDiv}>
            <div className={styles.searchIcon}>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
              />
            </div>
            <input
              className={styles.searchDivInput}
              placeholder={" |  Search by Name / Membership no. "}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleBookingRequest();
                }
              }}
            />
          </div>
        </div>
        <div className={styles.bookingNoMainDiv}>
          <BookingReqTblVenue
            columns={columns}
            allRequests={true}
            details={bookingRequestVenue}
            handleBookingRequest={handleBookingRequest}
          />
          {bookingRequestVenue.length > 0 && (
            <div className={styles.pagination}>
              <div>
                <div className={styles.paginationValuesDiv}>
                  <p>{offset + 1}</p>
                  <p>-</p>
                  <p>{offset + bookingRequestVenue.length}</p>
                  <p>of</p>
                  <p>{totalBookingRequestCount && totalBookingRequestCount}</p>
                </div>
                <div className={styles.paginationIconDiv}>
                  <span onClick={() => handleOffset("prev")}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                    />
                  </span>
                  <span onClick={() => handleOffset("next")}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default BookingVenueRequestView;
