import React from "react";
import { useState } from "react";
import styles from "../AddRole/addRole.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ToggleCrud from "./../ToggleCrud/ToggleCrud";

const AccordionListenerUserRights = ({
  moduleName,
  moduleId,
  moduleAccess,
  handleToggleChange,
}) => {
  const [expand, setExpand] = useState(false);
  const handleExpandedAccord = () => {
    setExpand((prevState) => !prevState);
  };

  return (
    <div>
      <Accordion
        style={{
          marginBottom: "15px",
          background: "rgba(250, 250, 250, 1)",
          border: "1px solid rgba(222, 223, 225, 1)",
          width: "890px",
          background: "white",
        }}
        expanded={expand}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={handleExpandedAccord}
          style={{
            display: "flex",
            justAlign: "center",
            width: "890px",
            borderRadius: "4px 4px 0px 0px",
            background: "rgba(248, 236, 243, 1)",
          }}
        >
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "20px",
              letterSpacing: "-0.04em",
            }}
          >
            {moduleName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div className={styles.userRightsCruds}>
            <div className={styles.userRightOuterDiv}>
              <div className={styles.crudDiv}>
                <div>Create</div>
                <ToggleCrud
                  toggle={moduleAccess[moduleId]?.create}
                  toggleFunction={() => handleToggleChange(moduleId, "create")}
                />
              </div>
              <div className={styles.crudDiv}>
                <div>Read</div>
                <ToggleCrud
                  toggle={moduleAccess[moduleId]?.read}
                  toggleFunction={() => handleToggleChange(moduleId, "read")}
                />
              </div>
            </div>
            <div className={styles.userRightOuterDiv}>
              <div className={styles.crudDiv}>
                <div>Update</div>
                <ToggleCrud
                  toggle={moduleAccess[moduleId]?.update}
                  toggleFunction={() => handleToggleChange(moduleId, "update")}
                />
              </div>
              <div className={styles.crudDiv}>
                <div>Delete</div>
                <ToggleCrud
                  toggle={moduleAccess[moduleId]?.delete}
                  toggleFunction={() => handleToggleChange(moduleId, "delete")}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionListenerUserRights;
