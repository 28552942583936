import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import styles from "./timePickerr.module.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import localizedFormat from "dayjs/plugin/localizedFormat";
import { toast } from "react-toastify";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
export const TimePickerr = ({
  isEdit,
  timeName,
  hideBorder,
  createEvent,
  handleTimePicker,
  customWidth,
  showMinute,
  isDisabled,
  startInitialTime,
  endInitialTime,
  trainersData,
  salonServiceDetails,
  dynamicValue,
  value,
}) => {
  const [updateTrainerTime, setUpdateTrainerTime] = useState();
  dayjs.extend(localizedFormat);
  const handleTime = (e, timeName) => {
    handleTimePicker(e, timeName);
  };

  const handleFacTimeChange = () => {
    trainersData?.forEach((tData) => {
      if (createEvent?.startTime || createEvent?.endTime) {
        tData.start_time = "";
        tData.end_time = "";
      }
    });
  };
  const getHours = (clockType, timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");
    let hours24 = parseInt(hours, 10);
    if (period === "PM" && hours24 !== 12) {
      hours24 += 12;
    } else if (period === "AM" && hours24 === 12) {
      hours24 = 0;
    }
    return [hours24, minutes];
  };

  const shouldDisableTime = (timeValue, clockType) => {
    if (salonServiceDetails) {
      let [startHour, startMins] =
        salonServiceDetails[0]?.start_time &&
        getHours("hours", salonServiceDetails[0]?.start_time);
      let [endHour, endMins] =
        salonServiceDetails[0]?.end_time &&
        getHours("hours", salonServiceDetails[0]?.end_time);
      const currentHour = dayjs(timeValue).hour();
      const currentMinute = dayjs(timeValue).minute();
      if (clockType === "hours") {
        if (currentHour < startHour || currentHour > endHour) {
          return true;
        }
      } else if (clockType === "minutes") {
        if (
          (currentHour === startHour && currentMinute < startMins) ||
          (currentHour === endHour && currentMinute > endMins)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div
      className={
        isDisabled ? styles.timePickerDivDisabled : styles.timePickerDiv
      }
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["TimePicker"]}>
          <TimePicker
            label=""
            closeOnSelect={false}
            maxTime={endInitialTime}
            minTime={startInitialTime}
            shouldDisableTime={shouldDisableTime}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: hideBorder
                    ? "#ffffff !important"
                    : "#c5c0c7 !important",
                },
                "&:hover fieldset": {
                  borderColor: hideBorder
                    ? "#ffffff !important"
                    : "#c5c0c7 !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: hideBorder
                    ? "#ffffff !important"
                    : "#c5c0c7 !important",
                },
              },
              "& .MuiInputBase-input": {
                height: "10px !important",
                width: `${customWidth} ! important`,
                outline: "none!important",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "20px",
                display: "flex",
                alignItems: "center",
              },
              "& .MuiList-root-MuiMultiSectionDigitalClockSection-root:after": {
                height: "calc(100% - 38px)",
              },
            }}
            views={showMinute && ["minutes"]}
            format={showMinute && "mm"}
            name={timeName}
            value={
              dynamicValue
                ? dayjs(value)
                : createEvent && dayjs(createEvent[timeName])
            }
            onChange={(e) => {
              if (isEdit && createEvent?.isTrainerPresent == 1) {
                toast.error("Please edit trainer timings");
              }
              if (process.env.REACT_APP_PRODUCT !== "DLF") {
                handleTime(e, timeName);
              } else if (!isDisabled) {
                handleTime(e, timeName);
                handleFacTimeChange();
              }
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};
