import React from "react";
import styles from "./bookingSuccess.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const BookingFailed = ({ open, onCloseModal, status, error }) => {
  return (
    <>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={false}>
        <div className={styles.tooltipChangeStatus}>
          <div className={styles.imgDiv}>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/failedIcon.png`}
            />
          </div>
          <p className={styles.modalDivp}>Booking Failed </p>
          {process.env.REACT_APP_PRODUCT == "DLF" && (
            <p className={styles.modalDivp1}>Retry Booking</p>
          )}
          <p className={styles.modalDivp2}>{error && error}</p>
        </div>
      </Modal>
    </>
  );
};
