import dayjs from "dayjs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { loadData } from "../../../Utils/localStorage";
import { rupee } from "../../../Utils/Validation";
import { EventConfig } from "../../EventConfig";
import { BookingTblModal } from "../BookingTblModal/BookingTblModal";
import { ViewBookingEvent } from "../ViewBookingEvent/ViewBookingEvent";
import styles from "./bookingHistoryTblEvent.module.css";
import NonMemberPayment from "../NonMemberPayment/NonMemberPayment";
import { useSelector } from "react-redux";
import { getCurrencySymbolByTimeZone } from "../../../Utils/currencySymbol";

export const BookingHistoryTblEvent = ({
  columns,
  details,
  handleBookingHistory,
  tableRef,
}) => {
  const { eventRights } = useSelector((state) => state.authSlice);
  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(profileDetails?.comm_time_zone)

  const [viewId, setViewId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [reason, setReason] = useState("");
  const [viewNonMemberId, setViewNonMemberId] = useState("");

  //bookingTblModal--On Click of action open Modal--------------------------------------
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const onOpenBookingModal = () => setOpenBookingModal(true);
  const onCloseBookingModal = () => {
    setOpenBookingModal(false);
    setReason("");
  };

  // non member payment modal --------------------------------------------------
  const [openNonMemberModal, setOpenNonMemberModal] = useState(false);
  const onOpenNonMemberModal = () => setOpenNonMemberModal(true);
  const onCloseNonMemberModal = () => setOpenNonMemberModal(false);

  //on click of booked for open Modal----------------------
  const [openBooking, setOpenBooking] = useState(false);
  const onOpenModal = () => setOpenBooking(true);
  const onCloseModal = () => setOpenBooking(false);

  //--------------------------------------------------------------
  const handleCancelBooking = (bookingNo) => {
    if (isDisabled) {
      return;
    }
    if (reason.trim() == "") {
      toast.error("Please enter Reason");
    } else {
      setIsDisabled(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/api/event/cancel_booking/${bookingNo}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: EventConfig.requestBy,
            cancel_reason: reason.trim(),
            ...(process.env.REACT_APP_PRODUCT != "DLF" && {
              community_type: loadData("Community_type"),
            }),
          }),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      )
        .then((d) => d.json())
        .then((res) => {
          if (res.status_code == 101) {
            toast(`${res.message}`, {
              type: "error",
            });
          }
          if (res.status_code == 201) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else {
            toast(`${res.message}`, {
              type: "success",
            });
            handleBookingHistory();
            onCloseBookingModal();
            setTimeout(() => {
              setIsDisabled(false);
            }, 1000);
          }
        })
        .catch((err) => {
          setIsDisabled(false);
        });
    }
  };

  return (
    <>
      <BookingTblModal
        open={openBookingModal}
        onCloseModal={onCloseBookingModal}
        viewId={viewId}
        details={details}
        disableBtn={isDisabled}
        handleCancelBooking={handleCancelBooking}
        topTitle={"Cancel Booking"}
        reason={reason}
        setReason={setReason}
        handleBookingHistory={handleBookingHistory}
        setViewId={setViewId}
      />
      <ViewBookingEvent
        open={openBooking}
        onCloseModal={onCloseModal}
        viewId={viewId}
        details={details}
        disableBtn={isDisabled}
        handleCancelBooking={handleCancelBooking}
        cancelBookingModalStatus={onOpenBookingModal}
      />
      <NonMemberPayment
        open={openNonMemberModal}
        onCloseModal={onCloseNonMemberModal}
        details={details}
        viewNonMemberId={viewNonMemberId}
      />
      <div
        style={{
          height: "360px",
          overflow: "auto",
        }}
      >
        {details?.length > 0 ? (
          <table
            style={{ borderCollapse: "separate", borderSpacing: "0 8px" }}
            ref={tableRef}
          >
            <thead>
              <tr className={styles.BHEventtableHead}>
                {columns?.map((col, index) => {
                  return <th key={index}>{col}</th>;
                })}
                {process.env.REACT_APP_PRODUCT == "DLF" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {details?.length > 0 &&
                details?.map((tbldata, index) => {
                  // let currDateTime = new Date();;
                  let BookingDateTime = dayjs(
                    tbldata?.event?.start_at?.split(" ")[0] +
                      " " +
                      tbldata?.event?.event_start_time,
                    "YYYY-MM-DD hh:mm A"
                  ).format("YYYY-MM-DD hh:mm A");
                  BookingDateTime = new Date(BookingDateTime);
                  let currDateTime = dayjs().format("YYYY-MM-DD hh:mm A");
                  currDateTime = new Date(currDateTime);
                  return (
                    <tr
                      // key={index}
                      className={styles.BHEventtableData}
                    >
                      {process.env.REACT_APP_PRODUCT != "DLF" && (
                        <td style={{ minWidth: "218px" }}>
                          {tbldata?.booking_date ? tbldata?.booking_date : "-"}
                        </td>
                      )}
                      <td
                        className={styles.bookedFor}
                        onClick={() => {
                          onOpenModal();
                          setViewId(tbldata?.id);
                        }}
                      >
                        {tbldata?.booking_no}
                      </td>
                      <td style={{ minWidth: "218px" }}>
                        {tbldata?.event?.name}
                      </td>
                      <td style={{ minWidth: "232px" }}>
                        <p>{tbldata?.member_details?.full_name}</p>
                      </td>
                      <td style={{ minWidth: "196px" }}>
                        {tbldata?.booked_by}
                      </td>
                      <td style={{ minWidth: "196px" }}>
                        {process.env.REACT_APP_PRODUCT == "DLF"
                          ? tbldata?.member_id
                          : tbldata?.block_id + "-" + tbldata?.ru_id}
                      </td>
                      {process.env.REACT_APP_PRODUCT === "DLF" && (
                        <td>
                          {tbldata?.is_member == 1 ? "Member" : "Non Member"}
                        </td>
                      )}
                      <td style={{ minWidth: "267px" }}>
                        {dayjs(
                          tbldata?.event?.start_at.split(" ")[0] +
                            " " +
                            tbldata?.event?.event_start_time,
                          "YYYY-MM-DD hh:mm A"
                        ).format("DD MMM YYYY, hh:mm A")}
                      </td>
                      <td style={{ minWidth: "255px" }}>
                        {dayjs(
                          tbldata?.event?.end_at.split(" ")[0] +
                            " " +
                            tbldata?.event?.event_end_time,
                          "YYYY-MM-DD hh:mm A"
                        ).format("DD MMM YYYY, hh:mm A")}
                      </td>
                      <td style={{ minWidth: "262px" }}>
                        <p
                          className={
                            tbldata?.status == "BOOKED" &&
                            tbldata?.payment_status == "PAID"
                              ? styles.open
                              : tbldata?.status == "CANCELED"
                              ? styles.cancelled
                              : tbldata?.status == "Declined".toUpperCase()
                              ? styles.rejected
                              : styles.close
                          }
                        >
                          {tbldata?.status == "BOOKED" &&
                          tbldata?.payment_status == "PAID"
                            ? "BOOKED"
                            : tbldata?.status == "BOOKED" &&
                              tbldata?.payment_status == "UNPAID"
                            ? "UNPAID"
                            : tbldata?.status}
                        </p>
                      </td>
                      <td
                        style={{
                          minWidth: "226px",
                          textAlign: "center",
                          paddingRight: "104px",
                        }}
                      >
                        {tbldata?.booked_seats?.length}
                      </td>
                      <td
                        style={{
                          minWidth: "140px",
                          textAlign:
                            process.env.REACT_APP_PRODUCT == "DLF"
                              ? "right"
                              : "center",
                          paddingRight:
                            process.env.REACT_APP_PRODUCT == "DLF"
                              ? "41px"
                              : "36px",
                        }}
                      >{`${process.env.REACT_APP_PRODUCT === "ALFUTTAIM"?"AED" : currencySymbol} ${rupee.format(
                        tbldata?.total_amount_with_tax
                      )}`}</td>
                      {process.env.REACT_APP_PRODUCT == "DLF" && (
                        <td
                          style={{ minWidth: "160px" }}
                          className={styles.view}
                        >
                          <div style={{ display: "flex" }}>
                            <div
                              onClick={() => {
                                if (
                                  process.env.REACT_APP_PRODUCT == "DLF" &&
                                  BookingDateTime >= currDateTime &&
                                  // tbldata?.event?.cancellation_allowed == 1 &&
                                  tbldata?.payment_status !== "PAID" &&
                                  tbldata?.status !== "CANCELED"
                                ) {
                                  if (eventRights?.update == 1) {
                                    onOpenBookingModal();
                                    setViewId(tbldata?.id);
                                  } else {
                                    toast.error(
                                      "You don't have update access rights"
                                    );
                                  }
                                }
                              }}
                            >
                              {process.env.REACT_APP_PRODUCT == "DLF" &&
                                BookingDateTime >= currDateTime &&
                                // tbldata?.event?.cancellation_allowed == 1 &&
                                tbldata?.payment_status !== "PAID" &&
                                tbldata?.status !==
                                  "CANCELED".toUpperCase() && (
                                  <img
                                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/viewIcon.png`}
                                  />
                                )}
                            </div>
                            {process.env.REACT_APP_PRODUCT === "DLF" &&
                              tbldata?.is_member == 0 &&
                              (tbldata?.status !== "BOOKED" ||
                                tbldata?.payment_status !== "PAID") && (
                                <div
                                  onClick={() => {
                                    setViewNonMemberId(tbldata?.id);
                                    onOpenNonMemberModal();
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/nonMemAddIcon.svg`}
                                  />
                                </div>
                              )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className={styles.noDataMainDiv}>
            <div className={styles.noDataDiv}>
              <span>
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
                />
              </span>
              <h2>No Data Found</h2>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
