export const EventConfig = {
  BookingHistory: `${process.env.REACT_APP_PATH}/events/event-history`,

  AddRequest: `${process.env.REACT_APP_PATH}/events/add-event`,

  EventCalender: `${process.env.REACT_APP_PATH}/events/event-calender`,

  EditEvent: `${process.env.REACT_APP_PATH}/events/edit-event`,

  event: `${process.env.REACT_APP_PATH}/`,

  eventDLF: `${process.env.REACT_APP_PATH}/events`,

  requestBy: "Admin",

  deleteStatus: "Cancelled",

  BookingRegistrationHistory: `${process.env.REACT_APP_PATH}/events/booking-registration`,

  BookingRegistrationHistoryDetails: `${process.env.REACT_APP_PATH}/events/booking-registration-details`,
};
