import React from "react";
import { Modal } from "react-responsive-modal";
import styles from "./viewRegistrationBookingModal.module.css";
import { TopHeader } from "../../../../../../Components/TopHeaderNav/TopHeader";
import dayjs from "dayjs";
export const ViewRegistrationBookingModal = ({
  open,
  onCloseModal,
  viewDetail,
  eventName,
}) => {
  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={true}>
        <div className={styles.navDiv}>
          <TopHeader title={"View Booking"} />
          <div className={styles.viewBookingMainDiv}>
            <div className={styles.viewBookingMainImgDiv}>
              <div className={styles.viewBookingImgDiv}>
                {viewDetail?.event_img ? (
                  <img src={viewDetail?.event_img} />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/YogaImgViewBooking.png`}
                  />
                )}
              </div>
              <div>
                <h2>{eventName}</h2>
                <p className={styles.categoryTitle}>
                  {viewDetail?.event?.event_venue?.name}
                </p>
                <p
                  className={
                    viewDetail?.status == "BOOKED"
                      ? styles.open
                      : viewDetail?.status == "CANCELED"
                      ? styles.cancelled
                      : viewDetail?.status == "Declined".toUpperCase()
                      ? styles.rejected
                      : styles.close
                  }
                >
                  {/* {viewDetail?.status} */}
                  {viewDetail?.status == "BOOKED"
                    ? "BOOKED"
                    : viewDetail?.status == "BOOKED"
                    ? "UNPAID"
                    : viewDetail?.status}
                </p>
              </div>
            </div>
            {viewDetail?.member_custom_details?.length > 0 &&
              viewDetail?.member_custom_details?.map((item, index) => {
                return (
                  <div className={styles.inputDivMain} key={index}>
                    <label>{item?.field_name}</label>
                    <div className={styles.inputDiv}>
                      {item?.field_type === "single_choice" ? (
                        item?.choice_label || "NA"
                      ) : item?.field_type === "checkbox" ? (
                        item?.field_value?.split(",").join(" , ") || "NA"
                      ) : item?.field_type === "file" ? (
                        item?.field_value ? (
                          <div className={styles.dynamicFormImgDiv}>
                            <img src={item?.field_value} alt="file" />
                          </div>
                        ) : (
                          "NA"
                        )
                      ) : item?.field_type === "date" ? (
                        item?.field_value ? (
                          `${dayjs(item?.field_value).format("DD MMM YYYY")}`
                        ) : (
                          "NA"
                        )
                      ) : item?.field_type === "date_range" ? (
                        item?.field_value?.split("#").length === 2 ? (
                          `${dayjs(item?.field_value.split("#")[0]).format(
                            "DD MMM YYYY"
                          )} - ${dayjs(item?.field_value.split("#")[1]).format(
                            "DD MMM YYYY"
                          )}`
                        ) : (
                          "NA"
                        )
                      ) : item?.field_type === "date_time" ? (
                        item?.field_value ? (
                          `${dayjs(item?.field_value).format(
                            "DD MMM YYYY, hh:mm A"
                          )}`
                        ) : (
                          "NA"
                        )
                      ) : item?.field_type === "time" ? (
                        item?.field_value ? (
                          `${dayjs(item?.field_value, "hh:mm:ss").format(
                            "hh:mm A"
                          )}`
                        ) : (
                          "NA"
                        )
                      ) : item?.field_value ? (
                        item?.field_value
                      ) : (
                        "NA"
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
