import React from "react";
import styles from "./topHeader.module.css";
import { ButtonSmall } from "../../../Components/Button/Buttons/ButtonSmall/ButtonSmall";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const TopHeader = ({ showBtn, title }) => {
  const navigate = useNavigate();
  const { roleManagementRights } = useSelector((state) => state.authSlice);

  const handleOpenAddModal = () => {
    if (roleManagementRights.create == 1) {
      navigate(process.env.REACT_APP_PATH + "/clubs/add-role");
    } else {
      toast.error("You don't have create access rights");
    }
  };

  return (
    <div className={styles.topNavMain}>
      <h3>{title}</h3>
      {showBtn && (
        <div className={styles.buttonsDiv}>
          <ButtonSmall title={"Add Role"} buttonHandler={handleOpenAddModal} />
        </div>
      )}
    </div>
  );
};
