import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import eventsSlice from "./eventsSlice";
import facilitiesSlice from "./facilitiesSlice";
import fnbSlice from "./fnbSlice";
import searchSlice from "./searchSlice";
import activeTabSidebar from "./tabSlice";
import venueSlice from "./venueSlice";
import cartSlice from "./cartSlice";
import salonSpaServicesSlice from "./salonSpaServicesSlice";
import salonSpaCartSlice from "./salonSpaCartSlice";
import conciergeSlice from "./conciergeSlice";
import clubsSlice from "./clubsSlice";

export const store = configureStore({
  reducer: {
    activeTabCheck: activeTabSidebar,
    eventsSlice: eventsSlice,
    fnbSlice: fnbSlice,
    authSlice: authSlice,
    searchSlice: searchSlice,
    facilitiesSlice: facilitiesSlice,
    venueSlice: venueSlice,
    cartSlice: cartSlice,
    salonSpaServicesSlice: salonSpaServicesSlice,
    salonSpaCartSlice: salonSpaCartSlice,
    conciergeSlice: conciergeSlice,
    clubsSlice: clubsSlice,
  },
});
