import React, { useState, useEffect } from "react";
import styles from "./requestModal.module.css";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { changeRequestStatus } from "../../Components/Redux/conciergeSlice";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  display: "flex",
  pt: 0,
  pl: 4,
  pr: 4,
  pl: 4,
  flexDirection: "column",
  gap: "32px",
  width: "70%",
  maxHeight: "600px",
  overflow: "scroll",
};

function RequestModal({ requestData, requestPayload }) {
  const dispatch = useDispatch();
  const { conciergeRights } = useSelector((state) => state.authSlice);

  const [status, setStatus] = useState({
    label: requestData?.status,
    value: requestData?.status,
  });
  const requestStatus = [
    { label: "Open", value: "Open" },
    { label: "Close", value: "Closed" },
    { label: "On hold", value: "On Hold" },
  ];

  const customStyles = {
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isSelected
        ? status?.value == "Open"
          ? "var(--statusOpen)"
          : status?.value == "Backd"
          ? "var(--TextDarkColor)"
          : "#FFB752"
        : "var(--TextDarkColor)",
      backgroundColor: state.isSelected
        ? "var(--bgcolor)"
        : "var(--colorWhite)",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
      borderBottom: "1px solid var(--GrayColor)",
    }),

    control: (baseStyles) => ({
      ...baseStyles,
      width: "100%",
      minWidth: "115px",
      height: "32px",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
      backgroundColor: "var(--colorWhite)",
      border: "none",
      boxShadow: "none",
      color:
        status?.value == "Open"
          ? "var(--statusOpen)"
          : status?.value == "Backd"
          ? "var(--TextDarkColor)"
          : "#FFB752",
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: "var(--TextDarkColor)",
    }),
  };

  return (
    <div className={styles.dataContainer}>
      <Box sx={style}>
        <div className={styles.tittle}>Request a callback</div>
        <div className={styles.requestStatusDiv}>
          <div className={styles.headingDiv}> Request Status</div>
          <div className={styles.selctBoxDiv}>
            <Select
              value={status}
              onChange={(e) => {
                if (conciergeRights?.update == 1) {
                  setStatus({ ...e });
                  const args = {
                    statusChangeArguments: { ...e, id: requestData?.id },
                    getHistoryPayload: { ...requestPayload },
                  };
                  dispatch(changeRequestStatus({ args }));
                } else {
                  toast.error("You don't have update access rights");
                }
              }}
              options={[...requestStatus]}
              styles={customStyles}
            />
          </div>
        </div>
        <div className={styles.memberDataDiv}>
          <div className={styles.title2}>Member details :</div>
          <div className={styles.detailsDiv}>
            <div className={styles.dataKeyValue}>
              <div className={styles.dataKey}>Request no.</div>
              <div className={styles.dataValue}>{requestData?.id}</div>
            </div>
            <div className={styles.dataKeyValue}>
              <div className={styles.dataKey}>Requester</div>
              <div className={styles.dataValue}>
                {requestData?.member_details?.full_name}
              </div>
            </div>
            <div className={styles.dataKeyValue}>
              <div className={styles.dataKey}>Contact no.</div>
              <div className={styles.dataValue}>
                {requestData?.member_details?.mobile_phone}
              </div>
            </div>
            <div className={styles.dataKeyValue}>
              <div className={styles.dataKey}>Membership no.</div>
              <div className={styles.dataValue}>{requestData?.member_id}</div>
            </div>
          </div>
        </div>
        <div className={styles.memberDataDiv}>
          <div className={styles.title2}>Request details :</div>
          <div className={styles.detailsDiv}>
            <div className={styles.dataKeyValue}>
              <div className={styles.dataKey}>Requested date for callback</div>
              <div className={styles.dataValue}>
                {dayjs(requestData?.date, "YYYY-MM-DD").format("DD MMM YYYY")}
              </div>
            </div>
            <div className={styles.dataKeyValue}>
              <div className={styles.dataKey}>Requested time for callback</div>
              <div className={styles.dataValue}>
                {dayjs(requestData?.time, "hh:mm:ss").format("hh:mm A")}
              </div>
            </div>
            <hr />
            <div className={styles.queryDiv}>
              <div className={styles.dataKey}>Query :</div>
              <div className={styles.queryBox}>{requestData?.query}</div>
            </div>
          </div>
        </div>
        <div className={styles.paddingBottom}></div>
      </Box>
    </div>
  );
}

export default RequestModal;
