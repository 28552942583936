import React from "react";
import Lottie from "lottie-react";
import logo from "../logo.json";
import AcLogo from "../AcLogo.json";
import PuravankaraLogo from "../PuravankaraLogo.json";
import ACBLogo from "../ACBLogo.json";
import NucleusLogo from "../NucleusLogo.json";
import AuroLogo from "../AuroLogo.json";
import MahindraLogo from "../MahindraLogo.json";
import AlfuttaimLogo from "../AlfuttaimLogo.json";
import DanubeLogo from "../DanubeLogo.json";
export const Logo = () => {
  const styleAc = {
    height: 250,
    marginTop: "5%",
    width: 449,
  };
  const styleSecondry = {
    height: 100,
    marginTop: "12%",
    width: 449,
  };
  const style = {
    height: 150,
    marginTop: "13%",
    width: 349,
  };
  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItem: "center" }}
    >
      <Lottie
        animationData={
          process.env.REACT_APP_PRODUCT === "ACB"
            ? ACBLogo
            : process.env.REACT_APP_PRODUCT === "PURAVANKARA"
            ? PuravankaraLogo
            : process.env.REACT_APP_PRODUCT === "NUCLEUS"
            ? NucleusLogo
            : process.env.REACT_APP_PRODUCT === "AURO"
            ? AuroLogo
            : process.env.REACT_APP_PRODUCT === "MAHINDRA"
            ? MahindraLogo
            : process.env.REACT_APP_PRODUCT === "ALFUTTAIM"
            ? AlfuttaimLogo
            : process.env.REACT_APP_PRODUCT === "DANUBE"
            ? DanubeLogo
            : process.env.REACT_APP_PRODUCT != "DLF"
            ? AcLogo
            : logo
        }
        loop={true}
        style={
          process.env.REACT_APP_PRODUCT === "ACB" ||
          process.env.REACT_APP_PRODUCT === "PURAVANKARA" ||
          process.env.REACT_APP_PRODUCT === "NUCLEUS" ||
          process.env.REACT_APP_PRODUCT === "AURO" ||
          process.env.REACT_APP_PRODUCT === "MAHINDRA" ||
          process.env.REACT_APP_PRODUCT === "ALFUTTAIM" ||
          process.env.REACT_APP_PRODUCT === "DANUBE"
            ? styleSecondry
            : process.env.REACT_APP_PRODUCT != "DLF"
            ? styleAc
            : style
        }
      />
    </div>
  );
};
