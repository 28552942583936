import { Select } from "evergreen-ui";
import React, { useState, useEffect } from "react";
import styles from "./eventCalender.module.css";
import moment from "moment";
import { TopHeader } from "../TopHeader/TopHeader";
import { useNavigate } from "react-router-dom";
import { EventConfig } from "../EventConfig";
import {
  handleCalender,
  handleEndCalender,
  handleEventMonth,
  handleStartCalender,
  handleStartCalenderDate,
  handleEndCalenderDate,
  handleAccessibleToSlice,
  handleStatusSlice,
  handlEventTypeSlice,
  handleCategorySlice,
  handleAvailableDays,
} from "../../Components/Redux/searchSlice";
import { useDispatch } from "react-redux";
import { loadData } from "../../Utils/localStorage";
import dayjs from "dayjs";

export const EventCalender = () => {
  var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
  dayjs.extend(isSameOrBefore);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [day, setDay] = useState("");
  const [selectValue, setSelectValue] = useState(
    `${new Date().getMonth()} ${new Date().getFullYear()}`
  );
  const [next, setNext] = useState([]);
  const [totalDay, setTotalDay] = useState([]);
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //--------------------
  function NextMonths() {
    // Get the current date
    const currentDate = moment();
    // Generate the next 13-months with year
    const nextMonths = [];
    for (let i = 0; i <= 5; i++) {
      const nextMonth = currentDate.clone().add(i, "months");
      nextMonths.push({
        month: nextMonth.format("MMMM"),
        year: nextMonth.format("YYYY"),
      });
    }
    setNext(nextMonths);
  }

  //----------
  const [event, setEvents] = useState([]);
  var count = day - 1;

  const calenderFun = (value) => {
    let temp = String(value).split(" ");
    let month = temp[0];
    let year = temp[1];
    var firstDay = new Date(year, month).getDay();
    let lastDay = 32 - new Date(year, month, 32).getDate();
    setDay(firstDay);
    let arr1 = new Array(lastDay).fill(0);
    setTotalDay(arr1);
    count = lastDay;
    calenderApi(month, year);
  };

  //APi calender
  const calenderApi = (month, year) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      month_year: `${parseInt(month) + 1}-${year}`,
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/event/search-events`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.data === "") {
          setEvents([]);
        } else {
          setEvents(res.data?.events);
        }
      })
      .catch((err) => {});
  };

  //calenderMonthBtn
  const handleMonthCalender = (value) => {
    dispatch(handleStartCalenderDate(""));
    dispatch(handleEndCalenderDate(""));
    let temp = String(value).split(" ");
    let month = temp[0];
    let year = temp[1];
    let selectMonth = `${parseInt(month) + 1}-${year}`;
    dispatch(handleEventMonth(selectMonth));
    if (process.env.REACT_APP_PRODUCT == "DLF") {
      navigate(EventConfig.eventDLF);
    } else {
      navigate(EventConfig.event);
    }
  };

  useEffect(() => {
    calenderFun(selectValue);
  }, [selectValue]);

  useEffect(() => {
    dispatch(handleAccessibleToSlice(""));
    dispatch(handleStatusSlice(""));
    dispatch(handlEventTypeSlice(""));
    dispatch(handleCalender(""));
    dispatch(handleCategorySlice(""));
    dispatch(handleAvailableDays([]));
    dispatch(handleStartCalender(""));
    dispatch(handleEndCalender(""));
    NextMonths();
  }, []);

  return (
    <div className={styles.calenderOuterDiv}>
      <TopHeader title={"Events"} showBtn={true} />
      {/* <SearchAndFilter /> */}
      <div className={styles.calenderDiv}>
        <div className={styles.navCalender}>
          <h1>Event List</h1>
          <div className={styles.dropDownDiv}>
            <div>
              <Select
                value={selectValue}
                onChange={(e) => {
                  setSelectValue(e.target.value);
                }}
                width={246}
                height={44}
              >
                {next.map((i, index) => {
                  return (
                    <option value={`${months.indexOf(i.month)} ${i.year}`}>
                      {i.month} {i.year}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div
              className={styles.eventCalenderLogoDiv}
              onClick={() => {
                handleMonthCalender(selectValue);
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/calenderEventIcon.png`}
              />
            </div>
          </div>
        </div>
        <div className={styles.calenderDivScroll}>
          <div className={styles.calenderMainDiv}>
            {totalDay?.map((item, index) => {
              if (count == 6) {
                count = -1;
              }
              count = count + 1;
              let temp1 = String(selectValue).split(" ");
              let eventDiv = event?.filter((i, evntDivIndex) => {
                let startDate = i.start_at.split(" ")[0];
                let endDate = i.end_at.split(" ")[0];
                const selectedMonthYear = selectValue?.split(" ");
                const selectedMonth = parseInt(selectedMonthYear[0]) + 1;
                const selectedYear = selectedMonthYear[1];
                return (
                  // (parseInt(startDate) == `${index + 1}`)
                  //     || (parseInt(endDate) == `${index + 1}`)
                  //     ||
                  //     (parseInt(startDate) <= `${index + 1}` && parseInt(endDate) >= `${index + 1}`)
                  //     ? i
                  //     : ""
                  dayjs(
                    `${selectedYear}-${selectedMonth}-${index + 1}`
                  ).isBetween(startDate, endDate, "day", "[]")
                    ? i
                    : ""
                );
              });

              return (
                <div
                  className={
                    eventDiv.length !== 0
                      ? styles.eventDateDiv
                      : styles.eventDateDiv1
                  }
                  onClick={() => {
                    if (eventDiv.length !== 0) {
                      let start1 = `${index + 1}-${parseInt(temp1[0]) + 1}-${
                        temp1[1]
                      }`;
                      let end1 = `${index + 1}-${parseInt(temp1[0]) + 1}-${
                        temp1[1]
                      }`;
                      dispatch(
                        handleStartCalenderDate(
                          moment(start1, "DD-MM-YYYY").format("YYYY-MM-DD")
                        )
                      );
                      dispatch(
                        handleEndCalenderDate(
                          moment(end1, "DD-MM-YYYY").format("YYYY-MM-DD")
                        )
                      );
                      dispatch(handleEventMonth(""));
                      if (process.env.REACT_APP_PRODUCT == "DLF") {
                        navigate(EventConfig.eventDLF);
                      } else {
                        navigate(EventConfig.event);
                      }
                    }
                  }}
                >
                  <span className={styles.dayDiv}>
                    {days[count]}{" "}
                    {index + 1 < 10 ? "0" + parseInt(index + 1) : index + 1}
                  </span>
                  <ul>
                    {eventDiv?.map((eventList, eventListIndex) => {
                      let eventNameAftterTruencate =
                        eventList?.name?.length > 16
                          ? eventList?.name.substring(0, 16) + "..."
                          : eventList?.name;
                      return eventListIndex < 4 ? (
                        <div className={styles.eventDisplay}>
                          <li>
                            <p className={styles.eventName}>
                              {eventNameAftterTruencate}
                            </p>
                          </li>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </ul>
                  {eventDiv?.length > 4 ? (
                    <p className={styles.viewAllDiv}> View All</p>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
            {}
          </div>
        </div>
      </div>
    </div>
  );
};
