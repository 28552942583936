import React from "react";
import styles from "./discardModal.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ButtonBorder } from "../Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonColor } from "../Button/Buttons/ButtonColor/ButtonColor";

export const DiscardModal = ({ open, onCloseModal, msg, handleDisacrd }) => {
  return (
    <>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={false}>
        <div className={styles.tooltipChangeStatus}>
          <p className={styles.modalDivp}>{msg} </p>
          <div className={styles.btnDiv}>
            <ButtonBorder
              text={"Back"}
              width={110}
              buttonHandler={() => handleDisacrd()}
            />
            <ButtonColor
              text={"No"}
              width={110}
              buttonHandler={() => onCloseModal()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
