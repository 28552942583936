import React, { useEffect, useRef, useState } from "react";
import styles from "./bookingHistoryRegistrationDetails.module.css";
import { TopHeader } from "../../../../../Components/TopHeaderNav/TopHeader";
import { BookingHistoryRegDetailsTbl } from "./BookingHistroyRegDetailsTbl/BookingHistoryRegDetailsTbl";
import { ViewRegistrationBookingModal } from "./ViewRegistrationBookingModal/ViewRegistrationBookingModal";
import { useParams } from "react-router-dom";
import { loadData } from "../../../../../Utils/localStorage";
import { EventConfig } from "../../../../../Events/EventConfig";
import { Logo } from "../../../../../Components/Logo";
import dayjs from "dayjs";
import { exportToCSV } from "../../../../../Utils/excelHelper";

export const BookingHistoryRegistrationDetails = () => {
  const { id } = useParams();
  const [bookingHistoryEvents, setBookingHistroyEvent] = useState({});
  const [totalBookingHistoryCount, setTotalBookingHistoryCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(4);
  const columns = [
    "Registration ID",
    "Registration Status",
    "Registration For",
    "Registration By",
    "Registration Time",
  ];
  const tableRef = useRef(null);
  //on click of booked for open Modal----------------------
  const [viewRegModal, setViewRegModal] = useState(false);
  const [viewDetail, setViewDetail] = useState("");
  // search
  const [inputValue, setInputValue] = useState("");
  const getHistoryApi = async (ofset) => {
    setLoader(true);
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      event_id: id,
    };
    if (inputValue !== "") {
      payload["search_by"] = inputValue;
    }
    if (offset !== "") {
      payload["offset"] = ofset !== undefined ? ofset : offset;
    }
    if (limit !== "") {
      payload["limit"] = limit;
    }
    fetch(
      process.env.REACT_APP_API_URL + "/api/event/participant-details-by-event",
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        setLoader(false);
        if (res?.success) {
          setTotalBookingHistoryCount(res?.total_record);
          setBookingHistroyEvent(res?.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const handleOffset = (val) => {
    if (val == "next") {
      offset + bookingHistoryEvents?.participant_details?.length <
        totalBookingHistoryCount && setOffset(offset + limit);
      offset + bookingHistoryEvents?.participant_details?.length <
        totalBookingHistoryCount && getHistoryApi(offset + limit);
    } else {
      offset > 0 && setOffset(offset - limit);
      offset > 0 && getHistoryApi(offset - limit);
    }
  };
  // function for excel
  const excelFun = () => {
    let requestBody = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      event_id: id,
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/api/event/participant-details-by-event`,
      {
        method: "POST",
        body: JSON.stringify({ ...requestBody }),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        const data = [];
        const fieldNamesMap = {};
        for (let x of res?.data?.participant_details) {
          const fieldNamesMap = {};
          const downloadData = {};
          downloadData["Registration ID"] = x?.booking_no;
          downloadData["Event Name"] = bookingHistoryEvents?.event_name;
          downloadData["Registration Status"] = x?.status;
          downloadData["Registration For"] = x?.booked_for;
          downloadData["Registration By"] = x?.booked_by;
          downloadData["Registration Time"] = dayjs(
            x?.created_at,
            "YYYY-MM-DD hh:mm:ss"
          ).format("DD MMM YYYY, hh:mm A");

          for (let item of x?.member_custom_details) {
            if (item.field_type !== "file") {
              let fieldName = item?.field_name;

              // If the field name already exists, append an index to make it unique
              if (fieldNamesMap[fieldName]) {
                fieldNamesMap[fieldName]++;
                fieldName = `${item?.field_name} (${fieldNamesMap[fieldName]})`;
              } else {
                fieldNamesMap[fieldName] = 1;
              }

              downloadData[fieldName] =
                item?.field_type === "single_choice"
                  ? item?.choice_label || "NA"
                  : item?.field_type === "checkbox"
                  ? item?.field_value?.split(",").join(" , ") || "NA"
                  : item?.field_type === "date"
                  ? item?.field_value
                    ? `${dayjs(item?.field_value).format("DD MMM YYYY")}`
                    : "NA"
                  : item?.field_type === "date_range"
                  ? item?.field_value?.split("#").length === 2
                    ? `${dayjs(item?.field_value.split("#")[0]).format(
                        "DD MMM YYYY"
                      )} - ${dayjs(item?.field_value.split("#")[1]).format(
                        "DD MMM YYYY"
                      )}`
                    : "NA"
                  : item?.field_type === "date_time"
                  ? item?.field_value
                    ? `${dayjs(item?.field_value).format(
                        "DD MMM YYYY, hh:mm A"
                      )}`
                    : "NA"
                  : item?.field_type === "time"
                  ? item?.field_value
                    ? `${dayjs(item?.field_value, "hh:mm:ss").format(
                        "hh:mm A"
                      )}`
                    : "NA"
                  : item?.field_value
                  ? item?.field_value
                  : "NA";
            }
          }

          data.push(downloadData);
        }

        data.push({
          "Registration Status": "",
        });

        let fileNameForDownload;
        fileNameForDownload =
          res?.data?.event_name +
          "_RegistrationHistory_" +
          dayjs().format("DD-MM-YYYY HH:mm:ss");

        exportToCSV(data, fileNameForDownload);
      });
  };

  useEffect(() => {
    setTimeout(0);
    const timer = setTimeout(() => {
      getHistoryApi(0);
    }, 500);
    return () => clearTimeout(timer);
  }, [inputValue]);
  if (loader) return <Logo />;
  return (
    <div className={styles.mainDivBookingHistory}>
      <TopHeader title={bookingHistoryEvents?.event_name} />

      <div className={styles.searchParentDiv}>
        <div className={styles.searchDiv}>
          <div className={styles.searchIcon}>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
            />
          </div>
          <input
            className={styles.searchDivInput}
            placeholder={"|  Search by Registration For"}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                getHistoryApi();
              }
            }}
          />
        </div>
        {totalBookingHistoryCount > 0 && (
          <div className={styles.excelDiv} onClick={excelFun}>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/excel.png`}
            />
          </div>
        )}
      </div>
      <div className={styles.tblMainDiv}>
        <ViewRegistrationBookingModal
          open={viewRegModal}
          onCloseModal={() => setViewRegModal(false)}
          viewDetail={viewDetail}
          eventName={bookingHistoryEvents?.event_name}
        />
        <BookingHistoryRegDetailsTbl
          openRegModal={() => setViewRegModal(true)}
          columns={columns}
          details={bookingHistoryEvents?.participant_details}
          tableRef={tableRef}
          setViewRegModal={setViewRegModal}
          setViewDetail={setViewDetail}
        />
        {bookingHistoryEvents?.participant_details?.length > 0 && (
          <div className={styles.pagination}>
            <div>
              <div className={styles.paginationValuesDiv}>
                <p>{offset + 1}</p>
                <p>-</p>
                <p>
                  {offset + bookingHistoryEvents?.participant_details?.length}
                </p>
                <p>of</p>
                <p>{totalBookingHistoryCount && totalBookingHistoryCount}</p>
              </div>
              <div className={styles.paginationIconDiv}>
                <span onClick={() => handleOffset("prev")}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                  />
                </span>
                <span onClick={() => handleOffset("next")}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
