const formatData = (users) => {
  return (
    users?.length > 0 &&
    users?.flatMap((user) => {
      return user?.user_role?.map((role) => {
        const formattedUser = {
          user_id: user?.user_id,
          full_name: user?.full_name,
          is_editable: role?.is_editable,
          is_email_editable: role?.is_email_editable,
          is_phone_editable: role?.is_phone_editable,
          is_active: role?.is_active,
          designation: {
            label: role?.designation_name,
            value: role?.designation_id,
          },
          mobile_phone: user?.mobile_phone,
          email: user?.email,
          user_profile_picture: user?.user_profile_picture,
          role_id: role?.role_id,
        };
        const validModules = role?.modules?.filter((mod) => mod?.id !== "");
        const accessRights = [];
        validModules?.forEach((mod) => {
          accessRights.push({
            id: mod?.id,
            name: mod?.name,
            read: parseInt(mod?.rights.read),
            create: parseInt(mod?.rights.create),
            update: parseInt(mod?.rights.update),
            delete: parseInt(mod?.rights.delete),
          });
        });
        formattedUser["access_rights"] = accessRights;
        return formattedUser;
      });
    })
  );
};
export { formatData };
