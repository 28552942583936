import React, { useEffect, useState } from "react";
import styles from "./spa.module.css";
import { useDispatch, useSelector } from "react-redux";
import SearchAndFilter from "../Components/SearchAndFilter/SearchAndFilter";
import { SpaConfig } from "../Spa/SpaConfig";
import { SpaList } from "./Components/SpaList/SpaList";
import { SpaDetails } from "./Components/SpaDetails/SpaDetails";
import { loadData } from "../Utils/localStorage";
import { Logo } from "../Components/Logo";
import { TopHeader } from "../Components/TopHeaderNav/TopHeader";
import { getAllSalonSpaServices } from "../Components/Redux/salonSpaServicesSlice";

const Spa = () => {
  const { searchValue, status, category, availableDays } = useSelector(
    (state) => state.searchSlice
  );
  const { Loader, salonSpaServicesList } = useSelector(
    (state) => state.salonSpaServicesSlice
  );
  const dispatch = useDispatch();
  const [categoryArr, setCategoriesArr] = useState([]);
  const club_id=loadData("Club_id");
  let dropdownOption = [
    {
      id: 0,
      name: "Available",
    },
    {
      id: 1,
      name: "Unavailable",
    },
  ];

  const getAllSalonSpaServicesFunc = () => {
    let data = categoryArr?.find((cat) => {
      return cat?.name == category;
    });
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      service: "SPA",
    };
    if (status !== "") {
      if (status === "Available") {
        payload["status"] = true;
      } else {
        payload["status"] = false;
      }
    }
    if (category !== "") {
      payload["categories"] = [data?.id];
    }
    if (availableDays.length > 0) {
      payload["availability"] = availableDays;
    }
    if (searchValue !== "") {
      payload["name"] = searchValue;
    }
    dispatch(getAllSalonSpaServices(payload));
  };

  useEffect(() => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      service: "SPA",
      status: 1,
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/services/get-category`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        setCategoriesArr(res?.data?.categories);
      })
      .catch((err) => {});
  }, [club_id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllSalonSpaServicesFunc();
    }, 500);
    return () => clearTimeout(timer);
  }, [status, category, searchValue,availableDays,club_id]);

  return (
    <div className={styles.mainDivEvents}>
      <TopHeader
        title={"Spa"}
        showBtn={true}
        btnTitle63={"Create Booking"}
        btnTitle24={"Booking History"}
        btnTitle31={"Create Service"}
        btnTitle52={"Professionals"}
        to63={SpaConfig.CreateBookingSpa}
        to24={SpaConfig.BookingHistorySpa}
        to31={SpaConfig.CreateSpa}
        to52={SpaConfig.SpaProfessionals}
        headerType={"spa"}
      />
      <SearchAndFilter
        handleSearch={getAllSalonSpaServicesFunc}
        dropdownOption={dropdownOption}
        showStatus={true}
        categoryArr={categoryArr}
        showCategory={true}
        showAvailability={true}
        showAccessibleTo={false}
        categoryTitle={"Category"}
        filterType={"spaFilter"}
      />
      {Loader ? (
        <Logo />
      ) : (
        <>
          {salonSpaServicesList?.length !== 0 ? (
            <div className={styles.mainMidDivEvents}>
              <div className={styles.divEventList}>
                <SpaList />
              </div>
              <SpaDetails />
            </div>
          ) : (
            <div className={styles.noDataMainDiv}>
              <div className={styles.noDataDiv}>
                <span className={styles.noDataImgDiv}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
                  />
                </span>
                <h2>Not Found</h2>
                <p>Search did not match current service listed</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Spa;
