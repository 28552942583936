import React, { useState } from "react";
import { TextInput, Textarea } from "evergreen-ui";
import styles from "../createEvent.module.css";
import { useSelector } from "react-redux";

export const Seatings = ({
  createEvent,
  handleCreateEventChange,
  setSeatCategorizationModal,
  seatCategory,
  setSeatCategory,
  removeSeatCategory,
  isEdit,
}) => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const handlePricePerCategory = (index, e) => {
    const { name, value } = e.target;
    const list = [...seatCategory];
    list[index][name] = value.replace(/[^0-9]/g, "");
    setSeatCategory(list);
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>
        <p>Seating(s)</p>
      </div>
      <div className={styles.displayFlexDiv}>
        {/* seating------------------------------------- */}
        <div>
          <label className={styles.labelFont}>Seating Capacity</label>
          <span className={styles.star}>*</span>
          <br />
          <TextInput
            className={styles.inputText}
            name="seatingCapacity"
            value={createEvent.seatingCapacity}
            onChange={handleCreateEventChange}
            type="text"
            // disabled={isEdit}
          />
        </div>
        {/*seating Style----------------------------------------------- */}
        {process.env.REACT_APP_PRODUCT == "DLF" && (
          <div>
            <label className={styles.labelFont}>Seating Style</label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.radioMainDiv}>
              <span
                className={
                  createEvent.seatingStyle === "OPEN"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="seatingStyle"
                  value="OPEN"
                  checked={createEvent.seatingStyle == "OPEN"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>Standing</label>
              </span>
              <span
                className={
                  createEvent.seatingStyle === "ROUND_TABLE"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="seatingStyle"
                  value="ROUND_TABLE"
                  checked={createEvent.seatingStyle === "ROUND_TABLE"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>Round Table</label>
              </span>
              <span
                className={
                  createEvent.seatingStyle === "THEATRE"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="seatingStyle"
                  value="THEATRE"
                  checked={createEvent.seatingStyle === "THEATRE"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>Theatre</label>
              </span>
            </div>
          </div>
        )}
      </div>
      {/* ----------------------------------------------------------------- */}
      <div className={styles.displayFlexDiv}>
        {/* Max no of seats------------------------------------- */}
        <div>
          <label className={styles.labelFont}>
            {process.env.REACT_APP_PRODUCT != "DLF"
              ? profileDetails?.comm_type == "Federation Parent Community"
                ? "Max. Number of Seats Per Occupant"
                : profileDetails?.comm_type == "Federation Child Community"
                ? "Max. Number of Seats Per Employee"
                : "Max. Number of Seats Per Unit"
              : "Max. number of seats per Membership"}
          </label>
          <span className={styles.star}>*</span>
          <br />
          <TextInput
            className={styles.inputText}
            name="maxNoOfSeats"
            value={createEvent.maxNoOfSeats}
            onChange={handleCreateEventChange}
            type="text"
            autoComplete="off"
            // disabled={isEdit}
          />
        </div>
        {process.env.REACT_APP_PRODUCT == "DLF" && (
          <div>
            <label className={styles.labelFont}>Accessible To</label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.radioMainDiv}>
              <span
                className={
                  createEvent?.accessibleTo === "ALL"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  name="accessibleTo"
                  value="ALL"
                  checked={createEvent?.accessibleTo === "ALL"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>All</label>
              </span>
              <span
                className={
                  createEvent?.accessibleTo == "MEMBERS"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={40}
                  name="accessibleTo"
                  value="MEMBERS"
                  checked={createEvent?.accessibleTo === "MEMBERS"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>Member</label>
              </span>
              <span
                className={
                  createEvent?.accessibleTo === "NON_MEMBERS"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={40}
                  name="accessibleTo"
                  value="NON_MEMBERS"
                  checked={createEvent?.accessibleTo === "NON_MEMBERS"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>Non Members</label>
              </span>
            </div>
          </div>
        )}
      </div>
      {/* ------------------------------ */}
      <div className={styles.displayFlexDiv}>
        {/* -----------Seating Categorization------------------------- */}
        {(process.env.REACT_APP_PRODUCT == "DLF" ||
          profileDetails?.event_payment === true) && (
          <div className={styles.seatingCategorizationWatingDiv}>
            <div>
              <label className={styles.labelFont}>Seating Categorization</label>
              <span className={styles.star}>*</span>
              <br />
              <div className={styles.radioMainDiv}>
                <span
                  className={
                    createEvent.isSeatingCategorization == 1
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    name="isSeatingCategorization"
                    value={1}
                    checked={createEvent?.isSeatingCategorization == 1}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>Yes</label>
                </span>
                <span
                  className={
                    createEvent.isSeatingCategorization == 0
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    name="isSeatingCategorization"
                    value={0}
                    checked={createEvent.isSeatingCategorization == 0}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>No</label>
                </span>
              </div>
            </div>
            {/* --------------waiting-------------------------------- */}
            {createEvent?.isSeatingCategorization != 1 &&
              process.env.REACT_APP_PRODUCT == "DLF" && (
                <div>
                  <label className={styles.labelFont}>Waiting List</label>
                  <br />
                  <TextInput
                    className={styles.inputText}
                    name="waitingList"
                    value={createEvent?.waitingList}
                    onChange={handleCreateEventChange}
                    autoComplete="off"
                    type="text"
                    // disabled={isEdit}
                  />
                </div>
              )}
          </div>
        )}
        <div></div>
      </div>

      {createEvent?.isSeatingCategorization == 1 && (
        <>
          <div className={styles.seatingCategorizationMainDiv}>
            <div className={styles.seatingCategorizationDiv}>
              {seatCategory?.map((i, index) => {
                return (
                  <div
                    style={{
                      position: "relative",
                      width:
                        process.env.REACT_APP_PRODUCT !== "AC"
                          ? "517px"
                          : "517px",
                    }}
                  >
                    <div className={styles.seatingCatName}>{i?.name}</div>
                    <div>
                      <p className={styles.seatingLabel}>Total seats</p>
                      <input
                        type="text"
                        className={styles.seatingInput}
                        name="total"
                        value={i?.total}
                        onChange={(e) => {
                          handlePricePerCategory(index, e);
                        }}
                      />
                    </div>
                    {(i?.waiting || i?.waiting == "") &&
                      process.env.REACT_APP_PRODUCT == "DLF" && (
                        <div>
                          <p className={styles.seatingLabel}>Waiting</p>
                          <input
                            type="text"
                            className={styles.seatingInput}
                            name="waiting"
                            value={i?.waiting}
                            onChange={(e) => handlePricePerCategory(index, e)}
                          />
                        </div>
                      )}
                    {!isEdit && (
                      <span
                        className={styles.seatingRemove}
                        onClick={() => removeSeatCategory(index)}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/seatingRemove.png`}
                        />
                      </span>
                    )}
                    {i?.total == "" ? (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "4px",
                          color: "red",
                        }}
                      >
                        Total seats cannot be empty
                      </span>
                    ) : i?.total == 0 ? (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "4px",
                          color: "red",
                        }}
                      >
                        Total seats cannot be zero
                      </span>
                    ) : i?.waiting == "" && i?.waiting !== 0 ? (
                      <span
                        style={{
                          position: "absolute",
                          bottom: "4px",
                          color: "red",
                        }}
                      >
                        Waiting cannot be empty
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
            {!isEdit && (
              <button
                className={styles.cancellationAllowedBtn}
                onClick={() => setSeatCategorizationModal(true)}
              >
                Add Category +
              </button>
            )}
          </div>
          {createEvent?.seatingCapacity != "" &&
            seatCategory.length > 0 &&
            createEvent?.seatingCapacity !=
              seatCategory.reduce(
                (acc, curr) => acc + parseInt(curr?.total),
                0
              ) && (
              <p
                className={styles.eventMainOuterLimitDiv}
                style={{ marginLeft: "24px" }}
              >
                Seating capacity does not match total seat categorization
              </p>
            )}
        </>
      )}
    </div>
  );
};
