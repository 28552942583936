export const FnBConfig = {
  OrderHistory: `${process.env.REACT_APP_PATH}/fnb/order-history`,

  TableBookingHistory: `${process.env.REACT_APP_PATH}/fnb/table-history`,

  CreateRestaurant: `${process.env.REACT_APP_PATH}/fnb/add-restaurant`,

  EditRestaurant: `${process.env.REACT_APP_PATH}/fnb/edit-restaurant`,

  cartPage: `${process.env.REACT_APP_PATH}/fnb/cart`,

  fnb: `${process.env.REACT_APP_PATH}/fnb`,

  requestBy: "Admin",

  deleteStatus: "Cancelled",
};
