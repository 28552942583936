import React, { useState } from "react";
// import styles from "./multiSelectProfComponent.module.css";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "&.Mui-checked .MuiSvgIcon-root": {
      color: "#690a3c !important",
    },
  },
  customSelect: {
    width: "100%",
    height: "40px",
    borderBottom: "none",
    borderColor: "none",
    outline: "none",
    fontSize: "14px",
    "& .MuiSelect-outlined": {
      borderColor: "#DEDFE1",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEDFE1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEDFE1",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DEDFE1",
    },
  },
});

const MultiSelectProfComponent = ({
  allProfessionals,
  selectAllProfessionals,
  setSelectAllProfessionals,
  professionalNames,
  setProfessionalNames,
  handleProfessionalChange,
  selectedProfessionals,
}) => {
  const classes = useStyles();

  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  return (
    <div style={{ height: "40px" }}>
      <FormControl>
        <div
          style={{
            width: "200px",
            borderRadius: "4px",
            height: "40px",
          }}
        >
          <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  padding: 0,
                },
              },
            }}
            className={classes.customSelect}
            labelId="category-label"
            id="category-select"
            multiple
            open={openCategoryModal}
            onOpen={() => {
              setOpenCategoryModal(true);
            }}
            onClose={() => {
              setOpenCategoryModal(false);
            }}
            placeholder="Select"
            value={professionalNames}
            displayEmpty
            renderValue={(selected) =>
              `${
                selected?.length == allProfessionals?.length
                  ? "All professionals"
                  : selected?.length
              } ${
                selected?.length == allProfessionals?.length
                  ? ""
                  : selected?.length == 1
                  ? "professional"
                  : "professionals"
              }`
            }
          >
            {allProfessionals?.length > 0 && (
              <MenuItem
                sx={{ "&:hover": { backgroundColor: "#f4e0eb" } }}
                style={{ padding: "0px 10px" }}
                key="selectAllProfessionals"
                value="selectAllProfessionals"
              >
                <Checkbox
                  classes={{
                    root: classes.root,
                  }}
                  checked={
                    selectAllProfessionals ||
                    allProfessionals?.length == selectedProfessionals?.length
                  }
                  onChange={(event) => {
                    const { checked } = event.target;
                    setSelectAllProfessionals(checked);
                    setProfessionalNames(
                      checked
                        ? allProfessionals?.length > 0 &&
                            allProfessionals?.map((prof) => ({
                              id: prof?.id,
                              name: prof?.name,
                            }))
                        : []
                    );
                  }}
                />
                <ListItemText primary="Select All" />
              </MenuItem>
            )}
            <div>
              {allProfessionals?.length > 0
                ? allProfessionals?.map((prof) => {
                    return (
                      <MenuItem
                        key={prof?.id}
                        value={prof?.name}
                        sx={{ "&:hover": { backgroundColor: "#f4e0eb" } }}
                        style={{
                          padding: "0px 10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Checkbox
                            classes={{
                              root: classes.root,
                            }}
                            checked={professionalNames?.some(
                              (item) => item?.id === prof?.id
                            )}
                            onChange={() => {
                              handleProfessionalChange({
                                target: {
                                  value: professionalNames?.some(
                                    (item) => item?.id === prof?.id
                                  )
                                    ? professionalNames.filter(
                                        (item) => item?.id !== prof?.id
                                      )
                                    : [
                                        ...professionalNames,
                                        {
                                          id: prof?.id,
                                          name: prof?.name,
                                        },
                                      ],
                                },
                              });
                            }}
                          />
                          <label>{prof?.name}</label>
                        </div>
                      </MenuItem>
                    );
                  })
                : "No profs available"}
            </div>
          </Select>
        </div>
      </FormControl>
    </div>
  );
};

export default MultiSelectProfComponent;
