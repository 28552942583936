import React from "react";
import styles from "./imageUpload.module.css";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import ImageUploading from "react-images-uploading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const ImageUpload = ({
  index,
  name,
  handleGuestImage,
  images,
  setImages,
}) => {
  // const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    handleGuestImage(index, name, imageList);
  };
  return (
    <>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        //  maxFileSize="3500000"
        // acceptType={["jpg"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            {images.length === 0 ? (
              <>
                <div
                  style={{
                    width: "44px",
                    height: "44px",
                    border: "1px solid grren",
                    cursor: "pointer",
                  }}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/imageUploadSmall.png`}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </>
            ) : (
              <>
                {imageList?.map((image, index) => (
                  <div
                    key={index}
                    className={styles.imgIconDiv}
                    style={{
                      width: "44px",
                      height: "44px",
                      border: "1px solid grren",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={styles.closeIcon}
                      onClick={() => onImageRemove(index)}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/cancelEventActive.png`}
                        alt="removeIcon"
                      />
                    </div>
                    <img src={image.data_url} alt="" width="44" height="44" />
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </ImageUploading>
    </>
  );
};
