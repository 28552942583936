import React, { useState } from "react";
import styles from "./header.module.css";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Profile } from "../Profile/Profile";
import Popover from "@mui/material/Popover";
import { useSelector, useDispatch } from "react-redux";
import { activeTab } from "../Redux/tabSlice";
import SwitchDropdown from "../SwitchDropdown/SwitchDropdown";
import ClubSwitchDropdown from "../ClubSwitchDropdown/ClubSwitchDropdown";
import { useNavigate } from "react-router-dom";

const Header = ({ customMg }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { active } = useSelector((state) => state.activeTabCheck);
  const { community, adminName } = useSelector((state) => state.authSlice);
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchEl, setSwitchEl] = useState(null);
  const [clubSwitchEl, setClubSwitchEl] = useState(null);
  const [activeState, setActiveState] = useState(false);
  const open = Boolean(anchorEl);
  const openSwitchDropdown = Boolean(switchEl);
  const openClubSwitchDropdown = Boolean(clubSwitchEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSwitchClose = (event) => {
    setSwitchEl(event.currentTarget);
    setActiveState(true);
  };

  const handleClickClubSwitchClose = (event) => {
    setClubSwitchEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchClose = () => {
    setSwitchEl(null);
    setActiveState(false);
  };

  const handleClubSwitchClose = () => {
    setClubSwitchEl(null);
  };

  const header = {
    id: 10,
    svg: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/helpdesk.png`,
    activeSvg: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/helpdeskActive.svg`,
    col: 1,
    link: process.env.REACT_APP_PATH + "/conceirge",
    name: "Clubs",
  };

  const switchCondo = {
    id: 1000,
    svg: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/switchIcon.svg`,
    activeSvg: `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/switchIconActive.svg`,
    col: 1,
    name: "Switch Condominium",
  };

  return (
    <div className={styles.headerMaindiv}>
      {process.env.REACT_APP_PRODUCT !== "DLF" &&
      process.env.REACT_APP_PRODUCT !== "NUCLEUS" ? (
        <>
          <p className={styles.HeaderHeading} style={{ marginLeft: customMg }}>
            <a
              className={styles.communityName}
              href={process.env.REACT_APP_LOGIN + "/main/dashboard/"}
            >
              {community}
            </a>
          </p>
        </>
      ) : (
        <>
          {process.env.REACT_APP_PRODUCT == "NUCLEUS" && (
            <p
              className={styles.HeaderHeading}
              style={{ marginLeft: customMg }}
            >
              {community}
            </p>
          )}
        </>
      )}
      {process.env.REACT_APP_PRODUCT == "DLF" && (
        <>
          <p
            className={styles.HeaderHeading}
            style={{
              marginLeft: customMg,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              cursor: "pointer",
            }}
            onClick={handleClickClubSwitchClose}
          >
            {community}
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/leftSearch2.png`}
              alt=""
            />
          </p>
          <Popover
            anchorReference="anchorPosition"
            anchorPosition={{ top: 85, left: 120 }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{ backgroundColor: "#F8ECF3 !important" }}
            // id={idClubSwitchDropdown}
            open={openClubSwitchDropdown}
            anchorEl={clubSwitchEl}
            onClose={handleClubSwitchClose}
          >
            <ClubSwitchDropdown handleClubSwitchClose={handleClubSwitchClose} />
          </Popover>
        </>
      )}
      <div className={styles.rightHeaderDiv}>
        {process.env.REACT_APP_PRODUCT == "DLF" && (
          <>
            <div
              onClick={() => {
                dispatch(activeTab(1000));
                setActiveState(true);
              }}
            >
              <div
                onClick={handleClickSwitchClose}
                className={styles.switchIconDiv}
              >
                <img
                  src={
                    activeState == true && active == 1000
                      ? switchCondo.activeSvg
                      : switchCondo.svg
                  }
                />
              </div>
              <Popover
                anchorReference="anchorPosition"
                anchorPosition={{ top: 85, left: 970 }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ backgroundColor: "#F8ECF3 !important" }}
                // id={idSwitchDropdown}
                open={openSwitchDropdown}
                anchorEl={switchEl}
                onClose={handleSwitchClose}
              >
                <SwitchDropdown />
              </Popover>
            </div>
            <div
              onClick={() => {
                dispatch(activeTab(10));
                setActiveState(true);
              }}
            >
              <div
                onClick={() =>
                  navigate(`${process.env.REACT_APP_PATH}` + "/concierge")
                }
                className={styles.switchIconDiv}
              >
                <img
                  src={
                    activeState == true && active == 10
                      ? header.activeSvg
                      : header.svg
                  }
                />
              </div>
            </div>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/notification.png`}
              />
            </span>
            <div>
              <div
                className={styles.profileDiv}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <span>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt={adminName.charAt(0)}
                      src="https://images.pexels.com/photooos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                  </Stack>
                </span>
                <p>{adminName}</p>
              </div>
              <Popover
                anchorReference="anchorPosition"
                anchorPosition={{ top: 82, left: 1500 }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ backgroundColor: "#F8ECF3 !important" }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <Profile />
              </Popover>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
