import React, { useEffect, useState } from "react";
import styles from "./multiSelectServiceComponent.module.css";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "&.Mui-checked .MuiSvgIcon-root": {
      color: "#690a3c !important",
    },
  },
  customSelect: {
    width: "100%",
    height: "40px",
    borderBottom: "none",
    borderColor: "none",
    outline: "none",
    fontSize: "14px",
    "& .MuiSelect-outlined": {
      borderColor: "#DEDFE1",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEDFE1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEDFE1",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DEDFE1",
    },
  },
});

const MultiSelectServiceComponent = ({
  allServices,
  selectAllServices,
  setSelectAllServices,
  serviceNames,
  setServiceNames,
  handleServiceChange,
  selectedServices,
}) => {
  const classes = useStyles();
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  useEffect(() => {
    if (allServices) {
      setServiceNames(
        allServices?.map((service) => ({
          id: service?.id,
          name: service?.name,
        }))
      );
    }
  }, [allServices]);

  return (
    <div style={{ height: "40px" }}>
      <FormControl>
        <div
          style={{
            width: "200px",
            borderRadius: "4px",
            height: "40px",
          }}
        >
          <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  padding: 0,
                },
              },
            }}
            className={classes.customSelect}
            labelId="category-label"
            id="category-select"
            multiple
            open={openCategoryModal}
            onOpen={() => {
              setOpenCategoryModal(true);
            }}
            onClose={() => {
              setOpenCategoryModal(false);
            }}
            placeholder="Select"
            value={serviceNames}
            displayEmpty
            // renderValue={(selected) =>
            //   selected.includes("selectAllServices") ? selected
            //     : `${selected?.length} ${
            //         selected?.length == 1 ? "service selected"
            //           : "services selected"
            //       }`
            // }
            renderValue={(selected) =>
              `${
                selected?.length == allServices?.length
                  ? "All services"
                  : selected?.length
              } ${
                selected?.length == allServices?.length
                  ? ""
                  : selected?.length == 1
                  ? "service"
                  : "services"
              }`
            }
          >
            {allServices?.length > 0 && (
              <MenuItem
                sx={{ "&:hover": { backgroundColor: "#f4e0eb" } }}
                style={{ padding: "0px 10px" }}
                key="selectAllServices"
                value="selectAllServices"
              >
                <Checkbox
                  classes={{
                    root: classes.root,
                  }}
                  checked={
                    selectAllServices ||
                    allServices?.length == selectedServices?.length
                  }
                  onChange={(event) => {
                    const { checked } = event.target;
                    setSelectAllServices(checked);
                    setServiceNames(
                      checked
                        ? allServices?.length > 0 &&
                            allServices?.map((serv) => ({
                              id: serv?.id,
                              name: serv?.name,
                            }))
                        : []
                    );
                  }}
                />
                <ListItemText primary="Select All" />
              </MenuItem>
            )}
            <div>
              {allServices?.length > 0
                ? allServices?.map((service) => {
                    return (
                      <MenuItem
                        key={service?.id}
                        value={service?.name}
                        sx={{ "&:hover": { backgroundColor: "#f4e0eb" } }}
                        style={{
                          padding: "0px 10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Checkbox
                            classes={{
                              root: classes.root,
                            }}
                            checked={serviceNames?.some(
                              (item) => item?.id === service?.id
                            )}
                            onChange={() =>
                              handleServiceChange({
                                target: {
                                  value: serviceNames?.some(
                                    (item) => item?.id === service?.id
                                  )
                                    ? serviceNames?.filter(
                                        (item) => item?.id !== service?.id
                                      )
                                    : [
                                        ...serviceNames,
                                        {
                                          id: service?.id,
                                          name: service?.name,
                                        },
                                      ],
                                },
                              })
                            }
                          />
                          <label>{service?.name}</label>
                        </div>
                      </MenuItem>
                    );
                  })
                : "No services available"}
            </div>
          </Select>
        </div>
      </FormControl>
    </div>
  );
};

export default MultiSelectServiceComponent;
