export const SalonConfig = {
  CreateBooking: `${process.env.REACT_APP_PATH}/salon/create-booking`,

  CreateSalon: `${process.env.REACT_APP_PATH}/salon/add-salon-service`,

  Professionals: `${process.env.REACT_APP_PATH}/salonProfessional`,

  AddProfessional: `${process.env.REACT_APP_PATH}/salonProfessional/add-professional`,

  EditProfessional: `${process.env.REACT_APP_PATH}/salonProfessional/edit-professional`,

  BookingHistorySalon: `${process.env.REACT_APP_PATH}/salon/booking-history`,

  SchedularView: `${process.env.REACT_APP_PATH}/salon/schedular-view`,

  EditSalon: `${process.env.REACT_APP_PATH}/salon/edit-salon-service`,

  Salon: `${process.env.REACT_APP_PATH}/salon`,

  requestBy: "Admin",
};
