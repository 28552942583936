import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const UploadDocument = ({ setMetaData }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setUploadedFile(meta);
    } else if (status === "removed") {
      setUploadedFile("");
      setErrorMsg("");
    }
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => callback(null, error);
  };

  const getUploadParams = ({ meta, file }) => {
    if (meta.size === 0) {
      setErrorMsg("File is empty.");
      return { abort: true };
    }
    if (meta.size > 3.5 * 1024 * 1024) {
      setErrorMsg("File size exceeds the limit of 3.5 MB");
      return { abort: true };
    } else {
      return new Promise((resolve, reject) => {
        getBase64(file, (base64Data, error) => {
          if (error) {
            reject(error);
          } else {
            setMetaData(base64Data);
            resolve({
              url:
                process.env.REACT_APP_API_URL + "/api/event/post-transactions",
              fileInBase64: base64Data,
            });
          }
        });
      });
    }
  };

  const textContainerStyle = {
    border: "1px solid #DEDFE1",
    borderRadius: "3px",
    padding: "10px 20px",
    display: "inline-block",
    fontSize: "12px",
    fontWeight: "600",
  };

  const secondTextStyle = {
    color: "#868686",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept=".pdf,.doc,.docx"
        maxFiles={1}
        multiple={false}
        inputContent={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <div style={textContainerStyle}>Browse</div>
          </div>
        }
        styles={{
          dropzone: {
            width: "500px",
            border: "2px dashed #cccccc",
            borderRadius: "4px",
            textAlign: "center",
          },
          inputLabel: {
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333333",
          },
        }}
      />
      {errorMsg && <div style={{ color: "Red" }}>{errorMsg}</div>}
      {uploadedFile && (
        <div>
          <p>Uploaded file: {uploadedFile?.name}</p>
        </div>
      )}
    </div>
  );
};

export default UploadDocument;
