import React from "react";
import styles from "./bookingRegistrationTbl.module.css";
import dayjs from "dayjs";
import { rupee } from "../../../../../Utils/Validation";
import { useNavigate } from "react-router-dom";
import { EventConfig } from "../../../../../Events/EventConfig";
export const BookingRegistrationTbl = ({ columns, details, tableRef }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "360px",
        overflow: "auto",
      }}
    >
      {details?.length > 0 ? (
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 8px",
            width: "100%",
          }}
          ref={tableRef}
        >
          <thead>
            <tr className={styles.BHEventtableHead}>
              {columns?.map((col, index) => {
                return <th key={index}>{col}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {details?.length > 0 &&
              details?.map((tbldata, index) => {
                return (
                  <tr
                    // key={index}
                    className={styles.BHEventtableData}
                  >
                    <td
                      className={styles.id}
                      onClick={() =>
                        navigate(
                          `${EventConfig?.BookingRegistrationHistoryDetails}/${tbldata?.event_id}`
                        )
                      }
                    >
                      {tbldata?.name}
                    </td>
                    <td>
                      <p
                        className={
                          tbldata?.status == "OPEN"
                            ? styles.open
                            : tbldata?.status == "CANCELED"
                            ? styles.cancelled
                            : tbldata?.status == "CLOSE".toUpperCase()
                            ? styles.rejected
                            : styles.close
                        }
                      >
                        {tbldata?.status === "COMING_SOON"
                          ? "Coming Soon"
                          : tbldata?.status}
                      </p>
                    </td>
                    <td> {tbldata?.user_participent_count}</td>

                    <td>
                      {dayjs(tbldata?.start_at, "YYYY-MM-DD hh:mm:ss").format(
                        "DD MMM YYYY, hh:mm A"
                      )}
                    </td>
                    <td>
                      {dayjs(tbldata?.end_at, "YYYY-MM-DD hh:mm ss").format(
                        "DD MMM YYYY, hh:mm A"
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className={styles.noDataMainDiv}>
          <div className={styles.noDataDiv}>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
              />
            </span>
            <h2>No Data Found</h2>
          </div>
        </div>
      )}
    </div>
  );
};
