const timeZoneToCurrencySymbol = {
    "Asia/Dubai": "AED",
    "Asia/Kolkata": "₹",
};


export const getCurrencySymbolByTimeZone = (timeZone) => {
    const unescapedTimeZone = timeZone?.replace(/\\/g, ""); 
    return timeZoneToCurrencySymbol[unescapedTimeZone] || "₹";
};
