import React from "react";
import styles from "./toggleDays.module.css";

const DAYS = [
  {
    id: 1,
    key: "Mon",
    label: "M",
  },
  {
    id: 2,
    key: "Tue",
    label: "T",
  },
  {
    id: 3,
    key: "Wed",
    label: "W",
  },
  {
    id: 4,
    key: "Thu",
    label: "T",
  },
  {
    id: 5,
    key: "Fri",
    label: "F",
  },
  {
    id: 6,
    key: "Sat",
    label: "S",
  },
  {
    id: 7,
    key: "Sun",
    label: "S",
  },
];

const ToggleDays = ({ days, handleChangeDays }) => {
  return (
    <>
      <div className={styles.weekMain}>
        {DAYS?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                days?.includes(item.key)
                  ? styles.weekDiv + " " + styles.weekDivActive
                  : styles.weekDiv
              }
              value={item.key}
              onClick={() => {
                handleChangeDays(item?.key);
              }}
            >
              {item?.label}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ToggleDays;
