import React, { useState } from "react";
import styles from "./cancelComponent.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const CancelComponent = ({
  open,
  onCloseModal,
  msg,
  changedMsg,
  title,
  handleUpdateStatus,
  removeTrainersData,
  isDisabled,
  btnText,
  type,
  setDeleteStatus,
  module,
}) => {
  const [toggleMsg, setToggleMsg] = useState(false);

  return (
    <>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={false}>
        <div className={styles.tooltipChangeStatus}>
          <h1 className={styles.modalDivHeading}>{title}</h1>
          <p className={styles.modalDivp}>
            {module !== "Salon" && module !== "Spa"
              ? msg
              : toggleMsg == true
              ? changedMsg
              : msg}
          </p>
          <div className={styles.btnDiv}>
            <button
              className={styles.discard}
              onClick={() => {
                onCloseModal();
                setToggleMsg(false);
              }}
            >
              {process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : "Discard"}
            </button>
            <button
              className={styles.cancel}
              disabled={isDisabled}
              onClick={() => {
                if (module == "Salon" || module == "Spa") {
                  if (toggleMsg == false) {
                    setToggleMsg(true);
                  } else {
                    handleUpdateStatus("Cancelled");
                    onCloseModal();
                    setToggleMsg(false);
                  }
                } else {
                  if (type == "trainer") {
                    setDeleteStatus(true);
                    removeTrainersData();
                    onCloseModal();
                  } else {
                    handleUpdateStatus("Cancelled");
                    onCloseModal();
                  }
                }
              }}
            >
              {btnText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
