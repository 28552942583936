import React from "react";
import { TextInput } from "evergreen-ui";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ImageUploading from "react-images-uploading";
import "react-toastify/dist/ReactToastify.css";
import styles from "./offerComponent.module.css";
import { DateTimeRangeComponent } from "../DateTimeRangeComponent/DateTimeRangeComponent";
import { ButtonBorder } from "../Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonColor } from "../Button/Buttons/ButtonColor/ButtonColor";

export const OfferComponent = ({
  offer,
  handleOffer,
  details,
  open,
  onCloseModal,
  heading,
  btnTitle,
  images,
  setImages,
  handleCreateOffer,
  imagesOffer,
  setImagesOffer,
  handleDateTimePicker,
  isDisabled,
  moduleName,
  isDisable,
}) => {
  const handleNavigate = () => {
    onCloseModal();
  };

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImagesOffer(
      imageList.map((i, index) => {
        return {
          data_url: i.data_url,
        };
      })
    );
  };

  const onRemoveImage = (index) => {
    const rows = [...imagesOffer];
    rows.splice(index, 1);
    setImagesOffer(rows);
  };

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} showCloseIcon={true} center>
        <h2 className={styles.popularEventTitle}>{heading}</h2>
        <div className={styles.formDiv}>
          {moduleName === "Venue" ? (
            <>
              <div className={styles.titleChargeDiv}>
                <h3>{details?.name}</h3>
                <span>
                  <p className={styles.facCharge}>
                    Venue Amount :{" "}
                    <span className={styles.facChargeValue}>
                      {details?.booking_type == "Both"
                        ? ` ₹ ${details?.price_per_day}`
                        : details?.booking_type == "Day"
                        ? ` ₹ ${details?.price_per_day}`
                        : ` ₹ ${details?.price_per_hrs}`}
                    </span>
                  </p>
                  <p className={styles.facCharge}>
                    Advance (%) :{" "}
                    <span
                      className={styles.facChargeValue}
                    >{`${details?.advance_amount}`}</span>
                  </p>
                </span>
              </div>
            </>
          ) : moduleName === "Event" ? (
            <div className={styles.titleFacChargeDiv}>
              <h3>{details?.name}</h3>
            </div>
          ) : (
            <div className={styles.titleFacChargeDiv}>
              <h3>{details?.name}</h3>
              <p className={styles.facCharge}>
                Facility Charge:{" "}
                <span
                  className={styles.facChargeValue}
                >{`₹ ${details?.price} / ${details?.slot_duration} min `}</span>
              </p>
            </div>
          )}
          <div className={styles.InputDiv}>
            <label className={styles.labelFont}>
              {moduleName == "Venue" ? "Discount (%) " : "Discount Percentage"}
            </label>
            <span className={styles.star}>*</span>
            <br />
            <TextInput
              className={styles.inputField}
              width={534}
              height={44}
              placeholder=""
              name="discountPercentage"
              value={offer?.discountPercentage}
              onChange={(e) => {
                handleOffer(e);
              }}
            />
          </div>
          {process.env.REACT_APP_PRODUCT == "DLF" && (
            <>
              {moduleName == "Event" ? (
                <div>
                  <label className={styles.labelFont}>Discount For</label>
                  <span className={styles.star}>*</span>
                  <br />
                  <div className={styles.radioMainDiv}>
                    <span
                      className={
                        offer?.discountFor === "All"
                          ? styles.radioDivActive
                          : styles.radioDiv
                      }
                    >
                      <input
                        type="radio"
                        name="discountFor"
                        value="ALL"
                        checked={offer?.discountFor === "ALL"}
                        onChange={handleOffer}
                        disabled={true}
                      />
                      <label className={styles.radioLabel}>All</label>
                    </span>
                    <span
                      className={
                        offer?.discountFor === "MEMBERS"
                          ? styles.radioDivActive
                          : styles.radioDiv
                      }
                    >
                      <input
                        type="radio"
                        width={300}
                        height={40}
                        name="discountFor"
                        value="MEMBERS"
                        checked={offer?.discountFor === "MEMBERS"}
                        onChange={handleOffer}
                        disabled={true}
                      />
                      <label className={styles.radioLabel}>Member</label>
                    </span>
                    <span
                      className={
                        offer?.accessibleTo === "NON_MEMBERS"
                          ? styles.radioDivActive
                          : styles.radioDiv
                      }
                    >
                      <input
                        type="radio"
                        width={300}
                        height={40}
                        name="discountFor"
                        value="NON_MEMBERS"
                        checked={offer?.discountFor === "NON_MEMBERS"}
                        onChange={handleOffer}
                        disabled={true}
                      />
                      <label className={styles.radioLabel}>Non Members</label>
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <label className={styles.labelFont}>Discount For</label>
                  <span className={styles.star}>*</span>
                  <br />
                  <div className={styles.radioMainDiv}>
                    <span
                      className={
                        offer?.discountFor === "All"
                          ? styles.radioDivActive
                          : styles.radioDiv
                      }
                    >
                      <input
                        type="radio"
                        name="discountFor"
                        value="All"
                        checked={offer?.discountFor === "All"}
                        onChange={handleOffer}
                        disabled={true}
                      />
                      <label className={styles.radioLabel}>All</label>
                    </span>
                    <span
                      className={
                        offer?.discountFor === "Member"
                          ? styles.radioDivActive
                          : styles.radioDiv
                      }
                    >
                      <input
                        type="radio"
                        width={300}
                        height={40}
                        name="discountFor"
                        value="Member"
                        checked={offer?.discountFor === "Member"}
                        onChange={handleOffer}
                        disabled={true}
                      />
                      <label className={styles.radioLabel}>Member</label>
                    </span>
                    <span
                      className={
                        offer?.accessibleTo === "Family Member"
                          ? styles.radioDivActive
                          : styles.radioDiv
                      }
                    >
                      <input
                        type="radio"
                        width={300}
                        height={40}
                        name="discountFor"
                        value="Family Member"
                        checked={offer?.discountFor === "Family Member"}
                        onChange={handleOffer}
                        disabled={true}
                      />
                      <label className={styles.radioLabel}>Family Member</label>
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          <div className={styles.InputDiv}>
            <label className={styles.labelFont} htmlFor="">
              Discount Start Date/Time
            </label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.timerInput}>
              <DateTimeRangeComponent
                Name="startDateTime"
                createEvent={offer}
                handleDateTimePicker={handleDateTimePicker}
                isAvailableDay={undefined}
                isDisable={isDisable ? true : false}
              />
            </div>
          </div>
          <div className={styles.InputDiv}>
            <label className={styles.labelFont} htmlFor="">
              Discount End Date/Time
            </label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.timerInput}>
              <DateTimeRangeComponent
                Name="endDateTime"
                createEvent={offer}
                handleDateTimePicker={handleDateTimePicker}
                isAvailableDay={undefined}
                isDisable={isDisable ? true : false}
              />
            </div>
          </div>
          <div className={styles.InputDiv1}>
            <label className={styles.labelFont}>
              Offer Image
              <span
                style={{
                  color: "#74676E",
                  fontSize: "12px",
                  marginLeft: "4px",
                  marginRight: "2px",
                }}
              >
                (Resolution 900px * 440px)
              </span>{" "}
              {process.env.REACT_APP_PRODUCT == "DLF" && (
                <span className={styles.star}>*</span>
              )}
            </label>
            <div className={styles.ImgUploadMainDiv}>
              <ImageUploading
                multiple={false}
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg", "jpeg", "png"]}
                maxFileSize="3500000"
                resolutionWidth={900}
                resolutionHeight={440}
                resolutionType="absolute"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                  errors,
                }) => (
                  <div className={styles.uploadImgDiv}>
                    <div className={styles.previewDiv}>
                      {imageList.map((image, index) => (
                        <div
                          key={index}
                          className={styles.imgIconDiv}
                          onClick={() => onImageRemove(index)}
                        >
                          <div
                            className={styles.closeIcon}
                            onClick={() => {
                              onRemoveImage(index);
                              onImageRemove(index);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/cancelEventActive.png`}
                              alt="removeIcon"
                            />
                          </div>
                          <img src={image.data_url} alt="" />
                        </div>
                      ))}
                      {images.length < 1 && (
                        <div className={styles.ImageDiv}>
                          <div className={styles.imgIconDiv}>
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/ImageUpload.png`}
                            />
                          </div>
                          <p>
                            <span style={{ color: "#121212" }}>
                              Upload Image
                            </span>
                            <span>(Less than 3.5mb)</span>
                          </p>
                        </div>
                      )}
                    </div>

                    {images.length < 1 && (
                      <button
                        onClick={onImageUpload}
                        className={styles.browseBtn}
                      >
                        Browse
                      </button>
                    )}
                    {errors?.maxFileSize && (
                      <span className={styles.error}>
                        Selected file size exceed Max File Size
                      </span>
                    )}
                    {errors?.acceptType && (
                      <span className={styles.error}>
                        Selected file type is not allowed
                      </span>
                    )}
                    {errors?.resolution && (
                      <span className={styles.error}>
                        Selected file does not match your desired resolution
                      </span>
                    )}
                  </div>
                )}
              </ImageUploading>
            </div>
          </div>
        </div>
        <div className={styles.btnDivGenerateOffer}>
          <ButtonBorder
            width={200}
            text={process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : "Discard"}
            buttonHandler={handleNavigate}
          />
          <ButtonColor
            width={200}
            text={btnTitle}
            buttonHandler={handleCreateOffer}
            isDisabled={isDisabled}
          />
        </div>
      </Modal>
    </div>
  );
};
