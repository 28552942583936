import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { ButtonBorder } from "../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { TopHeader } from "../../../Components/TopHeaderNav/TopHeader";
import styles from "./viewBookingEvent.module.css";
import FileViewer from "react-file-viewer";
import { toast } from "react-toastify";
import { getCurrencySymbolByTimeZone } from "../../../Utils/currencySymbol";
import { IS_REGISTRATION_FLOW } from "../../../Utils/moduleConfig";

export const ViewBookingEvent = ({
  open,
  onCloseModal,
  viewId,
  details,
  cancelBookingModalStatus,
}) => {
  const { community, adminName } = useSelector((state) => state.authSlice);
  const { eventRights } = useSelector((state) => state.authSlice);
  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(profileDetails?.comm_time_zone)  


  const [seatsConfirmed, setSeatsConfirmed] = useState({});
  const [seatsCancelled, setSeatsCancelled] = useState({});
  const [guestSeatsConfirmed, setGuestSeatsConfirmed] = useState({});
  const [guestSeatsCancelled, setGuestSeatsCancelled] = useState({});
  const [bookSeatAccordian, setBookSeatAccordian] = useState(false);
  const [cancelSeatAccordian, setCancelSeatAccordian] = useState(false);
  const [isHistoryDisabled, setIsHistoryDisabled] = useState(false);
  let currentDate = dayjs();

  let viewDetails =
    details?.length > 0 &&
    details?.filter((i, index) => {
      return i.id == viewId ? i : "";
    });

  const fileOptions = {
    fileType: "pdf",
  };

  const handleSeatingYes = () => {
    let bookedSeats = {};
    let cancelledSeats = {};
    viewDetails[0]?.booked_seats?.map((seat) => {
      if (seat?.seat_canceled == 0) {
        if (bookedSeats.hasOwnProperty(seat?.seat_type?.name)) {
          bookedSeats[seat?.seat_type?.name]["booked_seats_count"] =
            bookedSeats[seat?.seat_type?.name]["booked_seats_count"] + 1;
          bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
        } else {
          bookedSeats[seat?.seat_type?.name] = {};
          bookedSeats[seat?.seat_type?.name]["booked_seats_count"] = 1;
          bookedSeats[seat?.seat_type?.name]["price"] = seat?.seat_price;
          bookedSeats[seat?.seat_type?.name]["seats_data"] = [];
          bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
        }
      }
    });
    viewDetails[0]?.cancelled_seats?.map((seat) => {
      if (seat?.seat_canceled == 1) {
        if (cancelledSeats.hasOwnProperty(seat?.seat_type?.name)) {
          cancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] =
            cancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] + 1;
          cancelledSeats[seat?.seat_type?.name]["seats_data"].push(seat);
        } else {
          cancelledSeats[seat?.seat_type?.name] = {};
          cancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] = 1;
          cancelledSeats[seat?.seat_type?.name]["price"] = seat?.seat_price;
          cancelledSeats[seat?.seat_type?.name]["seats_data"] = [];
          cancelledSeats[seat?.seat_type?.name]["seats_data"].push(seat);
        }
      }
    });
    setSeatsCancelled(cancelledSeats);
    setSeatsConfirmed(bookedSeats);
  };

  const handleSeatingYesAc = () => {
    let bookedSeats = {};
    let cancelledSeats = {};
    let guestbookedSeats = {};
    let guestcancelledSeats = {};
    let categoryExits = false;
    viewDetails[0]?.booked_seats?.map((seat) => {
      categoryExits =
        seat?.seat_booked_for == "Guest"
          ? guestbookedSeats.hasOwnProperty(seat?.seat_type?.name)
          : bookedSeats.hasOwnProperty(seat?.seat_type?.name);
      if (seat?.seat_canceled == 0) {
        if (categoryExits) {
          //check if booking is for guest
          if (seat?.seat_booked_for == "Guest") {
            guestbookedSeats[seat?.seat_type?.name][
              "guest_booked_seats_count"
            ] =
              guestbookedSeats[seat?.seat_type?.name][
                "guest_booked_seats_count"
              ] + 1;
            guestbookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          } else {
            bookedSeats[seat?.seat_type?.name]["booked_seats_count"] =
              bookedSeats[seat?.seat_type?.name]["booked_seats_count"] + 1;
            bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          }
        } else {
          if (seat?.seat_booked_for == "Guest") {
            guestbookedSeats[seat?.seat_type?.name] = {};
            guestbookedSeats[seat?.seat_type?.name][
              "guest_booked_seats_count"
            ] = 1;
            guestbookedSeats[seat?.seat_type?.name]["guest_price"] =
              seat?.seat_type?.guest_price;
            // guestbookedSeats[seat?.seat_type?.name]["booked_seats_count"] = 0;
            // bookedSeats[seat?.seat_type?.name]["price"] =
            //   seat?.seat_type?.price;
            guestbookedSeats[seat?.seat_type?.name]["seats_data"] = [];
            guestbookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          } else {
            bookedSeats[seat?.seat_type?.name] = {};
            // bookedSeats[seat?.seat_type?.name]["guest_booked_seats_count"] = 0;
            // bookedSeats[seat?.seat_type?.name]["guest_price"] =
            //   seat?.seat_type?.guest_price;
            bookedSeats[seat?.seat_type?.name]["booked_seats_count"] = 1;
            bookedSeats[seat?.seat_type?.name]["price"] =
              seat?.seat_type?.price;
            bookedSeats[seat?.seat_type?.name]["seats_data"] = [];
            bookedSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          }
        }
      }
    });
    // ------------------------------
    viewDetails[0]?.cancelled_seats?.map((seat) => {
      categoryExits =
        seat?.seat_booked_for == "Guest"
          ? guestcancelledSeats.hasOwnProperty(seat?.seat_type?.name)
          : cancelledSeats.hasOwnProperty(seat?.seat_type?.name);
      if (seat?.seat_canceled == 1) {
        if (categoryExits) {
          if (seat?.seat_booked_for == "Guest") {
            guestcancelledSeats[seat?.seat_type?.name][
              "guest_cancelled_seats_count"
            ] =
              guestcancelledSeats[seat?.seat_type?.name][
                "guest_cancelled_seats_count"
              ] + 1;
            guestcancelledSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          } else {
            cancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] =
              cancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] +
              1;
            cancelledSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          }
        } else {
          if (seat?.seat_booked_for == "Guest") {
            guestcancelledSeats[seat?.seat_type?.name] = {};
            guestcancelledSeats[seat?.seat_type?.name][
              "guest_cancelled_seats_count"
            ] = 1;
            guestcancelledSeats[seat?.seat_type?.name]["guest_price"] =
              seat?.seat_type?.guest_price;
            // guestcancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] = 0;
            // guestcancelledSeats[seat?.seat_type?.name]["price"] =
            // seat?.seat_type?.price;
            guestcancelledSeats[seat?.seat_type?.name]["seats_data"] = [];
            guestcancelledSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          } else {
            cancelledSeats[seat?.seat_type?.name] = {};
            // cancelledSeats[seat?.seat_type?.name][
            //   "guest_cancelled_seats_count"
            // ] = 0;
            // cancelledSeats[seat?.seat_type?.name]["guest_price"] =
            //   seat?.seat_type?.guest_price;
            cancelledSeats[seat?.seat_type?.name]["cancelled_seats_count"] = 1;
            cancelledSeats[seat?.seat_type?.name]["price"] =
              seat?.seat_type?.price;
            cancelledSeats[seat?.seat_type?.name]["seats_data"] = [];
            cancelledSeats[seat?.seat_type?.name]["seats_data"].push(seat);
          }
        }
      }
    });
    setSeatsCancelled(cancelledSeats);
    setSeatsConfirmed(bookedSeats);
    setGuestSeatsCancelled(guestcancelledSeats);
    setGuestSeatsConfirmed(guestbookedSeats);
  };

  const handleSeatingNo = () => {
    let bookedSeats = {};
    let cancelledSeats = {};
    viewDetails[0]?.booked_seats?.map((seat) => {
      if (seat?.seat_canceled == 0) {
        if (bookedSeats.hasOwnProperty("Seats")) {
          bookedSeats["Seats"]["booked_seats_count"] =
            bookedSeats["Seats"]["booked_seats_count"] + 1;
          bookedSeats["Seats"]["seats_data"].push(seat);
        } else {
          bookedSeats["Seats"] = {};
          bookedSeats["Seats"]["booked_seats_count"] = 1;
          bookedSeats["Seats"]["price"] = viewDetails[0]?.event?.price;
          bookedSeats["Seats"]["seats_data"] = [];
          bookedSeats["Seats"]["seats_data"].push(seat);
        }
      }
    });
    // ------------------------------
    viewDetails[0]?.cancelled_seats?.map((seat) => {
      if (seat?.seat_canceled == 1) {
        if (cancelledSeats.hasOwnProperty("Seats")) {
          cancelledSeats["Seats"]["cancelled_seats_count"] =
            cancelledSeats["Seats"]["cancelled_seats_count"] + 1;
          cancelledSeats["Seats"]["seats_data"].push(seat);
        } else {
          cancelledSeats["Seats"] = {};
          cancelledSeats["Seats"]["cancelled_seats_count"] = 1;
          cancelledSeats["Seats"]["price"] = viewDetails[0]?.event?.price;
          cancelledSeats["Seats"]["seats_data"] = [];
          cancelledSeats["Seats"]["seats_data"].push(seat);
        }
      }
    });
    setSeatsCancelled(cancelledSeats);
    setSeatsConfirmed(bookedSeats);
  };

  const handleSeatingNoAc = () => {
    let bookedSeats = {};
    let cancelledSeats = {};
    let guestbookedSeats = {};
    let guestcancelledSeats = {};
    let categoryExits = false;
    viewDetails[0]?.booked_seats?.map((seat) => {
      categoryExits =
        seat?.seat_booked_for == "Guest"
          ? guestbookedSeats.hasOwnProperty("Seats")
          : bookedSeats.hasOwnProperty("Seats");
      if (seat?.seat_canceled == 0) {
        if (categoryExits) {
          if (seat?.seat_booked_for == "Guest") {
            guestbookedSeats["Seats"]["guest_booked_seats_count"] =
              guestbookedSeats["Seats"]["guest_booked_seats_count"] + 1;
            guestbookedSeats["Seats"]["seats_data"].push(seat);
          } else {
            bookedSeats["Seats"]["booked_seats_count"] =
              bookedSeats["Seats"]["booked_seats_count"] + 1;
            bookedSeats["Seats"]["seats_data"].push(seat);
          }
        } else {
          if (seat?.seat_booked_for == "Guest") {
            guestbookedSeats["Seats"] = {};
            guestbookedSeats["Seats"]["guest_booked_seats_count"] = 1;
            guestbookedSeats["Seats"]["guest_price"] =
              viewDetails[0].event?.guest_price;
            guestbookedSeats["Seats"]["seats_data"] = [];
            guestbookedSeats["Seats"]["seats_data"].push(seat);
          } else {
            bookedSeats["Seats"] = {};
            bookedSeats["Seats"]["booked_seats_count"] = 1;
            bookedSeats["Seats"]["price"] = viewDetails[0].event?.price;
            bookedSeats["Seats"]["seats_data"] = [];
            bookedSeats["Seats"]["seats_data"].push(seat);
          }
        }
      }
    });
    // ------------------------------
    viewDetails[0]?.cancelled_seats?.map((seat) => {
      categoryExits =
        seat?.seat_booked_for == "Guest"
          ? guestcancelledSeats.hasOwnProperty("Seats")
          : cancelledSeats.hasOwnProperty("Seats");
      if (seat?.seat_canceled == 1) {
        if (categoryExits) {
          if (seat?.seat_booked_for == "Guest") {
            guestcancelledSeats["Seats"]["guest_cancelled_seats_count"] =
              guestcancelledSeats["Seats"]["guest_cancelled_seats_count"] + 1;
            guestcancelledSeats["Seats"]["seats_data"].push(seat);
          } else {
            cancelledSeats["Seats"]["cancelled_seats_count"] =
              cancelledSeats["Seats"]["cancelled_seats_count"] + 1;
            cancelledSeats["Seats"]["seats_data"].push(seat);
          }
        } else {
          if (seat?.seat_booked_for == "Guest") {
            guestcancelledSeats["Seats"] = {};
            guestcancelledSeats["Seats"]["guest_cancelled_seats_count"] = 1;
            guestcancelledSeats["Seats"]["guest_price"] =
              viewDetails[0].event?.guest_price;
            guestcancelledSeats["Seats"]["seats_data"] = [];
            guestcancelledSeats["Seats"]["seats_data"].push(seat);
          } else {
            cancelledSeats["Seats"] = {};
            cancelledSeats["Seats"]["cancelled_seats_count"] = 1;
            cancelledSeats["Seats"]["price"] = viewDetails[0].event?.price;
            cancelledSeats["Seats"]["seats_data"] = [];
            cancelledSeats["Seats"]["seats_data"].push(seat);
          }
        }
      }
    });
    setSeatsCancelled(cancelledSeats);
    setSeatsConfirmed(bookedSeats);
    setGuestSeatsCancelled(guestcancelledSeats);
    setGuestSeatsConfirmed(guestbookedSeats);
  };

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCT != "DLF") {
      viewDetails[0]?.event?.has_seating_category
        ? handleSeatingYesAc()
        : handleSeatingNoAc();
    } else {
      viewDetails[0]?.event?.has_seating_category
        ? handleSeatingYes()
        : handleSeatingNo();
    }
  }, [viewId]);

  useEffect(() => {
    let stat =
      dayjs(viewDetails[0]?.event?.start_at).format("DD MMM YYYY, hh:mm A") <=
      dayjs(currentDate).format("DD MMM YYYY, hh:mm A")
        ? true
        : false;
    setIsHistoryDisabled(stat);
  }, [viewDetails[0], open]);

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center showCloseIcon={true}>
        <div className={styles.navDiv}>
          {" "}
          <TopHeader title={"View Booking"} />
        </div>
        <div className={styles.viewBookingMainDiv}>
          <div className={styles.viewBookingMainImgDiv}>
            <div className={styles.viewBookingImgDiv}>
              {viewDetails[0]?.event.all_event_images ? (
                <img src={viewDetails[0].event.all_event_images[0]?.path} />
              ) : (
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/YogaImgViewBooking.png`}
                />
              )}
            </div>
            <div>
              <h2>{viewDetails[0]?.event.name}</h2>
              <p className={styles.categoryTitle}>
                {viewDetails[0]?.event?.event_venue?.name}
              </p>
              <p
                className={
                  viewDetails[0]?.status == "BOOKED" &&
                  viewDetails[0]?.payment_status == "PAID"
                    ? styles.open
                    : viewDetails[0]?.status == "CANCELED"
                    ? styles.cancelled
                    : viewDetails[0]?.status == "Declined".toUpperCase()
                    ? styles.rejected
                    : styles.close
                }
              >
                {/* {viewDetails[0]?.status} */}
                {viewDetails[0]?.status == "BOOKED" &&
                viewDetails[0]?.payment_status == "PAID"
                  ? "BOOKED"
                  : viewDetails[0]?.status == "BOOKED" &&
                    viewDetails[0]?.payment_status == "UNPAID"
                  ? "UNPAID"
                  : viewDetails[0]?.status}
              </p>
            </div>
          </div>
          <div className={styles.inputDivMain}>
            <label>Booked For</label>
            <div className={styles.inputDiv}>
              {viewDetails[0]?.member_details?.full_name
                ? viewDetails[0]?.member_details?.full_name
                : "-"}
            </div>
          </div>
          <div className={styles.inputDivMain}>
            <label>Booked By</label>
            <div className={styles.inputDiv}>
              {viewDetails[0]?.booked_by ? viewDetails[0]?.booked_by : "-"}
            </div>
          </div>
          {/* ----------------------------- */}
          <div className={styles.inputDivMain}>
            <label>Contact no.</label>
            <div className={styles.inputDiv}>
              {viewDetails[0]?.member_details?.mobile_phone
                ? viewDetails[0]?.member_details?.mobile_phone
                : "-"}
            </div>
          </div>
          {/* ----------------------------- */}
          <div className={styles.inputDivMain}>
            <label>
              {process.env.REACT_APP_PRODUCT == "DLF"
                ? "Membership no."
                : profileDetails?.comm_type == "Federation Parent Community"
                ? "Occupant"
                : profileDetails?.comm_type == "Federation Child Community"
                ? "Employee"
                : "Unit No."}
            </label>
            <div className={styles.inputDiv}>
              {/* {viewDetails[0]?.member_id ? viewDetails[0]?.member_id : "-"} */}
              {process.env.REACT_APP_PRODUCT == "DLF"
                ? viewDetails[0]?.member_id
                : viewDetails[0]?.block_id + "-" + viewDetails[0]?.ru_id}
            </div>
          </div>
          {/* -------------------------- */}
          <div className={styles.inputDivMain}>
            <label>
              {process.env.REACT_APP_PRODUCT == "DLF"
                ? "Booking Date and Time"
                : "Preferred Date"}
            </label>
            <div className={styles.inputDiv}>
              {viewDetails[0]?.event.start_at
                ? `${dayjs(viewDetails[0]?.event?.start_at).format(
                    "DD MMM YYYY, HH:mm A"
                  )} - ${dayjs(viewDetails[0]?.event.end_at).format(
                    "DD MMM YYYY, HH:mm A"
                  )}`
                : "-"}
            </div>
          </div>
          {/* -------------------------------- */}
          <div className={styles.inputDivMain}>
            <label>Amount charged</label>
            <div className={styles.inputDiv}>
              {viewDetails[0]?.total_amount == 0 &&
              viewDetails[0].event.booking_type !== "PAID"
                ? `${
                    process.env.REACT_APP_PRODUCT === "DLF"
                      ? "With Compliments"
                      : "Free"
                  }`
                : (viewDetails[0]?.status == "BOOKED" ||
                    viewDetails[0]?.status == "CANCELED") &&
                  viewDetails[0]?.payment_status == "UNPAID"
                ? `${currencySymbol} ${viewDetails[0]?.total_amount_with_tax}`
                : viewDetails[0]?.total_amount_with_tax
                ? `${currencySymbol} ${viewDetails[0]?.total_amount_with_tax}`
                : "-"}
            </div>
          </div>
          {/* ----------------------------- */}
          <div className={styles.inputDivMain}>
            <label>
              {process.env.REACT_APP_PRODUCT == "DLF"
                ? "Creation Date and Time"
                : "Booking Date and Time"}
            </label>
            <div className={styles.inputDiv}>
              {viewDetails[0]?.booking_date
                ? viewDetails[0]?.booking_date
                : "-"}
            </div>
          </div>
          {/* ------------------------------- */}
          <div className={styles.inputDivMain}>
            <label>
              {process.env.REACT_APP_PRODUCT != "DLF" ? "Community" : "Club"}
            </label>
            <div className={styles.inputDiv}>{community ? community : "-"}</div>
          </div>
          {/* --------------------------------- */}
          {process.env.REACT_APP_PRODUCT == "DLF" &&
            Object.keys(seatsConfirmed)?.length > 0 && (
              <div className={styles.inputDivMain}>
                <label>Booking</label>
                <div className={styles.fieldDiv}>
                  <div className={styles.flexDiv}>
                    <p>Seats booked</p>
                    <p>{viewDetails[0]?.booked_seats?.length}</p>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #ECEBED",
                      marginRight: "20px",
                    }}
                  ></div>
                  {Object.keys(seatsConfirmed)?.map((seat) => {
                    return (
                      <div className={styles.flexDiv}>
                        <span>
                          <p>{seat}</p>
                          <p> {currencySymbol} {seatsConfirmed[seat]?.price}</p>
                        </span>
                        <p>{seatsConfirmed[seat]?.booked_seats_count}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          {/* --------------------------------- */}
          {process.env.REACT_APP_PRODUCT == "DLF" &&
            Object.keys(seatsCancelled)?.length > 0 && (
              <div className={styles.inputDivMain}>
                <label>Cancelled</label>
                <div className={styles.fieldDiv}>
                  <div className={styles.flexDiv}>
                    <p>Seats cancelled</p>
                    <p>{viewDetails[0]?.cancelled_seats?.length}</p>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #ECEBED",
                      marginRight: "20px",
                    }}
                  ></div>
                  {Object.keys(seatsCancelled)?.map((seat) => {
                    return (
                      <div className={styles.flexDiv}>
                        <span>
                          <p>{seat}</p>
                          <p> {currencySymbol} {seatsCancelled[seat]?.price}</p>
                        </span>
                        <p>{seatsCancelled[seat]?.cancelled_seats_count}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          {/* ------------------------------- */}
          {/* -----------AC--------------------------- */}
          {process.env.REACT_APP_PRODUCT != "DLF" &&
            Object.keys(seatsConfirmed)?.length +
              Object.keys(guestSeatsConfirmed)?.length >
              0 && (
              <div className={styles.inputDivMain}>
                <label>No. of Seats</label>
                <div className={styles.fieldDiv}>
                  <div className={styles.flexDiv}>
                    <p>{viewDetails[0]?.booked_seats?.length}</p>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => setBookSeatAccordian(!bookSeatAccordian)}
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/accordian.png`}
                      alt="accordian"
                    />
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #ECEBED",
                      marginRight: "20px",
                    }}
                  ></div>
                  <>
                    {bookSeatAccordian && (
                      <>
                        {Object.keys(seatsConfirmed)?.length > 0 && (
                          <>
                            <p className={styles.categoryTitle}>For Members</p>
                            {Object.keys(seatsConfirmed)?.map((seat) => (
                              <div className={styles.flexDiv} key={seat}>
                                <span>
                                  <p>{seat}</p>
                                  <p>
                                    {process.env.REACT_APP_PRODUCT ===
                                    "ALFUTTAIM"
                                      ? "AED "
                                      : currencySymbol+" "}
                                    {seatsConfirmed[seat]?.price}
                                  </p>
                                </span>
                                <p>
                                  {seatsConfirmed[seat]?.booked_seats_count}
                                </p>
                              </div>
                            ))}
                          </>
                        )}
                        {Object.keys(guestSeatsConfirmed)?.length > 0 && (
                          <>
                            <p className={styles.categoryTitle}>For Guests</p>
                            {Object.keys(guestSeatsConfirmed)?.map((seat) => (
                              <div className={styles.flexDiv} key={seat}>
                                <span>
                                  <p>{seat}</p>
                                  <p>
                                    {process.env.REACT_APP_PRODUCT ===
                                    "ALFUTTAIM"
                                      ? "AED "
                                      : currencySymbol+" "}
                                    {guestSeatsConfirmed[seat]?.guest_price}
                                  </p>
                                </span>
                                <p>
                                  {
                                    guestSeatsConfirmed[seat]
                                      ?.guest_booked_seats_count
                                  }
                                </p>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            )}
          {/*--------------------- dynaic audience form detail------------------------------------- */}
          {IS_REGISTRATION_FLOW &&
            viewDetails[0]?.audience_details?.length > 0 &&
            viewDetails[0]?.audience_details?.map((item, index) => {
              return (
                <div className={styles.inputDivMain} key={index}>
                  <label>{item?.field_name}</label>
                  <div className={styles.inputDiv}>
                    {item?.field_type === "single_choice" ? (
                      item?.choice_label || "NA"
                    ) : item?.field_type === "checkbox" ? (
                      item?.field_value?.split(",").join(" , ") || "NA"
                    ) : item?.field_type === "file" ? (
                      item?.field_value ? (
                        <div className={styles.dynamicFormImgDiv}>
                          <img src={item?.field_value} alt="file" />
                        </div>
                      ) : (
                        "NA"
                      )
                    ) : item?.field_type === "date" ? (
                      item?.field_value ? (
                        `${dayjs(item?.field_value).format("DD MMM YYYY")}`
                      ) : (
                        "NA"
                      )
                    ) : item?.field_type === "date_range" ? (
                      item?.field_value?.split("#").length === 2 ? (
                        `${dayjs(item?.field_value.split("#")[0]).format(
                          "DD MMM YYYY"
                        )} - ${dayjs(item?.field_value.split("#")[1]).format(
                          "DD MMM YYYY"
                        )}`
                      ) : (
                        "NA"
                      )
                    ) : item?.field_type === "date_time" ? (
                      item?.field_value ? (
                        `${dayjs(item?.field_value).format(
                          "DD MMM YYYY, hh:mm A"
                        )}`
                      ) : (
                        "NA"
                      )
                    ) : item?.field_type === "time" ? (
                      item?.field_value ? (
                        `${dayjs(item?.field_value, "hh:mm:ss").format(
                          "hh:mm A"
                        )}`
                      ) : (
                        "NA"
                      )
                    ) : item?.field_value ? (
                      item?.field_value
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              );
            })}
          {/* ------------------------------------------------------------------------------------ */}
          {process.env.REACT_APP_PRODUCT != "DLF" &&
            Object.keys(seatsCancelled)?.length +
              Object.keys(guestSeatsCancelled)?.length >
              0 && (
              <div className={styles.inputDivMain}>
                <label>Cancelled</label>
                <div className={styles.fieldDiv}>
                  <div className={styles.flexDiv}>
                    <p>{viewDetails[0]?.cancelled_seats?.length}</p>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setCancelSeatAccordian(!cancelSeatAccordian)
                      }
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/accordian.png`}
                      alt="accordian"
                    />
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #ECEBED",
                      marginRight: "20px",
                    }}
                  ></div>
                  <>
                    {cancelSeatAccordian && (
                      <>
                        {Object.keys(seatsCancelled)?.length > 0 && (
                          <>
                            <p className={styles.categoryTitle}>For Members</p>
                            {Object.keys(seatsCancelled)?.map((seat) => (
                              <div className={styles.flexDiv} key={seat}>
                                <span>
                                  <p>{seat}</p>
                                  <p> {currencySymbol} {seatsCancelled[seat]?.price}</p>
                                </span>
                                <p>
                                  {seatsCancelled[seat]?.cancelled_seats_count}
                                </p>
                              </div>
                            ))}
                          </>
                        )}
                        {Object.keys(guestSeatsCancelled)?.length > 0 && (
                          <>
                            <p className={styles.categoryTitle}>For Guests</p>
                            {Object.keys(guestSeatsCancelled)?.map((seat) => (
                              <div className={styles.flexDiv} key={seat}>
                                <span>
                                  <p>{seat}</p>
                                  <p>
                                    {" "}
                                    {currencySymbol} {guestSeatsCancelled[seat]?.guest_price}
                                  </p>
                                </span>
                                <p>
                                  <p>
                                    {
                                      guestSeatsCancelled[seat]
                                        ?.guest_cancelled_seats_count
                                    }
                                  </p>
                                </p>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            )}
          {viewDetails[0]?.cancel_reason && (
            <div className={styles.fieldsDiv}>
              <label>Reason For Cancellation</label>
              <br />
              <div className={styles.inputTextArea}>
                {viewDetails[0]?.cancel_reason}
              </div>
            </div>
          )}

          {process.env.REACT_APP_PRODUCT == "DLF" &&
            viewDetails[0]?.event_transaction !== null && (
              <div>
                <div className={styles.inputDivMain}>
                  <label>Transaction Details</label>
                  <div className={styles.fieldDivTransaction}>
                    <div className={styles.flexDivTransaction}>
                      <p>Mode of payment</p>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #ECEBED",
                        marginRight: "20px",
                      }}
                    ></div>
                    <div className={styles.flexDivTransaction}>
                      <span>
                        <p>{viewDetails[0]?.event_transaction?.payment_mode}</p>
                      </span>
                    </div>
                  </div>
                  <div className={styles.fieldDivTransaction}>
                    <div className={styles.flexDivTransaction}>
                      <p>Transaction number</p>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #ECEBED",
                        marginRight: "20px",
                      }}
                    ></div>
                    <div className={styles.flexDivTransaction}>
                      <span>
                        <p>
                          {viewDetails[0]?.event_transaction?.transaction_id}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.inputDivMain}>
                  <div className={styles.inputDivMainTransaction}>
                    <p>Document</p>
                  </div>
                  <div className={styles.inputDivMain}>
                    <span className={styles.inputDivMainTransactionF}>
                      <FileViewer
                        fileType={fileOptions.fileType}
                        filePath={viewDetails[0]?.event_transaction?.document}
                        onError={(e) => {}}
                      />
                    </span>
                  </div>
                </div>
                {/* <label style={{ fontSize: "15px", padding: "10px 0px 0px" }}>
                  Transaction Details
                </label>
                <div className={styles.inputDivMainP}>
                  <label>Mode of payment:</label>
                  <div className={styles.inputDiv}>
                    {viewDetails[0]?.event_transaction?.payment_mode}
                  </div>
                </div>

                <div className={styles.inputDivMain}>
                  <div className={styles.inputDivMainTransaction}>
                    <FileViewer
                      fileType={fileOptions.fileType}
                      filePath={viewDetails[0]?.event_transaction?.document}
                      onError={(e) => {}}
                    />
                  </div>
                </div> */}
              </div>
            )}
        </div>
        {process.env.REACT_APP_PRODUCT == "DLF" &&
          // viewDetails[0]?.event?.cancellation_allowed == 1 &&
          viewDetails[0]?.payment_status !== "PAID" &&
          viewDetails[0]?.status !== "CANCELED" &&
          viewDetails[0]?.event?.status !== "COMPLETED" &&
          isHistoryDisabled == false && (
            <div className={styles.btnDiv}>
              <ButtonBorder
                text="Cancel Booking"
                width={200}
                buttonHandler={() => {
                  if (process.env.REACT_APP_PRODUCT == "DLF") {
                    if (eventRights?.update == 1) {
                      onCloseModal();
                      cancelBookingModalStatus();
                    } else {
                      toast.error("You don't have update access rights");
                    }
                  }
                }}
              />
              <ButtonColor
                text="Back"
                width={200}
                buttonHandler={() => {
                  onCloseModal();
                }}
              />
            </div>
          )}
      </Modal>
    </div>
  );
};
