import React, { useEffect, useState } from "react";
import styles from "./facilities.module.css";
import { useDispatch, useSelector } from "react-redux";
import { TopHeader } from "../Components/TopHeaderNav/TopHeader";
import SearchAndFilter from "../Components/SearchAndFilter/SearchAndFilter";
import { FacilitiesConfig } from "./FacilitiesConfig";
import { FacilitiesList } from "./FacilitiesList/FacilitiesList";
import { FacilitiesDetails } from "./FacilitiesDetails/FacilitiesDetails";
import { getAllFacilities } from "../Components/Redux/facilitiesSlice";
import { loadData } from "../Utils/localStorage";
import { Logo } from "../Components/Logo";

export const Facilities = () => {
  const dispatch = useDispatch();
  const { searchValue, accessibleTo, status, category, availableDays } =
    useSelector((state) => state.searchSlice);
  const { Loader, facilitiesList } = useSelector(
    (state) => state.facilitiesSlice
  );
  const [categoryArr, setCategoriesArr] = useState([]);
  const club_id=loadData("Club_id");
  let dropdownOption = [
    {
      id: 0,
      name: "Available",
    },
    {
      id: 1,
      name: "Unavailable",
    },
  ];

  const getAllFacilitiesFun = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
    };
    if (status !== "") {
      payload["status"] = status;
    }
    if (accessibleTo !== "") {
      payload["access_to"] = accessibleTo;
    }
    if (category !== "") {
      payload["category"] = category;
    }
    if (availableDays.length > 0) {
      payload["available_days"] = availableDays;
    }
    if (searchValue !== "") {
      payload["fname"] = searchValue;
    }
    dispatch(getAllFacilities(payload));
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/facility/all-active-categories `,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        setCategoriesArr(res?.data);
      })
      .catch((err) => {});
  }, [club_id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllFacilitiesFun();
    }, 500);
    return () => clearTimeout(timer);
  }, [status, accessibleTo, category, searchValue,club_id]);

  return (
    <div className={styles.mainDivEvents}>
      <TopHeader
        title={"Recreation & More"}
        showBtn={true}
        btnTitle1={"Create Facility"}
        btnTitle2={"Category List"}
        btnTitle3={"Booking Requests"}
        btnTitle4={"Booking History"}
        to1={FacilitiesConfig.createFacilities}
        to2={FacilitiesConfig.category}
        to3={FacilitiesConfig.BookingRequest}
        to4={FacilitiesConfig.BookingHistory}
        showCategoryBtn={true}
        headerType={"facilities"}
      />
      <SearchAndFilter
        handleSearch={getAllFacilitiesFun}
        dropdownOption={dropdownOption}
        showStatus={true}
        categoryArr={categoryArr}
        showCategory={true}
        showAvailability={true}
        showAccessibleTo={true}
        categoryTitle={"Facility Category"}
        filterType={"facilityFilter"}
      />
      {Loader ? (
        <Logo />
      ) : (
        <>
          {facilitiesList.length !== 0 ? (
            <div className={styles.mainMidDivEvents}>
              <div className={styles.divEventList}>
                <FacilitiesList />
              </div>
              <FacilitiesDetails />
            </div>
          ) : (
            <div className={styles.noDataMainDiv}>
              <div className={styles.noDataDiv}>
                <span className={styles.noDataImgDiv}>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
                  />
                </span>
                <h2>Not Found</h2>
                <p>Search did not match current facility listed</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
