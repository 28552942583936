import React, { useEffect, useState } from "react";
import styles from "../createForm.module.css";
import { TagInput } from "evergreen-ui";
import { WeekCalender } from "../../../Components/WeekCalender/WeekCalender";
import { Textarea, TextInput } from "evergreen-ui";
import { TimePickerr } from "../../../Components/TimePicker/TimePickerr";
import "bootstrap-daterangepicker/daterangepicker.css";
import "rsuite/dist/rsuite.min.css";
import { AddCategoryServices } from "../AddCategoryServices/AddCategoryServices";
import { SpaConfig } from "../../SpaConfig";
import { loadData } from "../../../Utils/localStorage";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import axios from "axios";
import { makeStyles } from "@mui/styles";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const useStyles = makeStyles({
  root: {
    "&.Mui-checked .MuiSvgIcon-root": {
      color: "#690a3c !important",
    },
  },
  customSelect: {
    width: "100%",
    height: "43px",
    borderBottom: "none",
    borderColor: "none",
    outline: "none",
    fontSize: "13px",
    "& .MuiSelect-outlined": {
      borderColor: "#DEDFE1",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEDFE1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DEDFE1",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DEDFE1",
    },
  },
});

export const DetailsForm = ({
  isEdit,
  create,
  handleCreate,
  handleTimePicker,
  heading,
  days,
  handleChangeDays,
  categoryArr,
  categoryOptions,
  handleGetActiveCategories,
  addCategoryModal,
  setAddCategoryModal,
  setSelectedCategories,
  categoryNames,
  setCategoryNames,
  selectAllCategories,
  setSelectAllCategories,
  setSelectedProfessionals,
  professionalNames,
  setProfessionalNames,
  selectAllProfessionals,
  setSelectAllProfessionals,
  setSelectedTags,
  tags,
  setTags,
}) => {
  const classes = useStyles();

  const [expandProf, setExpandProf] = useState(false);
  const [allProfessionals, setAllProfessionals] = useState([]);
  const [isCategEdit, setIsCategEdit] = useState();
  const [categ, setCateg] = useState("");
  const [selectedCatEdit, setSelectedCatEdit] = useState({});

  //search professionals
  const [searchValue, setSearchValue] = useState("");
  const [searchedProfessionals, setSearchedProfessionals] = useState([]);

  // edit category modal
  const [editCategoryModal, setEditCategoryModal] = useState(false);

  //select categories modal
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const handleAddTag = (newTag) => {
    if (tags?.length < 3) {
      setTags([...tags, newTag]);
    }
  };

  const handleRemoveTag = (removedTag) => {
    const updatedTags = tags?.filter((tag) => tag !== removedTag);
    setTags(updatedTags);
  };

  const handleCategoryChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value.includes("Select All")) {
      if (selectAllCategories) {
        setCategoryNames([]);
        setSelectAllCategories(false);
      } else {
        setCategoryNames(
          categoryArr?.map((cat) => ({ id: cat?.id, name: cat?.name }))
        );
        setSelectAllCategories(true);
      }
    } else {
      setCategoryNames(value.filter((cat) => cat?.name !== "Select All"));
      setSelectAllCategories(false);
    }
  };

  const handleProfessionalsChange = (e) => {
    const {
      target: { value },
    } = e;
    if (value.includes("Select All Professionals")) {
      if (selectAllProfessionals) {
        setProfessionalNames([]);
        setSelectAllProfessionals(false);
      } else {
        setProfessionalNames(
          allProfessionals?.map((prof) => ({ id: prof?.id, name: prof?.name }))
        );
        setSelectAllProfessionals(true);
      }
    } else {
      setProfessionalNames(
        value?.filter((prof) => prof?.name !== "Select All Professionals")
      );
      setSelectAllProfessionals(false);
    }
  };

  const getAllSpaProfessionals = async () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: SpaConfig.requestBy,
      service: "SPA",
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professionals",
        payload
      );
      const data = response?.data?.data?.professionals;
      setAllProfessionals(data);
    } catch (err) {}
  };

  const handleProfessionalSearch = async () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: SpaConfig.requestBy,
      service: "SPA",
      name: searchValue,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professionals",
        payload
      );
      const data = response?.data?.data?.professionals;
      setSearchedProfessionals(data);
    } catch (err) {}
  };

  const handleClearIconHide = () => {
    setSearchValue("");
    setSearchedProfessionals([]);
  };

  useEffect(() => {
    setSelectedCategories(categoryNames.map((cat) => cat?.id));
    setSelectedProfessionals(professionalNames.map((prof) => prof?.id));
    setSelectedTags(tags);
  }, [professionalNames, categoryNames, tags]);

  useEffect(() => {
    getAllSpaProfessionals();
  }, []);

  return (
    <div className={styles.mainDiv}>
      <AddCategoryServices
        open={isCategEdit ? editCategoryModal : addCategoryModal}
        onCloseModal={() => {
          if (isCategEdit) {
            setEditCategoryModal(false);
          } else {
            setAddCategoryModal(false);
          }
        }}
        categoryApi={handleGetActiveCategories}
        categoryArr={categoryArr}
        categoryOptions={categoryOptions}
        selectedCatEdit={selectedCatEdit}
        categ={categ}
        isCategEdit={isCategEdit}
      />
      <div className={styles.titleDiv}>
        <p>{heading}</p>
      </div>
      {/* --------Name of Spa, Category -------- */}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>Name of Spa Service</label>
          <span className={styles.star}>*</span>
          <br />
          <TextInput
            className={styles.inputText}
            name="name"
            placeholder="Enter Name"
            value={create?.name}
            onChange={handleCreate}
            disabled={isEdit}
            maxLength="30"
          />
        </div>
        <div>
          <label className={styles.labelFont}>Service Category</label>
          <span className={styles.star}>*</span>
          <br />
          <div className={styles.dropdownDivOuter}>
            <FormControl>
              <div
                style={{
                  width: "240px",
                  borderRadius: "4px",
                  height: "44px",
                }}
              >
                <Select
                  // style={{
                  //   width: "100%",
                  //   borderBottom: "none",
                  //   fontSize: "13px",
                  // }}
                  className={classes.customSelect}
                  labelId="category-label"
                  id="category-select"
                  multiple
                  open={openCategoryModal}
                  onOpen={() => {
                    setOpenCategoryModal(true);
                  }}
                  onClose={() => {
                    setOpenCategoryModal(false);
                  }}
                  value={categoryNames}
                  // onChange={handleCategoryChange}
                  renderValue={(selected) =>
                    selected.includes("selectAllCategories")
                      ? selected
                      : `${selected?.length} ${
                          selected?.length == 1
                            ? "category selected"
                            : "categories selected"
                        }`
                  }
                >
                  {categoryArr?.length > 0 && (
                    <MenuItem
                      sx={{ "&:hover": { backgroundColor: "#f4e0eb" } }}
                      style={{ padding: "4px 10px" }}
                      key="selectAllCategories"
                      value="selectAllCategories"
                    >
                      <Checkbox
                        classes={{
                          root: classes.root,
                        }}
                        checked={selectAllCategories}
                        onChange={(event) => {
                          const { checked } = event.target;
                          setSelectAllCategories(checked);
                          setCategoryNames(
                            checked
                              ? categoryArr?.map((cat) => ({
                                  id: cat?.id,
                                  name: cat?.name,
                                }))
                              : []
                          );
                        }}
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  )}
                  <div>
                    {categoryArr?.length > 0
                      ? categoryArr?.map((cat) => {
                          return (
                            <MenuItem
                              key={cat}
                              value={cat?.name}
                              sx={{ "&:hover": { backgroundColor: "#f4e0eb" } }}
                              style={{
                                padding: "0px 10px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <Checkbox
                                  classes={{
                                    root: classes.root,
                                  }}
                                  checked={categoryNames?.some(
                                    (item) => item?.id === cat?.id
                                  )}
                                  onChange={() =>
                                    handleCategoryChange({
                                      target: {
                                        value: categoryNames?.some(
                                          (item) => item?.id === cat?.id
                                        )
                                          ? categoryNames?.filter(
                                              (item) => item?.id !== cat?.id
                                            )
                                          : [
                                              ...categoryNames,
                                              { id: cat?.id, name: cat?.name },
                                            ],
                                      },
                                    })
                                  }
                                />
                                <label>{cat?.name}</label>
                              </div>
                              <div>
                                <button
                                  className={styles.radioBtnDiv}
                                  onClick={() => {
                                    setCateg(cat?.name);
                                    setIsCategEdit(true);
                                    setEditCategoryModal(true);
                                    setSelectedCatEdit(cat);
                                    setOpenCategoryModal(false);
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/editIcon.svg`}
                                    alt=""
                                  />
                                  <div style={{ color: "#690a3c" }}>Edit</div>
                                </button>
                              </div>
                            </MenuItem>
                          );
                        })
                      : "No categories available"}
                  </div>
                </Select>
              </div>
            </FormControl>
            <button
              className={styles.categoryBtn}
              onClick={() => {
                setIsCategEdit(false);
                setAddCategoryModal(true);
              }}
            >
              + Add New Category
            </button>
          </div>
        </div>
      </div>
      {/* -------------Description instructions-------------------- */}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>Synopsis</label>
          <span className={styles.star}>*</span>
          <br />
          <Textarea
            className={styles.inputTextInputArea}
            style={{ marginTop: "12px" }}
            // width={562}
            // height={152}
            name="description"
            placeholder="Enter Synopsis"
            value={create?.description}
            onChange={handleCreate}
            autoComplete="off"
            // maxLength="250"
          />
          {create?.description?.length <= 10000 ? (
            <div className={styles.textCountDiv}>
              {create?.description?.length}/10000
            </div>
          ) : (
            <div className={styles.mainOuterLimitDiv}>
              <div className={styles.maxCountText}>
                Exceeded maximum character length of 10000
              </div>
              <div className={styles.maxLimitCountNumber}>
                {create?.description?.length}/10000
              </div>
            </div>
          )}
        </div>
        <div>
          <label className={styles.labelFont}>Terms & Conditions</label>
          <br />
          <Textarea
            className={styles.inputTextInputArea}
            style={{ marginTop: "12px" }}
            placeholder="Enter Terms & Conditions"
            name="instructions"
            value={create?.instructions}
            onChange={handleCreate}
            autoComplete="off"
          />
          {create?.instructions?.length <= 10000 ? (
            <div className={styles.textCountDiv}>
              {create?.instructions?.length}/10000
            </div>
          ) : create?.instructions == null ? (
            <div className={styles.textCountDiv}>0/10000</div>
          ) : (
            <div className={styles.mainOuterLimitDiv}>
              <div className={styles.maxCountText}>
                Exceeded maximum character length of 10000
              </div>
              <div className={styles.maxLimitCountNumber}>
                {create?.instructions?.length}/10000
              </div>
            </div>
          )}
        </div>
      </div>
      {/* --------------Available days Additional benefits ------------------- */}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>Available Days</label>
          <span className={styles.star}>*</span>
          <br />
          <WeekCalender days={days} handleChangeDays={handleChangeDays} />
        </div>
        <div>
          <label className={styles.labelFont}>Additional Benefits</label>
          <br />
          <div className={styles.inputTagDiv}>
            <TagInput
              inputProps={{
                placeholder:
                  tags.length < 3 && "Type and press ENTER to add chips",
                disabled: tags.length >= 3,
                style: {
                  width: 10,
                  backgroundColor: "inherit",
                },
              }}
              values={tags}
              onChange={(tags) => setTags(tags)}
              onAdd={handleAddTag}
              onRemove={handleRemoveTag}
              className={styles.inputTag}
              tagProps={{
                style: {
                  height: 25,
                  fontWeight: 400,
                  fontSize: "12px",
                  backgroundColor: "#F8ECF3",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            />
          </div>
        </div>
      </div>
      {/* ------------------Start Time end time Intercom ---------------- */}
      <div className={styles.displayFlexDiv}>
        <div className={styles.timePickerMainDiv}>
          <div>
            <label className={styles.labelFont}>Start Time</label>
            <span className={styles.star}>*</span>
            <br />
            <div style={{ marginTop: "12px" }}>
              <TimePickerr
                timeName="startSalonTime"
                createEvent={create}
                handleTimePicker={handleTimePicker}
                customWidth={"100%"}
                isEdit={true}
              />
            </div>
          </div>
          <div>
            <label className={styles.labelFont}>End Time</label>
            <span className={styles.star}>*</span>
            <br />
            <div style={{ marginTop: "12px" }}>
              <TimePickerr
                timeName="endSalonTime"
                createEvent={create}
                handleTimePicker={handleTimePicker}
                customWidth={"100%"}
                isEdit={true}
              />
            </div>
          </div>
        </div>
        <div>
          <label className={styles.labelFont}>Mobile no. / Intercom</label>
          <span className={styles.star}>*</span>
          <br />
          <TextInput
            className={styles.inputText}
            type="mobile"
            name="intercomNo"
            value={create?.intercomNo}
            placeholder="Enter"
            onChange={handleCreate}
          />
        </div>
      </div>
      {/* --------------------Professionals ----------------------------------*/}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>Professionals</label>
          {/* <span className={styles.star}>*</span> */}
          <br />
          <div className={styles.dropdownDivProfessional}>
            <FormControl sx={{ width: 520 }}>
              <Select
                className={classes.customSelect}
                // labelId="demo-multiple-checkbox-label"
                // id="demo-multiple-checkbox"
                multiple
                value={professionalNames}
                // onChange={handleProfessionalsChange}
                renderValue={(selected) =>
                  selected.includes("selectAllprofessionals")
                    ? selected
                    : `${selected?.length} ${
                        selected?.length == 1
                          ? "professional selected"
                          : "professionals selected"
                      }`
                }
                MenuProps={MenuProps}
              >
                <div className={styles.filterLstMain}>
                  {/* <div className={styles.filterLst}>
                    <div className={styles.bookingSearch}>
                      <div className={styles.searchIcon}>
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
                        />
                      </div>
                      <input
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          handleProfessionalSearch(searchValue);
                        }}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            handleProfessionalSearch(searchValue);
                          }
                        }}
                      />
                      {!searchValue ? (
                        ""
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "20px",
                            width: "30px",
                            fontWeight: "400",
                            borderRadius: "0px 4px 4px 0px",
                          }}
                        >
                          <CancelOutlinedIcon
                            sx={{
                              height: "20px",
                              width: "20px",
                              color: "#74676E",
                            }}
                            onClick={() => handleClearIconHide()}
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className={styles.profListPaddingSelectAll}>
                    {allProfessionals?.length > 0 && (
                      <div className={styles.singleProfSelectAll}>
                        <MenuItem
                          sx={{ "&:hover": { backgroundColor: "transparent" } }}
                          style={{ paddingLeft: "0px" }}
                          key="selectAllprofessionals"
                          value="selectAllprofessionals"
                        >
                          <Checkbox
                            classes={{
                              root: classes.root,
                            }}
                            checked={selectAllProfessionals}
                            onChange={(event) => {
                              const { checked } = event.target;
                              setSelectAllProfessionals(checked);
                              setProfessionalNames(
                                checked
                                  ? allProfessionals?.map((prof) => ({
                                      id: prof?.id,
                                      name: prof?.name,
                                    }))
                                  : []
                              );
                            }}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      </div>
                    )}
                    {/* <div
                      className={styles.backLessThan}
                      onClick={() => {
                        if (selectAllProfessionals == true) {
                          setSearchValue("");
                          setSearchedProfessionals([]);
                          setProfessionalNames([]);
                          setSelectAllProfessionals(false);
                        }
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/backLessThan.svg`}
                        alt=""
                      />
                    </div> */}
                  </div>
                  <div className={styles.horizLine}></div>
                  <div className={styles.scrollDiv}>
                    {allProfessionals?.length > 0
                      ? (searchedProfessionals?.length > 0
                          ? searchedProfessionals
                          : allProfessionals && allProfessionals
                        )?.map((prof) => {
                          const filteredDays =
                            prof && prof?.timings && prof?.timings
                              ? Object.fromEntries(
                                  Object.entries(prof?.timings)?.filter(
                                    ([key, value]) => value === 1
                                  )
                                )
                              : {};
                          const filteredDayss = Object.keys(filteredDays)?.map(
                            (day) =>
                              day?.charAt(0)?.toUpperCase() + day?.slice(1)
                          );
                          return (
                            <div className={styles.profListPadding}>
                              <div className={styles.singleProf}>
                                <div>
                                  <MenuItem
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                    style={{ paddingLeft: "0px" }}
                                    key={prof}
                                    value={prof?.name}
                                  >
                                    <Checkbox
                                      classes={{
                                        root: classes.root,
                                      }}
                                      checked={professionalNames?.some(
                                        (item) => item?.id === prof?.id
                                      )}
                                      onChange={() => {
                                        handleProfessionalsChange({
                                          target: {
                                            value: professionalNames?.some(
                                              (item) => item?.id === prof?.id
                                            )
                                              ? professionalNames.filter(
                                                  (item) =>
                                                    item?.id !== prof?.id
                                                )
                                              : [
                                                  ...professionalNames,
                                                  {
                                                    id: prof?.id,
                                                    name: prof?.name,
                                                  },
                                                ],
                                          },
                                        });
                                      }}
                                    />
                                    <div
                                      onClick={() =>
                                        setExpandProf((prev) => !prev)
                                      }
                                    >
                                      <ListItemText primary={prof?.name} />
                                    </div>
                                  </MenuItem>
                                </div>
                                <div
                                  className={styles.filterRightIconDiv}
                                  onClick={() => setExpandProf((prev) => !prev)}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/leftSearch2.png`}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div>
                                {expandProf && (
                                  <div className={styles.profDetails}>
                                    <div
                                      className={styles.innerHorizLine}
                                    ></div>
                                    <div className={styles.profDetailsInnerDiv}>
                                      <div>Available Days:</div>
                                      <div>{filteredDayss?.join(", ")}</div>
                                    </div>
                                    <div className={styles.profDetailsInnerDiv}>
                                      <div>Timings:</div>
                                      <div>
                                        {prof?.start_time +
                                          " " +
                                          "-" +
                                          " " +
                                          prof?.end_time}
                                      </div>
                                    </div>
                                    <div className={styles.profDetailsInnerDiv}>
                                      <div>Services:</div>
                                      <div>
                                        {prof?.expertise == null
                                          ? "No service"
                                          : prof?.expertise}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : "No professionals available"}
                  </div>
                </div>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};
