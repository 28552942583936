import React from "react";
import styles from "./concierge.module.css";
import { ConciergeConfig } from "./ConciergeConfig";
import { TopHeader } from "../Components/TopHeaderNav/TopHeader";
import Helpdesk from "./Helpdesk/Helpdesk";

function Concierge() {
  return (
    <>
      <div
        className={`${styles.mainDivConceirge}  ${
          process.env.REACT_APP_PRODUCT !== "DLF" && styles.incresedLeftPdding
        }`}
      >
        <TopHeader
          title={"Concierge Management"}
          showBtn={true}
          btnTitle1={"Directory"}
          to1={ConciergeConfig.Directory}
        />
        <Helpdesk />
      </div>
    </>
  );
}

export default Concierge;
