import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonSmall } from "../../Components/Button/Buttons/ButtonSmall/ButtonSmall";
import {
  handleAccessibleToSlice,
  handleCalender,
  handleEndCalender,
  handleEventMonth,
  handleSearchSlice,
  handleStartCalender,
  handleStatusSlice,
  handlEventTypeSlice,
} from "../../Components/Redux/searchSlice";
import { EventConfig } from "../EventConfig";
import styles from "./topHeader.module.css";
import { toast } from "react-toastify";

export const TopHeader = ({ showBtn, title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventRights } = useSelector((state) => state.authSlice);

  const handleCreateButton = (path) => {
    dispatch(handleCalender(""));
    dispatch(handleEventMonth(""));
    dispatch(handleSearchSlice(""));
    dispatch(handlEventTypeSlice(""));
    dispatch(handleAccessibleToSlice(""));
    dispatch(handleStatusSlice(""));
    dispatch(handleStartCalender(""));
    dispatch(handleEndCalender(""));
    if (process.env.REACT_APP_PRODUCT == "DLF") {
      if (eventRights?.create == 1) {
        navigate(path);
      } else {
        toast.error("You don't have create access rights");
      }
    } else {
      navigate(path);
    }
  };

  const handleHistoryButton = (path) => {
    dispatch(handleCalender(""));
    dispatch(handleEventMonth(""));
    dispatch(handleSearchSlice(""));
    dispatch(handlEventTypeSlice(""));
    dispatch(handleAccessibleToSlice(""));
    dispatch(handleStatusSlice(""));
    dispatch(handleStartCalender(""));
    dispatch(handleEndCalender(""));
    navigate(path);
  };

  return (
    <div className={styles.topNavMain}>
      <h3>{title}</h3>
      {showBtn && (
        <div className={styles.buttonsDiv}>
          <ButtonSmall
            title={"Create Event"}
            buttonHandler={handleCreateButton}
            to={EventConfig.AddRequest}
          />
          <ButtonSmall
            title={"Booking History"}
            buttonHandler={handleHistoryButton}
            to={EventConfig.BookingHistory}
          />
        </div>
      )}
    </div>
  );
};
