import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState: {
    searchValue: "",
    loading: false,
    error: null,
    accessibleTo: "",
    eventType: "",
    status: "",
    eventDateCalender: "",
    startCalender: "",
    endCalender: "",
    membershipNo: "",
    eventMonth: "",
    imgValue: "",
    category: "",
    availableDays: [],
    startCalenderDate: "",
    endCalenderDate: "",
  },
  reducers: {
    handleSearchSlice: (state, action) => {
      state.searchValue = action.payload;
    },
    handleAccessibleToSlice: (state, action) => {
      state.accessibleTo = action.payload;
    },
    handlEventTypeSlice: (state, action) => {
      state.eventType = action.payload;
    },
    handleStatusSlice: (state, action) => {
      state.status = action.payload;
    },
    handleCalender: (state, action) => {
      state.eventDateCalender = action.payload;
    },
    handleStartCalender: (state, action) => {
      state.startCalender = action.payload;
    },
    handleEndCalender: (state, action) => {
      state.endCalender = action.payload;
    },
    handleMembershipNo: (state, action) => {
      state.membershipNo = action.payload;
    },
    handleEventMonth: (state, action) => {
      state.eventMonth = action.payload;
    },
    handleImg: (state, action) => {
      state.imgValue = action.payload;
    },
    handleCategorySlice: (state, action) => {
      state.category = action.payload;
    },
    handleAvailableDays: (state, action) => {
      state.availableDays = action.payload;
    },
    handleStartCalenderDate: (state, action) => {
      state.startCalenderDate = action.payload;
    },
    handleEndCalenderDate: (state, action) => {
      state.endCalenderDate = action.payload;
    },
  },
});
export const {
  handleSearchSlice,
  handleAccessibleToSlice,
  handleStatusSlice,
  handlEventTypeSlice,
  handleCalender,
  handleStartCalender,
  handleEndCalender,
  handleMembershipNo,
  handleEventMonth,
  handleImg,
  handleCategorySlice,
  handleAvailableDays,
  handleStartCalenderDate,
  handleEndCalenderDate,
} = searchSlice.actions;
export default searchSlice.reducer;
