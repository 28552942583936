import React from "react";
import Select from "react-select";

export const CustomSelectWithAdd = ({
  options,
  value,
  inputValue,
  onInputChange,
  onChange,
  padding = "4px",
  border = "1px solid #DEDFE1",
  isEdit = false,
  customStyles = {},
}) => {
  return (
    <Select
      isDisabled={isEdit}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: "1px solid #DEDFE1 !important",
          outline: "none",
          borderRadius: padding,
          boxShadow: "none",
          height: "40px",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        border: "none",
        outline: "none",
        colors: {
          ...theme.colors,
          primary25: `var(--bgColorActiveTab)`,
          primary: `var(--fontColorActiveTab1)`,
        },
      })}
      className="basic-single"
      classNamePrefix="select"
      value={value}
      onInputChange={onInputChange}
      onChange={onChange}
      inputValue={inputValue}
      placeholder="Select"
      options={options}
    />
  );
};
