import { TextInput, Textarea } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "../createEvent.module.css";
import { ImageUpload } from "../../../Components/ImageUpload/ImageUpload";
import { DateTimeRangeComponent } from "../../../Components/DateTimeRangeComponent/DateTimeRangeComponent";
import { CustomSelect } from "../../../Components/CustomSelect/CustomSelect";
import { CustomSelectWithAdd } from "../../../Components/CustomSelectWithAdd/CustomSelectWithAdd";
import CircularProgress from "@mui/material/CircularProgress";

export const EventDetails = ({
  isEdit,
  createEvent,
  setCreateEvent,
  handleCreateEventChange,
  handleCreateEventTypeChange,
  handleTimePicker,
  handleCalender,
  guest,
  setGuest,
  value,
  setValue,
  handleDateTimePicker,
  images0,
  setImages0,
  images1,
  setImages1,
  images2,
  setImages2,
  handleEventTypesChange,
  selectedEventTypes,
  eventTypes,
  handleSuitableAgeGroupsChange,
  selectedSuitableAgeGroups,
  suitableAgeGroups,
  handleVenueChange,
  handleCreateVenue,
  venues,
  setVenues,
  selectedVenue,
  setSelectedVenue,
  isVenueLoading,
  handleLanguageChange,
  languages,
  selectedLanguage,
  customStyles,
  eventStatus,
}) => {
  const handleGuestChange = (index, event) => {
    const { name, value } = event.target;
    const guestList = [...guest];
    guestList[index][name] = value;
    setGuest(guestList);
  };

  const handleGuestImage = (index, name, value) => {
    const guestList1 = [...guest];
    guestList1[index][name] = value[0]?.data_url;
    setGuest(guestList1);
  };

  const removeGuest = (index) => {
    if (index === 0) {
      setImages0(images1);
    } else if (index === 1) {
      setImages1(images2);
    } else if (index === 2) {
      setImages2([]);
    }
    const rows = [...guest];
    rows.splice(index, 1);
    setGuest(rows);
  };

  const handleAddGuest = () => {
    guest.length < 3 &&
      setGuest([
        ...guest,
        {
          guestName: "",
          data_url: "",
        },
      ]);
  };

  useEffect(() => {
    setCreateEvent({
      ...createEvent,
      guest: guest,
    });
  }, [guest]);

  // const [startdate, setStartdate] = useState(
  //   "Thu May 18 2023 15:14:46 GMT+0530"
  // ); /*  startdate  */

  // const [enddate, setEnddate] = useState(
  //   "Thu May 18 2023 15:14:46 GMT+0530"
  // ); /*  end tdate  */

  // const handleCallbackforClosingOn = (date) => {
  //   // setStartdate(moment(startDate[0]).format("YYYY-MM-DD"));
  //   // setEnddate(moment(startDate[1]).format("DD-MM-YYYY"));
  //   handleCalender(date);
  // };

  const [filteredOptions, setFilteredOptions] = useState(venues);
  const [inputValue, setInputValue] = useState("");

  // Update options based on search input
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    const matches = venues.filter((option) =>
      option.label.toLowerCase().includes(newValue.toLowerCase())
    );

    // If no matches, add a custom "Create" option
    if (matches.length === 0 && newValue) {
      setFilteredOptions([
        ...venues,
        {
          label: `Create\u00A0  \u00A0"${newValue}"`,
          value: newValue,
          isCreateOption: true,
        },
      ]);
    } else {
      setFilteredOptions(venues);
    }
  };

  // Handle selection of an option (including the "Create" option)
  const handleChange = (selectedOption) => {
    if (selectedOption?.isCreateOption) {
      handleCreateVenue(selectedOption.value);
    } else {
      handleVenueChange(selectedOption);
    }
    setInputValue(""); // Reset input value after selection
  };

  useEffect(() => {
    setFilteredOptions(venues);
    setInputValue("");
  }, [venues]);

  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>
        <p>Event Details</p>
      </div>

      {/* ---------------------Name of event------------------- */}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>Name of Event</label>
          <span className={styles.star}>*</span>
          <br />
          <TextInput
            className={styles.inputText}
            name="name"
            value={createEvent.name}
            onChange={handleCreateEventChange}
            autoComplete="off"
            maxLength="50"
            disabled={isEdit}
          />
        </div>

        {/* -------------------Venue------------------- */}
        <div>
          <div
            className={
              process.env.REACT_APP_PRODUCT != "DLF" &&
              styles.venueLabelContainer
            }
          >
            <label className={styles.labelFont}>Venue</label>
            {process.env.REACT_APP_PRODUCT == "DLF" && (
              <span className={styles.star}>*</span>
            )}
            {process.env.REACT_APP_PRODUCT != "DLF" && isVenueLoading && (
              <CircularProgress color="inherit" size={16} />
            )}
          </div>
          {process.env.REACT_APP_PRODUCT == "DLF" && <br />}
          {/* <TextInput
                className={styles.inputText}
                name="location"
                value={createEvent.location}
                onChange={handleCreateEventChange}
                autoComplete="off"
            /> */}
          <div style={{ marginTop: "12px" }}>
            {process.env.REACT_APP_PRODUCT == "DLF" ? (
              <CustomSelect
                options={venues}
                value={selectedVenue}
                name="venue"
                handleOnChangeDropdown={handleVenueChange}
                padding={"4px"}
                border={"none"}
                isEdit={isEdit}
              />
            ) : (
              <CustomSelectWithAdd
                options={filteredOptions}
                value={selectedVenue}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleChange}
                name="venue"
                padding="4px"
                border="none"
                isEdit={isEdit}
              />
            )}
          </div>
        </div>
      </div>

      {/* ---------------------Description-------------------------- */}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>
            {process.env.REACT_APP_PRODUCT === "DLF"
              ? "Synopsis"
              : "Description"}
          </label>
          <span className={styles.star}>*</span>
          <br />
          <Textarea
            className={styles.inputTextArea}
            name="description"
            value={createEvent.description}
            onChange={handleCreateEventChange}
            // maxlength="254"
          />
          {createEvent.description?.length <=
          (process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 700) ? (
            <div className={styles.textEventCountDiv}>
              {createEvent.description?.length}/
              {process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 700}
            </div>
          ) : (
            <div className={styles.eventMainOuterLimitDiv}>
              <div className={styles.eventMaxCountText}>
                Exceeded maximum character length of{" "}
                {process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 700}
              </div>
              <div className={styles.eventMaxLimitCountNumber}>
                {createEvent.description?.length}/
                {process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 700}
              </div>
            </div>
          )}
        </div>

        {/* --------------Instructions---------------- */}
        <div>
          <label className={styles.labelFont}>
            {" "}
            {process.env.REACT_APP_PRODUCT === "DLF"
              ? "Terms & Conditions"
              : "Instructions"}
          </label>
          <br />
          <Textarea
            className={styles.inputTextArea}
            name="instructions"
            value={createEvent.instructions}
            onChange={handleCreateEventChange}
            // maxlength="254"
          />
          {createEvent.instructions?.length <=
          (process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 500) ? (
            <div className={styles.textEventCountDiv}>
              {createEvent.instructions?.length}/
              {process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 500}
            </div>
          ) : createEvent.instructions == null ? (
            <div className={styles.textEventCountDiv}>
              0/{process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 500}
            </div>
          ) : (
            <div className={styles.eventMainOuterLimitDiv}>
              <div className={styles.eventMaxCountText}>
                Exceeded maximum character length of{" "}
                {process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 500}
              </div>
              <div className={styles.eventMaxLimitCountNumber}>
                {createEvent.instructions?.length}/
                {process.env.REACT_APP_PRODUCT === "DLF" ? 10000 : 500}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.displayFlexDiv}>
        {/* -----------------Event status-------------------- */}
        <div>
          <label className={styles.labelFont}>Event Status</label>
          <span className={styles.star}>*</span>
          <br />
          <div className={styles.radioMainDiv}>
            <span
              className={
                createEvent?.eventStatus === "OPEN"
                  ? styles.radioDivActive
                  : styles.radioDiv
              }
            >
              <input
                type="radio"
                width={300}
                height={44}
                name="eventStatus"
                value="OPEN"
                checked={createEvent?.eventStatus === "OPEN"}
                onChange={handleCreateEventChange}
              />
              <label className={styles.radioLabel}>Open</label>
            </span>
            {!isEdit && (
              <span
                className={
                  createEvent?.eventStatus === "COMING_SOON"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="eventStatus"
                  value="COMING_SOON"
                  checked={createEvent.eventStatus === "COMING_SOON"}
                  onChange={handleCreateEventChange}
                />
                <label className={styles.radioLabel}>Coming Soon</label>
              </span>
            )}
            {isEdit && (
              <span
                className={
                  createEvent.eventStatus === "CLOSE"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="eventStatus"
                  value="CLOSE"
                  checked={createEvent.eventStatus === "CLOSE"}
                  onChange={handleCreateEventChange}
                />
                <label className={styles.radioLabel}>Close</label>
              </span>
            )}
          </div>
        </div>

        {/* --------------Event type------------------- */}
        {process.env.REACT_APP_PRODUCT == "DLF" && (
          <div>
            <label className={styles.labelFont}>Event Type</label>
            <br />
            <div style={{ marginTop: "12px" }}>
              <CustomSelect
                options={eventTypes}
                value={selectedEventTypes}
                name="eventType"
                handleOnChangeDropdown={handleEventTypesChange}
                padding={"4px"}
                border={"none"}
                isEdit={isEdit}
              />
            </div>
            {/* </span>
              );
            })} 
           <span
              className={
                createEvent.eventType === "Party"
                  ? styles.radioDivActive
                  : styles.radioDiv
              }
            >
              <input
                type="radio"
                width={300}
                height={44}
                name="eventType"
                value="Party"
                checked={createEvent.eventType == "Party"}
                onChange={handleCreateEventChange}
              />
              <label className={styles.radioLabel}>Party</label>
            </span>
            <span
              className={
                createEvent.eventType === "Social Gathering"
                  ? styles.radioDivActive
                  : styles.radioDiv
              }
            >
              <input
                type="radio"
                width={300}
                height={44}
                name="eventType"
                value="Social Gathering"
                checked={createEvent.eventType == "Social Gathering"}
                onChange={handleCreateEventChange}
              />
              <label className={styles.radioLabel}>Social Gathering</label>
            </span>
            <span
              className={
                createEvent.eventType === "Other"
                  ? styles.radioDivActive
                  : styles.radioDiv
              }
            >
              <input
                type="radio"
                width={300}
                height={44}
                name="eventType"
                value="Other"
                checked={createEvent.eventType == "Other"}
                onChange={handleCreateEventChange}
              />
              <label className={styles.radioLabel}>Other</label>
            </span> */}
          </div>
        )}
        {/* --------allowBookingAfterStartTimePassed------- */}
        {process.env.REACT_APP_PRODUCT != "DLF" && (
          <div>
            {/* ------------------------------------ */}
            <div>
              <label className={styles.labelFont}>
                Allow Booking After Event Start
              </label>
              <span className={styles.star}>*</span>
              <br />
              <div className={styles.radioMainDiv}>
                <span
                  className={
                    createEvent?.allowBookingAfterStartTimePassed == 1
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    width={300}
                    height={44}
                    name="allowBookingAfterStartTimePassed"
                    value={1}
                    checked={createEvent?.allowBookingAfterStartTimePassed == 1}
                    onChange={handleCreateEventChange}
                  />
                  <label className={styles.radioLabel}>Yes</label>
                </span>

                <span
                  className={
                    createEvent?.allowBookingAfterStartTimePassed == 0
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    width={300}
                    height={44}
                    name="allowBookingAfterStartTimePassed"
                    value={0}
                    checked={createEvent?.allowBookingAfterStartTimePassed == 0}
                    onChange={handleCreateEventChange}
                  />
                  <label className={styles.radioLabel}>No</label>
                </span>
              </div>
            </div>
            <div></div>
          </div>
        )}
      </div>

      {/* --------------Start end time of event------------------- */}
      {/* <div className={styles.displayFlexDiv}>
                <div>
                    <label className={styles.labelFont}>Date of Event</label>
                    <span className={styles.star}>*</span>
                    <br />
                    <DateRangePicker format={('dd-MM-yyyy')}
                        //   defaultValue={[createEvent.startDate, createEvent.enddate]}

                        value={value}
                        onChange={setValue}
                        placeholder="Select Date Range"
                        disabledDate={beforeToday()}
                        // onChange={handleCallbackforClosingOn}
                        // value={[createEvent.startDate, createEvent.endDate]}
                        showOneCalendar
                        className={styles.dateRangePicker}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                    />
                </div>
                <div className={styles.timePickerMainDiv}>
                    <div>
                        <label className={styles.labelFont}>Start Time</label>
                        <span className={styles.star}>*</span>
                        <br />
                        <div style={{ marginTop: "12px" }}>
                            <TimePickerr timeName="startTime" createEvent={createEvent} setCreateEvent={setCreateEvent} handleTimePicker={handleTimePicker} customWidth={"19.5rem"} />
                        </div>
                    </div>
                    <div>
                        <label className={styles.labelFont}>End Time</label>
                        <br />
                        <div style={{ marginTop: "12px" }}>
                            <TimePickerr timeName="endTime" createEvent={createEvent} setCreateEvent={setCreateEvent} handleTimePicker={handleTimePicker} customWidth={"19.5rem"} />
                        </div>
                    </div>
                </div>
            </div> */}
      <div className={styles.displayFlexDiv}>
        <div>
          <label className={styles.labelFont}>Start Date/Time of Event</label>
          <span className={styles.star}>*</span>
          <br />
          <div style={{ marginTop: "12px" }}>
            <DateTimeRangeComponent
              Name="startDateTime"
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleDateTimePicker={handleDateTimePicker}
            />
          </div>
        </div>

        <div>
          <label className={styles.labelFont}>End Date/Time of Event</label>
          <span className={styles.star}>*</span>
          <br />
          <div style={{ marginTop: "12px" }}>
            <DateTimeRangeComponent
              Name="endDateTime"
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleDateTimePicker={handleDateTimePicker}
            />
          </div>
        </div>
      </div>

      {/* ------------------Special Guest------------------ */}
      <div className={styles.displayFlexDiv}>
        <div>
          <div className={styles.specialGuestLabel}>
            <label className={styles.labelFont}>Artist</label>
            {guest?.length < 3 && (
              <div className={styles.guestAdd} onClick={handleAddGuest}>
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addGuestIcon.png`}
                />
              </div>
            )}
          </div>
          <div>
            {guest?.map((data, index) => {
              // let tempName = images+${index}
              // let setTempName = setImages${index}
              let state, setState;
              if (index === 0) {
                state = images0;
                setState = setImages0;
              } else if (index === 1) {
                state = images1;
                setState = setImages1;
              } else if (index === 2) {
                state = images2;
                setState = setImages2;
              }
              return (
                <>
                  <div className={styles.ImgUploadGuestDiv}>
                    <div className={styles.guestImg}>
                      <ImageUpload
                        index={index}
                        name="data_url"
                        handleGuestImage={handleGuestImage}
                        images={state}
                        setImages={setState}
                      />
                    </div>
                    <TextInput
                      className={styles.guestInput}
                      placeholder="Enter Name"
                      // width={506}
                      height={44}
                      name="guestName"
                      value={data.guestName}
                      autoComplete="off"
                      onChange={(e) => handleGuestChange(index, e)}
                    />

                    {/* btn */}
                    {guest.length !== 1 ? (
                      <span
                        className={styles.removeIconDivSplGuest}
                        onClick={() => removeGuest(index)}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/vector.png`}
                          alt="removeIcon"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* -----------Language----------------------------- */}
        {process.env.REACT_APP_PRODUCT == "DLF" ? (
          <div>
            <label className={styles.labelFont}>Language</label>
            {process.env.REACT_APP_PRODUCT == "DLF" && (
              <span className={styles.star}>*</span>
            )}
            <br />
            {/* <TextInput
                className={styles.inputText}
                name="location"
                value={createEvent.location}
                onChange={handleCreateEventChange}
                autoComplete="off"
            /> */}
            <div style={{ marginTop: "12px" }}>
              <CustomSelect
                options={languages}
                value={selectedLanguage}
                name="language"
                handleOnChangeDropdown={handleLanguageChange}
                padding={"4px"}
                border={"none"}
                isEdit={isEdit}
                type={"language"}
                // customStyles={customStyles}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {/* -----------------Suitable for--------------- */}
      {process.env.REACT_APP_PRODUCT == "DLF" && (
        <div className={styles.displayFlexDiv}>
          <div>
            <label className={styles.labelFont}>Suitable for</label>
            <br />
            <div style={{ marginTop: "12px" }}>
              <CustomSelect
                options={suitableAgeGroups}
                value={selectedSuitableAgeGroups}
                name="suitableAgeGroups"
                handleOnChangeDropdown={handleSuitableAgeGroupsChange}
                padding={"4px"}
                border={"none"}
                // isEdit={isEdit}
              />
            </div>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
};
