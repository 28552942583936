import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";

export const getAllVenue = createAsyncThunk(
  "getAllVenue",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/venue/venue_list",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue(
        "Opps found an error in getVenueDetailsById",
        err.response.data
      );
    }
  }
);

//venue by id
export const getVenueDetailsbyId = createAsyncThunk(
  "getVenueDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/venue/venue_list/${id}`,
        {
          method: "POST",
          body: JSON.stringify({
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: "Admin",
          }),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue(
        "Opps found an error in getVenueDetailsById",
        err.response.data
      );
    }
  }
);

//bookingRequest
export const getBookingRequest = createAsyncThunk(
  "getBookingRequest",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/venue/venue_booking_history",
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

//bookingRequestbyId
export const getBookingRequestById = createAsyncThunk(
  "getBookingRequestById",
  async ({ args, venueActiveTab }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/venue/venue_booking_history/${venueActiveTab}`,
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

//bookingHistory
export const getBookingHistory = createAsyncThunk(
  "getBookingHistory",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/venue/venue_booking_history",
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const venueSlice = createSlice({
  name: "venueSlice",
  initialState: {
    venueList: [],
    venueDetails: {},
    venueActiveTab: "",
    bookingRequestVenue: [],
    totalBookingRequestCount: "",
    bookingHistoryVenue: [],
    totalBookingHistoryCount: "",
    loading: false,
    error: null,
    Loader: false,
  },
  reducers: {
    handleVenueActiveTab: (state, action) => {
      state.venueActiveTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVenue.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getAllVenue.fulfilled, (state, action) => {
        state.venueActiveTab = action?.payload?.data[0]?.id;
        state.venueList = action?.payload?.data;
        state.loading = false;
        state.Loader = false;
      })
      .addCase(getAllVenue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.Loader = false;
      })
      .addCase(getVenueDetailsbyId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getVenueDetailsbyId.fulfilled, (state, action) => {
        state.loading = false;
        state.venueDetails = action.payload?.data[0];
      })
      .addCase(getVenueDetailsbyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBookingRequest.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getBookingRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingRequestVenue = action.payload.data;
        state.totalBookingRequestCount = action.payload.total_records;
        state.Loader = false;
      })
      .addCase(getBookingRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.Loader = false;
      })
      .addCase(getBookingHistory.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryVenue = action.payload.data;
        state.totalBookingHistoryCount = action.payload.total_records;
        state.Loader = false;
      })
      .addCase(getBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.Loader = false;
      })
      .addCase(getBookingRequestById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBookingRequestById.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingRequestVenue = action.payload.data;
        state.totalBookingRequestCount = action.payload.total_records;
      })
      .addCase(getBookingRequestById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { handleVenueActiveTab } = venueSlice.actions;
export default venueSlice.reducer;
