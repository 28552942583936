import React from "react";
import styles from "./buttonBorder.module.css";

export const ButtonBorder = ({
  text,
  buttonHandler,
  width,
  isDisabled,
  height,
  fontWeight,
  lineHeight,
  fontSize,
}) => {
  const buttonStyle = {
    width: `${width}px`,
    height: `${height}px`,
    fontWeight: `${fontWeight}`,
    lineHeight: `${lineHeight}px`,
    fontSize: `${fontSize}px`,
  };
  return (
    <button
      disabled={isDisabled ? true : false}
      className={styles.btn}
      style={buttonStyle}
      onClick={buttonHandler}
    >
      {text}
    </button>
  );
};
