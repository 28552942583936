import { Routing } from "./Routing/Routing";
import "./App.css";
import "@fontsource/poppins";
import { ThemeSelector } from "./Theme/ThemeSelector";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function App() {
  const { community, adminName } = useSelector((state) => state.authSlice);

  useEffect(() => {
    document.getElementById("mainTitleLogo").href =
      process.env.REACT_APP_PRODUCT == "ACB"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconACB.png`
        : process.env.REACT_APP_PRODUCT === "PURAVANKARA"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconPurva.png`
        : process.env.REACT_APP_PRODUCT === "AURO"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconAuro.png`
        : process.env.REACT_APP_PRODUCT === "SIGNATURE"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconSignature.png`
        : process.env.REACT_APP_PRODUCT === "NUCLEUS"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconNucleus.png`
        : process.env.REACT_APP_PRODUCT === "MAHINDRA"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconMahindra.png`
        : process.env.REACT_APP_PRODUCT === "ALFUTTAIM"
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/faviconAlfuttaim.png`
        : `${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/favicon.png`;
    document.title =
      process.env.REACT_APP_PRODUCT == "AC"
        ? community + "-ANACITY IN"
        : process.env.REACT_APP_PRODUCT == "AURO"
        ? community + "-Auro Realty"
        : process.env.REACT_APP_PRODUCT == "ACB"
        ? community + "-ANACITY Business"
        : process.env.REACT_APP_PRODUCT == "SIGNATURE"
        ? community + "-Signature Global"
        : process.env.REACT_APP_PRODUCT == "PURAVANKARA"
        ? community + "-Purva Life"
        : process.env.REACT_APP_PRODUCT == "NUCLEUS"
        ? community + "-NUCLEUS"
        : process.env.REACT_APP_PRODUCT == "MAHINDRA"
        ? community + "-Mahindra Lifespaces"
        : process.env.REACT_APP_PRODUCT == "ALFUTTAIM"
        ? community + "-AL-Futtaim"
        : process.env.REACT_APP_PRODUCT == "DANUBE"
        ? community + "-Danube"
        : "Club Dashboard";
  }, [community]);

  return (
    <>
      <ThemeSelector>
        <Routing />
      </ThemeSelector>
    </>
  );
}

export default App;
