import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styles from "./AcBooking.module.css";
import { TopHeader } from "../../../Components/TopHeaderNav/TopHeader";
import { CustomSelect } from "../../../Components/CustomSelect/CustomSelect";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { useDispatch, useSelector } from "react-redux";
import { loadData } from "../../../Utils/localStorage";
import ImageUploading from "react-images-uploading";
import {
  checkIfBetweenDiscountPeriod,
  validateEmail,
  validatePhoneNumber,
} from "../../../Utils/Validation";
import { EventConfig } from "../../../Events/EventConfig";
import {
  eventFilter,
  getBookingHistoryById,
  getEventsDetailsbyId,
} from "../../../Components/Redux/eventsSlice";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { Textarea, TextInput } from "evergreen-ui";
import dayjs from "dayjs";
import { DateTimeRangeComponent } from "../../../Components/DateTimeRangeComponent/DateTimeRangeComponent";
import { stringify } from "uuid";
import { TimePickerr } from "../../../Components/TimePicker/TimePickerr";
import { IS_REGISTRATION_FLOW } from "../../../Utils/moduleConfig";
import { getCurrencySymbolByTimeZone } from "../../../Utils/currencySymbol";
export const AcBooking = ({
  open,
  onCloseModal,
  onOpen,
  failedStatus,
  setBookEventError,
}) => {
  const { eventsDetails, eventsActiveTab } = useSelector(
    (state) => state.eventsSlice
  );
  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(profileDetails?.comm_time_zone)
  const effectiveTimeZone = profileDetails.comm_time_zone || dayjs.tz.guess();

  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("event_id");
  const dispatch = useDispatch();
  const [memberData, setMemberData] = useState({
    name: "",
    contactNo: "",
    membershipNo: "",
    maxBookedSeatsPerMember: "",
    seats: "",
    amount: 0,
  });
  const [showGuest, setShowGuest] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [seatsData, setSeatsData] = useState([]);
  const [showMaxError, setShowMaxError] = useState(false);
  const [maxUserCanBook, setMaxUserCanBook] = useState();
  const [noOfSeatSelected, setnoOfSeatSelected] = useState(0);
  const [countedPrice, setCountedPrice] = useState(0);
  const [toastMessage, setToastMessage] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountAfterDis, setAmountAfterDis] = useState(0);
  const [btnStatus, setBtnStatus] = useState(false);
  const [memberBook, setMemberBook] = useState();
  const [disableBooking, setDisableBooking] = useState(false);
  const [seatError, setSeatError] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [tax, setTax] = useState([]);
  const [taxStructureId, setTaxStructureId] = useState("");
  const [roundingOff, setRoundingOff] = useState("none");
  const [amountGST, setAmountGST] = useState({
    totalAmount: 0.0,
  });
  const roundingMethods = {
    ceil: Math.ceil,
    floor: Math.floor,
    round: Math.round,
  };
  const amountUpdateFunc = (numOfTimesPrice) => {
    setMemberData({
      ...memberData,
      amount: numOfTimesPrice,
    });
  };

  const handleAdd = (cat, isGuest) => {
    setShowMaxError(false);
    const data = [...seatsData];
    const existingCount = data.reduce(
      (a, b) =>
        a +
        (b?.book_count +
          b?.waiting_count +
          b?.guest_book_count +
          b?.guest_waiting_count),
      0
    );
    const itemIndex = data.findIndex((item) => item?.name === cat);
    let left_seats =
      data[itemIndex]?.total_seats -
        data[itemIndex]?.booked_seats -
        data[itemIndex]?.book_count -
        data[itemIndex]?.guest_book_count >
      0
        ? data[itemIndex]?.total_seats -
          data[itemIndex]?.booked_seats -
          data[itemIndex]?.book_count -
          data[itemIndex]?.guest_book_count
        : data[itemIndex]?.waiting_seats -
          data[itemIndex]?.waiting_count -
          data[itemIndex]?.guest_waiting_count;
    if (existingCount < maxUserCanBook && left_seats > 0) {
      let seatsLeftToBeBooked =
        data[itemIndex]?.total_seats +
        data[itemIndex]?.waiting_seats +
        data[itemIndex]?.booked_seats;
      if (
        itemIndex !== -1 &&
        data[itemIndex]?.waiting_count +
          data[itemIndex]?.book_count +
          data[itemIndex]?.guest_waiting_count +
          data[itemIndex]?.guest_book_count <
          seatsLeftToBeBooked
      ) {
        if (
          data[itemIndex]?.total_seats -
            (data[itemIndex]?.booked_seats +
              data[itemIndex]?.book_count +
              data[itemIndex]?.guest_book_count) >
          0
        ) {
          if (isGuest) {
            data[itemIndex].guest_book_count += 1;
          } else {
            data[itemIndex].book_count += 1;
          }
        } else {
          if (isGuest) {
            data[itemIndex].guest_waiting_count += 1;
          } else {
            data[itemIndex].waiting_count += 1;
          }
        }
        setnoOfSeatSelected(existingCount + 1);
      }
    } else {
      if (existingCount >= maxUserCanBook) {
        setShowMaxError(true);
        setToastMessage(
          ` Can book maximum ${maxUserCanBook} ${
            maxUserCanBook == 1 ? "seat" : "seats"
          }`
        );
      }
      if (left_seats == 0) {
        setShowMaxError(true);
        setToastMessage(` Seats exhausted for selected category`);
      }
    }
    let numOfTimesPrice = data?.reduce((acc, el) => {
      return (
        acc +
        parseInt(el?.price * (el?.book_count + el?.waiting_count)) +
        parseInt(
          el?.guest_price * (el?.guest_book_count + el?.guest_waiting_count)
        )
      );
    }, 0);
    setCountedPrice(numOfTimesPrice);
    setSeatsData(data);
    amountUpdateFunc(numOfTimesPrice);
  };

  const handleSubtract = (cat, isGuest) => {
    setShowMaxError(false);
    const data = [...seatsData];
    const existingCount = data.reduce(
      (a, b) =>
        a +
        (b?.book_count +
          b?.waiting_count +
          b?.guest_book_count +
          b?.guest_waiting_count),
      0
    );
    const itemIndex = data.findIndex((item) => item.name === cat);
    if (existingCount >= 0) {
      if (
        itemIndex !== -1 &&
        data[itemIndex]?.guest_book_count +
          data[itemIndex].guest_waiting_count >
          0 &&
        isGuest
      ) {
        if (
          data[itemIndex]?.guest_book_count > 0 &&
          data[itemIndex]?.guest_waiting_count == 0
        ) {
          data[itemIndex].guest_book_count -= 1;
        } else {
          data[itemIndex].guest_waiting_count -= 1;
        }
        setnoOfSeatSelected(existingCount - 1);
      } else if (
        itemIndex !== -1 &&
        data[itemIndex]?.book_count + data[itemIndex].waiting_count > 0 &&
        !isGuest
      ) {
        if (
          data[itemIndex]?.book_count > 0 &&
          data[itemIndex]?.waiting_count == 0
        ) {
          data[itemIndex].book_count -= 1;
        } else {
          data[itemIndex].waiting_count -= 1;
        }
        setnoOfSeatSelected(existingCount - 1);
      }
    }
    let numOfTimesPrice = data?.reduce((acc, el) => {
      return (
        acc +
        parseInt(el?.price * (el?.book_count + el?.waiting_count)) +
        parseInt(
          el?.guest_price * (el?.guest_book_count + el?.guest_waiting_count)
        )
      );
    }, 0);
    setCountedPrice(numOfTimesPrice);
    setSeatsData(data);
    amountUpdateFunc(numOfTimesPrice);
  };
  const handleSeatsMemberPerEventCount = () => {
    const payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      member_id: selectedUnit.value,
      event_id: eventsDetails?.id,
    };
    fetch(process.env.REACT_APP_API_URL + "/api/event/member-booked-seats", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.success == true) {
          setMemberData({
            ...memberData,
            maxBookedSeatsPerMember: res?.data?.booked_seats,
          });
          let countOfMax =
            eventsDetails?.max_per_member_seats - res?.data?.booked_seats;
          setMaxUserCanBook(countOfMax);
        } else if (res.success == false) {
          toast(`User not found,search again`, {
            type: "error",
          });
        }
      })
      .catch((err) => {});
  };
  // ------------------------------member booked seats API - get the booked seats count----------------------
  useEffect(() => {
    open && handleSeatsMemberPerEventCount();
    open && selectedUnit && eventsDetails?.booking_type === "PAID" && loadTax();
  }, [selectedUnit]);
  const handleBookEventSubmit = () => {
    if (btnStatus) {
      return;
    }
    let eventsBookingData = [...seatsData];
    let bookingDetail = [];
    let guestBookingDetail = [];
    for (let i = 0; i < eventsBookingData?.length; i++) {
      if (
        eventsBookingData[i]["book_count"] +
          eventsBookingData[i]["waiting_count"] >
        0
      ) {
        let catBookData = {};
        catBookData["id"] = eventsBookingData[i]["id"];
        catBookData["booked"] =
          eventsBookingData[i]["book_count"] +
          eventsBookingData[i]["waiting_count"];
        bookingDetail.push(catBookData);
      }
    }
    //guest---------------
    for (let i = 0; i < eventsBookingData?.length; i++) {
      if (
        eventsBookingData[i]["guest_book_count"] +
          eventsBookingData[i]["guest_waiting_count"] >
        0
      ) {
        let catBookData = {};
        catBookData["id"] = eventsBookingData[i]["id"];
        catBookData["guest_booked"] =
          eventsBookingData[i]["guest_book_count"] +
          eventsBookingData[i]["guest_waiting_count"];
        guestBookingDetail.push(catBookData);
      }
    }
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      event_id: eventsDetails?.id,
      block_id: selectedBlock.label,
      ru_id: selectedUnit.label,
      ru_id_for_booking: selectedUnit.value,
      community_type: "AC_COMM",
      tax_structure_id:
        eventsDetails?.booking_type === "PAID" ? taxStructureId : null,
    };
    payload["is_member"] = true;
    payload["name"] = memberData?.name;
    payload["member_id"] = selectedUnit?.label;
    payload["mobile_no"] = memberData?.contactNo;

    if (eventsDetails?.has_seating_category == 1) {
      payload["has_seating"] = true;
      payload["seats"] = [...bookingDetail];
      if (guestBookingDetail.length > 0) {
        payload["guest_seats"] = [...guestBookingDetail];
      }
    }
    if (eventsDetails?.has_seating_category == 0) {
      payload["has_seating"] = false;
      payload["total_seats"] =
        eventsBookingData[0]?.book_count + eventsBookingData[0]?.waiting_count;
      if (
        eventsBookingData[0]?.guest_book_count +
          eventsBookingData[0]?.guest_waiting_count >
        0
      ) {
        payload["guest_total_seats"] =
          eventsBookingData[0]?.guest_book_count +
          eventsBookingData[0]?.guest_waiting_count;
      }
    }
    if (audienceForm?.length > 0) {
      let tempForm = audienceForm?.map((i, index) => {
        let field = {
          field_name: i?.label,
          field_type: i?.type,
          is_mandatory: i?.isMandatory,
          form_field_id: i?.formFieldId,
        };
        if (i?.type === "single_choice") {
          field.field_value = [i?.value] || "";
        } else if (i?.type === "dropdown") {
          field.field_value = i?.value?.label || "";
        } else if (i?.type === "checkbox") {
          field.field_value = i?.value?.join() || "";
        } else if (i?.type === "file") {
          field.field_value = i?.value[0]?.data_url || "";
        } else if (i?.type === "date_range") {
          field.field_value = i?.value1 || "";
          field.field_value_end = i?.value2 || "";
        } else {
          field.field_value = i?.value || "";
        }
        return field;
      });
      payload["form_data"] = tempForm;
    }

    let seatingCouunt;
    let guestSeatingCouunt;
    if (eventsDetails?.has_seating_category == 1) {
      seatingCouunt = payload?.seats?.reduce((acc, el) => {
        return acc + parseInt(el?.booked);
      }, 0);
      guestSeatingCouunt = payload?.guest_seats?.reduce((acc, el) => {
        return acc + parseInt(el?.guest_booked);
      }, 0);
    } else {
      seatingCouunt = payload?.total_seats;
      guestSeatingCouunt =
        eventsBookingData[0]?.guest_book_count +
        eventsBookingData[0]?.guest_waiting_count;
    }

    // if (
    //   eventsDetails?.total_seats <=
    //   parseInt(eventsDetails?.max_per_member_seats)
    // ) {
    //   toast.error("Maximum booking per user limit reached !");
    //   setDisableBooking(true);
    // }

    if (Object.keys(selectedBlock)?.length <= 0 || selectedBlock?.value == "") {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Please select Tenant"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Please select Tenant"
            : "Please select Block"
        }`
      );
    } else if (
      Object.keys(selectedUnit)?.length <= 0 ||
      selectedUnit?.value == ""
    ) {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Please select Occupant"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Please select Employee"
            : "Please select unit no."
        }`
      );
    } else if (
      memberBook == true &&
      memberData?.maxBookedSeatsPerMember >= eventsDetails?.max_no_seats
    ) {
      toast.error("Max Booking Limit per member reached");
    } else if (seatingCouunt + guestSeatingCouunt < 1) {
      toast.error("No. of seats booked cannot be zero");
    } else if (audienceForm?.length > 0 && dynamicFormError) {
      let validationErr = false;
      audienceForm?.map((field, index) => {
        if (validationErr) {
          return;
        }
        const { label, isMandatory, options, type, value, value1, value2 } =
          field;

        // Check for mandatory fields
        if (field.type == "date_range") {
          if (isMandatory == 1 && (!value1 || !value2)) {
            toast(`${label} cannot be empty`, {
              type: "error",
            });
            validationErr = true;
            return;
          }
        } else if (field.type == "checkbox") {
          if (isMandatory == 1 && value.length < 1) {
            toast(`${label} cannot be empty`, {
              type: "error",
            });
            validationErr = true;
            return;
          }
        } else if (isMandatory == 1 && !value) {
          toast(`${label} cannot be empty`, {
            type: "error",
          });
          validationErr = true;
          return;
        }
      });
      if (!validationErr) {
        setBtnStatus(true);
        handleBookAdmin(payload);
      }
    } else {
      setBtnStatus(true);
      handleBookAdmin(payload);
    }
  };
  const handleBookAdmin = (payload) => {
    fetch(process.env.REACT_APP_API_URL + "/api/event/book-event-admin", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setMemberData({
            name: "",
            membershipNo: "",
            contactNo: "",
            maxBookedSeatsPerMember: "",
            seats: "",
            amount: 0,
          });
          dispatch(getEventsDetailsbyId(eventsDetails?.id));
          let args = {
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: EventConfig.requestBy,
            event_id: eventsActiveTab,
            limit: 4,
            offset: 0,
          };
          dispatch(getBookingHistoryById({ args, eventsActiveTab }));
          setSeatError(false);
          onCloseModal();
          onOpen();
          setBtnStatus(false);
        } else {
          setBtnStatus(false);
          setBookEventError(res.message);
          let payload = {
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: EventConfig.requestBy,
          };
          failedStatus();
          setTimeout(() => {
            dispatch(eventFilter({ args: payload, paramId: eventId }));
          }, 2000);
        }
      })
      .catch((err) => {
        setBtnStatus(false);
      });
  };
  //for setting data in seats array-----------------------
  //for loading Units
  const loadBlock = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/block/get-blocks`, {
      method: "POST",
      body: JSON.stringify({
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        const blockOptions = res?.data?.map((block) => ({
          value: block?.block_id,
          label: block?.name,
        }));
        setBlocks(blockOptions);
      });
  };
  const loadUnit = (value) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/unit/get-units`, {
      method: "POST",
      body: JSON.stringify({
        block_id: value,
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        setSelectedUnit("");
        const unitOptions = res?.data?.map((unit) => ({
          value: unit?.ru_id,
          label: unit?.ru_num,
        }));
        setUnits(unitOptions);
      });
  };
  const handleBlockChange = (selectedOption) => {
    setMemberData({
      name: "",
      membershipNo: "",
      contactNo: "",
      maxBookedSeatsPerMember: "",
      seats: "",
      amount: 0,
    });
    setTotalAmount(0);
    setSelectedBlock(selectedOption);
    loadUnit(selectedOption?.value);
    setTaxStructureId("");
    setTax([]);
  };
  const handleUnitChange = (selectedOption) => {
    setMemberData({
      name: "",
      membershipNo: "",
      contactNo: "",
      maxBookedSeatsPerMember: "",
      seats: "",
      amount: 0,
    });
    setTotalAmount(0);
    setSelectedUnit(selectedOption);
  };
  //Tax GST SGST-------------------------------------------------------------
  const loadTax = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get_event_gst_details`, {
      method: "POST",
      body: JSON.stringify({
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
        ru_id: selectedUnit.value,
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success === true) {
          setTax(res?.data?.event);
          setTaxStructureId(res?.data?.tax_structure_id);
          setRoundingOff(res?.data?.rounding_off_val);
        }
      });
  };
  const calculateGST = (amount, gst) => {
    const gstRates = gst.reduce((acc, curr) => {
      acc[curr.tax_name] = parseFloat(curr.tax_rate);
      return acc;
    }, {});
    const roundFunction = roundingMethods[roundingOff];
    const amountNum = parseFloat(amount);
    let tempAmountGst = {};
    Object.keys(gstRates).map((i, index) => {
      let temp =
        roundingOff == "none"
          ? (amountNum * gstRates[i]) / 100
          : roundFunction((amountNum * gstRates[i]) / 100);
      tempAmountGst[i] = temp;
    });
    let totalAmount = Object.keys(tempAmountGst).reduce(
      (acc, curr) => acc + tempAmountGst[curr],
      amountNum
    );
    totalAmount =
      roundingOff == "none" ? totalAmount : roundFunction(totalAmount);
    tempAmountGst.totalAmount = totalAmount;
    setAmountGST(tempAmountGst);
  };
  //-------------------------Dynaic Form---------------------------------------
  let dynamicFormError = true;
  const [audienceForm, setAudienceForm] = useState();

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e?.target;
    let list = [...audienceForm];
    if (type === "checkbox") {
      const optionValue = value;
      if (checked) {
        list[index].value = [...(list[index].value || []), optionValue]; // add checked value
      } else {
        list[index].value = list[index].value.filter((v) => v !== optionValue); // remove unchecked value
      }
    } else {
      list[index].value = value;
    }

    setAudienceForm(list);
  };
  const handleDropdown = (e, index) => {
    let list = [...audienceForm];
    list[index].value = e;
    setAudienceForm(list);
  };
  const handleDateTime = (e, index, name) => {
    let list = [...audienceForm];
    list[index].value = e;
    setAudienceForm(list);
  };
  const handleDateRange = (e, index, name) => {
    let list = [...audienceForm];
    if (name == "value1") {
      list[index][name] = e;
      list[index].value2 = e;
    } else {
      list[index][name] = e;
    }
    setAudienceForm(list);
  };
  const maxNumber = 1;
  const onChange = (imageList, index) => {
    let list = [...audienceForm];
    list[index].value = imageList;
    setAudienceForm(list);
  };

  const onRemoveImage = (index) => {
    let list = [...audienceForm];
    list[index].value = "";
    setAudienceForm(list);
  };

  const getSelecteFormDetailApi = (formId) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      form_id: formId,
      request_from: "event_audience",
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/event/get_form_details_by_id`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setAudienceForm(
            res?.data
              ?.filter((item) => item?.is_visible && item?.is_deleted == 0)
              ?.map((i, index) => {
                let field = {
                  label: i?.field_name,
                  type: i?.field_type,
                  fieldOrder: i?.event_form_id,
                  isMandatory: i?.is_mandatory,
                  options: i?.options?.map((j, index) => {
                    return {
                      label: j?.label,
                      value: i?.field_type === "checkbox" ? j?.label : j?.id,
                    };
                  }),
                  isVisible: i?.is_visible,
                  isDelete: i?.is_deleted,
                  formFieldId: i?.form_field_id,
                };
                // Handle date types (date, date_time, time)
                if (["date", "date_time", "time"].includes(i?.field_type)) {
                  field.value = dayjs();
                }
                // Handle date_range type
                else if (i?.field_type === "date_range") {
                  field.value1 = dayjs();
                  field.value2 = dayjs();
                } else if (i?.field_type === "checkbox") {
                  field.value = [];
                } else {
                  field.value = "";
                }
                return field;
              })
          );
        }
      })
      .catch((err) => {
        console.error("API response error:", err.message || "Unknown error");
      });
  };

  //-----------------------------------------------------------------
  //----------------------------------------------------------
  useEffect(() => {
    let total = seatsData?.reduce((accumulator, item) => {
      accumulator += item.book_count + item.guest_book_count;
      return accumulator;
    }, 0);
    setTotalCount(total);
  }, [seatsData]);
  let totalAmountNew;
  let amountAfterDisNew;
  //for setting amount
  useEffect(() => {
    totalAmountNew = memberData?.amount;
    setTotalAmount(totalAmountNew);
    amountAfterDisNew = eventsDetails?.event_offer?.discount_percentage
      ? memberData?.amount -
        (memberData?.amount * eventsDetails?.event_offer?.discount_percentage) /
          100
      : memberData?.amount;
    setAmountAfterDis(amountAfterDisNew);
  }, [handleAdd, handleSubtract]);
  useEffect(() => {
    checkIfBetweenDiscountPeriod(
      eventsDetails?.event_offer?.offer_start_date,
      eventsDetails?.event_offer?.offer_end_date,
      eventsDetails?.event_offer?.offer_start_time,
      eventsDetails?.event_offer?.offer_end_time
    )
      ? calculateGST(amountAfterDis, tax)
      : calculateGST(totalAmount, tax);
  }, [totalAmount]);
  useEffect(() => {
    loadBlock();
    IS_REGISTRATION_FLOW &&
      getSelecteFormDetailApi(eventsDetails?.audience_form_id);
    let seatingDetails = [];
    if (eventsDetails?.has_seating_category == 1) {
      seatingDetails = eventsDetails?.event_seats?.map((seat) => {
        return {
          event_id: seat?.event_id,
          name: seat?.name,
          price: seat?.price,
          guest_price: seat?.guest_price,
          book_count: 0,
          waiting_count: 0,
          guest_book_count: 0,
          guest_waiting_count: 0,
          id: seat?.id,
          booked_seats: eventsDetails?.seats_booked[seat?.name]?.booked_seats,
          total_seats: eventsDetails?.seats_booked[seat?.name]?.total_seats,
          waiting_seats: eventsDetails?.seats_booked[seat?.name]?.waiting_seats,
        };
      });
    } else if (eventsDetails?.has_seating_category == 0) {
      seatingDetails = [
        {
          event_id: eventsDetails?.id,
          name: "Seat",
          price: eventsDetails?.price,
          guest_price: eventsDetails?.guest_price,
          book_count: 0,
          waiting_count: 0,
          guest_book_count: 0,
          guest_waiting_count: 0,
          booked_seats: eventsDetails?.seats_booked?.booked_seats,
          total_seats: eventsDetails?.seats_booked?.total_seats,
          waiting_seats: eventsDetails?.seats_booked?.waiting_seats,
        },
      ];
    }
    setSeatsData([...seatingDetails]);
    setShowMaxError(false);
    eventsDetails?.booking_type === "PAID" &&
      setAmountGST({
        totalAmount: 0.0,
      });
  }, [open, selectedUnit, selectedBlock]);
  // -------------------------------------------------
  useEffect(() => {
    setShowGuest(false);
    setMemberData({
      name: "",
      membershipNo: "",
      contactNo: "",
      maxBookedSeatsPerMember: "",
      seats: "",
      amount: 0,
    });
    setSelectedBlock("");
    setSelectedUnit("");
    setShowMaxError(false);
    setTaxStructureId("");
    setTax([]);
  }, [onCloseModal]);
  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseModal}
        showCloseIcon={true}
        center
        classNames={{
          modal: styles.customModal,
        }}
      >
        <div>
          <div className={styles.navDiv}>
            <TopHeader title={"Book Event"} />
          </div>
          <div className={styles.mainDiv}>
            <h2 className={styles.heading}>Basic Details</h2>
            <div className={styles.flexDiv}>
              <div className={styles.fieldsDiv}>
                <label className={styles.label}>
                  {" "}
                  {profileDetails?.comm_type == "Federation Parent Community"
                    ? "Tenant"
                    : profileDetails?.comm_type == "Federation Child Community"
                    ? "Tenant"
                    : "Block"}
                </label>
                <span style={{ color: "#FF443A" }}>*</span>
                <div className={styles.dropDownDiv}>
                  <CustomSelect
                    padding={"4px 5px 4px 4px"}
                    border={"1px solid #DEDFE1"}
                    options={blocks}
                    value={selectedBlock}
                    name="block"
                    handleOnChangeDropdown={handleBlockChange}
                    // isEdit={isEdit}
                  />
                </div>
              </div>
              <div className={styles.fieldsDiv}>
                <label className={styles.label}>
                  {profileDetails?.comm_type == "Federation Parent Community"
                    ? "Occupant"
                    : profileDetails?.comm_type == "Federation Child Community"
                    ? "Employee"
                    : "Unit no."}
                </label>
                <span style={{ color: "#FF443A" }}>*</span>
                <div className={styles.dropDownDiv}>
                  <CustomSelect
                    padding={"4px 5px 4px 4px"}
                    border={"1px solid #DEDFE1"}
                    options={units}
                    value={selectedUnit}
                    name="unit"
                    handleOnChangeDropdown={handleUnitChange}
                  />
                </div>
              </div>
            </div>
            {/* ---------------Seats-------------------- */}
            <h2 className={styles.heading} style={{ marginTop: "32px" }}>
              Select Seats
            </h2>
            <div className={styles.seatMainDiv}>
              <label className={styles.label} style={{ marginLeft: "16px" }}>
                For members
              </label>
              <span style={{ color: "#FF443A" }}>*</span>
              <div className={styles.flexSeatDiv}>
                {seatsData?.map((ele, index) => {
                  return (
                    <div className={styles.counterMainDiv}>
                      <div>
                        <p className={styles.label}>{ele?.name}</p>
                        <p className={styles.label}>
                          {process.env.REACT_APP_PRODUCT === "ALFUTTAIM"
                            ? "AED"
                            : currencySymbol}{" "}
                          {ele?.price} per seat
                        </p>
                      </div>
                      <div>
                        <div className={styles.counterDiv}>
                          <div
                            className={styles.addSubtDiv}
                            onClick={() => {
                              handleSubtract(ele.name, false);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/subtractIcon.png`}
                            />
                          </div>
                          <div className={styles.numberDiv}>
                            {ele?.waiting_count + ele?.book_count}
                          </div>
                          <div
                            className={styles.addSubtDiv}
                            onClick={() => {
                              handleAdd(ele.name, false);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addIcon.png`}
                            />
                          </div>
                        </div>
                        <div className={styles.leftWaitingTotal}>
                          <span className={styles.colorPurple}>
                            {ele?.total_seats -
                              ele?.booked_seats -
                              ele?.book_count -
                              ele?.guest_book_count >
                            0
                              ? ele?.total_seats -
                                ele?.booked_seats -
                                ele?.book_count -
                                ele?.guest_book_count
                              : ele?.waiting_seats -
                                ele?.waiting_count -
                                ele?.guest_book_count}
                          </span>{" "}
                          {ele?.waiting_count + ele?.guest_waiting_count >= 0 &&
                          ele?.total_seats - ele?.booked_seats ==
                            ele?.book_count + ele?.guest_book_count
                            ? "Left"
                            : "Left"}{" "}
                          <span className={styles.colorBlack}>
                            / {ele.total_seats}
                          </span>{" "}
                          Total
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* -----------Guest--------------------- */}
              {showGuest && (
                <>
                  <label
                    className={styles.label}
                    style={{ marginLeft: "16px" }}
                  >
                    For guest
                  </label>
                  <span style={{ color: "#FF443A" }}>*</span>
                  <div className={styles.flexSeatDiv}>
                    {seatsData?.map((ele, index) => {
                      return (
                        <div className={styles.counterMainDiv}>
                          <div>
                            <p className={styles.label}>{ele?.name}</p>
                            <p className={styles.label}>
                              {currencySymbol} {ele?.guest_price} per seat
                            </p>
                          </div>
                          <div>
                            <div className={styles.counterDiv}>
                              <div
                                className={styles.addSubtDiv}
                                onClick={() => {
                                  handleSubtract(ele.name, true);
                                }}
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/subtractIcon.png`}
                                />
                              </div>
                              <div className={styles.numberDiv}>
                                {ele?.guest_waiting_count +
                                  ele?.guest_book_count}
                              </div>
                              <div
                                className={styles.addSubtDiv}
                                onClick={() => {
                                  handleAdd(ele.name, true);
                                }}
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addIcon.png`}
                                />
                              </div>
                            </div>
                            <div className={styles.leftWaitingTotal}>
                              <span className={styles.colorPurple}>
                                {ele?.total_seats -
                                  ele?.booked_seats -
                                  ele?.book_count -
                                  ele?.guest_book_count >
                                0
                                  ? ele?.total_seats -
                                    ele?.booked_seats -
                                    ele?.book_count -
                                    ele?.guest_book_count
                                  : ele?.waiting_seats -
                                    ele?.waiting_count -
                                    ele?.guest_waiting_count}
                              </span>{" "}
                              {ele?.waiting_count + ele?.guest_waiting_count >=
                                0 &&
                              ele?.total_seats - ele?.booked_seats ==
                                ele?.book_count + ele?.guest_book_count
                                ? "Left"
                                : "Left"}{" "}
                              <span className={styles.colorBlack}>
                                / {ele.total_seats}
                              </span>{" "}
                              Total
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {!showGuest &&
                ((eventsDetails?.has_seating_category == 1 &&
                  eventsDetails?.guest_price_status == 0) ||
                  (eventsDetails?.has_seating_category == 0 &&
                    eventsDetails?.guest_no_category == 0)) && (
                  <button
                    className={styles.cancellationAllowedBtn}
                    onClick={() => setShowGuest(true)}
                  >
                    Add Guest +
                  </button>
                )}
            </div>
            <div className={styles.dynamicFormDiv}>
              {audienceForm?.map((item, index) => {
                if (item?.type === "text") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <TextInput
                        className={styles.inputText}
                        value={item?.value}
                        onChange={(e) => handleChange(e, index)}
                        autoComplete="off"
                      />
                    </div>
                  );
                } else if (item?.type === "numeric") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <TextInput
                        className={styles.inputText}
                        type={"number"}
                        value={item?.value}
                        onChange={(e) => handleChange(e, index)}
                        autoComplete="off"
                      />
                    </div>
                  );
                } else if (
                  item?.type === "text_area" ||
                  item?.type === "long_text"
                ) {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <Textarea
                        className={styles.inputTextArea}
                        value={item?.value}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                  );
                } else if (item?.type === "dropdown") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <div style={{ marginTop: "12px" }}>
                        <CustomSelect
                          options={item?.options}
                          value={item?.value}
                          name="type"
                          handleOnChangeDropdown={(e) => {
                            handleDropdown(e, index);
                          }}
                          padding={"4px"}
                          border={"none"}
                        />
                      </div>
                    </div>
                  );
                } else if (item.type === "single_choice") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <div className={styles.dynamicRadioDiv}>
                        {item.options.map((option, i) => (
                          <span
                            key={i}
                            className={
                              item.value == option.value
                                ? styles.radioDivActive
                                : styles.radioDiv
                            }
                          >
                            <input
                              type="radio"
                              name={item.label}
                              value={option.value}
                              checked={item.value == option.value}
                              onChange={(e) => handleChange(e, index)}
                              className={styles.radioInput}
                              width={300}
                              height={44}
                            />

                            <label className={styles.radioLabel}>
                              {option.label}
                            </label>
                          </span>
                        ))}
                      </div>
                    </div>
                  );
                } else if (item.type === "checkbox") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <div className={styles.dynamicRadioDiv}>
                        {item.options.map((option, i) => (
                          <span
                            key={i}
                            className={
                              item.value?.includes(String(option.value))
                                ? styles.radioDivActive
                                : styles.radioDiv
                            }
                          >
                            <input
                              type="checkbox"
                              name={item.label}
                              value={option.value}
                              checked={
                                item.value?.includes(String(option.value)) ||
                                false
                              }
                              onChange={(e) => handleChange(e, index)}
                              className={styles.radioInput}
                              width={300}
                              height={44}
                            />

                            <label className={styles.radioLabel}>
                              {option.label}
                            </label>
                          </span>
                        ))}
                      </div>
                    </div>
                  );
                } else if (item?.type === "time") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <div className={styles.dateDiv}>
                        <TimePickerr
                          timeName={item?.label}
                          handleTimePicker={(e) => handleDateTime(e, index)}
                          customWidth={"100%"}
                          value={item?.value || dayjs()}
                          dynamicValue={true}
                        />
                      </div>
                    </div>
                  );
                } else if (item?.type === "date") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <div className={styles.dateDiv}>
                        <DateTimeRangeComponent
                          Name={item?.label}
                          handleDateTimePicker={(e) => handleDateTime(e, index)}
                          showOnlyDate={true}
                          value={item?.value || dayjs()}
                          dynamicValue={true}
                        />
                      </div>
                    </div>
                  );
                } else if (item?.type === "date_time") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <div className={styles.dateDiv}>
                        <DateTimeRangeComponent
                          Name={item?.label}
                          value={item?.value || dayjs()}
                          handleDateTimePicker={(e) => handleDateTime(e, index)}
                          dynamicValue={true}
                        />
                      </div>
                    </div>
                  );
                } else if (item?.type === "date_range") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <div className={styles.dateRangeDiv}>
                        <div>
                          <DateTimeRangeComponent
                            Name={item?.label}
                            handleDateTimePicker={(e) =>
                              handleDateRange(e, index, "value1")
                            }
                            showOnlyDate={true}
                            value={item?.value1}
                            dynamicValue={true}
                          />
                        </div>
                        <div>
                          <DateTimeRangeComponent
                            Name={item?.label}
                            handleDateTimePicker={(e) =>
                              handleDateRange(e, index, "value2")
                            }
                            showOnlyDate={true}
                            value={item?.value2}
                            minDate={item?.value1}
                            dynamicValue={true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                } else if (item?.type === "file") {
                  return (
                    <div className={styles.fieldDiv} key={index}>
                      <label className={styles.label}>{item?.label}</label>
                      {item?.isMandatory ? (
                        <span className={styles.star}>*</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <div className={styles.ImgUploadMainDiv}>
                        <ImageUploading
                          multiple={false}
                          value={item?.value}
                          onChange={(imageList) => onChange(imageList, index)}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["jpg", "jpeg", "png"]}
                          // maxFileSize="3500000"
                          // resolutionWidth={900}
                          // resolutionHeight={440}
                          resolutionType="absolute"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                            errors,
                          }) => (
                            <div className={styles.uploadImgDiv}>
                              <div className={styles.previewDiv}>
                                {imageList.map((image, idx) => (
                                  <div
                                    key={idx}
                                    className={styles.imgIconDiv}
                                    onClick={() => onImageRemove(idx)}
                                  >
                                    <div
                                      className={styles.closeIcon}
                                      onClick={() => {
                                        onRemoveImage(index);
                                      }}
                                    >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/cancelEventActive.png`}
                                        alt="removeIcon"
                                      />
                                    </div>
                                    <img src={image.data_url} alt="" />
                                  </div>
                                ))}
                                {item?.value?.length < 1 && (
                                  <div className={styles.ImageDiv}>
                                    <div className={styles.imgIconDiv}>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/ImageUpload.png`}
                                      />
                                    </div>
                                    <p>
                                      <span style={{ color: "#121212" }}>
                                        Upload Image
                                      </span>
                                      {/* <span>(Less than 3.5mb)</span> */}
                                    </p>
                                  </div>
                                )}
                              </div>

                              {item?.value?.length < 1 && (
                                <button
                                  onClick={onImageUpload}
                                  className={styles.browseBtn}
                                >
                                  Browse
                                </button>
                              )}
                              {errors?.maxFileSize && (
                                <span className={styles.error}>
                                  Selected file size exceed Max File Size
                                </span>
                              )}
                              {errors?.acceptType && (
                                <span className={styles.error}>
                                  Selected file type is not allowed
                                </span>
                              )}
                              {errors?.resolution && (
                                <span className={styles.error}>
                                  Selected file does not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div>
            {showMaxError && <p className={styles.seatError}>{toastMessage}</p>}
          </div>
          <div className={styles.btnDiv}>
            <div>
              <p className={styles.rupee}>
                {eventsDetails?.booking_type == "FREE" ? (
                  "FREE"
                ) : checkIfBetweenDiscountPeriod(
                    eventsDetails?.event_offer?.offer_start_date,
                    eventsDetails?.event_offer?.offer_end_date,
                    eventsDetails?.event_offer?.offer_start_time,
                    eventsDetails?.event_offer?.offer_end_time,
                    effectiveTimeZone
                  ) ? (
                  <p className={styles.amount}>
                    {(
                      amountAfterDis +
                      (amountAfterDis * eventsDetails?.tax) / 100
                    ).toFixed(2)}
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#868686",
                        textDecoration: "line-through",
                      }}
                    >
                      {currencySymbol} {totalAmount.toFixed(2)}
                    </span>
                    <span style={{ fontSize: "12px", color: "#AB0000" }}>
                      ({eventsDetails?.event_offer?.discount_percentage} % off)
                    </span>
                  </p>
                ) : (
                  `${currencySymbol} ${(
                    totalAmount +
                    (totalAmount * eventsDetails?.tax) / 100
                  ).toFixed(2)}`
                )}
              </p>
              {tax.length <= 0 && (
                <p className={styles.finalCount}>
                  {`${parseInt(totalCount)} seats`}
                </p>
              )}
            </div>
            {eventsDetails?.booking_type !== "FREE" && tax.length > 0 && (
              <div className={styles.taxDiv}>
                {tax?.map((i, index) => {
                  return (
                    <div key={index}>
                      <p>{`${i?.tax_name}  (${i?.tax_rate}%)`}</p>
                      <p>
                        {currencySymbol}{" "}
                        {amountGST?.[i?.tax_name]
                          ? amountGST?.[i?.tax_name]?.toFixed(2)
                          : (0).toFixed(2)}
                      </p>
                    </div>
                  );
                })}
                <div className={styles.netAmountDiv}>
                  <p className={styles.netPayable}>Net Payable </p>
                  <p className={styles.valueNetAmount}>
                    {currencySymbol} {amountGST?.totalAmount?.toFixed(2)}
                  </p>
                  <p className={styles.finalCount}>
                    {`${parseInt(totalCount)} seats`}
                  </p>
                </div>
              </div>
            )}
            <ButtonColor
              text="Book"
              width="200"
              buttonHandler={() => {
                // handleBooking();
                handleBookEventSubmit();
              }}
              isDisabled={btnStatus}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
