export const SpaConfig = {
  CreateBookingSpa: `${process.env.REACT_APP_PATH}/spa/create-booking`,

  CreateSpa: `${process.env.REACT_APP_PATH}/spa/add-spa-service`,

  SpaProfessionals: `${process.env.REACT_APP_PATH}/spaProfessional`,

  AddProfessionalSpa: `${process.env.REACT_APP_PATH}/spaProfessional/add-professional`,

  EditProfessionalSpa: `${process.env.REACT_APP_PATH}/spaProfessional/edit-professional`,

  BookingHistorySpa: `${process.env.REACT_APP_PATH}/spa/booking-history`,

  EditSpa: `${process.env.REACT_APP_PATH}/spa/edit-spa-service`,

  Spa: `${process.env.REACT_APP_PATH}/spa`,

  requestBy: "Admin",
};
