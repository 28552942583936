import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "rsuite";
import { CustomSelect } from "../../Components/CustomSelect/CustomSelect";
import { TopHeader } from "../../Components/TopHeaderNav/TopHeader";
import styles from "./bookingHistory.module.css";
import { useReactToPrint } from "react-to-print";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { loadData } from "../../Utils/localStorage";
import { getBookingHistory } from "../../Components/Redux/eventsSlice";
import { EventConfig } from "../EventConfig";
import { BookingHistoryTblEvent } from "./BookingHistoryTblEvent/BookingHistoryTblEvent";
import dayjs from "dayjs";
import { Logo } from "../../Components/Logo";
import { useNavigate } from "react-router-dom";
import { activeTab } from "../../Components/Redux/tabSlice";
import { BookingRegistrationHistory } from "../../AC/Event/Booking/BookingRegistrationHistory/BookingRegistrationHistory";
import { IS_REGISTRATION_FLOW } from "../../Utils/moduleConfig";

export const BookingHistory = () => {
  const { bookingHistoryEvents, Loader, totalBookingHistoryCount } =
    useSelector((state) => state.eventsSlice);
  const navigate = useNavigate();
  const { profileDetails } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const columns = [
    "Booking Id",
    "Event Name",
    "Booked For",
    "Booked By",
    process.env.REACT_APP_PRODUCT != "DLF"
      ? profileDetails?.comm_type == "Federation Parent Community"
        ? "Occupant"
        : profileDetails?.comm_type == "Federation Child Community"
        ? "Employee"
        : "Unit No."
      : "Membership No.",
    process.env.REACT_APP_PRODUCT === "DLF" && "User Type",
    "Start Date & Time",
    "End Date & Time",
    "Booking Status",
    // "Amount Paid",
    "Booked Seat",
    "Total Price",
  ];
  if (process.env.REACT_APP_PRODUCT != "DLF") {
    columns.unshift("Booking Time");
  }
  if (process.env.REACT_APP_PRODUCT != "DLF") {
    columns.splice(6, 1);
  }
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);

  const handleOffset = (val) => {
    if (val == "next") {
      offset + bookingHistoryEvents?.length < totalBookingHistoryCount &&
        setOffset(
          offset + (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
        );
      offset + bookingHistoryEvents?.length < totalBookingHistoryCount &&
        handleBookingHistory(
          offset + (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
        );
    } else {
      offset > 0 &&
        setOffset(
          offset - (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
        );
      offset > 0 &&
        handleBookingHistory(
          offset - (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
        );
    }
  };

  //search
  const [inputValue, setInputValue] = useState("");

  // category-------------------------------------------
  const [categoryArr, setCategoriesArr] = useState([]);
  const [selectCategory, setSelelctCategory] = useState("");
  const [selectCategoryInput, setSelelctCategoryInput] = useState("");
  const handleOnChangeCategory = (selectedOption) => {
    setSelelctCategory(selectedOption.value);
    setSelelctCategoryInput(selectedOption);
  };

  //status----------------------------------------------
  const [status, setStatus] = useState("");
  const handleSetStatus = (selectedOption) => {
    setStatus(selectedOption);
  };

  //user type----------------------------------------------
  const [userType, setUserType] = useState("");
  const handleSetUserType = (selectedOption) => {
    setUserType(selectedOption);
  };

  //date--------------------------------------------------
  const [value, setValue] = useState(null);
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [activeTab, setActiveTab] = useState(
    process.env.REACT_APP_PRODUCT === "NUCLEUS"
      ? profileDetails?.show_audience_flow
        ? "Booking"
        : "Registration"
      : "Booking"
  );
  useEffect(() => {
    let startDate =
      value?.length > 0 ? dayjs(value[0]).format("YYYY-MM-DD") : "";
    let endDate = value?.length > 0 ? dayjs(value[1]).format("YYYY-MM-DD") : "";
    setStartDateValue(startDate);
    setEndDateValue(endDate);
  }, [value]);

  //go btn
  const handleGoBtn = () => {
    setOffset(0);
    handleBookingHistory(0);
  };

  // --------Table export----------------
  const tableRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  //pdf
  const exportPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
    });
    doc.save("data.pdf");
  };

  //csv
  const { onDownload } = useDownloadExcel({
    filename: "users table",
    sheet: "users",
    currentTableRef: tableRef.current,
  });

  //for fetch all data\
  const handleBookingHistory = (ofset) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      is_history_api: "yes",
    };
    if (inputValue !== "") {
      payload["name"] = inputValue;
    }
    if (userType.value !== "") {
      if (userType.value == "MEMBER") {
        payload["is_member"] = 1;
      } else if (userType.value == "NON MEMBER") {
        payload["is_member"] = 0;
      } else {
        payload["is_member"] = null;
      }
    }
    if (status.value !== "") {
      if (status.value == "BOOKED") {
        payload["status"] = status.value;
        payload["payment_status"] = "PAID";
      } else if (status.value == "UNPAID") {
        payload["status"] = "BOOKED";
        payload["payment_status"] = "UNPAID";
      } else {
        payload["status"] = status.value;
      }
    }
    if (startDateValue !== "" && endDateValue !== "") {
      payload["start_date"] = startDateValue;
      payload["end_date"] = endDateValue;
    }
    if (selectCategory !== "") {
      payload["venue_name"] = selectCategory;
    }
    if (offset !== "") {
      payload["offset"] = ofset !== undefined ? ofset : offset;
    }
    if (limit !== "") {
      payload["limit"] = process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit;
    }
    if (process.env.REACT_APP_PRODUCT != "DLF") {
      payload["community_type"] = loadData("Community_type");
    }
    if (process.env.REACT_APP_PRODUCT != "DLF") {
      payload["community_type"] = "AC_COMM";
    }
    dispatch(getBookingHistory(payload));
  };

  useEffect(() => {
    setOffset(0);
    const timer = setTimeout(() => {
      handleBookingHistory(0);
    }, 500);
    return () => clearTimeout(timer);
  }, [status, userType, selectCategory, inputValue]);

  let statusOption = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Booked",
      value: "BOOKED",
    },
    {
      label: "Unpaid",
      value: "UNPAID",
    },
    {
      label: "Canceled",
      value: "CANCELED",
    },
  ];

  let userTypeOptions = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Member",
      value: "MEMBER",
    },
    {
      label: "Non Member",
      value: "NON MEMBER",
    },
  ];

  useEffect(() => {
    let arr = Object.keys(categoryArr).map((i, index) =>
      categoryArr[i].map((j, idx) => {
        return {
          label: j.name,
          value: j.name,
        };
      })
    );
    arr = [[{ label: "Select", value: "" }], ...arr];
    let tempCategoryArr = Object.keys(categoryArr);
    tempCategoryArr = ["", ...tempCategoryArr];
    let arr1 = tempCategoryArr.map((j, index) => {
      return {
        label: j,
        options: arr[index],
      };
    });
    setproductType(arr1);
  }, [categoryArr]);

  const [productType, setproductType] = useState([]);
  useEffect(() => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/venue/category_wise_venue`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        setCategoriesArr(res?.data);
      })
      .catch((err) => {});
  }, []);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupTextStyles = {
    fontSize: 12,
    color: "#591438",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      {data.label !== "" && (
        <>
          <span style={groupTextStyles}>{data.label}</span>
        </>
      )}
    </div>
  );

  if (Loader) return <Logo />;
  return activeTab === "Booking" ? (
    <div className={styles.mainDivBookingHistory}>
      <TopHeader title={"Booking History"} />
      {IS_REGISTRATION_FLOW && profileDetails?.show_audience_flow && (
        <div className={styles.activeTabDiv}>
          <span className={activeTab == "Booking" && styles.activeTab}>
            Booking
          </span>
          <span
            className={activeTab != "Booking" && styles.acitveTab}
            onClick={() => setActiveTab("Registration")}
          >
            Registration
          </span>
        </div>
      )}
      <div className={styles.bookingSearch}>
        <div className={styles.searchDiv}>
          <div
            className={styles.searchIcon}
            onClick={() => {
              if (inputValue !== "") {
                handleBookingHistory();
              }
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
            />
          </div>
          <input
            className={styles.searchDivInput}
            placeholder={" |  Search Event"}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleBookingHistory();
              }
            }}
          />
        </div>
        <div className={styles.statusSelectDiv}>
          <div className={styles.selectTitle}>Status</div>
          <CustomSelect
            options={statusOption}
            value={status}
            handleOnChangeDropdown={handleSetStatus}
            padding={"0px 8px 8px 0px"}
            border={"none"}
          />
        </div>
        {process.env.REACT_APP_PRODUCT === "DLF" && (
          <div className={styles.statusSelectDiv}>
            <div className={styles.selectTitle}>User Type</div>
            <CustomSelect
              options={userTypeOptions}
              value={userType}
              handleOnChangeDropdown={handleSetUserType}
              padding={"0px 8px 8px 0px"}
              border={"none"}
            />
          </div>
        )}
        <span className={styles.dateDiv}>
          <DateRangePicker
            format={"dd MMM yy"}
            placeholder={
              process.env.REACT_APP_PRODUCT == "DLF"
                ? "Select Date Range"
                : "Booking Date Range"
            }
            value={value}
            onChange={setValue}
            character={"  To  "}
            showOneCalendar
            className={styles.dateRangePicker}
          />
          <span onClick={handleGoBtn}>Go</span>
        </span>
        {/* <div lassName={styles.btnDiv} >
                    <button className={styles.btn}>XLS</button>
                    <button className={styles.btn} onClick={onDownload}>CSV</button>
                    <button className={styles.btn} onClick={exportPdf}>PDF</button>
                    <button className={styles.btn} onClick={handlePrint}>PRINT</button>
                </div> */}
      </div>
      <div
        className={`${styles.tblMainDiv} ${
          process.env.REACT_APP_PRODUCT != "DLF" && styles.tblMainDivAC
        } `}
      >
        {Loader ? (
          <Logo />
        ) : (
          <>
            <BookingHistoryTblEvent
              columns={columns}
              details={bookingHistoryEvents}
              handleBookingHistory={handleBookingHistory}
              tableRef={tableRef}
            />
            {bookingHistoryEvents?.length > 0 && (
              <div className={styles.pagination}>
                <div>
                  <div className={styles.paginationValuesDiv}>
                    <p>{offset + 1}</p>
                    <p>-</p>
                    <p>{offset + bookingHistoryEvents?.length}</p>
                    <p>of</p>
                    <p>
                      {totalBookingHistoryCount && totalBookingHistoryCount}
                    </p>
                  </div>
                  <div className={styles.paginationIconDiv}>
                    <span onClick={() => handleOffset("prev")}>
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                      />
                    </span>
                    <span onClick={() => handleOffset("next")}>
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <BookingRegistrationHistory
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};
