import React from "react";

const AccessDenied = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        color: "rgba(146, 33, 92, 1)",
      }}
    >
      <div
        style={{
          fontSize: "24px",
        }}
      >
        Access Denied
      </div>
      <div
        style={{
          fontSize: "16px",
        }}
      >
        Please contact your super admin for admin rights
      </div>
    </div>
  );
};

export default AccessDenied;
