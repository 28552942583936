import React, { useState, useEffect } from "react";
import styles from "./directory.module.css";
import { Typography, Link, Breadcrumbs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ButtonBorder } from "../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { useSelector, useDispatch } from "react-redux";
import { CustomSelect } from "../../Components/CustomSelect/CustomSelect";
import { Modal } from "@material-ui/core";
import DirectoryForm from "../Components/DirectoryForm";
import { loadData } from "../../Utils/localStorage";
import { Logo } from "../../Components/Logo";
import { setModalState } from "../../Components/Redux/conciergeSlice";
import {
  getDepartments,
  getDesignation,
  getDirectoryData,
  setEdit,
} from "../../Components/Redux/conciergeSlice";
import { toast } from "react-toastify";

function Directory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    designationData,
    departmentData,
    directoryData,
    loadingDirectory,
    totalDirectoryCount,
    modalState,
    edit,
  } = useSelector((state) => state.conciergeSlice);
  const { conciergeRights } = useSelector((state) => state.authSlice);

  const [departments, setDepartments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(4);
  const [viewData, setViewData] = useState({});
  const [filterValue, setFilterValue] = useState({
    label: "All",
    value: "",
  });
  const [requestPayload, setRequestPayload] = useState({});
  const handleNavigate = () => {
    navigate("/concierge", { replace: true });
  };
  const club_id=loadData("Club_id");

  const columns = [
    "Name",
    "Designation",
    "Department",
    "Contact no.",
    "Intercom",
    "Email Id.",
  ];

  const handleModalClose = () => {
    dispatch(setModalState(false));
    dispatch(setEdit(false));
  };

  const handleOffset = (val) => {
    if (val == "next") {
      offset + directoryData?.length < totalDirectoryCount &&
        setOffset(offset + limit);
      offset + directoryData?.length < totalDirectoryCount &&
        handleRequestData(offset + limit);
    } else {
      offset > 0 && setOffset(offset - limit);
      offset > 0 && handleRequestData(offset - limit);
    }
  };

  const breadcrumbStyle = {
    "&:hover": {
      textDecoration: "none",
      color: "rgba(116, 103, 110, 1)",
      cursor: "pointer",
    },
  };

  const breadcrumbs = [
    <div className={styles.tabOuterDiv}>
      <Link
        sx={breadcrumbStyle}
        underline="none"
        key="2"
        color="rgba(116, 103, 110, 1)"
        onClick={handleNavigate}
      >
        Concierge management
      </Link>
    </div>,
    <Typography
      fontSize="25px"
      fontWeight="600"
      key="3"
      color="rgba(172, 16, 99, 1)"
    >
      Directory
    </Typography>,
  ];

  const handleRequestData = (offset) => {
    const body = {
      user_id: loadData("Token"),
      club_id: loadData("Club_id"),
      offset: offset,
      limit: limit,
      request_by: "Admin",
    };
    if (filterValue?.value != "") {
      body["filter"] = filterValue.value;
    }
    if (searchText.length > 0) {
      body["search"] = searchText;
    }
    setRequestPayload({ ...body });
    dispatch(getDirectoryData(body));
  };

  const handleSetStatus = (e) => {
    setFilterValue(e);
  };

  useEffect(() => {
    handleRequestData();
  }, [searchText, filterValue,club_id]);

  useEffect(() => {
    let body = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
    };
    dispatch(getDesignation(body));
    dispatch(getDepartments(body));
  }, []);

  useEffect(() => {
    setDepartments([{ label: "All", value: "" }, ...departmentData]);
  }, [departmentData]);

  return (
    <>
      <div className={`${styles.mainDivDirectory}`}>
        <div className={styles.directoryHead}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="large" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <ButtonBorder
            text={"Add new"}
            width={200}
            buttonHandler={() => {
              if (conciergeRights?.create == 1) {
                dispatch(setModalState(true));
              } else {
                toast.error("You don't have create access rights");
              }
            }}
          />
        </div>
        <div className={styles.directorySearch}>
          <div className={styles.searchDiv}>
            <div className={styles.searchIcon}>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
              />
            </div>
            <input
              className={styles.searchDivInput}
              placeholder={" |  Search "}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className={styles.departmentSelectDiv}>
            <div className={styles.selectTitle}>Department</div>
            <CustomSelect
              options={departments}
              value={filterValue}
              handleOnChangeDropdown={handleSetStatus}
              padding={"0px 8px 8px 0px"}
              border={"none"}
            />
          </div>
        </div>
        {loadingDirectory ? (
          <Logo />
        ) : (
          <>
            <div
              style={{
                height: "360px",  
                overflow: "auto",
              }}
            >
              {directoryData?.length > 0 ? (
                <table
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 8px",
                    width: "100%",
                    padding: "0px 30px",
                  }}
                >
                  <thead>
                    <tr className={styles.tableHead}>
                      {columns?.map((col, index) => {
                        return (
                          <th
                            style={{ minWidth: "100px", textAlign: "center" }}
                            key={index}
                          >
                            {col}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {directoryData?.length > 0 &&
                      directoryData?.map((tbldata, index) => {
                        return (
                          <tr className={styles.tableData}>
                            <td
                              style={{ minWidth: "100px", textAlign: "center" }}
                              className={styles.bookedFor}
                              onClick={() => {
                                const singleDirectoryData = directoryData?.find(
                                  (ele) => {
                                    return ele.id == tbldata?.id;
                                  }
                                );
                                setViewData({ ...singleDirectoryData });
                                dispatch(setEdit(true));
                                dispatch(setModalState(true));
                              }}
                            >
                              {tbldata?.name ? tbldata?.name : "-"}
                            </td>
                            <td
                              style={{ minWidth: "100px", textAlign: "center" }}
                            >
                              {tbldata?.designation?.name
                                ? tbldata?.designation?.name
                                : "-"}
                            </td>
                            <td
                              style={{ minWidth: "100px", textAlign: "center" }}
                            >
                              {tbldata?.department?.name
                                ? tbldata?.department?.name
                                : "-"}
                            </td>
                            <td
                              style={{ minWidth: "100px", textAlign: "center" }}
                            >
                              {tbldata?.phone ? tbldata?.phone : "-"}
                            </td>
                            <td
                              style={{ minWidth: "100px", textAlign: "center" }}
                            >
                              {tbldata?.intercom ? tbldata?.intercom : "-"}
                            </td>
                            <td
                              style={{ minWidth: "100px", textAlign: "center" }}
                            >
                              {tbldata?.email ? tbldata?.email : "-"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div className={styles.noDataMainDiv}>
                  <div className={styles.noDataDiv}>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
                      />
                    </span>
                    <h2>No Data Found</h2>
                  </div>
                </div>
              )}
            </div>
            {directoryData?.length > 0 && (
              <div className={styles.pagination}>
                <div>
                  <div className={styles.paginationValuesDiv}>
                    <p>{offset + 1}</p>
                    <p>-</p>
                    <p>{offset + directoryData?.length}</p>
                    <p>of</p>
                    <p>{totalDirectoryCount && totalDirectoryCount}</p>
                  </div>
                  <div className={styles.paginationIconDiv}>
                    <span
                      onClick={() => {
                        handleOffset("prev");
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                      />
                    </span>
                    <span
                      onClick={() => {
                        handleOffset("next");
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        open={modalState}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DirectoryForm
          designationData={designationData}
          departmentData={departmentData}
          viewData={viewData}
          handleModalClose={handleModalClose}
          requestPayload={requestPayload}
        />
      </Modal>
    </>
  );
}

export default Directory;
