import React from "react";
import styles from "./buttonColor.module.css";

export const ButtonColor = ({
  text,
  buttonHandler,
  width,
  isDisabled,
  type,
  height,
  fontWeight,
  lineHeight,
  fontSize,
}) => {
  const buttonStyle = {
    width: type ? `${width}%` : `${width}px`,
    height: `${height}px`,
    fontWeight: `${fontWeight}`,
    lineHeight: `${lineHeight}px`,
    fontSize: `${fontSize}px`,
  };
  return (
    <button
      disabled={isDisabled ? true : false}
      className={styles.btn}
      style={buttonStyle}
      onClick={buttonHandler}
    >
      {text}
    </button>
  );
};
