import React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { ClickAwayListener } from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useSelector } from "react-redux";
dayjs.extend(utc);
dayjs.extend(timezone);
export const DateTimeRangeComponent = ({
  Name,
  hideBorder,
  createEvent,
  handleDateTimePicker,
  customWidth,
  showOnlyDate,
  isAvailableDay,
  disableDays,
  minDate,
  isDisable,
  value,
  dynamicValue,
}) => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const effectiveTimeZone = profileDetails.comm_time_zone || dayjs.tz.guess();
  const yesterday = dayjs().subtract(1, "day");
  const today = dayjs().tz(effectiveTimeZone);
  const handleDateTime = (e, Name) => {
    handleDateTimePicker(e.utc(), Name);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ClickAwayListener onClickAway={() => {}}>
          <DemoContainer components={["DateTimePicker"]}>
            <DemoItem label="">
              <DateTimePicker
                shouldDisableDate={
                  disableDays ? (e) => !isAvailableDay(e) : undefined
                }
                //   shouldDisableDate={false}
                closeOnSelect={false}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: hideBorder
                        ? "#ffffff"
                        : "#c5c0c7 !important",
                    },
                    "&:hover fieldset": {
                      borderColor: hideBorder
                        ? "#ffffff"
                        : "#c5c0c7 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: hideBorder
                        ? "#ffffff"
                        : "#c5c0c7 !important",
                    },
                  },
                  "& .MuiInputBase-input": {
                    height: "10px !important",
                    width: `${customWidth} ! important`,
                    outline: "none!important",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                  },
                  "& .MuiList-root-MuiMultiSectionDigitalClockSection-root:after":
                    {
                      height: "calc(100% - 38px)",
                    },
                }}
                timezone={effectiveTimeZone}
                defaultValue={today}
                minDate={minDate || dayjs().tz(effectiveTimeZone)}
                disablePast
                views={
                  showOnlyDate
                    ? ["year", "month", "day"]
                    : ["year", "month", "day", "hours", "minutes"]
                }
                format={showOnlyDate ? "DD/MM/YYYY" : "DD/MM/YYYY hh:mm A"}
                name={Name}
                value={
                  dynamicValue
                    ? value
                    : createEvent && createEvent[Name]
                    ? dayjs(createEvent[Name])
                    : null
                }
                onChange={(e) => handleDateTime(e, Name)}
                disabled={isDisable ? true : false}
              />
            </DemoItem>
          </DemoContainer>
        </ClickAwayListener>
      </LocalizationProvider>
    </>
  );
};
