import React from "react";
import styles from "./bookingHistoryRegDetailsTbl.module.css";
import dayjs from "dayjs";

export const BookingHistoryRegDetailsTbl = ({
  columns,
  details,
  tableRef,
  setViewDetail,
  setViewRegModal,
}) => {
  return (
    <div
      style={{
        height: "360px",
        overflow: "auto",
      }}
    >
      {details?.length > 0 ? (
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 8px",
            width: "100%",
          }}
          ref={tableRef}
        >
          <thead>
            <tr className={styles.BHEventtableHead}>
              {columns?.map((col, index) => {
                return <th key={index}>{col}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {details?.length > 0 &&
              details?.map((tbldata, index) => {
                return (
                  <tr
                    // key={index}
                    className={styles.BHEventtableData}
                  >
                    <td
                      className={styles.id}
                      onClick={() => {
                        setViewDetail(tbldata);
                        tbldata?.member_custom_details.length > 0 &&
                          setViewRegModal(true);
                      }}
                    >
                      {tbldata?.booking_no}
                    </td>
                    <td
                      className={
                        tbldata?.status == "BOOKED"
                          ? styles.open
                          : tbldata?.status == "CANCELED"
                          ? styles.cancelled
                          : tbldata?.status == "Declined".toUpperCase()
                          ? styles.rejected
                          : styles.close
                      }
                    >
                      {tbldata?.status}
                    </td>
                    <td>
                      <p>{tbldata?.booked_for}</p>
                    </td>
                    <td>{tbldata?.booked_by}</td>

                    <td>
                      {dayjs(tbldata?.created_at, "YYYY-MM-DD hh:mm:ss").format(
                        "DD MMM YYYY, hh:mm A"
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className={styles.noDataMainDiv}>
          <div className={styles.noDataDiv}>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/NoData.png`}
              />
            </span>
            <h2>No Data Found</h2>
          </div>
        </div>
      )}
    </div>
  );
};
