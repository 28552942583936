import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";
import axios from "axios";

// homepage all restaurants
export const getAllRestaurants = createAsyncThunk(
  "getAllRestaurants",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/fnb/get-restaurants",
        {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

//restaurantsDetails
export const getRestaurantsDetailsById = createAsyncThunk(
  "getRestaurantsDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/fnb/get-restaurant-details",
        {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
          restaurant_id: id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response;
      return result;
    } catch (err) {
      return rejectWithValue(
        "Oops found an error in getRestaurantsDetailsById",
        err.response.data
      );
    }
  }
);

//search
export const fnbFilter = createAsyncThunk(
  "fnbFilter",
  async ({ args, paramId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/fnb/get-restaurants",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response.data;
      return { data: result, paramId };
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response.data);
    }
  }
);

//bookingHistory
export const getBookingHistory = createAsyncThunk(
  "getBookingHistory",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/fnb/get-admin-order",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response.data);
    }
  }
);

export const fnbSlice = createSlice({
  name: "fnbSlice",
  initialState: {
    restaurants: [],
    restaurantsDetails: {},
    restaurantsActiveTab: "",
    loading: false,
    error: null,
    RestaurantLoading: true,
    bookingHistoryRestaurants: [],
    totalBookingHistoryCount: "",
    Loader: true,
  },
  reducers: {
    handleRestaurantsActiveTab: (state, action) => {
      state.restaurantsActiveTab = action?.payload;
    },
    handleRestaurantDetails: (state, action) => {
      state.restaurantsDetails = {};
    },
  },

  extraReducers: (builder) => {
    builder
      // get all restaurants data
      .addCase(getAllRestaurants.pending, (state, action) => {
        state.loading = true;
        state.RestaurantLoading = true;
      })
      .addCase(getAllRestaurants.fulfilled, (state, action) => {
        state.loading = false;
        let restaurantList = action?.payload?.data?.data?.restaurants;
        state.restaurants = restaurantList;
        if (restaurantList?.length > 0) {
          state.restaurantsActiveTab = restaurantList[0]?.id;
        }
        state.RestaurantLoading = false;
      })
      .addCase(getAllRestaurants.rejected, (state, action) => {
        state.loading = false;
        state.RestaurantLoading = false;
        state.error = action?.payload;
      })
      // get restaurant details by id data
      .addCase(getRestaurantsDetailsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRestaurantsDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurantsDetails = action?.payload?.data?.data?.restaurant;
      })
      .addCase(getRestaurantsDetailsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      // search restaurant data
      .addCase(fnbFilter.pending, (state, action) => {
        state.RestaurantLoading = true;
        state.loading = false;
        state.restaurants = [];
        state.restaurantsDetails = {};
        state.restaurantsActiveTab = "";
      })
      .addCase(fnbFilter.fulfilled, (state, action) => {
        state.RestaurantLoading = true;
        state.loading = false;
        state.restaurants = action?.payload?.data?.data?.restaurants;
        state.restaurantsDetails =
          action?.payload?.data?.data?.restaurants?.length == 0 && {};
        state.restaurantsActiveTab = action?.payload?.paramId
          ? action?.payload?.paramId
          : action?.payload?.data?.data?.restaurants[0]?.id;
        state.RestaurantLoading = false;
      })
      .addCase(fnbFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload?.message;
        state.RestaurantLoading = false;
      })
      // booking history data
      .addCase(getBookingHistory.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryRestaurants = action?.payload?.data;
        state.totalBookingHistoryCount = action?.payload?.data?.records;
        state.Loader = false;
      })
      .addCase(getBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.Loader = false;
      });
  },
});

export const { handleRestaurantsActiveTab, handleRestaurantDetails } =
  fnbSlice.actions;
export default fnbSlice.reducer;
