import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBorder } from "../Button/Buttons/ButtonBorder/ButtonBorder";
import { ButtonSmall } from "../Button/Buttons/ButtonSmall/ButtonSmall";
import styles from "./topHeader.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const TopHeader = ({
  showBtn,
  title,
  btnTitle1,
  btnTitle2,
  btnTitle24,
  btnTitle3,
  btnTitle31,
  btnTitle4,
  btnTitle52,
  btnTitle5,
  btnTitle6,
  btnTitle63,
  btnTitle7,
  btnTitle55,
  to1,
  to2,
  to24,
  to3,
  to31,
  to4,
  to5,
  to52,
  to6,
  to63,
  to7,
  to55,
  showAddCategoryBtn,
  showCategoryBtn,
  categoryState,
  headerType,
}) => {
  const navigate = useNavigate();
  const { facilityRights, venueRights, fnBRights, salonRights, spaRights } =
    useSelector((state) => state.authSlice);

  const handleButton = (path) => {
    navigate(path);
  };

  const handleCreateButton = (path) => {
    if (headerType === "facilities" && facilityRights?.create == 1) {
      navigate(path);
    } else if (headerType === "fnb" && fnBRights?.create == 1) {
      navigate(path);
    } else if (headerType === "venues" && venueRights?.create == 1) {
      navigate(path);
    } else if (headerType === "salon" && salonRights?.create == 1) {
      navigate(path);
    } else if (headerType === "spa" && spaRights?.create == 1) {
      navigate(path);
    } else {
      toast.error("You don't have create access rights");
    }
  };

  const handleCreateBooking = (path) => {
    if (
      (headerType === "salon" && salonRights?.create == 1) ||
      (headerType === "spa" && spaRights?.create == 1)
    ) {
      navigate(path);
    } else {
      toast.error("You don't have create access rights");
    }
  };

  return (
    <div className={styles.topNavMain}>
      <h3>{title}</h3>
      {showBtn && (
        <div className={styles.buttonsDiv}>
          {btnTitle1 && to1 && (
            <ButtonSmall
              title={btnTitle1}
              buttonHandler={handleButton}
              to={to1}
            />
          )}
          {btnTitle2 && to2 && (
            <ButtonSmall
              title={btnTitle2}
              buttonHandler={handleButton}
              to={to2}
            />
          )}
          {btnTitle6 && to6 && (
            <ButtonSmall
              title={btnTitle6}
              buttonHandler={handleCreateBooking}
              to={to6}
            />
          )}
          {btnTitle7 && to7 && (
            <ButtonSmall
              title={btnTitle7}
              buttonHandler={handleCreateButton}
              to={to7}
            />
          )}
          {btnTitle3 && to3 && (
            <ButtonSmall
              title={btnTitle3}
              buttonHandler={handleCreateButton}
              to={to3}
            />
          )}
          {btnTitle31 && to31 && (
            <ButtonSmall
              title={btnTitle31}
              buttonHandler={handleCreateButton}
              to={to31}
            />
          )}
          {btnTitle5 && to5 && (
            <ButtonSmall
              title={btnTitle5}
              buttonHandler={handleButton}
              to={to5}
            />
          )}
          {btnTitle52 && to52 && (
            <ButtonSmall
              title={btnTitle52}
              buttonHandler={handleButton}
              to={to52}
            />
          )}
          {btnTitle63 && to63 && (
            <ButtonSmall
              title={btnTitle63}
              buttonHandler={handleCreateBooking}
              to={to63}
            />
          )}
          {btnTitle24 && to24 && (
            <ButtonSmall
              title={btnTitle24}
              buttonHandler={handleButton}
              to={to24}
            />
          )}
          {btnTitle55 && to55 && (
            <ButtonSmall
              title={btnTitle55}
              buttonHandler={handleButton}
              to={to55}
            />
          )}
          {showCategoryBtn && (
            <ButtonSmall
              title={btnTitle4}
              buttonHandler={handleButton}
              to={to4}
            />
          )}
        </div>
      )}
      {showAddCategoryBtn && (
        <div className={styles.buttonsDiv}>
          <ButtonBorder
            text={btnTitle5}
            buttonHandler={() => {
              if (headerType === "facilities" && facilityRights?.create == 1) {
                categoryState();
              } else if (headerType === "venues" && venueRights?.create == 1) {
                categoryState();
              } else {
                toast.error("You don't have create access rights");
              }
            }}
            width={160}
          />
        </div>
      )}
    </div>
  );
};
