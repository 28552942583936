import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { CancelEventModal } from "../CancelEventModal/CancelEventModal";
import { Textarea } from "evergreen-ui";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import styles from "./deleteModal.module.css";
import { ButtonColor } from "../../../Components/Button/Buttons/ButtonColor/ButtonColor";
import { ButtonBorder } from "../../../Components/Button/Buttons/ButtonBorder/ButtonBorder";
import { loadData } from "../../../Utils/localStorage";
import { EventConfig } from "../../EventConfig";
import { Togle } from "../../../Components/Togle/Togle";
import { eventFilter } from "../../../Components/Redux/eventsSlice";

export const DeleteModal = ({ open, onCloseModal }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const { eventsDetails, eventsActiveTab, events } = useSelector(
    (state) => state.eventsSlice
  );
  const dispatch = useDispatch();

  //---------------toggle---------------------------------------
  const [toggle, setToggle] = useState(false);
  const [reason, setReason] = useState("");
  const toggleFunction = () => {
    if (reason.trim() === "" && toggle == false) {
      toast.error("Please enter reason for cancellation");
    } else {
      setToggle(!toggle);
    }
  };

  // cancelModal------------------------------------------------
  const [cancelModal, setCancelModal] = useState(false);
  const onOpenCancelModal = () => setCancelModal(true);
  const onCloseCancelModal = () => setCancelModal(false);

  const handleUpdateStatus = () => {
    if (isDisabled) {
      return;
    }
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      event_id: eventsDetails?.id,
      cancel_reason: reason.trim(),
    };
    if (reason.trim() == "") {
      toast.error("Please enter reason for cancellation");
    } else if (process.env.REACT_APP_PRODUCT == "DLF" && !toggle) {
      toast.error("Please Select Toggle");
    } else {
      setIsDisabled(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/event/admin-cancel-event`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      })
        .then((d) => d.json())
        .then((res) => {
          if (res.status_code == 101) {
            toast(`${res.message}`, {
              type: "error",
            });
          }
          if (res.status_code == 201) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else {
            toast(`${res.message}`, {
              type: "success",
            });
          }
          let payload = {
            club_id: loadData("Club_id"),
            user_id: loadData("Token"),
            request_by: EventConfig.requestBy,
          };
          setIsDisabled(false);
          if (process.env.REACT_APP_PRODUCT == "DLF") {
            navigate(EventConfig.eventDLF);
          } else {
            navigate(EventConfig.event);
          }
          // dispatch(getAllEvents());
          dispatch(eventFilter({ args: payload, paramId: "" }));
        })
        .catch((err) => {
          setIsDisabled(false);
        });
    }
  };

  return (
    <Modal open={open} onClose={onCloseModal} showCloseIcon={true} center>
      <CancelEventModal
        open={cancelModal}
        onCloseModal={onCloseCancelModal}
        handleUpdateStatus={handleUpdateStatus}
        isDisabled={isDisabled}
      />
      <div className={styles.mainDiv}>
        <h2 className={styles.title}>{eventsDetails?.name}</h2>
        <p className={styles.text}>Cancel Event</p>
        <div>
          <div className={styles.fieldsDiv}>
            <label className={styles.labelFont}>
              Reason For Cancellation of Event
            </label>
            <span className={styles.star}>*</span>
            <br />
            <Textarea
              className={styles.inputTextArea}
              width={534}
              height={44}
              placeholder="Enter reason for cancellation of event"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          {process.env.REACT_APP_PRODUCT == "DLF" && (
            <div className={styles.suspendDiv}>
              <p className={styles.suspendTitle}>Suspend Bookings</p>
              <Togle toggleFunction={toggleFunction} toggle={toggle} />
            </div>
          )}
          <p className={styles.disclaimer}>
            Disclaimer : All bookings will be cancelled.
          </p>
          <div className={styles.btnDiv}>
            <ButtonBorder
              text={process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : "Discard"}
              width={200}
              buttonHandler={() => {
                setReason("");
                setToggle(false);
                onCloseModal();
              }}
            />
            <ButtonColor
              text="Cancel"
              width={200}
              buttonHandler={() => {
                if (reason.trim() == "") {
                  toast.error("Please enter reason for cancellation");
                } else if (process.env.REACT_APP_PRODUCT == "DLF" && !toggle) {
                  toast.error("Please Select Toggle");
                } else {
                  onOpenCancelModal();
                }
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
