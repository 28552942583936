import React, { useEffect, useState } from "react";
import { TopHeader } from "../TopHeader/TopHeader";
import { EventDetails } from "../CreateEvent/EventDetails/EventDetails";
import { Financials } from "../CreateEvent/Financials/Financials";
import { Seatings } from "../CreateEvent/Seatings/Seatings";
import styles from "./editEvent.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { EventConfig } from "../EventConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DiscardModal } from "../DiscardModal/DiscardModal";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { loadData } from "../../Utils/localStorage";
import { UploadImageForm } from "../../Components/UploadImageForm/UploadImageForm";
import { AddSeatCategorization } from "../CreateEvent/AddSeatCategorization/AddSeatCategorization";
import { useSelector } from "react-redux";
import { RegistrationFieldModal } from "../CreateEvent/RegistrationDetail/Audience/RegistrationFieldModal/RegistrationFieldModal";
import { RegistrationDetailPage } from "../CreateEvent/RegistrationDetail/Participant/RegistrationDetailPage";
import { RegistrationDetail } from "../CreateEvent/RegistrationDetail/Audience/RegistrationDetail";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  IS_REGISTRATION_FLOW,
  SHOW_REGISTRATION_PAGE,
} from "../../Utils/moduleConfig";
dayjs.extend(utc);
dayjs.extend(timezone);

export const EditEvent = () => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const effectiveTimeZone = profileDetails.comm_time_zone || dayjs.tz.guess();
  const effectiveTimeZoneSecondry = dayjs.tz.guess();
  const { eventsDetails } = useSelector((state) => state.eventsSlice);
  const [isDisabled, setDisabled] = useState(false);
  const [bookingCapacity, setBookingCapacity] = useState("");
  dayjs.extend(localizedFormat);
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = React.useState([]);
  const [createEvent, setCreateEvent] = useState({
    name: "",
    description: "",
    venue_id: "",
    instructions: "",
    startTime: "",
    endTime: "",
    eventStatus: "",
    eventType: "",
    seatingCapacity: "",
    waitingList: "",
    maxNoOfSeats: "",
    seatingStyle: "",
    accessibleTo: "",
    bookingType: "",
    bookingPrice: "",
    tax: "",
    cancellationAllowed: "",
    startDate: "",
    endDate: "",
    guest: [],
    cancellationPolicy: "",
    startDateTime: "",
    prevStartDateTime: "",
    endDateTime: "",
    isSeatingCategorization: 0,
    bookingPriceGuest: "",
    allowBookingAfterStartTimePassed: 0,
    invoiceDescription: "",
  });
  useEffect(() => {
    value
      ? setCreateEvent({
          ...createEvent,
          startDate: value[0],
          endDate: value[1],
        })
      : setCreateEvent({
          ...createEvent,
          startDate: "",
          endDate: "",
        });
  }, [value]);

  //guest--------------------------------------------------
  const [guest, setGuest] = useState([
    {
      guestName: "",
      data_url: "",
    },
  ]);

  // suitable age group dropdown
  const [suitableAgeGroups, setSuitableAgeGroups] = useState([
    {
      value: "",
      label: "Select",
    },
  ]);
  const [selectedSuitableAgeGroups, setSelectedSuitableAgeGroups] =
    useState(null);

  const handleSuitableAgeGroupsChange = (selectedOption) => {
    setSelectedSuitableAgeGroups(selectedOption);
  };

  // venue dropdown
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);

  const handleVenueChange = (selectedOption) => {
    setSelectedVenue(selectedOption);
  };

  // language dropdown
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };

  // event types dropdown
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState(null);

  const handleEventTypesChange = (selectedOption) => {
    setSelectedEventTypes(selectedOption);
  };

  // event status radio
  const [eventStatus, setEventStatus] = useState({});

  // seat types
  const [seatTypes, setSeatTypes] = useState({});

  // accessible to
  const [accessibleTo, setAccessibleTo] = useState({});

  // booking types radio
  const [bookingTypes, setBookingTypes] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get-data`, {
      method: "POST",
      body: JSON.stringify({
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: "Admin",
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        const languageOptions = res?.data?.languages?.map((language) => ({
          value: language.id,
          label: language.name,
        }));
        setLanguages(languageOptions);

        const audienceOptions = res?.data?.audience?.map((audience) => ({
          value: audience.id,
          label: audience.name,
        }));
        setSuitableAgeGroups([...suitableAgeGroups, ...audienceOptions]);

        const eventTypeOptions = res?.data?.eventTypes?.map((eventTypes) => ({
          value: eventTypes.id,
          label: eventTypes.name,
        }));
        setEventTypes(eventTypeOptions);
        const venueOptions = res?.data?.venue?.map((venues) => ({
          value: venues?.id,
          label: venues?.name,
        }));
        setVenues(venueOptions);
      })
      .catch((err) => {});
  };

  const handleCreateEventChange = (e) => {
    const { name, value, type, checked } = e.target;
    type == "checkbox"
      ? checked
        ? setCreateEvent({
            ...createEvent,
            [name]: [...createEvent.accessibleTo, value],
          })
        : setCreateEvent({
            ...createEvent,
            [name]: createEvent.accessibleTo.filter((e) => e !== value),
          })
      : name == "bookingPrice" ||
        name == "waitingList" ||
        name == "seatingCapacity" ||
        name == "maxNoOfSeats"
      ? setCreateEvent({
          ...createEvent,
          [name]: value.replace(/[^0-9]/g, ""),
        })
      : setCreateEvent({
          ...createEvent,
          [name]: value,
        });
  };
  const handleBookingType = (e) => {
    const { name, value } = e.target;
    if (value == "FREE") {
      setCreateEvent({
        ...createEvent,
        [name]: value,
        isSeatingCategorization: 0,
      });
    } else {
      setCreateEvent({
        ...createEvent,
        [name]: value,
      });
    }
  };

  //function for timepicker----------------------------------------
  const handleTimePicker = (e, timeName) => {
    setCreateEvent({
      ...createEvent,
      [timeName]: e.$d,
    });
  };

  const handleDateTimePicker = (e, Name) => {
    setCreateEvent({
      ...createEvent,
      [Name]: e,
    });
  };

  // function for calenderComponent
  const handleCallbackforClosingOn = (date) => {
    setCreateEvent({
      ...createEvent,
      ["startDate"]: date[0],
      ["endDate"]: date[1],
    });
  };

  //cancellation--------------------------------------------
  const [inputFields, setInputFields] = useState([
    {
      duration: "",
      deduction: "",
    },
  ]);

  //images--------------------------------------------------------------
  const [markCoverId, setMarkCoverId] = useState(0);
  const [imagesEvent, setImagesEvent] = useState([]);
  const [imagesEventHomeCover, setImagesEventHomeCover] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedImagesHome, setDeletedImagesHome] = useState([]);
  // ----------------------imgaeLibrary
  const [images, setImages] = React.useState([]);
  const [imagesHomeCover, setImagesHomeCover] = React.useState([]);
  const [imagesSplGuest0, setImagesSplGuest0] = React.useState([]);
  const [imagesSplGuest1, setImagesSplGuest1] = React.useState([]);
  const [imagesSplGuest2, setImagesSplGuest2] = React.useState([]);
  //------------------AC--------------------------------------
  //------------------AC--------------------------------------
  const [showGuest, setShowGuest] = useState();
  //guest category------------------------------
  const [guestSeatCategoryBtn, setGuestSeatCategoryBtn] = useState();
  const [guestSeatCategory, setGuestSeatCategory] = useState([]);
  //-------------------------------------------
  const addSeatCategoryAc = (categoryName, total, waiting) => {
    setSeatCategory([
      ...seatCategory,
      {
        id: 1,
        name: categoryName,
        total: total,
        waiting: waiting,
        price: "",
        guest_price: "",
      },
    ]);
  };
  useEffect(() => {
    setImagesEvent(
      imagesEvent.map((i, index) => {
        return markCoverId == index
          ? {
              ...i,
              is_main: true,
            }
          : {
              ...i,
              is_main: false,
            };
      })
    );
  }, [markCoverId]);

  const handleSubmit = () => {
    let tempImagesArr1 = [];
    imagesEvent.map((i, index) => {
      markCoverId == index
        ? tempImagesArr1.push({
            ...i,
            is_main: true,
          })
        : tempImagesArr1.push({
            ...i,
            is_main: false,
          });
    });

    let currTime = dayjs().format("h:mm A");
    let currDate = dayjs().format("YYYY-MM-DD h:mm A");
    let currNewDate = new Date();
    let sDate = new Date(createEvent.startDateTime.$d);
    let eDate = new Date(createEvent.endDateTime.$d);
    let payload = {
      event_id: id,
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      description: createEvent.description,
      venue_id: selectedVenue?.value,
      instructions: createEvent.instructions,
      event_start_time: dayjs(createEvent.startDateTime.$d).format("hh:mm A"),
      event_end_time: dayjs(createEvent.endDateTime.$d).format("hh:mm A"),
      status: createEvent.eventStatus,
      type: createEvent.eventType,
      total_seats: createEvent.seatingCapacity,
      seat_type: createEvent.seatingStyle,
      accessible_to: createEvent.accessibleTo,
      target_audience: selectedSuitableAgeGroups?.value,
      has_seating_category: parseInt(createEvent?.isSeatingCategorization),
      booking_type: createEvent.bookingType,
      price:
        createEvent?.bookingType == "PAID" &&
        createEvent?.isSeatingCategorization == 0 &&
        createEvent?.bookingPrice,
      cancellation_allowed: createEvent?.cancellationAllowed,
      waiting_list:
        parseInt(createEvent?.isSeatingCategorization) == 0 &&
        createEvent?.waitingList !== ""
          ? createEvent?.waitingList
          : 0,
      max_per_member_seats: createEvent?.maxNoOfSeats,
      tax: createEvent?.tax,
      start_at: dayjs(createEvent?.startDateTime).format("YYYY-MM-DD hh:mm A"),
      end_at: dayjs(createEvent?.endDateTime).format("YYYY-MM-DD hh:mm A"),
      has_artist: 0,
      images: tempImagesArr1,
      homescreen_image: imagesEventHomeCover[0]?.path,
    };
    if (parseInt(createEvent?.isSeatingCategorization) == 1) {
      payload["seats"] =
        createEvent?.isSeatingCategorization == 1 && seatCategory;
    }
    if (imagesEventHomeCover.length <= 0) {
      payload["homescreen_image"] = "deleted";
    }
    let tempGuest = createEvent?.guest
      ?.filter((i, index) => {
        return i.guestName !== "" && i.data_url !== "" ? i : "";
      })
      .map((i, index) => {
        return {
          name: i.guestName,
          image: i.data_url,
        };
      });
    if (tempGuest?.length > 0) {
      payload["has_artist"] = 1;
    }
    if (tempGuest?.length > 0 && payload["has_artist"] == 1) {
      payload["artists"] = tempGuest;
    }
    let tempCancellation = createEvent.cancellationPolicy?.filter(
      (i, index) => {
        return i.duration !== "" && i.deduction !== "" ? i : "";
      }
    );
    payload["cancellation"] = tempCancellation;
    let tempSeatCategorization = seatCategory.filter((i, index) => {
      return i.total == "" ||
        i.waiting == "" ||
        i.total == 0 ||
        i.total < i.waiting
        ? i
        : "";
    });
    if (createEvent?.description == "") {
      toast.error("Please Enter Synopsis");
    } else if (createEvent?.description?.length < 30) {
      toast.error("Minimum Synopsis Length should be 30");
    } else if (createEvent?.description?.length > 10000) {
      toast.error("Synopsis length should be less than 10,000");
    } else if (createEvent?.instructions?.length > 10000) {
      toast.error("T&C length should be less than 10,000");
    } else if (createEvent?.eventStatus == "") {
      toast.error("Event status cannot be empty");
    } else if (createEvent?.startDateTime == "") {
      toast.error("Start Date Time cannot be empty ");
    } else if (sDate < currNewDate) {
      toast.error("Plese Select Valid Start Date Time ");
    } else if (createEvent?.endDateTime == "") {
      toast.error("End Date Time cannot be empty ");
    } else if (sDate >= eDate) {
      toast.error("End Date Time should be greater than Start Date Time");
    } else if (createEvent?.seatingCapacity == "") {
      toast.error("Seating Capacity cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.seatingCapacity != "" &&
      createEvent?.seatingCapacity !=
        seatCategory.reduce((acc, curr) => acc + parseInt(curr?.total), 0)
    ) {
      toast.error("Seating capacity does not match total seat categorization");
    } else if (createEvent?.seatingCapacity < 1) {
      toast.error("Please Enter valid Seating Capacity");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      (seatCategory == undefined || seatCategory.length == 0)
    ) {
      toast.error("Please add a new category");
    } else if (
      createEvent?.waitingList !== "" &&
      parseInt(createEvent?.waitingList) < 0
    ) {
      toast.error("Please enter valid Waiting List");
    } else if (
      createEvent?.waitingList !== "" &&
      parseInt(createEvent?.waitingList) >
        parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error("Waiting List cannot be greater than Seating Capacity");
    } else if (createEvent?.maxNoOfSeats == "") {
      toast.error("Max. number of seats per membership cannot be empty");
    } else if (parseInt(createEvent?.maxNoOfSeats) < 1) {
      toast.error("Please enter valid Max. number of seats per membership");
    } else if (
      parseInt(createEvent?.maxNoOfSeats) >
      parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error(
        "Max. number of seats per membership cannot be greater than Seating Capacity"
      );
    } else if (createEvent?.bookingType == "") {
      toast.error("Booking Type cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice == ""
    ) {
      toast.error("Booking Price cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice < 1
    ) {
      toast.error("Please Enter valid Booking Price");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.bookingType == "PAID" &&
      seatCategory.every((category) => {
        return (
          category.price == null ||
          category.price == undefined ||
          category.price == ""
        );
      })
    ) {
      toast.error("Price per category cannot be empty");
    } else if (
      createEvent?.cancellationAllowed == 1 &&
      tempCancellation?.length < 1
    ) {
      toast.error("Please enter Cancellation Policy");
    } else if (createEvent?.eventImages?.length < 1) {
      toast.error("Upload Image is Mandatory");
    } else if (imagesEventHomeCover?.length < 1) {
      toast.error("Home Screen Cover Images is Mandatory");
    } else {
      setDisabled(true);

      // edit event API
      fetch(`${process.env.REACT_APP_API_URL}/api/event/event-update`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      })
        .then((d) => d.json())
        .then((res) => {
          if (res.status_code == 101) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else if (res.status_code == 102) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else if (res?.success == true) {
            toast(`${res?.message}`, {
              type: "success",
            });
            navigate({
              pathname: process.env.REACT_APP_PATH + "/events",
              search: `?event_id=${id}`,
            });
          }
          setDisabled(false);
        })
        .catch((err) => {
          setDisabled(false);
        });
    }
  };
  //-----------------dynamic Form----------------------------------
  const [showParticipantReg, setShowParticipantReg] = useState(false);
  const [payloadSecondry, setPayloadSecondry] = useState("");
  const [registrationFieldModal, setRegistrationFieldModal] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState([]);

  const [
    participantRegistrationFieldModal,
    setParticipantRegistrationFieldModal,
  ] = useState(false);
  // const [showParticipantRegModal, setShowParticipantReg] = useState(true);
  const [activeRegistrationBtn, setActiveRegistrationBtn] = useState("");
  const [participantValue, setParticipantValue] = useState("{");
  // const [registrationFieldModal, setRegistrationFieldModal] = useState(false);
  const [dropDownFieldType, setDropDownFieldType] = useState([]);
  const [audienceForm, setAudienceForm] = useState([]);
  const [selectedAudienceForm, setSelectedAudienceForm] = useState({});
  const [selectedAudienceFormDetails, setSelectedAudienceFormDetails] =
    useState([]);
  const [selectedParticipantForm, setSelectedParticipantForm] = useState({});
  const handleChangeAudienceDropdown = (selectedOption) => {
    setSelectedAudienceForm(selectedOption);
    getSelecteFormDetailApi(selectedOption.value);
  };
  const addParticipantRegistrationField = (newFields, existingformName) => {
    if (existingformName) {
      let updatedForms = [...selectedAudienceFormDetails, ...newFields];
      setSelectedAudienceFormDetails(updatedForms);
    }

    setParticipantRegistrationFieldModal(false);
  };
  const getFormList = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      request_from: "event_audience",
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get_exiting_form_list/`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setAudienceForm(
            res?.data?.Form_list?.map((i, index) => {
              return {
                label: i?.form_name,
                value: i?.id,
              };
            })
          );
        }
      })
      .catch((err) => {});
  };
  const getInputTypeList = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/event/get_request_form_custom_fields/`,
      {
        method: "POST",

        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setDropDownFieldType(
            Object.keys(res?.data).map((i, index) => {
              return {
                label: res?.data[i],
                value: i,
              };
            })
          );
        }
      })
      .catch((err) => {});
  };

  const getSelecteFormDetailApi = (formId) => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      community_type: "AC_COMM",
      form_id: formId,
      request_from: "event_audience",
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/event/get_form_details_by_id`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res?.success == true) {
          setSelectedAudienceFormDetails(
            res?.data?.map((i, index) => {
              return {
                label: i?.field_name,
                value: "",
                type: i?.field_type,
                fieldOrder: i?.event_form_id,
                isMandatory: i?.is_mandatory,
                options: i?.options,
                isVisible: i?.is_visible,
                isDelete: i?.is_deleted,
                formFieldId: i?.form_field_id,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.error("API response error:", err.message || "Unknown error");
      });
  };

  // -----------------------------------------------------------
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/event/get-event-details`, {
      method: "POST",
      body: JSON.stringify({
        event_id: id,
        club_id: loadData("Club_id"),
        user_id: loadData("Token"),
        request_by: EventConfig.requestBy,
      }),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        setBookingCapacity(parseInt(res?.data?.event?.no_of_seats_booked));
        setCreateEvent({
          name: res?.data?.event?.name,
          description: res?.data?.event?.description,
          venue_id: res?.data?.event?.venue_id,
          instructions: res?.data?.event?.instructions,
          startTime: dayjs(res?.data?.event?.event_start_time, "hh:mm A"),
          endTime: dayjs(res?.data?.event?.event_end_time, "hh:mm A"),
          eventStatus: res?.data?.event?.status,
          eventType: res?.data?.event?.type,
          isSeatingCategorization: res?.data?.event?.has_seating_category,
          seatingCapacity: res?.data?.event?.total_seats,
          seatingStyle: res?.data?.event?.seat_type,
          accessibleTo: res?.data?.event?.accessible_to,
          bookingType: res?.data?.event?.booking_type,
          bookingPrice:
            res?.data?.event?.has_seating_category == 0 &&
            res?.data?.event?.price,
          cancellationAllowed: res?.data?.event?.cancellation_allowed,
          startDate: dayjs(res?.data?.event?.start_at, "YYYY-MM-DD").$d,
          endDate: dayjs(res?.data?.event?.end_at, "YYYY-MM-DD").$d,
          waitingList: res?.data?.event?.waiting_list,
          maxNoOfSeats: res?.data?.event?.max_per_member_seats,
          tax: res?.data?.event?.tax,
          startDateTime: dayjs
            .tz(
              res?.data?.event?.start_at.split(" ")[0] +
                " " +
                res?.data?.event?.event_start_time,
              "YYYY-MM-DD hh:mm A",
              effectiveTimeZone
            )
            .tz(effectiveTimeZone),
          prevStartDateTime: dayjs
            .tz(
              res?.data?.event?.start_at.split(" ")[0] +
                " " +
                res?.data?.event?.event_start_time,
              "YYYY-MM-DD hh:mm A",
              effectiveTimeZone
            )
            .tz(effectiveTimeZone),
          endDateTime: dayjs
            .tz(
              res?.data?.event?.end_at.split(" ")[0] +
                " " +
                res?.data?.event?.event_end_time,
              "YYYY-MM-DD hh:mm A",
              effectiveTimeZone
            )
            .tz(effectiveTimeZone),
          bookingPriceGuest: res?.data?.event?.guest_price,

          allowBookingAfterStartTimePassed:
            res?.data?.event?.allow_booking_after_start_time_passed,
          invoiceDescription: res?.data?.event?.invoice_description || "",
        });
        res?.data?.event?.has_artist == 1 &&
          res?.data?.event?.event_artist &&
          setGuest(
            res?.data?.event?.event_artist?.map((i, index) => {
              return {
                guestName: i.name,
                data_url: i.image,
              };
            })
          );

        res?.data?.event?.has_artist == 1 &&
          res?.data?.event?.event_artist?.map((i, index) => {
            let state, setState;
            if (index === 0) {
              state = imagesSplGuest0;
              setState = setImagesSplGuest0;
            } else if (index === 1) {
              state = imagesSplGuest1;
              setState = setImagesSplGuest1;
            } else if (index === 2) {
              state = imagesSplGuest2;
              setState = setImagesSplGuest2;
            }
            setState([
              {
                data_url: i.image,
              },
            ]);
          });

        res?.data?.event?.event_cancellation_policies &&
          setInputFields(
            res?.data?.event?.event_cancellation_policies?.map((i, index) => {
              return {
                duration: i.duration,
                deduction: i.deduction,
              };
            })
          );

        setValue([
          dayjs(res?.data?.event?.start_at, "YYYY-MM-DD").$d,
          dayjs(res?.data?.event?.end_at, "YYYY-MM-DD").$d,
        ]);

        res?.data?.event?.all_event_images &&
          setImages(
            res?.data?.event?.all_event_images
              ?.filter((i, index) =>
                i.type == "EVENT" || i.type == "MAIN" ? i : ""
              )
              .map((img, index) => {
                img.is_main == true && setMarkCoverId(index);
                return {
                  path: img.path,
                  // images_name: img.imageName,
                  // img_status: 0,
                };
              })
          );

        //----------------
        res?.data?.event?.all_event_images &&
          setImagesEvent(
            res?.data?.event?.all_event_images
              ?.filter((i, index) =>
                i.type == "EVENT" || i.type == "MAIN" ? i : ""
              )
              .map((img, index) => {
                return {
                  path: img.path,
                  // images_name: img.imageName,
                  // img_status: 0,
                };
              })
          );

        res?.data?.event?.all_event_images &&
          setImagesHomeCover(
            res?.data?.event?.all_event_images
              ?.filter((i, index) => (i.type == "HOME_SCREEN" ? i : ""))
              .map((img, index) => {
                return {
                  path: img.path,
                  // images_name: img.imageName,
                  // img_status: 0,
                };
              })
          );

        res?.data?.event?.all_event_images &&
          setImagesEventHomeCover(
            res?.data?.event?.all_event_images
              ?.filter((i, index) => (i.type == "HOME_SCREEN" ? i : ""))
              .map((img, index) => {
                return {
                  path: img.path,
                  // images_name: img.imageName,
                  // img_status: 0,
                };
              })
          );
        //for setting location in drop down-------------------
        setSelectedVenue({
          label: res?.data?.event?.event_venue?.name,
          value: res?.data?.event?.event_venue?.id,
        });
        setSelectedEventTypes({
          label: res?.data?.event?.event_type?.name,
          value: res?.data?.event?.event_type?.id,
        });
        setSelectedLanguage({
          label: res?.data?.event?.event_language?.name,
          value: res?.data?.event?.event_language?.id,
        });
        setSelectedSuitableAgeGroups({
          label: res?.data?.event?.target_audience?.name,
          value: res?.data?.event?.target__audience?.id,
        });
        process.env.REACT_APP_PRODUCT != "DLF"
          ? setSeatCategory(
              Object.keys(res?.data?.event?.seats_booked).map((i, index) => {
                return {
                  id: index + 1,
                  name: i,
                  price: res?.data?.event?.seats_booked[i].price_per_seat,
                  total: res?.data?.event?.seats_booked[i]?.total_seats,
                  waiting: res?.data?.event?.seats_booked[i]?.waiting_seats,
                  guest_price:
                    res?.data?.event?.seats_booked[i]?.guest_price_per_seat,
                };
              })
            )
          : setSeatCategory(
              Object.keys(res?.data?.event?.seats_booked).map((i, index) => {
                return {
                  id: index + 1,
                  name: i,
                  price: res?.data?.event?.seats_booked[i].price_per_seat,
                  total: res?.data?.event?.seats_booked[i]?.total_seats,
                  waiting: res?.data?.event?.seats_booked[i]?.waiting_seats,
                };
              })
            );
        process.env.REACT_APP_PRODUCT != "DLF" &&
          setShowGuest(
            res?.data?.event?.guest_price_status === 1 ? true : false
          );
        process.env.REACT_APP_PRODUCT != "DLF" &&
          setGuestSeatCategoryBtn(
            res?.data?.event?.guest_no_category === 1 ? true : false
          );
        res?.data?.event?.participant_form_id !== null &&
          setSelectedParticipantForm({
            label: res?.data?.event?.participant_form_name,
            value: res?.data?.event?.participant_form_id,
          });
        res?.data?.event?.audience_form_id !== null &&
          setSelectedAudienceForm({
            label: res?.data?.event?.audience_form_name,
            value: res?.data?.event?.audience_form_id,
          });
        setParticipantValue({
          isParticipantRegAllowed: res?.data?.event?.is_participant_form,
          isParticipantRegAllowedAfterEvent:
            res?.data?.event?.is_participant_allowed_after_start,
          participantCount: res?.data?.event?.participant_count,
        });
        getSelecteFormDetailApi(res?.data?.event?.audience_form_id);
      })
      .catch((err) => {});
    if (IS_REGISTRATION_FLOW) {
      getFormList();
      getInputTypeList();
    }
  }, []);
  const [discardModal, setDiscardModal] = useState(false);
  const onOpenDiscardModal = () => setDiscardModal(true);
  const onCloseDiscardModal = () => setDiscardModal(false);

  //seat categorization---------------------------------------
  const [seatCategorizationModal, setSeatCategorizationModal] = useState(false);
  const [seatCategory, setSeatCategory] = useState([]);

  const addSeatCategory = (categoryName, isWaitingList) => {
    setSeatCategory([
      ...seatCategory,
      isWaitingList == "yes"
        ? {
            id: 1,
            name: categoryName,
            total: "",
            waiting: "0",
            price: "",
          }
        : {
            id: 1,
            name: categoryName,
            total: "",
            price: "",
          },
    ]);
  };
  const removeSeatCategory = (index) => {
    const rows = [...seatCategory];
    rows.splice(index, 1);
    setSeatCategory(rows);
  };
  //-----------------AC----------------------------
  const handleSubmitAc = () => {
    let tempImagesArr1 = [];
    imagesEvent.map((i, index) => {
      markCoverId == index
        ? tempImagesArr1.push({
            ...i,
            is_main: true,
          })
        : tempImagesArr1.push({
            ...i,
            is_main: false,
          });
    });
    // Get the current date in the specified timezone
    let currNewDate = dayjs().tz(effectiveTimeZone).toDate();

    // Convert start and end times to the specified timezone
    let sDate = dayjs(createEvent.startDateTime).tz(effectiveTimeZone).toDate();
    let eDate = dayjs(createEvent.endDateTime).tz(effectiveTimeZone).toDate();

    // Format the start date and previous start date according to the specified timezone
    let sDate1 = dayjs(createEvent.startDateTime)
      .tz(effectiveTimeZone)
      .format("DD MM YYYY hh:mm A");
    let sDatePrev = dayjs(createEvent.prevStartDateTime)
      .tz(effectiveTimeZone)
      .format("DD MM YYYY hh:mm A");

    let payload = {
      event_id: id,
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      description: createEvent.description,
      venue_id: selectedVenue?.value,
      instructions: createEvent.instructions,
      event_start_time: dayjs(createEvent.startDateTime.$d)
        .tz(effectiveTimeZone)
        .format("hh:mm A"),
      event_end_time: dayjs(createEvent.endDateTime.$d)
        .tz(effectiveTimeZone)
        .format("hh:mm A"),
      status: createEvent.eventStatus,
      type: createEvent.eventType,
      total_seats: createEvent.seatingCapacity,
      seat_type: createEvent.seatingStyle,
      accessible_to: createEvent.accessibleTo,
      target_audience: selectedSuitableAgeGroups?.value,
      has_seating_category: parseInt(createEvent?.isSeatingCategorization),
      booking_type: createEvent.bookingType,
      price:
        createEvent?.bookingType == "PAID" &&
        createEvent?.isSeatingCategorization == 0 &&
        createEvent?.bookingPrice,
      cancellation_allowed: createEvent?.cancellationAllowed,
      waiting_list: 0,
      max_per_member_seats: createEvent?.maxNoOfSeats,
      tax: createEvent?.tax,
      start_at: dayjs(createEvent?.startDateTime)
        .tz(effectiveTimeZone)
        .format("YYYY-MM-DD hh:mm A"),
      end_at: dayjs(createEvent?.endDateTime)
        .tz(effectiveTimeZone)
        .format("YYYY-MM-DD hh:mm A"),
      has_artist: 0,
      images: tempImagesArr1,
      homescreen_image: imagesEventHomeCover[0]?.path,
      guest_price_status: showGuest ? 1 : 0,
      guest_no_category: guestSeatCategoryBtn ? 1 : 0,
      allow_booking_after_start_time_passed:
        createEvent?.allowBookingAfterStartTimePassed,
    };
    if (createEvent?.bookingType == "PAID") {
      payload["invoice_description"] = createEvent?.invoiceDescription;
    }
    if (parseInt(createEvent?.isSeatingCategorization) == 1) {
      payload["seats"] =
        createEvent?.isSeatingCategorization == 1 && seatCategory;
    }
    if (imagesEventHomeCover.length <= 0) {
      payload["homescreen_image"] = "deleted";
    }
    let tempGuest = guest
      ?.filter((i, index) => {
        return (
          i.guestName !== "" ||
          (i.data_url !== "" &&
            i.data_url !== undefined &&
            i.data_url !== null &&
            i)
        );
      })
      .map((i, index) => {
        return {
          name: i.guestName,
          image: i.data_url,
        };
      });
    if (tempGuest?.length > 0) {
      payload["has_artist"] = 1;
    }
    if (tempGuest?.length > 0 && payload["has_artist"] == 1) {
      payload["artists"] = tempGuest;
    }
    let tempCancellation = createEvent.cancellationPolicy?.filter(
      (i, index) => {
        return i.duration !== "" && i.deduction !== "" ? i : "";
      }
    );
    payload["cancellation"] = tempCancellation;
    let tempSeatCategorization = seatCategory.filter((i, index) => {
      return i.total == "" ||
        i.waiting == "" ||
        i.total == 0 ||
        i.total < i.waiting
        ? i
        : "";
    });
    if (createEvent.isSeatingCategorization == 0 && !guestSeatCategoryBtn) {
      payload["guest_price"] = createEvent.bookingPriceGuest;
    }
    if (Object.keys(selectedAudienceForm)?.length > 0) {
      let tempForm = selectedAudienceFormDetails?.map((i, index) => {
        let tempOption = i?.options
          ?.filter((option) => option.label !== "")
          ?.map((j, idx) => {
            return j?.label;
          });
        return {
          field_name: i?.label,
          field_type: i?.type?.value,
          is_mandatory: i?.isMandatory,
          options: tempOption?.length > 0 ? tempOption : [],
          is_visible: i?.isVisible,
          is_deleted: i?.isDelete,
          status: i?.status ? 1 : 0,
          form_field_id: i?.formFieldId,
          field_order: index,
        };
      });
      payload["audience_form_id"] = selectedAudienceForm?.value;
      payload["audience_form"] = tempForm;
    }
    if (createEvent?.description == "") {
      toast.error("Please Enter Description");
    } else if (createEvent?.description?.length < 30) {
      toast.error("Minimum Description Length should be 30");
    } else if (createEvent?.description?.length > 700) {
      toast.error("Description length should be less than 700");
    } else if (createEvent?.instructions?.length > 500) {
      toast.error("Instruction length should be less than 500");
    } else if (createEvent?.eventStatus == "") {
      toast.error("Event status cannot be empty");
    } else if (createEvent?.startDateTime == "") {
      toast.error(" Start Date Time cannot be empty ");
    } else if (sDatePrev !== sDate1 && sDate < currNewDate) {
      toast.error("Please Select Valid Start Date Time ");
    } else if (createEvent?.endDateTime == "") {
      toast.error(" End Date Time cannot be empty ");
    } else if (sDate >= eDate) {
      toast.error("End Date Time should be greater than Start Date Time");
    } else if (
      tempGuest?.length > 0 &&
      payload["has_artist"] == true &&
      tempGuest?.some((category) => {
        return (
          category.name == null ||
          category.name == undefined ||
          category.name == ""
        );
      })
    ) {
      toast.error("Enter valid artist name");
    } else if (
      tempGuest?.length > 0 &&
      payload["has_artist"] == true &&
      tempGuest?.some((category) => {
        return (
          category.image == null ||
          category.image == undefined ||
          category.image == ""
        );
      })
    ) {
      toast.error("Please upload artist image");
    } else if (createEvent?.seatingCapacity == "") {
      toast.error("Seating Capacity cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.seatingCapacity != "" &&
      createEvent?.seatingCapacity !=
        seatCategory.reduce((acc, curr) => acc + parseInt(curr?.total), 0)
    ) {
      toast.error("Seating capacity does not match total seat categorization");
    } else if (createEvent?.maxNoOfSeats == "") {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Max. Number of Seats Per Occupant cannot be empty"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Max. Number of Seats Per Employee cannot be empty"
            : "Max. number of seats per unit cannot be empty"
        }`
      );
    } else if (parseInt(createEvent?.maxNoOfSeats) < 1) {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Please enter valid Max. number of seats per occupant"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Please enter valid Max. number of seats per employee"
            : "Please enter valid Max. number of seats per unit"
        }`
      );
    } else if (
      parseInt(createEvent?.maxNoOfSeats) >
      parseInt(createEvent?.seatingCapacity)
    ) {
      toast.error(
        `${
          profileDetails?.comm_type == "Federation Parent Community"
            ? "Max. number of seats per occupant cannot be greater than seating capacity"
            : profileDetails?.comm_type == "Federation Child Community"
            ? "Max. number of seats per employee cannot be greater than seating capacity"
            : "Max. number of seats per unit cannot be greater than seating capacity"
        }`
      );
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice == ""
    ) {
      toast.error("Booking Price cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPrice < 1
    ) {
      toast.error("Please Enter valid Booking Price");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      !guestSeatCategoryBtn &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPriceGuest == ""
    ) {
      toast.error("Price for guest cannot be empty");
    } else if (
      createEvent?.isSeatingCategorization == 0 &&
      !guestSeatCategoryBtn &&
      createEvent?.bookingType == "PAID" &&
      createEvent?.bookingPriceGuest < 1
    ) {
      toast.error("please enter valid price for guest");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.bookingType == "PAID" &&
      seatCategory.some((category) => {
        return (
          category.price == null ||
          category.price == undefined ||
          category.price == "" ||
          category.price == 0
        );
      })
    ) {
      toast.error("Enter valid price per category");
    } else if (
      createEvent?.isSeatingCategorization == 1 &&
      createEvent?.bookingType == "PAID" &&
      showGuest == false &&
      seatCategory.some((category) => {
        return (
          category.guest_price == null ||
          category.guest_price == undefined ||
          category.guest_price == "" ||
          category.guest_price == 0
        );
      })
    ) {
      toast.error("Enter valid guest price per category");
    } else if (
      createEvent?.bookingType == "PAID" &&
      createEvent?.invoiceDescription == ""
    ) {
      toast.error("Please Enter Invoice Description");
    } else if (
      createEvent?.bookingType == "PAID" &&
      createEvent?.invoiceDescription?.length < 10
    ) {
      toast.error("Minimum Invoice Description Length should be 10");
    } else if (
      createEvent?.bookingType == "PAID" &&
      createEvent?.invoiceDescription?.length > 500
    ) {
      toast.error("Invoice Description length should be less than 500");
    } else if (
      createEvent?.cancellationAllowed == 1 &&
      tempCancellation?.length < 1
    ) {
      toast.error("Please enter Cancellation Policy");
    } else if (createEvent?.eventImages?.length < 1) {
      toast.error("Upload Image is Mandatory");
    } else if (imagesEventHomeCover?.length < 1) {
      toast.error("Home Screen Cover Images is Mandatory");
    } else if (IS_REGISTRATION_FLOW) {
      setPayloadSecondry(payload);
      setShowParticipantReg(true);
    } else {
      setDisabled(true);
      handleFormSubmit(payload);
    }
  };
  const handleFormSubmit = (payload) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/event/event-update`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "content-type": "application/json",
        Authorization: loadData("Auth"),
      },
    })
      .then((d) => d.json())
      .then((res) => {
        if (res.status_code == 101) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res.status_code == 102) {
          toast(`${res.message}`, {
            type: "error",
          });
        } else if (res?.success == true) {
          toast(`${res?.message}`, {
            type: "success",
          });
          navigate({
            pathname: process.env.REACT_APP_PATH + "/",
            search: `?event_id=${id}`,
          });
        }
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
      });
  };
  return (
    <>
      {!showParticipantReg && (
        <div>
          <TopHeader title={"Edit Event"} />
          <div className={styles.creatEventMainDiv}>
            <DiscardModal
              open={discardModal}
              onCloseModal={onCloseDiscardModal}
              msg={"Are you sure you want to discard the changes"}
            />
            <AddSeatCategorization
              title={"Add Category"}
              open={seatCategorizationModal}
              onCloseModal={() => setSeatCategorizationModal(false)}
              addSeatCategory={addSeatCategory}
              removeSeatCategory={removeSeatCategory}
              addSeatCategoryAc={addSeatCategoryAc}
            />
            {IS_REGISTRATION_FLOW && profileDetails?.show_audience_flow && (
              <RegistrationFieldModal
                title={"Add New Field"}
                open={participantRegistrationFieldModal}
                onCloseModal={() => setParticipantRegistrationFieldModal(false)}
                dropDownFieldType={dropDownFieldType}
                activeRegistrationBtn={activeRegistrationBtn}
                setActiveRegistrationBtn={setActiveRegistrationBtn}
                selectedAudienceForm={selectedAudienceForm}
                addParticipantRegistrationField={
                  addParticipantRegistrationField
                }
                getFormList={getFormList}
                selectedAudienceFormDetails={selectedAudienceFormDetails}
              />
            )}
            <EventDetails
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleCreateEventChange={handleCreateEventChange}
              handleTimePicker={handleTimePicker}
              handleCalender={handleCallbackforClosingOn}
              guest={guest}
              setGuest={setGuest}
              isEdit={true}
              value={value}
              setValue={setValue}
              handleDateTimePicker={handleDateTimePicker}
              images0={imagesSplGuest0}
              setImages0={setImagesSplGuest0}
              images1={imagesSplGuest1}
              setImages1={setImagesSplGuest1}
              images2={imagesSplGuest2}
              setImages2={setImagesSplGuest2}
              handleEventTypesChange={handleEventTypesChange}
              selectedEventTypes={selectedEventTypes}
              eventTypes={eventTypes}
              handleSuitableAgeGroupsChange={handleSuitableAgeGroupsChange}
              selectedSuitableAgeGroups={selectedSuitableAgeGroups}
              suitableAgeGroups={suitableAgeGroups}
              handleVenueChange={handleVenueChange}
              selectedVenue={selectedVenue}
              venues={venues}
              handleLanguageChange={handleLanguageChange}
              selectedLanguage={selectedLanguage}
              languages={languages}
              eventStatus={eventStatus}
            />
            <Seatings
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleCreateEventChange={handleCreateEventChange}
              setSeatCategorizationModal={setSeatCategorizationModal}
              seatCategory={seatCategory}
              setSeatCategory={setSeatCategory}
              removeSeatCategory={removeSeatCategory}
              isEdit={true}
            />
            <Financials
              createEvent={createEvent}
              setCreateEvent={setCreateEvent}
              handleCreateEventChange={handleCreateEventChange}
              inputFields={inputFields}
              setInputFields={setInputFields}
              isEdit={true}
              showGuest={showGuest}
              setShowGuest={setShowGuest}
              seatCategory={seatCategory}
              setSeatCategory={setSeatCategory}
              guestSeatCategory={guestSeatCategory}
              setGuestSeatCategory={setGuestSeatCategory}
              guestSeatCategoryBtn={guestSeatCategoryBtn}
              setGuestSeatCategoryBtn={setGuestSeatCategoryBtn}
              handleBookingType={handleBookingType}
            />
            <UploadImageForm
              title={"Event Images"}
              imagesEvent={imagesEvent}
              setImagesEvent={setImagesEvent}
              images={images}
              setImages={setImages}
              maxNumber={5}
              note={"(Resolution 900px * 440px)"}
              resolutionWidth={900}
              resolutionHeight={440}
              markCoverId={markCoverId}
              setMarkCoverId={setMarkCoverId}
              showCover={true}
              deletedImages={deletedImages}
              setDeletedImages={setDeletedImages}
            />
            <UploadImageForm
              title={"Home Screen Cover Image"}
              imagesEvent={imagesEventHomeCover}
              setImagesEvent={setImagesEventHomeCover}
              images={imagesHomeCover}
              setImages={setImagesHomeCover}
              maxNumber={1}
              note={"(Resolution 710px * 960px)"}
              resolutionWidth={710}
              resolutionHeight={960}
              deletedImages={deletedImagesHome}
              setDeletedImages={setDeletedImagesHome}
            />

            {IS_REGISTRATION_FLOW && profileDetails?.show_audience_flow && (
              <RegistrationDetail
                selectedAudienceForm={selectedAudienceForm}
                selectedAudienceFormDetails={selectedAudienceFormDetails}
                setSelectedAudienceFormDetails={setSelectedAudienceFormDetails}
                audienceForm={audienceForm}
                handleChangeAudienceDropdown={handleChangeAudienceDropdown}
                setActiveRegistrationBtn={setActiveRegistrationBtn}
                participantRegistrationFieldModal={
                  participantRegistrationFieldModal
                }
                setParticipantRegistrationFieldModal={
                  setParticipantRegistrationFieldModal
                }
                getFormList={getFormList}
              />
            )}
            {SHOW_REGISTRATION_PAGE ? (
              <span className={styles.btnCreateEvent}>
                <button
                  className={styles.discardBtn}
                  onClick={() => onOpenDiscardModal()}
                >
                  {process.env.REACT_APP_PRODUCT == "DLF" ? "Back" : "Discard"}
                </button>
                <button
                  className={styles.createBtn}
                  onClick={() => {
                    process.env.REACT_APP_PRODUCT != "DLF"
                      ? handleSubmitAc()
                      : handleSubmit();
                  }}
                  disabled={isDisabled}
                >
                  Save Changes
                </button>
              </span>
            ) : (
              <span className={styles.saveBtn}>
                <button
                  disabled={isDisabled}
                  className={styles.createBtn}
                  onClick={() => {
                    handleSubmitAc();
                  }}
                >
                  Next
                </button>
              </span>
            )}

            <ToastContainer />
          </div>
        </div>
      )}
      {IS_REGISTRATION_FLOW && showParticipantReg && (
        <div>
          <TopHeader title={"Registration Event"} />
          <RegistrationDetailPage
            payloadSecondry={payloadSecondry}
            handleFormSubmit={handleFormSubmit}
            selectedParticipantForm={selectedParticipantForm}
            setSelectedParticipantForm={setSelectedParticipantForm}
            participantValueSecondry={participantValue}
            dropDownFieldType={dropDownFieldType}
            setShowParticipantReg={setShowParticipantReg}
          />
        </div>
      )}
    </>
  );
};
