import React from "react";
import styles from "./uploadImageForm.module.css";
import ImageUploading from "react-images-uploading";
import "react-toastify/dist/ReactToastify.css";

export const UploadImageForm = ({
  title,
  note,
  resolutionWidth,
  resolutionHeight,
  imagesEvent,
  setImagesEvent,
  images,
  setImages,
  showCover,
  maxNumber,
  markCoverId,
  setMarkCoverId,
  deletedImages,
  setDeletedImages,
}) => {
  // setImages - for uploading images first time from storage
  // setImagesEvent -

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImagesEvent(
      imageList?.map((i, index) => {
        return {
          path: i?.path,
        };
      })
    );
  };

  const onRemoveImage = (index) => {
    if (index == markCoverId) {
      setMarkCoverId(0);
    }
    const rows = [...imagesEvent];
    const del_arr = rows
      .filter((i, idx) => (idx == index && i?.img_status == 0 ? i : ""))
      .map((i, idx) => {
        return {
          path: i.path,
        };
      });
    del_arr.length > 0 && setDeletedImages([...deletedImages, del_arr[0]]);
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>
        <p>
          {title}
          <span
            style={{ color: "#74676E", fontSize: "12px", marginLeft: "5px" }}
          >
            {note}
          </span>{" "}
          <span className={styles.star}>*</span>
        </p>
      </div>
      <div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="path"
          acceptType={["jpg", "jpeg", "png"]}
          maxFileSize="3500000"
          resolutionWidth={resolutionWidth}
          resolutionHeight={resolutionHeight}
          resolutionType="absolute"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => {
            return (
              <>
                <div className={styles.uploadImgDiv}>
                  <div className={styles.previewDiv}>
                    {images?.length !== 0 ? (
                      <>
                        {imageList?.map((image, index) => (
                          <div key={index} className={styles.imgIconDiv}>
                            <div
                              className={styles.closeIcon}
                              onClick={() => {
                                onRemoveImage(index);
                                onImageRemove(index);
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/cancelEventActive.png"
                                }
                                alt="removeIcon"
                              />
                            </div>
                            <img src={image.path} alt="" />
                            {showCover && (
                              <div className={styles.coverDiv}>
                                <input
                                  type="radio"
                                  width={300}
                                  height={44}
                                  name={index}
                                  value={index}
                                  checked={markCoverId == index}
                                  onChange={() => setMarkCoverId(index)}
                                />
                                <p style={{ color: "#121212" }}>
                                  Mark as Cover
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className={styles.imgIconDiv}>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/ImageUpload.png"
                            }
                          />
                        </div>
                        <p className={styles.imgSizeText}>
                          Upload Images <span>(Less than 3.5mb)</span>
                        </p>
                      </>
                    )}
                  </div>
                  {images?.length < maxNumber && (
                    <button
                      style={isDragging ? { color: "red" } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                      className={styles.browseBtn}
                    >
                      Browse
                    </button>
                  )}
                </div>
                {errors?.maxNumber && (
                  <span className={styles.error}>
                    You can only upload a maximum of {maxNumber} images
                  </span>
                )}
                {errors?.maxFileSize && (
                  <span className={styles.error}>
                    Selected file size exceeds Max File Size
                  </span>
                )}
                {errors?.acceptType && (
                  <span className={styles.error}>
                    Selected file type is not allowed
                  </span>
                )}
                {errors?.resolution && (
                  <span className={styles.error}>
                    Selected file does not match your desired resolution
                  </span>
                )}
              </>
            );
          }}
        </ImageUploading>
      </div>
    </div>
  );
};
