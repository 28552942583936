import React, { useEffect, useState } from "react";
import styles from "./events.module.css";
import { useSearchParams } from "react-router-dom";
import { EventConfig } from "./EventConfig";
import { useDispatch, useSelector } from "react-redux";
import { activeTab } from "../Components/Redux/tabSlice";
import { eventFilter } from "../Components/Redux/eventsSlice";
import { EventsList } from "./EventsList/EventsList";
import { EventsDetails } from "./EventsDetails/EventsDetails";
import { TopHeader } from "./TopHeader/TopHeader";
import SearchAndFilter from "../Components/SearchAndFilter/SearchAndFilter";
import { Logo } from "../Components/Logo";
import { loadData } from "../Utils/localStorage";

export const Events = () => {
  const {
    searchValue,
    accessibleTo,
    eventType,
    status,
    eventDateCalender,
    startCalender,
    endCalender,
    eventMonth,
    startCalenderDate,
    endCalenderDate,
  } = useSelector((state) => state.searchSlice);
  const dispatch = useDispatch();
  const { events, EventLoading } = useSelector((state) => state.eventsSlice);
  const { eventRights } = useSelector((state) => state.authSlice);
  const { clubId } = useSelector((state) => state.authSlice);
  const [searchParam, setSearchParam] = useSearchParams();
  const [paramId, setparamId] = useState(+searchParam.get("event_id"));

  //search
  const handleSearch = () => {
    let payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
    };
    if (searchValue !== "") {
      payload["name"] = searchValue;
    }
    if (accessibleTo !== "") {
      payload["accessible_to"] = accessibleTo;
    }
    if (eventType !== "") {
      payload["booking_type"] = eventType;
    }
    if (status !== "") {
      payload["status"] = status;
    }
    if (eventDateCalender !== "") {
      payload["event_date"] = eventDateCalender;
    }
    if (eventMonth !== "") {
      payload["month_year"] = eventMonth;
    }
    if (startCalender !== "" && endCalender !== "") {
      payload["start_date"] = startCalender;
      payload["end_date"] = endCalender;
    }
    if (startCalenderDate !== "" && endCalenderDate !== "") {
      payload["start_date_calender"] = startCalenderDate;
      payload["end_date_calender"] = endCalenderDate;
    }
    if (
      searchValue ||
      eventType ||
      accessibleTo ||
      status ||
      eventDateCalender ||
      eventMonth ||
      startCalender ||
      startCalenderDate
    ) {
      dispatch(eventFilter({ args: payload }));
    } else {
      dispatch(eventFilter({ args: payload, paramId }));
    }
  };

  useEffect(() => {
    dispatch(activeTab(2));
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch();
    }, 500);
    return () => clearTimeout(timer);
  }, [
    status,
    accessibleTo,
    eventType,
    startCalender,
    eventDateCalender,
    eventMonth,
    searchValue,
    clubId,
    startCalenderDate,
  ]);

  return (
    <>
      <div
        className={`${styles.mainDivEvents}  ${
          process.env.REACT_APP_PRODUCT !== "DLF" && styles.incresedLeftPdding
        }`}
      >
        <TopHeader title={"Events"} showBtn={true} />
        <SearchAndFilter
          handleSearch={handleSearch}
          showDate={true}
          showEventType={true}
          showEventAccessibleTo={true}
          filterType={"eventFilter"}
        />
        {EventLoading ? (
          <Logo />
        ) : (
          <>
            {events?.length !== 0 ? (
              <div className={styles.mainMidDivEvents}>
                <div className={styles.divEventList}>
                  <EventsList
                    searchParam={searchParam}
                    setSearchParam={setSearchParam}
                    paramId={paramId}
                    setparamId={setparamId}
                  />
                </div>
                <EventsDetails />
              </div>
            ) : (
              !EventLoading && (
                <div className={styles.noDataMainDiv}>
                  <div className={styles.noDataDiv}>
                    <span className={styles.noDataImgDiv}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/${process.env.REACT_APP_IMAGES}/NoData.png`
                        }
                      />
                    </span>
                    <h2>Not Found</h2>
                    <p>Search did not match current event listed</p>
                  </div>
                </div>
              )
            )}
          </>
        )}
      </div>
    </>
  );
};
