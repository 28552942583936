import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadData } from "../../Utils/localStorage";
import axios from "axios";

export const getAllSalonSpaServices = createAsyncThunk(
  "getAllSalonSpaServices",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-service",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const getCategories = createAsyncThunk(
  "getCategories",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-category",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const getAllSalonServiceById = createAsyncThunk(
  "getAllSalonServiceById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-service",
        {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
          service: "SALON",
          ids: [id],
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data?.services;
      return result;
    } catch (err) {
      return rejectWithValue(
        "Oops found an error in getRestaurantsDetailsById",
        err.response.data
      );
    }
  }
);

export const getAllSpaServiceById = createAsyncThunk(
  "getAllSpaServiceById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-service",
        {
          club_id: loadData("Club_id"),
          user_id: loadData("Token"),
          request_by: "Admin",
          service: "SPA",
          ids: [id],
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data?.services;
      return result;
    } catch (err) {
      return rejectWithValue(
        "Oops found an error in getRestaurantsDetailsById",
        err.response.data
      );
    }
  }
);

export const getBookingHistorySalonSpa = createAsyncThunk(
  "getBookingHistorySalonSpa",
  async (args, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/services/get-orders",
        {
          method: "POST",
          body: JSON.stringify(args),
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = await response.json();
      return result;
    } catch (err) {
      return rejectWithValue("Opps found an error", err.response.data);
    }
  }
);

export const getAllSpaProfessionals = createAsyncThunk(
  "getAllSpaProfessionals",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professionals",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const getAllProfessionals = createAsyncThunk(
  "getAllProfessionals",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professionals",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const getAllProfessionalsById = createAsyncThunk(
  "getAllProfessionalsById",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professionals",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const getServicesAssignedAndNumberOfBookings = createAsyncThunk(
  "getServicesAssignedAndNumberOfBookings",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professional-data",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const getSpaServicesAssignedAndNumberOfBookings = createAsyncThunk(
  "getSpaServicesAssignedAndNumberOfBookings",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/services/get-professional-data",
        args,
        {
          headers: {
            "content-type": "application/json",
            Authorization: loadData("Auth"),
          },
        }
      );
      const result = response?.data?.data;
      return result;
    } catch (err) {
      return rejectWithValue("Oops found an error", err.response?.data);
    }
  }
);

export const salonSpaServicesSlice = createSlice({
  name: "salonSpaServicesSlice",
  initialState: {
    loading: false,
    error: null,
    Loader: false,
    salonSpaServicesList: [],
    salonServicesCategories: [],
    salonServiceDetails: {},
    salonSpaServicesActiveTab: "",
    bookingHistoryData: [],
    totalBookingHistoryCount: 0,
    professionalsList: [],
    spaProfessionalsList: [],
    professionalDetails: {},
    professionalsActiveTab: "",
    spaProfessionalsActiveTab: "",
    servicesAssigned: [],
    spaServicesAssigned: [],
    noOfBookingsAssignedForProf: [],
    noOfSpaBookingsAssignedForProf: [],
  },
  reducers: {
    searchUser: (state, action) => {
      state.searchData = action?.payload;
    },
    handlesalonSpaServicesActiveTab: (state, action) => {
      state.salonSpaServicesActiveTab = action?.payload;
    },
    handleProfessionalsActiveTab: (state, action) => {
      state.professionalsActiveTab = action?.payload;
    },
    handleSpaProfessionalsActiveTab: (state, action) => {
      state.spaProfessionalsActiveTab = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSalonSpaServices.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getAllSalonSpaServices.fulfilled, (state, action) => {
        state.loading = false;
        state.salonSpaServicesList = action?.payload?.services;
        // state.salonServicesCategories = action?.payload?.service_categories;
        state.salonSpaServicesActiveTab = action?.payload?.services[0]?.id;
        state.Loader = false;
      })
      .addCase(getAllSalonSpaServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.Loader = false;
      })
      .addCase(getCategories.pending, (state, action) => {
        state.loading = true;
        state.Loader = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.salonServicesCategories = action?.payload?.categories;
        state.Loader = false;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
        state.Loader = false;
      })
      .addCase(getAllSalonServiceById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSalonServiceById.fulfilled, (state, action) => {
        state.loading = false;
        state.salonServiceDetails = action?.payload;
      })
      .addCase(getAllSalonServiceById.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getAllSpaServiceById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSpaServiceById.fulfilled, (state, action) => {
        state.loading = false;
        state.salonServiceDetails = action?.payload;
      })
      .addCase(getAllSpaServiceById.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getBookingHistorySalonSpa.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBookingHistorySalonSpa.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryData = action?.payload?.data?.orders;
        state.totalBookingHistoryCount = action?.payload?.data?.records;
      })
      .addCase(getBookingHistorySalonSpa.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getAllProfessionals.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllProfessionals.fulfilled, (state, action) => {
        state.loading = false;
        state.professionalsList = action?.payload?.professionals;
        state.professionalsActiveTab = action?.payload?.professionals[0]?.id;
      })
      .addCase(getAllProfessionals.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getAllSpaProfessionals.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllSpaProfessionals.fulfilled, (state, action) => {
        state.loading = false;
        state.spaProfessionalsList = action?.payload?.professionals;
        state.spaProfessionalsActiveTab = action?.payload?.professionals[0]?.id;
      })
      .addCase(getAllSpaProfessionals.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getAllProfessionalsById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllProfessionalsById.fulfilled, (state, action) => {
        state.loading = false;
        state.professionalDetails = action?.payload?.professionals;
      })
      .addCase(getAllProfessionalsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(
        getServicesAssignedAndNumberOfBookings.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        getServicesAssignedAndNumberOfBookings.fulfilled,
        (state, action) => {
          state.loading = false;
          state.servicesAssigned = action?.payload?.services;
          state.noOfBookingsAssignedForProf = action?.payload?.bookings;
        }
      )
      .addCase(
        getServicesAssignedAndNumberOfBookings.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.payload;
        }
      )
      .addCase(
        getSpaServicesAssignedAndNumberOfBookings.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        getSpaServicesAssignedAndNumberOfBookings.fulfilled,
        (state, action) => {
          state.loading = false;
          state.spaServicesAssigned = action?.payload?.services;
          state.noOfSpaBookingsAssignedForProf = action?.payload?.bookings;
        }
      )
      .addCase(
        getSpaServicesAssignedAndNumberOfBookings.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.payload;
        }
      );
  },
});
export const {
  searchUser,
  handlesalonSpaServicesActiveTab,
  handleProfessionalsActiveTab,
  handleSpaProfessionalsActiveTab,
} = salonSpaServicesSlice.actions;
export default salonSpaServicesSlice.reducer;
