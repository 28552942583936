import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loadData } from "../../../Utils/localStorage";
import { OfferComponent } from "../../../Components/OfferComponent/OfferComponent";
import { getEventsDetailsbyId } from "../../../Components/Redux/eventsSlice";
import styles from "./createOffer.module.css";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const CreateOffer = ({
  open,
  onCloseModal,
  heading,
  btnTitle,
  subHeading,
}) => {
  const dispatch = useDispatch();
  const { eventsDetails, eventsActiveTab, events } = useSelector(
    (state) => state.eventsSlice
  );

  const { profileDetails } = useSelector((state) => state.authSlice);
  const effectiveTimeZone = profileDetails.comm_time_zone || dayjs.tz.guess();

  const [isDisabled, setIsDisabled] = useState(false);
  const [title, setTitle] = useState("");
  const [offer, setOffer] = useState({
    discountPercentage: "",
    discountFor: "",
    startDateTime: "",
    endDateTime: "",
  });

  const handleCloseModal = () => {
    onCloseModal();
    dispatch(getEventsDetailsbyId(eventsActiveTab));
  };

  //----------------------------dateTime
  const handleDateTimePicker = (e, Name) => {
    setOffer({
      ...offer,
      [Name]: dayjs(e.$d).format("YYYY-MM-DD h:mm A"),
    });
  };

  //---------------------
  const handleOffer = (e) => {
    const { name, value } = e.target;
    if (name == "discountPercentage") {
      if (value === "") {
        setOffer({
          ...offer,
          [name]: value,
        });
      } else if (value <= 100 && value >= 1) {
        setOffer({
          ...offer,
          [name]: value.replace(/[^0-9]/g, ""),
        });
      }
    } else {
      setOffer({
        ...offer,
        [name]: value,
      });
    }
  };

  //Images library state
  const [images, setImages] = useState([]);
  const [imagesOffer, setImagesOffer] = useState("");

  useEffect(() => {
    setOffer({
      ...offer,
      discountPercentage: "",
      discountFor: eventsDetails?.accessible_to,
      startDateTime: "",
      endDateTime: "",
    });
    setImagesOffer([]);
    setImages([]);
  }, [eventsDetails]);

  const handleCreateOffer = () => {
    if (isDisabled) {
      return;
    }
    let currNewDate = new Date();
    let currNewTime = new Date().getTime();
    let discountStart = dayjs(offer?.startDateTime, "YYYY-MM-DD hh:mm A");
    discountStart = discountStart.$d;
    let discountEnd = dayjs(offer?.endDateTime, "YYYY-MM-DD hh:mm A");
    discountEnd = discountEnd.$d;
    let eventEnd = dayjs(eventsDetails?.end_at);
    eventEnd = eventEnd["$d"];
    
    const payload = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: "Admin",
      event_id: eventsDetails?.id,
      discount_percentage: offer.discountPercentage,
      discount_for: offer.discountFor,
      start_date: dayjs(offer?.startDateTime, "YYYY-MM-DD hh:mm A").tz(effectiveTimeZone)
      .format("YYYY-MM-DD hh:mm A"),
      end_date: dayjs(offer?.endDateTime, "YYYY-MM-DD hh:mm A").tz(effectiveTimeZone)
      .format("YYYY-MM-DD hh:mm A"),
      image: imagesOffer[0]?.data_url,
    };
    if (offer.discountPercentage == "") {
      toast.error("Please Enter Discount Percentage");
    } else if (offer.discountFor == "") {
      toast.error("Please Select  Discount For");
    } else if (offer.startDateTime == "") {
      toast.error("Please Select Start Date Time");
    } 
    else if (offer.startDateTime !== "" && discountStart < currNewDate) {
      toast.error("Please select valid discount start date time");
    } 
    else if (offer.endDateTime == "") {
      toast.error("Please Select End Date Time");
    } else if (offer.endDateTime !== "" && discountEnd <= discountStart) {
      toast.error(
        "Discount end date time should be greater than Discount Start Date Time"
      );
    } else if (offer.endDateTime !== "" && discountStart > eventEnd) {
      toast.error(
        "Discount start date time should be less than event end date time"
      );
    } else if (offer.endDateTime !== "" && discountEnd > eventEnd) {
      toast.error(
        "Discount end date time should be less than event end date time"
      );
    } else if (
      process.env.REACT_APP_PRODUCT == "DLF" &&
      imagesOffer.length < 1
    ) {
      toast.error("Upload Offer Image is Mandatory");
    } else {
      setIsDisabled(true);
      fetch(process.env.REACT_APP_API_URL + "/api/event/create-offer", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      })
        .then((d) => d.json())
        .then((res) => {
          if (res.status_code == 101) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else if (res.status_code == 102) {
            toast(`${res.message}`, {
              type: "error",
            });
          } else {
            toast(`${res.message}`, {
              type: "success",
            });
            dispatch(getEventsDetailsbyId(eventsActiveTab));
            onCloseModal();
            setTimeout(() => {
              setIsDisabled(false);
            }, 1000);
          }

          // if (res.status == "success") {
          //     navigate(FacilitiesConfig.facilites);
          // }
        })
        .catch((err) => {
          setIsDisabled(false);
        });
    }
  };

  return (
    <div>
      <OfferComponent
        open={open}
        onCloseModal={handleCloseModal}
        heading={heading}
        subHeading={subHeading}
        btnTitle={btnTitle}
        title={title}
        setTitle={setTitle}
        images={images}
        setImages={setImages}
        imagesOffer={imagesOffer}
        setImagesOffer={setImagesOffer}
        handleCreateOffer={handleCreateOffer}
        offer={offer}
        handleOffer={handleOffer}
        handleDateTimePicker={handleDateTimePicker}
        details={eventsDetails}
        isDisabled={isDisabled}
        moduleName="Event"
        disableDays={false}
        // isDisable={true}
      />
    </div>
  );
};
