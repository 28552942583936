import React from "react";
import styles from "../createRestaurant.module.css";
import AccordionListener from "../../../Components/CreateRestaurantMenu/AccordionListener";

const Menu = ({ menuData }) => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>
        <p>Menu</p>
      </div>

      <div className={styles.singleRestaurantContentDiv}>
        {menuData &&
          menuData?.flatMap((mData) => {
            return (
              <>
                {(mData?.SubMenus || [])?.flatMap((subMenu, i) => {
                  return (
                    <>
                      <AccordionListener
                        key={i}
                        SubMenuName={subMenu?.SubMenuName}
                        SubMenuCode={subMenu?.SubMenuCode}
                        MenuItem={(subMenu.MenuItems || [])?.map(
                          (menuItem) => menuItem
                        )}
                      />
                    </>
                  );
                })}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default Menu;
