import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styles from "./bookingEventViewModal.module.css";
import { BookingHistoryTblEvent } from "../../BookingHistory/BookingHistoryTblEvent/BookingHistoryTblEvent";
import { getBookingHistoryById } from "../../../Components/Redux/eventsSlice";
import { loadData } from "../../../Utils/localStorage";
import { EventConfig } from "../../EventConfig";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { rupee } from "../../../Utils/Validation";
import dayjs from "dayjs";
import { getCurrencySymbolByTimeZone } from "../../../Utils/currencySymbol";

export const BookingEventViewModal = ({ open, setNoOfBooking }) => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(profileDetails?.comm_time_zone) 
  const [downloadData, setDownloadData] = useState([]);
  const columns = [
    "Booking Id",
    "Event Name",
    "Booked For",
    "Booked By",
    process.env.REACT_APP_PRODUCT != "DLF"
      ? profileDetails?.comm_type == "Federation Parent Community"
        ? "Occupant"
        : profileDetails?.comm_type == "Federation Child Community"
        ? "Employee"
        : "Unit No."
      : "Membership No.",
    process.env.REACT_APP_PRODUCT === "DLF" && "User Type",
    "Start Date & Time",
    "End Date & Time",
    "Booking Status",
    "Booked Seat",
    "Total Price",
  ];
  if (process.env.REACT_APP_PRODUCT != "DLF") {
    columns.unshift("Booking Time");
  }
  if (process.env.REACT_APP_PRODUCT != "DLF") {
    columns.splice(6, 1);
  }
  const dispatch = useDispatch();
  const {
    bookingHistoryEvents,
    eventsActiveTab,
    eventsDetails,
    totalBookingHistoryCount,
  } = useSelector((state) => state.eventsSlice);

  // pagination
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);

  const handleOffset = (val) => {
    if (val == "next") {
      if (offset + bookingHistoryEvents?.length < totalBookingHistoryCount) {
        setOffset(
          offset + (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
        );
        handleBookingHistory(
          offset + (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
        );
      }
    } else if (offset > 0) {
      setOffset(offset - (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit));
      handleBookingHistory(
        offset - (process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit)
      );
    }
  };

  // search
  const [inputValue, setInputValue] = useState("");

  // function for excel
  const excelFun = () => {
    let requestBody = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      event_id: eventsActiveTab,
    };
    if (process.env.REACT_APP_PRODUCT != "DLF") {
      requestBody["community_type"] = "AC_COMM";
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/api/event/export-event-booking-list`,
      {
        method: "POST",
        body: JSON.stringify({ ...requestBody }),
        headers: {
          "content-type": "application/json",
          Authorization: loadData("Auth"),
        },
      }
    )
      .then((d) => d.json())
      .then((res) => {
        const data = [];
        let totalAmount = 0;
        let totalSeats = 0;
        let totalMemberSeats = {};
        let totalGuestSeats = {};
        for (let x of res?.data?.bookings) {
          const downloadData = {};
          const fieldNamesMap = {};
          downloadData["Booking Time"] = x?.booked_date;
          downloadData["Booking Id"] = x?.booking_no;
          downloadData["Event Name"] = x?.event_name;
          if (process.env.REACT_APP_PRODUCT != "DLF") {
            downloadData[
              profileDetails?.comm_type == "Federation Parent Community"
                ? "Occupant"
                : profileDetails?.comm_type == "Federation Child Community"
                ? "Employee"
                : "Unit No."
            ] = x?.unit;
          } else {
            downloadData["Membership Id"] = x?.membership_id;
          }
          downloadData["Booked For"] = x?.booked_for;
          downloadData["Booked By"] = x?.booked_by;
          if (x?.has_seating_category === 1) {
            // when seating category is true
            if (x?.guest_price_status == 0) {
              //when  seating category is true and  guest price is  true
              for (let cat of x?.category_booking_list) {
                const memberKey = `Member ${cat?.category}`;
                const guestKey = `Guest ${cat?.category}`;
                if (process.env.REACT_APP_PRODUCT != "DLF") {
                  downloadData[guestKey] = cat?.guest_booking;
                }
                downloadData[memberKey] = cat?.member_booking;
                // Increment counters for dynamic member and guest seats
                totalMemberSeats[memberKey] =
                  (totalMemberSeats[memberKey] || 0) +
                  (cat?.member_booking || 0);
                if (process.env.REACT_APP_PRODUCT != "DLF") {
                  totalGuestSeats[guestKey] =
                    (totalGuestSeats[guestKey] || 0) +
                    (cat?.guest_booking || 0);
                }
              }
            } else if (x?.guest_price_status == 1) {
              //when  seating category is true and  guest price is false
              for (let cat of x?.category_booking_list) {
                const memberKey = `Member ${cat?.category}`;
                downloadData[`Member ${cat?.category}`] = cat?.member_booking;
                // Increment counter for dynamic member seats
                totalMemberSeats[memberKey] =
                  (totalMemberSeats[memberKey] || 0) +
                  (cat?.member_booking || 0);
              }
            }
          } else if (x?.has_seating_category === 0) {
            //when  seating category is false
            if (x?.guest_no_category == 0) {
              //when  seating category is false and  guest price is true
              downloadData["Member Seats"] = x?.member_booking;
              if (process.env.REACT_APP_PRODUCT != "DLF") {
                downloadData["Guest Seats"] = x?.guest_booking;
              }
              totalMemberSeats["Member Seats"] =
                (totalMemberSeats["Member Seats"] || 0) +
                (x?.member_booking || 0);
              if (process.env.REACT_APP_PRODUCT != "DLF") {
                totalMemberSeats["Guest Seats"] =
                  (totalMemberSeats["Guest Seats"] || 0) +
                  (x?.guest_booking || 0);
              }
            } else {
              downloadData["Member Seats"] = x?.member_booking;
              totalMemberSeats["Member Seats"] =
                (totalMemberSeats["Member Seats"] || 0) +
                (x?.member_booking || 0);
            }
          }

          downloadData["Total Seats"] = x?.total_seats;
          downloadData["Amount"] = `${
            process.env.REACT_APP_PRODUCT === "ALFUTTAIM" ? "AED" : currencySymbol
          } ${rupee.format(x?.total_amount)}`;
          downloadData["Status"] = x?.status;

          for (let item of x?.audience_details) {
            if (item.field_type !== "file") {
              let fieldName = item?.field_name;

              // If the field name already exists, append an index to make it unique
              if (fieldNamesMap[fieldName]) {
                fieldNamesMap[fieldName]++;
                fieldName = `${item?.field_name} (${fieldNamesMap[fieldName]})`;
              } else {
                fieldNamesMap[fieldName] = 1;
              }

              downloadData[fieldName] =
                item?.field_type === "single_choice"
                  ? item?.choice_label || "NA"
                  : item?.field_type === "checkbox"
                  ? item?.field_value?.split(",").join(" , ") || "NA"
                  : item?.field_type === "date"
                  ? item?.field_value
                    ? `${dayjs(item?.field_value).format("DD MMM YYYY")}`
                    : "NA"
                  : item?.field_type === "date_range"
                  ? item?.field_value?.split("#").length === 2
                    ? `${dayjs(item?.field_value.split("#")[0]).format(
                        "DD MMM YYYY"
                      )} - ${dayjs(item?.field_value.split("#")[1]).format(
                        "DD MMM YYYY"
                      )}`
                    : "NA"
                  : item?.field_type === "date_time"
                  ? item?.field_value
                    ? `${dayjs(item?.field_value).format(
                        "DD MMM YYYY, hh:mm A"
                      )}`
                    : "NA"
                  : item?.field_type === "time"
                  ? item?.field_value
                    ? `${dayjs(item?.field_value, "hh:mm:ss").format(
                        "hh:mm A"
                      )}`
                    : "NA"
                  : item?.field_value
                  ? item?.field_value
                  : "NA";
            }
          }

          data.push(downloadData);
          // Sum up the values for total amount and total seats
          totalAmount += parseFloat(x?.total_amount) || 0;
          totalSeats += parseInt(x?.total_seats) || 0;
        }
        // Add total member and guest seats to the data array
        // Add total amount and total seats to the data array
        data.push({
          "Booked By": "TOTAL :",
          ...totalMemberSeats,
          ...totalGuestSeats,
          Amount:
            `${process.env.REACT_APP_PRODUCT === "ALFUTTAIM" ? "AED " : currencySymbol+" "}` +
            totalAmount,
          "Total Seats": totalSeats,
        });
        setDownloadData(data);
        let fileNameForDownload;
        if (process.env.REACT_APP_PRODUCT != "DLF") {
          fileNameForDownload =
            eventsDetails?.name +
            "_BookingHistory_" +
            dayjs().format("DD-MM-YYYY HH:mm:ss");
        } else {
          fileNameForDownload =
            eventsDetails?.name?.replace(/ /g, "_") + // Replace spaces with underscores in name
            "_Booking_History_" +
            dayjs().format("DD_MM_YYYY_hh_mm_A"); // Format date and time
        }
        exportToCSV(data, fileNameForDownload);
      });
  };

  const exportToCSV = (apiData, fileName) => {
    // Set the file type and extension
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // Convert the data to worksheet
    const ws = XLSX.utils.json_to_sheet(apiData);
    // Customize the style for the header row (bold and grey background)
    const headerStyle = {
      font: { bold: true }, // Make the text bold
      // fill: { fgColor: { rgb: "FF808080" } }, // Set the background color to grey
    };

    // Set the header style for each cell in the first row
    const range = XLSX.utils.decode_range(ws["!ref"]); // Get the range of cells
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ c: C, r: range.s.r }); // Encode the cell coordinates
      ws[headerCell].s = headerStyle; // Apply the header style to the cell
    }

    // Get the index of the last row
    const lastRowIndex = range.e.r;

    // Check if the last row is within the range of existing cells
    if (lastRowIndex >= range.s.r) {
      // Set the bold style for each cell in the last row
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const lastRowCell = XLSX.utils.encode_cell({ c: C, r: lastRowIndex }); // Encode the cell coordinates of the last row
        if (ws[lastRowCell]) {
          // Check if the cell exists before styling it
          ws[lastRowCell].s = headerStyle; // Apply the bold style to the cell in the last row
        }
      }
    }

    // Create the workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");

    // Convert the workbook to array buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    // Save the file
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleBookingHistory = (ofset) => {
    let args = {
      club_id: loadData("Club_id"),
      user_id: loadData("Token"),
      request_by: EventConfig.requestBy,
      event_id: eventsActiveTab,
    };
    if (inputValue !== "") {
      args["membership_no"] = inputValue;
    }
    if (offset !== "") {
      args["offset"] = ofset !== undefined ? ofset : offset;
    }
    if (limit !== "") {
      args["limit"] = process.env.REACT_APP_PRODUCT != "DLF" ? 4 : limit;
    }
    if (process.env.REACT_APP_PRODUCT != "DLF") {
      args["community_type"] = "AC_COMM";
    }
    dispatch(getBookingHistoryById({ args, eventsActiveTab }));
  };

  useEffect(() => {
    setOffset(0);
    const timer = setTimeout(() => {
      handleBookingHistory(0);
    }, 500);
    return () => clearTimeout(timer);
  }, [eventsActiveTab, inputValue]);

  return (
    <div className={styles.facilityBookingRequestViewMainContainer}>
      <Modal
        open={open}
        onClose={() => {
          setInputValue("");
          setLimit(25);
          setOffset(0);
          setNoOfBooking(false);
        }}
        showCloseIcon={true}
        center
        classNames={{
          overlay: styles.customOverlay,
          modal: styles.customModal,
        }}
      >
        <h2 className={styles.heading}>{eventsDetails?.name}</h2>
        <div className={styles.searchParentDiv}>
          <div className={styles.searchDiv}>
            <div className={styles.searchIcon}>
              <img
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/searchIcon.png`}
              />
            </div>
            <input
              className={styles.searchDivInput}
              placeholder={
                process.env.REACT_APP_PRODUCT !== "DLF"
                  ? "|  Search by Name"
                  : "|  Search by Name / Membership no."
              }
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleBookingHistory();
                }
              }}
            />
          </div>
          {/* {process.env.REACT_APP_PRODUCT != "DLF" && ( */}
          <div className={styles.excelDiv} onClick={excelFun}>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/excel.png`}
            />
          </div>
          {/* )} */}
        </div>
        <div className={styles.bookingNoMainDiv}>
          <BookingHistoryTblEvent
            columns={columns}
            allRequests={true}
            details={bookingHistoryEvents}
            handleBookingHistory={handleBookingHistory}
          />
          {bookingHistoryEvents?.length > 0 && (
            <div className={styles.pagination}>
              <div>
                <div className={styles.paginationValuesDiv}>
                  <p>{offset + 1}</p>
                  <p>-</p>
                  <p>{offset + bookingHistoryEvents?.length}</p>
                  <p>of</p>
                  <p>{totalBookingHistoryCount && totalBookingHistoryCount}</p>
                </div>
                <div className={styles.paginationIconDiv}>
                  <span onClick={() => handleOffset("prev")}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/lt.png`}
                    />
                  </span>
                  <span onClick={() => handleOffset("next")}>
                    <img
                      src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/gt.png`}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
