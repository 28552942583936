import React from "react";
import styles from "./bookingSuccess.module.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const BookingSuccess = ({
  open,
  onCloseModal,
  status,
  isNonMember,
  bookingStatus,
  msg,
}) => {
  return (
    <Modal open={open} onClose={onCloseModal} center showCloseIcon={false}>
      <div className={styles.tooltipChangeStatus}>
        <div className={styles.imgDiv}>
          <img
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/successIcon.png`}
          />
        </div>
        <p className={styles.modalDivp}>
          {process.env.REACT_APP_PRODUCT == "DLF"
            ? `${msg}`
            : "Booking Successful"}
        </p>
        {bookingStatus !== "FREE" && (
          <p className={styles.modalDivp1}>
            Notification sent to{" "}
            <span>{isNonMember ? "non-member" : "member"}</span>{" "}
            {process.env.REACT_APP_PRODUCT == "DLF" ? "" : "for payment"}
          </p>
        )}
      </div>
    </Modal>
  );
};
