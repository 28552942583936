import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./togle.css";

export const Togle = ({ toggleFunction, toggle, open }) => {
  return (
    <>
      <label>
        <Toggle
          checked={toggle}
          icons={false}
          value={toggle}
          onChange={() => {
            toggleFunction();
          }}
        />
      </label>
    </>
  );
};
