import React, { useEffect, useState } from "react";
import { TextInput, Textarea, Select } from "evergreen-ui";
import styles from "../createEvent.module.css";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { getCurrencySymbolByTimeZone } from "../../../Utils/currencySymbol";
export const Financials = ({
  createEvent,
  setCreateEvent,
  isEdit,
  handleCreateEventChange,
  inputFields,
  setInputFields,
  seatCategory,
  showGuest,
  setShowGuest,
  setSeatCategory,
  price,
  guestSeatCategory,
  setGuestSeatCategory,
  guestSeatCategoryBtn,
  setGuestSeatCategoryBtn,
  handleBookingType,
}) => {
  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(
    profileDetails?.comm_time_zone
  );

  const [valid, setValid] = useState(false);
  const [hideInput, setHideInput] = useState(false);

  const handleChangeCancellation = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    if (name == "deduction" && value <= 100 && value >= 0) {
      list[index][name] = value.replace(/[^0-9]/g, "");
      setInputFields(list);
    } else if (name == "duration") {
      list[index][name] = value;
      setInputFields(list);
    }
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const addInputFieldCancellation = () => {
    setInputFields([
      ...inputFields,
      {
        duration: "",
        deduction: "",
      },
    ]);
  };

  useEffect(() => {
    createEvent.bookingType === "PAID"
      ? setHideInput(false)
      : setHideInput(true);
    createEvent.bookingType === "FREE" &&
      setCreateEvent({
        ...createEvent,
        bookingPrice: "",
        tax: 0,
      });
    if (
      process.env.REACT_APP_PRODUCT !== "DLF" &&
      createEvent.bookingType === "FREE"
    ) {
      setShowGuest(true);
      setGuestSeatCategoryBtn(true);
    }
  }, [createEvent.bookingType]);
  useEffect(() => {
    createEvent.isSeatingCategorization == 0
      ? setCreateEvent({
          ...createEvent,
          bookingType: "FREE",
        })
      : setCreateEvent({
          ...createEvent,
          bookingType: "PAID",
        });
  }, [createEvent?.isSeatingCategorization]);
  const handlePricePerCategory = (index, e) => {
    const { name, value } = e.target;
    const list = [...seatCategory];
    list[index][name] = value.replace(/[^0-9]/g, "");
    setSeatCategory(list);
  };
  const handlePricePerCategoryGuest = (index, e) => {
    const { name, value } = e.target;
    const list = [...guestSeatCategory];
    list[index][name] = value.replace(/[^0-9]/g, "");
    setGuestSeatCategory(list);
  };
  //cancellatioPolicy
  useEffect(() => {
    setCreateEvent({
      ...createEvent,
      cancellationPolicy: inputFields,
    });
  }, [inputFields]);

  useEffect(() => {
    createEvent.cancellationAllowed == 0 &&
      setInputFields([
        {
          duration: "",
          deduction: "",
        },
      ]);
  }, [createEvent.cancellationAllowed]);

  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>
        <p>Financials</p>
      </div>
      <div className={styles.displayFlexDiv}>
        <div className={styles.chargeNpriceDiv}>
          {/* <div>
            <label className={styles.labelFont}>Booking Type</label>
            <span className={styles.star}>*</span>
            <br />
            <Select
              width={"100%"}
              height={44}
              style={{ marginTop: "12px" }}
              name="bookingType"
              value={createEvent.bookingType}
              onChange={(e) => {
                setHideInput(e.target.value == "Free" ? true : false);
                handleCreateEventChange(e);
              }}
            >
              <option value="" selected>
                Select
              </option>
              <option value="Paid">Paid</option>
              <option value="Free">Free</option>
            </Select>
          </div> */}
          <div style={{ width: "400px" }}>
            <label className={styles.labelFont}>Booking Type</label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.radioMainDiv}>
              {(process.env.REACT_APP_PRODUCT == "DLF" ||
                profileDetails?.event_payment === true) && (
                <span
                  className={
                    createEvent.bookingType === "PAID"
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    name="bookingType"
                    value="PAID"
                    checked={createEvent.bookingType === "PAID"}
                    onChange={handleBookingType}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>Paid</label>
                </span>
              )}
              <span
                className={
                  createEvent.bookingType === "FREE"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  name="bookingType"
                  value="FREE"
                  checked={createEvent.bookingType == "FREE"}
                  onChange={handleBookingType}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>
                  {process.env.REACT_APP_PRODUCT === "DLF"
                    ? "With Compliments"
                    : "Free"}
                </label>
              </span>
            </div>
          </div>
          {!hideInput && createEvent?.isSeatingCategorization == 0 && (
            <div style={{ position: "relative" }}>
              <label className={styles.labelFont}>Price</label>
              <span className={styles.star}>*</span>
              <br />
              {hideInput ? (
                <TextInput
                  width={"100%"}
                  height={44}
                  placeholder={""}
                  style={{ marginTop: "12px" }}
                  name="bookingPrice"
                  value={""}
                  onChange={handleCreateEventChange}
                  disabled
                />
              ) : (
                <>
                  <p className={styles.rupee}>{currencySymbol}</p>
                  <TextInput
                    width={"100%"}
                    height={44}
                    placeholder={""}
                    style={{
                      marginTop: "12px",
                      paddingLeft: `${
                        currencySymbol == "AED" ? "46px" : "22px"
                      }`,
                    }}
                    name="bookingPrice"
                    value={createEvent.bookingPrice}
                    onChange={handleCreateEventChange}
                    autoComplete="off"
                    // type="number"
                    disabled={isEdit}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {!hideInput && process.env.REACT_APP_PRODUCT == "DLF" && (
          <div>
            <label className={styles.labelFont}>Tax</label>
            <br />

            <div className={styles.radioMainDiv}>
              <span
                className={
                  createEvent.tax === "5"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="tax"
                  value="5"
                  checked={createEvent.tax == "5"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>5%</label>
              </span>
              <span
                className={
                  createEvent.tax === "12"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="tax"
                  value="12"
                  checked={createEvent.tax == "12"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>12%</label>
              </span>
              {/* <span
                className={
                  createEvent.tax === "15"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  width={300}
                  height={44}
                  name="tax"
                  value="15"
                  checked={createEvent.tax == "15"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>15%</label>
              </span> */}
              <span
                className={
                  createEvent.tax === "18"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  name="tax"
                  value="18"
                  checked={createEvent.tax == "18"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>18%</label>
              </span>
              <span
                className={
                  createEvent.tax === "18.9"
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  name="tax"
                  value="18.9"
                  checked={createEvent.tax == "18.9"}
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>18.9%</label>
              </span>
            </div>
          </div>
        )}
        {process.env.REACT_APP_PRODUCT != "DLF" &&
          createEvent?.bookingType == "PAID" &&
          createEvent?.isSeatingCategorization == 0 &&
          (guestSeatCategoryBtn ? (
            !isEdit && (
              <div>
                <div
                  className={styles.addGuestPrice}
                  onClick={() => {
                    setGuestSeatCategoryBtn(false);
                  }}
                >
                  + Add Guest Price
                </div>
                <div></div>
              </div>
            )
          ) : (
            <div>
              <div style={{ position: "relative", width: "45%" }}>
                <label className={styles.labelFont}>Price for guest</label>
                <span className={styles.star}>*</span>
                <br />
                {hideInput ? (
                  <TextInput
                    width={"100%"}
                    height={44}
                    placeholder={""}
                    style={{ marginTop: "12px" }}
                    name="bookingPriceGuset"
                    value={""}
                    onChange={handleCreateEventChange}
                    disabled
                  />
                ) : (
                  <>
                    <p className={styles.rupee}>{currencySymbol} </p>
                    <TextInput
                      width={"100%"}
                      height={44}
                      placeholder={""}
                      style={{
                        marginTop: "12px",
                        paddingLeft: `${
                          currencySymbol == "AED" ? "46px" : "22px"
                        }`,
                      }}
                      name="bookingPriceGuest"
                      value={createEvent.bookingPriceGuest}
                      onChange={handleCreateEventChange}
                      autoComplete="off"
                      disabled={isEdit}
                    />
                    {!isEdit && (
                      <span
                        className={styles.removeIconDivSplGuestPrice}
                        onClick={() => {
                          setGuestSeatCategoryBtn(true);
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/vector.png`}
                          alt="removeIcon"
                        />
                      </span>
                    )}
                  </>
                )}
              </div>
              <div></div>
            </div>
          ))}
      </div>
      {/* ---------------------price per category--------------------------------------- */}
      {createEvent?.bookingType === "PAID" &&
        createEvent?.isSeatingCategorization == 1 && (
          <div className={styles.pricePerCategoryDiv}>
            <div className={styles.pricePerCategoryTitle}>
              <label className={styles.PricePerCategoryLabel}>
                Price per category
              </label>
              <span className={styles.star}>*</span>
              <div className={styles.pricePerCategoryContent}>
                {seatCategory?.map((i, index) => {
                  return (
                    <div>
                      <label className={styles.seatingLabel}>{i?.name}</label>
                      <input
                        type="text"
                        className={styles.seatingInput}
                        style={{ width: "160px" }}
                        name="price"
                        value={i?.price}
                        onChange={(e) => {
                          handlePricePerCategory(index, e);
                        }}
                        disabled={isEdit}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {process.env.REACT_APP_PRODUCT != "DLF" &&
              !showGuest &&
              createEvent?.isSeatingCategorization == 1 && (
                <div className={styles.pricePerCategoryTitle}>
                  <label className={styles.PricePerCategoryLabel}>
                    Guest price per category
                  </label>

                  <span className={styles.star}>*</span>
                  {!isEdit && (
                    <span
                      className={styles.removeIconDivSplGuestPriceCategory}
                      onClick={() => {
                        setShowGuest(true);
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/vector.png`}
                        alt="removeIcon"
                      />
                    </span>
                  )}
                  <div className={styles.pricePerCategoryContent}>
                    {seatCategory?.map((i, index) => {
                      return (
                        <div>
                          <label className={styles.seatingLabel}>
                            {i?.name}
                          </label>
                          <input
                            type="text"
                            className={styles.seatingInput}
                            style={{ width: "160px" }}
                            name="guest_price"
                            value={i?.guest_price}
                            onChange={(e) => {
                              handlePricePerCategory(index, e);
                            }}
                            disabled={isEdit}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            {!isEdit &&
              process.env.REACT_APP_PRODUCT != "DLF" &&
              showGuest &&
              // !isEdit &&
              createEvent?.isSeatingCategorization == 1 && (
                <button
                  className={styles.cancellationAllowedBtn}
                  onClick={() => {
                    setShowGuest(false);
                  }}
                >
                  Add Guest Price +
                </button>
              )}
          </div>
        )}

      {process.env.REACT_APP_PRODUCT == "DLF" && (
        <div className={styles.displayFlexDiv}>
          {!hideInput && process.env.REACT_APP_PRODUCT != "DLF" && (
            <div>
              <label className={styles.labelFont}>Tax</label>
              <br />

              <div className={styles.radioMainDiv}>
                <span
                  className={
                    createEvent.tax === "0"
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    width={300}
                    height={44}
                    name="tax"
                    value="0"
                    checked={createEvent.tax == "0"}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>0%</label>
                </span>
                <span
                  className={
                    createEvent.tax === "12"
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    width={300}
                    height={44}
                    name="tax"
                    value="12"
                    checked={createEvent.tax == "12"}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>12%</label>
                </span>
                <span
                  className={
                    createEvent.tax === "15"
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    width={300}
                    height={44}
                    name="tax"
                    value="15"
                    checked={createEvent.tax == "15"}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>15%</label>
                </span>
                <span
                  className={
                    createEvent.tax === "18"
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    name="tax"
                    value="18"
                    checked={createEvent.tax == "18"}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>18%</label>
                </span>
                <span
                  className={
                    createEvent.tax === "28"
                      ? styles.radioDivActive
                      : styles.radioDiv
                  }
                >
                  <input
                    type="radio"
                    name="tax"
                    value="28"
                    checked={createEvent.tax == "28"}
                    onChange={handleCreateEventChange}
                    disabled={isEdit}
                  />
                  <label className={styles.radioLabel}>28%</label>
                </span>
              </div>
            </div>
          )}
          <div></div>
        </div>
      )}
      {process.env.REACT_APP_PRODUCT != "DLF" &&
        createEvent?.bookingType == "PAID" && (
          <div className={styles.displayFlexDiv}>
            <div>
              <label className={styles.labelFont}>Invoice Description</label>
              <span className={styles.star}>*</span>
              <br />
              <Textarea
                className={styles.inputTextArea}
                name="invoiceDescription"
                value={createEvent.invoiceDescription}
                onChange={handleCreateEventChange}
              />
              {createEvent?.invoiceDescription?.length <= 500 ? (
                <div className={styles.textEventCountDiv}>
                  {createEvent?.invoiceDescription?.length}/ 500
                </div>
              ) : (
                <div className={styles.eventMainOuterLimitDiv}>
                  <div className={styles.eventMaxCountText}>
                    Exceeded maximum character length of 500
                  </div>
                  <div className={styles.eventMaxLimitCountNumber}>
                    {createEvent?.invoiceDescription?.length}/500
                  </div>
                </div>
              )}
            </div>

            {/* --------------Instructions---------------- */}
          </div>
        )}
      {process.env.REACT_APP_PRODUCT == "DLF" && (
        <div className={styles.displayFlexDiv}>
          <div>
            <label className={styles.labelFont}>Cancellation Allowed</label>
            <span className={styles.star}>*</span>
            <br />
            <div className={styles.radioMainDiv}>
              <span
                className={
                  createEvent.cancellationAllowed == 1
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  name="cancellationAllowed"
                  value={process.env.REACT_APP_PRODUCT == "DLF" ? 1 : 0}
                  checked={
                    createEvent.cancellationAllowed ==
                    (process.env.REACT_APP_PRODUCT == "DLF" ? 1 : 0)
                  }
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>Yes</label>
              </span>
              <span
                className={
                  createEvent.cancellationAllowed == 0
                    ? styles.radioDivActive
                    : styles.radioDiv
                }
              >
                <input
                  type="radio"
                  name="cancellationAllowed"
                  value={process.env.REACT_APP_PRODUCT == "DLF" ? 0 : 1}
                  checked={
                    createEvent.cancellationAllowed ==
                    (process.env.REACT_APP_PRODUCT == "DLF" ? 0 : 1)
                  }
                  onChange={handleCreateEventChange}
                  disabled={isEdit}
                />
                <label className={styles.radioLabel}>No</label>
              </span>
            </div>
          </div>
        </div>
      )}
      {/* ----------------------------------------- */}
      {process.env.REACT_APP_PRODUCT == "DLF" &&
        createEvent?.cancellationAllowed == 1 &&
        createEvent?.bookingType == "PAID" &&
        inputFields.length > 0 && (
          <div className={styles.cancellationAllowedDiv}>
            {inputFields?.map((data, index) => {
              const selectedCancellationTypes = inputFields.map(
                (item) => item.duration
              );
              return (
                <div className={styles.cancellationAllowedflexDiv}>
                  <div>
                    <p>TIME Of CANCELLATION</p>
                    <Select
                      width={"100%"}
                      height={44}
                      value={data.duration}
                      onChange={(evnt) => {
                        handleChangeCancellation(index, evnt);
                      }}
                      disabled={isEdit}
                      name="duration"
                    >
                      <option value="on the date of event">
                        Select Time of Cancellation
                      </option>
                      <option
                        disabled={selectedCancellationTypes.includes("6 hours")}
                        value="6 hours"
                      >
                        6 hours before start of event
                      </option>
                      <option
                        disabled={selectedCancellationTypes.includes(
                          "12 hours"
                        )}
                        value="12 hours"
                      >
                        12 hours before start of event
                      </option>
                      <option
                        disabled={selectedCancellationTypes.includes(
                          "24 hours"
                        )}
                        value="24 hours"
                      >
                        24 hours before start of event
                      </option>
                    </Select>
                  </div>
                  <div className={styles.percentageInput}>
                    <p>DEDUCTION FOR CANCELLATION (%)</p>
                    <TextInput
                      width={"100%"}
                      height={44}
                      name="deduction"
                      disabled={isEdit}
                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                      value={data.deduction}
                      onChange={(evnt) => handleChangeCancellation(index, evnt)}
                    />
                  </div>
                  {valid && (
                    <p className={styles.errorPercentage}>
                      value must be betwwen 1 to 100
                    </p>
                  )}
                  {!isEdit && inputFields.length !== 1 ? (
                    <span
                      className={styles.removeIconDiv}
                      onClick={() => removeInputFields(index)}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/remove.png"}
                        alt="removeIcon"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
            {!isEdit && inputFields?.length < 3 && (
              <button
                className={styles.cancellationAllowedBtn}
                onClick={addInputFieldCancellation}
              >
                Add Rule +
              </button>
            )}
          </div>
        )}
    </div>
  );
};
