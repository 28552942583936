import React, { useEffect, useState } from "react";
import styles from "./eventsDetailsCard.module.css";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateOffer } from "./CreateOffer/CreateOffer";
import { EditOffer } from "./EditOffer/EditOffer";
import { BookingEventViewModal } from "../EventsDetails/BookingEventViewModal/BookingEventViewModal";
import { BookingHistoryRegistrationModal } from "../../AC/Event/Booking/BookingRegistrationHistory/BookingHistoryRegistrationDetails/BookingHistroyRegistrationModal/BookingHistoryRegistrationModal";
import { getCurrencySymbolByTimeZone } from "../../Utils/currencySymbol";

export const EventsDetailsCard = ({
  total_seats,
  booking_type,
  description,
  waiting_list,
  instructions,
  start_at,
  end_at,
  event_end_time,
  seat_type,
  accessible_to,
  status,
  price,
  event_start_time,
  has_seating_category,
  seats_booked,
  event_cancellation_policies,
  cancellation_allowed,
  guest_no_category,
  guest_price,
  has_artist,
  event_artist,
  event_venue,
}) => {
  dayjs.extend(localizedFormat);
  dayjs.extend(customParseFormat);
  const { eventsDetails, eventsActiveTab } = useSelector(
    (state) => state.eventsSlice
  );
  const { profileDetails } = useSelector((state) => state.authSlice);
  const currencySymbol = getCurrencySymbolByTimeZone(profileDetails?.comm_time_zone)

  const { eventRights } = useSelector((state) => state.authSlice);
  const [seeMoreData, setSeeMoreData] = useState(true);
  const [open, setOpen] = useState(false);
  const [editOffer, setEditOffer] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [viewId, setViewId] = useState("");
  const [noOfBooking, setNoOfBooking] = useState(false);
  let seeLess = description;
  let seeMore = description?.slice(0, 250);


  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onOpenEditModal = () => setEditOffer(true);
  const onCloseEditModal = () => setEditOffer(false);

  const [viewRegBookingModal, setViewRegBookingModal] = useState(false);

  useEffect(() => {
    eventsDetails?.is_popular == 1 ? setToggle(true) : setToggle(false);
  }, [eventsDetails, open]);

  return (
    <div className={styles.mainDivEventsDetailsCard}>
      <CreateOffer
        open={open}
        onCloseModal={onCloseModal}
        heading={"Create Offer"}
        subHeading={"Enter details and add image to add offer for Event"}
        btnTitle={"Create Offer"}
      />
      <EditOffer
        open={editOffer}
        onCloseModal={onCloseEditModal}
        heading={"Edit Offer"}
        subHeading={"Enter details and add image to add offer for Event"}
        btnTitle={"Save Changes"}
      />
      <BookingEventViewModal
        open={noOfBooking}
        setNoOfBooking={setNoOfBooking}
      />
      <BookingHistoryRegistrationModal
        open={viewRegBookingModal}
        onClose={() => setViewRegBookingModal(false)}
      />
      <div className={styles.mainDivBookingNPopular}>
        {process.env.REACT_APP_PRODUCT === "DLF" ||
        profileDetails?.show_audience_flow ? (
          <div className={styles.bookingDiv}>
            <span>
              <p className={styles.bookingTitle}>No. of Bookings :</p>
              <p className={styles.bookingNo}>
                {eventsDetails?.number_of_bookings}
              </p>
            </span>
            {eventsDetails?.number_of_bookings > 0 && (
              <p
                className={styles.viewAll}
                onClick={() => {
                  setNoOfBooking(true);
                  setViewId(eventsActiveTab);
                }}
              >
                View All
              </p>
            )}
          </div>
        ) : (
          <div className={styles.bookingDiv}>
            <span>
              <p className={styles.bookingTitle}>No. of Registrations :</p>
              <p className={styles.bookingNo}>
                {eventsDetails?.number_of_participant}
              </p>
            </span>
            {eventsDetails?.number_of_participant > 0 && (
              <p
                className={styles.viewAll}
                onClick={() => {
                  setViewRegBookingModal(true);
                }}
              >
                View All
              </p>
            )}
          </div>
        )}

        {!eventsDetails?.event_offer?.discount_percentage ? (
          <div
            className={
              // process.env.REACT_APP_PRODUCT == "DLF" && eventsDetails?.booking_type !== "PAID" ? styles.noOfferDiv :
              styles.bookingDiv
            }
          >
            <span>
              {process.env.REACT_APP_PRODUCT !== "ALFUTTAIM" && (
                <p className={styles.bookingTitle}>Create Offer </p>
              )}
              <div className={styles.i} title="Create Offer">
                {process.env.REACT_APP_PRODUCT == "DLF" && (
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/i.svg`}
                    alt="detail"
                  />
                )}
              </div>
            </span>
            {/* {process.env.REACT_APP_PRODUCT == "DLF" &&
              eventsDetails?.booking_type !== "PAID" && (
                <span
                  style={{ color: "rgba(255, 183, 82, 1)", fontSize: "12px" }}
                >
                  Offers cannot be created for free events
                </span>
              )} */}
            {((process.env.REACT_APP_PRODUCT == "DLF" &&
              eventsDetails?.booking_type === "PAID" &&
              eventsDetails?.status !== "CLOSE" &&
              eventsDetails?.status !== "COMPLETED" &&
              (dayjs(eventsDetails?.start_at, "YYYY-MM-DD hh:mm A").isAfter(
                dayjs()
              ) ||
                dayjs(eventsDetails?.start_at, "YYYY-MM-DD hh:mm A").isSame(
                  dayjs()
                ))) ||
              (process.env.REACT_APP_PRODUCT !== "DLF" &&
                eventsDetails?.booking_type === "PAID" &&
                eventsDetails?.status !== "CLOSE" &&
                eventsDetails?.status !== "COMPLETED")) && (
              <div
                className={styles.addIconDiv}
                onClick={() => {
                  if (
                    eventsDetails?.booking_type === "PAID" &&
                    eventsDetails?.status !== "CLOSE" &&
                    eventsDetails?.status !== "COMPLETED"
                  ) {
                    if (process.env.REACT_APP_PRODUCT == "DLF") {
                      if (eventRights?.create == 1) {
                        onOpenModal();
                      } else {
                        toast.error("You don't have create access rights");
                      }
                    } else {
                      if (
                        eventsDetails?.booking_type === "PAID" &&
                        eventsDetails?.status !== "CLOSE" &&
                        eventsDetails?.status !== "COMPLETED"
                      ) {
                        onOpenModal();
                      }
                    }
                  }
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/addIconFacility.png`}
                  alt="addIconDiv"
                />
              </div>
            )}
          </div>
        ) : (
          <div className={styles.bookingDiv}>
            <span>
              <p className={styles.bookingTitle}>Edit Offer</p>
              <div className={styles.i} title="Edit Offer">
                {process.env.REACT_APP_PRODUCT == "DLF" && (
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/i.svg`}
                    alt="detail"
                  />
                )}
              </div>
            </span>
            {((process.env.REACT_APP_PRODUCT == "DLF" &&
              eventsDetails?.booking_type === "PAID" &&
              eventsDetails?.status !== "CLOSE" &&
              eventsDetails?.status !== "COMPLETED" &&
              (dayjs(eventsDetails?.start_at, "YYYY-MM-DD hh:mm A").isAfter(
                dayjs()
              ) ||
                dayjs(eventsDetails?.start_at, "YYYY-MM-DD hh:mm A").isSame(
                  dayjs()
                ))) ||
              (process.env.REACT_APP_PRODUCT !== "DLF" &&
                eventsDetails?.booking_type === "PAID" &&
                eventsDetails?.status !== "CLOSE" &&
                eventsDetails?.status !== "COMPLETED")) && (
              <div
                className={styles.editIconDiv}
                onClick={() => {
                  if (process.env.REACT_APP_PRODUCT == "DLF") {
                    if (eventRights?.update == 1) {
                      onOpenEditModal();
                    } else {
                      toast.error("You don't have update access rights");
                    }
                  } else {
                    onOpenEditModal();
                  }
                }}
              >
                Edit
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.middleDivEventsDetailsCard}>
        <div className={styles.basicInfoTopImgEventsDetailsCard}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/basicDetails.svg`}
              alt="detailsLogo"
            />
          </div>
          <h6>Details</h6>
        </div>
        <div className={styles.generalInfoDivEventsDetailsCard}>
          <div>
            <p>EVENT DATE</p>
            <div>
              {start_at
                ? start_at?.split(" ")[0] == end_at?.split(" ")[0]
                  ? `${dayjs(start_at, "YYYY-MM-DD HH:mm A").format(
                      "DD MMM YYYY"
                    )}`
                  : `${dayjs(start_at, "YYYY-MM-DD HH:mm A").format(
                      "DD MMM YYYY"
                    )} -
                      ${dayjs(end_at, "YYYY-MM-DD HH:mm A").format(
                        "DD MMM YYYY"
                      )}`
                : "-"}
            </div>
            {has_seating_category == 0 && (
              <>
                <p>NO OF SEATS</p>
                <div>{total_seats ? total_seats : "-"}</div>
              </>
            )}
            {process.env.REACT_APP_PRODUCT == "DLF" && (
              <>
                {" "}
                <p>SEATING STYLE</p>
                <div>
                  {seat_type && seat_type == "OPEN"
                    ? "STANDING"
                    : status == "ROUND_TABLE"
                    ? "ROUND TABLE"
                    : "THEATRE"}
                </div>
              </>
            )}
            {process.env.REACT_APP_PRODUCT != "DLF" &&
              has_seating_category == 0 &&
              guest_no_category == 0 && (
                <>
                  <p>GUEST PRICE</p>
                  <div>{guest_price ? guest_price : "-"}</div>
                </>
              )}
          </div>
          <div>
            <p>EVENT TIMINGS</p>
            <div>
              {event_start_time && event_end_time
                ? `${dayjs(event_start_time, "HH:mm A").format(
                    "h:mm A"
                  )}-${dayjs(event_end_time, "HH:mm A").format("h:mm A")}`
                : event_start_time
                ? `${dayjs(event_start_time, "HH:mm A").format("h:mm A")}`
                : "-"}
            </div>

            {has_seating_category == 0 && (
              <>
                {" "}
                <p>SEATS BOOKED</p>
                <div>
                  {has_seating_category == 0 && seats_booked?.booked_seats}
                </div>
              </>
            )}

            {process.env.REACT_APP_PRODUCT == "DLF" && (
              <>
                {" "}
                <p> STATUS</p>
                <div
                  className={
                    status === "OPEN"
                      ? styles.statusColorOpen
                      : styles.statusColor
                  }
                >
                  {status && status == "OPEN"
                    ? "OPEN"
                    : status == "COMING_SOON"
                    ? "COMING SOON"
                    : status == "COMPLETED"
                    ? "COMPLETED"
                    : "CLOSE"}
                </div>
              </>
            )}
          </div>
          <div>
            {has_seating_category == 0 && (
              <>
                {" "}
                <p>AMOUNT</p>
                <div>
                  {booking_type === "FREE"
                    ? `${
                        process.env.REACT_APP_PRODUCT === "DLF"
                          ? "With Compliments"
                          : "Free"
                      }`
                    : `${currencySymbol} ${price}`}
                </div>
                {process.env.REACT_APP_PRODUCT == "DLF" && (
                  <>
                    <p>WAITING LIST</p>
                    <div>{waiting_list ? waiting_list : 0}</div>
                  </>
                )}
              </>
            )}
            {event_venue?.name && process.env.REACT_APP_PRODUCT != "DLF" && (
              <>
                {" "}
                <p>VENUE</p>
                <div>{event_venue?.name}</div>
              </>
            )}
            {process.env.REACT_APP_PRODUCT == "DLF" && (
              <>
                <p>ACCESSIBLE TO</p>
                <div>
                  {accessible_to && accessible_to == "ALL"
                    ? "ALL"
                    : accessible_to == "MEMBERS"
                    ? "MEMBERS"
                    : "NON MEMBERS"}
                </div>
              </>
            )}
          </div>
        </div>
        {has_seating_category == 1 && (
          <>
            <div className={styles.basicInfoTopImgEventsDetailsCard}>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/images/basicDetails.svg"}
                  alt="detailsLogo"
                />
              </div>
              <h6>Seating(s)</h6>
            </div>
            {process.env.REACT_APP_PRODUCT != "DLF" && (
              <h5 className={styles.categoryTitle}>For Member</h5>
            )}
            <div
              className={styles.seatingCategorizationEnabledGridCardOuterDiv}
            >
              {Object.keys(seats_booked)?.map((bSeats) => {
                const seat = seats_booked[bSeats];
                return (
                  <div className={styles.seatingCategorizationEnabledGridCard}>
                    <div>{bSeats}</div>
                    <p>
                      Price:{" "}
                      <span style={{ color: "#000" }}>
                        {currencySymbol} {seat?.price_per_seat}
                      </span>{" "}
                    </p>
                    <p>
                      Seats:{" "}
                      <span>
                        <span style={{ color: "#663879" }}>
                          {seat?.total_seats - seat?.booked_seats == 0
                            ? seat?.waiting_seats
                            : seat?.total_seats - seat?.booked_seats}
                        </span>{" "}
                        {seat?.total_seats - seat?.booked_seats == 0
                          ? "Waiting"
                          : "Left"}
                        <span style={{ color: "#000" }}>
                          / {seat?.total_seats}
                        </span>{" "}
                        Total
                      </span>{" "}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* {guest} */}
            {process.env.REACT_APP_PRODUCT != "DLF" &&
              eventsDetails?.has_seating_category == 1 &&
              eventsDetails?.guest_price_status == 0 && (
                <>
                  <h5 className={styles.categoryTitle}>For Guest</h5>
                  <div
                    className={
                      styles.seatingCategorizationEnabledGridCardOuterDiv
                    }
                  >
                    {Object.keys(seats_booked)?.map((bSeats) => {
                      const seat = seats_booked[bSeats];
                      return (
                        <div
                          className={
                            styles.seatingCategorizationEnabledGridCard
                          }
                        >
                          <div>{bSeats}</div>
                          <p>
                            Price:{" "}
                            <span style={{ color: "#000" }}>
                              {currencySymbol} {seat?.guest_price_per_seat}
                            </span>{" "}
                          </p>
                          <p>
                            Seats:{" "}
                            <span>
                              <span style={{ color: "#663879" }}>
                                {seat?.total_seats - seat?.booked_seats == 0
                                  ? seat?.waiting_seats
                                  : seat?.total_seats - seat?.booked_seats}
                              </span>{" "}
                              {seat?.total_seats - seat?.booked_seats == 0
                                ? "Waiting"
                                : "Left"}
                              <span style={{ color: "#000" }}>
                                / {seat?.total_seats}
                              </span>{" "}
                              Total
                            </span>{" "}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
          </>
        )}
        {process.env.REACT_APP_PRODUCT === "DLF" && has_artist === 1 && (
          <>
            <div className={styles.instructionsEventsDetailsCard}>
              <div className={styles.basicInfoTopImgEventsDetailsCard}>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/basicDetails.svg`}
                    alt="detailsLogo"
                  />
                </div>
                <h6>Artists</h6>
              </div>
            </div>
            {event_artist?.map((artist) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginLeft: "58px",
                      marginTop: "16px",
                    }}
                  >
                    <div>
                      <img
                        src={artist?.image}
                        alt="detailsLogo"
                        width={"40px"}
                        height={"40px"}
                      />
                    </div>
                    <div>{has_artist === 1 && artist?.name}</div>
                  </div>
                </>
              );
            })}
          </>
        )}
        {instructions && (
          <div className={styles.instructionsEventsDetailsCard}>
            <div className={styles.basicInfoTopImgEventsDetailsCard}>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/basicDetails.svg`}
                  alt="detailsLogo"
                />
              </div>
              <h6>
                {process.env.REACT_APP_PRODUCT === "DLF"
                  ? "Terms & Conditions"
                  : "Instructions"}
              </h6>
            </div>
            <p>{instructions ? instructions : "-"}</p>
          </div>
        )}
        {description && (
          <div className={styles.instructionsEventsDetailsCard}>
            <div className={styles.basicInfoTopImgEventsDetailsCard}>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/basicDetails.svg`}
                  alt="detailsLogo"
                />
              </div>
              <h6>
                {process.env.REACT_APP_PRODUCT === "DLF"
                  ? "Synopsis"
                  : "Description"}
              </h6>
            </div>
            <p className={styles.info}>
              {seeLess.length <= 260 ? (
                <>{seeLess}</>
              ) : (
                <div>
                  {seeMoreData ? seeMore : seeLess}
                  <span
                    className={styles.seeMoreDiv}
                    onClick={() => setSeeMoreData(!seeMoreData)}
                  >
                    {seeMoreData ? " ...see more" : " see less"}
                  </span>
                </div>
              )}
            </p>
          </div>
        )}
        {process.env.REACT_APP_PRODUCT === "DLF" && (
          <div className={styles.instructionsEventsDetailsCard}>
            <div className={styles.basicInfoTopImgEventsDetailsCard}>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_IMAGES}/basicDetails.svg`}
                  alt="detailsLogo"
                />
              </div>
              <h6>Cancellation Policy</h6>
            </div>
            {cancellation_allowed == 0 ? (
              <p>Cancellation is not allowed</p>
            ) : cancellation_allowed == 1 &&
              event_cancellation_policies.length == 0 ? (
              <p>Cancellation is allowed.</p>
            ) : event_cancellation_policies ? (
              event_cancellation_policies?.map((item, index) => {
                return (
                  <p
                    key={index}
                  >{`${item.deduction}% Deduction : ${item.duration} before start of event`}</p>
                );
              })
            ) : (
              <p>No deduction upon cancellation.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
